import React, { useMemo } from 'react';
import { Select } from 'antd';

import { Language, languages } from '@hooks/useTranslations';

interface Props {
  readonly availableLanguages: Language[];
  readonly selectedLanguage: Language;
  readonly onChange: (language: Language) => void;
  readonly style?: React.CSSProperties;
}

export const LanguageSelect = ({
  availableLanguages,
  selectedLanguage,
  onChange,
  style,
}: Props) => {
  const options = useMemo(
    () =>
      availableLanguages
        .filter(Boolean)
        .map((language) => languages[language])
        .filter(Boolean),
    [availableLanguages],
  );

  return (
    <Select
      key='language-select'
      style={{ float: 'left', minWidth: '150px', ...style }}
      value={selectedLanguage}
      onChange={onChange}
      placeholder='Select Language'
      options={options}
    />
  );
};
