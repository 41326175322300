import d3js from 'd3';
import React from 'react';
import ReactDOM from 'react-dom';

import { ReactBubbleChartD3 } from './ReactBubbleChartD3';

type ColorLegend =
  | string
  | {
      color: string;
      text?: string;
      textColor?: string;
    };

interface Props {
  className: string;
  data: Array<{
    _id: string; // unique id (required)
    value: number; // used to determine relative size of bubbles (required)
    displayText: string; // will use _id if undefined
    colorValue: number; // used to determine color
    selected: boolean; // if true will use selectedColor/selectedTextColor for circle/text
  }>;
  colorLegend?: ColorLegend[];
  fixedDomain?: { min: number; max: number };
  selectedColor?: string;
  selectedTextColor?: string;
  onClick?: (d: d3js.layout.pack.Node) => void;
  smallDiameter?: number;
  mediumDiameter?: number;
  legend?: boolean;
  legendSpacing?: number;
  tooltip?: boolean;
  tooltipProps?: Array<{ css: string; prop: string; display?: string }>;
  tooltipFunc?: (domNode: Node, d: d3js.layout.pack.Node, tooltipColor: string) => void;
  fontSizeFactor?: number;
  duration?: number;
  delay?: number;
}

export class ReactBubbleChart extends React.Component<Props> {
  /* eslint-disable lines-between-class-members */
  resizeTimeout?: NodeJS.Timeout;
  bubbleChart?: ReactBubbleChartD3;
  handleResizeListener: (e: Event) => void;
  /* eslint-enable lines-between-class-members */

  constructor(props: Props) {
    super(props);
    // define the method this way so that we have a clear reference to it
    // this is necessary so that window.removeEventListener will work properly
    this.handleResizeListener = () => this.handleResize();
  }

  /** When we mount, intialize resize handler and create the bubbleChart */
  componentDidMount() {
    window.addEventListener('resize', this.handleResizeListener);
    this.bubbleChart = new ReactBubbleChartD3(
      this.getDOMNode() as HTMLElement,
      this.getChartState(),
    );
  }

  /** When we update, update our friend, the bubble chart */
  componentDidUpdate() {
    this.bubbleChart?.update(this.getDOMNode() as HTMLElement, this.getChartState());
  }

  /** When we're piecing out, remove the handler and destroy the chart */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeListener);
    this.bubbleChart?.destroy();
  }

  /** On a debounce, adjust the size of our graph area and then update the chart */
  handleResize() {
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.bubbleChart?.adjustSize(this.getDOMNode() as HTMLElement);
      this.bubbleChart?.update(this.getDOMNode() as HTMLElement, this.getChartState());
      delete this.resizeTimeout;
    }, 200);
  }

  /** Define what props get passed down to the d3 chart */
  getChartState() {
    const {
      data,
      colorLegend,
      fixedDomain,
      selectedColor,
      selectedTextColor,
      onClick,
      smallDiameter,
      mediumDiameter,
      legendSpacing,
      legend,
      tooltip,
      tooltipProps,
      tooltipFunc,
      fontSizeFactor,
      duration,
      delay,
    } = this.props;

    return {
      data,
      colorLegend,
      fixedDomain,
      selectedColor,
      selectedTextColor,
      onClick: onClick || (() => {}),
      smallDiameter,
      mediumDiameter,
      legendSpacing,
      legend,
      tooltip,
      tooltipProps,
      tooltipFunc,
      fontSizeFactor,
      duration,
      delay,
    };
  }

  /** Helper method to reference this dom node */
  getDOMNode() {
    // @ts-ignore
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(this);
  }

  /** Render town */
  render() {
    const { className } = this.props;

    return <div className={`bubble-chart-container ${className}`} />;
  }
}
