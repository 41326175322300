import { message } from 'antd';

export function getErrorMessage(
  error?: string,
  statusCode?: number,
  extraData?: any,
  fallback?: string,
) {
  switch (error) {
    case 'vle_error_recaptcha_failed':
      return `Captcha failed, please try again. Error code: ${extraData?.errorCode ?? 0}`;
    case 'vle_error_account_locked':
      return 'Your account is locked, probably due to too many failed login attempts';
    case 'vle_error_account_credentials':
      return 'The username or password is incorrect';
    case 'vle_error_account_invitation_not_accepted':
      return 'Invitation not yet accepted';
    case 'vle_error_2fa_not_setup':
      return '2FA has not been setup';
    case 'vle_error_2fa_already_setup':
      return '2FA is already setup';
    case 'vle_error_2fa_invalid':
      return 'Invalid 2FA verification code';
    case 'vle_error_unlock_token_not_found':
      return 'Account unlock token not found';
    case 'vle_error_login_token_expired':
      return 'Account login token expired';
    case 'vle_error_not_found':
      return 'Resource not found';
    case 'vle_error_page_not_found':
      return 'Page not found';
    case 'vle_error_event_not_found':
      return 'Event not found';
    case 'vle_error_video_not_found':
      return 'Video not found';
    case 'vle_error_invalid_options':
      return `Invalid options${extraData?.reason ? `, ${extraData.reason}` : ''}`;
    default:
      return statusCode === 401 ? 'Credentials incorrect' : fallback || 'An error occurred';
  }
}

export function showError(error?: string, statusCode?: number, extraData?: any, fallback?: string) {
  message.error(getErrorMessage(error, statusCode, extraData, fallback));
}

export function showErrorResponse(response?: {
  body: { error?: string; message?: string; data?: any };
  response: { status?: number };
}) {
  showError(
    response?.body?.message || response?.body?.error,
    response?.response?.status,
    response?.body?.data,
  );
}
