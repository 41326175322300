import parse from '@utils/parse';

export default function transform(data: any) {
  return parse.one(data, (attributes) => {
    return {
      uri: attributes.string('uri'),
      secret: attributes.string('secret'),
    };
  });
}

export type TFASetupResource = ReturnType<typeof transform>;
