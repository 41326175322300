import parse, { Attributes } from '@utils/parse';

import { parseUser } from './user';

export const parseAnnouncement = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    message: attributes.string('message'),
    user: attributes.custom('user', (user) => {
      return parse.one(user, parseUser);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseAnnouncement);
}

export function many(data: any) {
  return parse.many(data, parseAnnouncement);
}

export type AnnouncementResource = ReturnType<typeof one>;
export type Announcement = Omit<AnnouncementResource, 'id'>;

export default { one, many };
