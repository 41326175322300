import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, List, Typography, Space } from 'antd';

import { useSessions } from '@hooks/useSessions';
import { StageResource } from '@transforms/stage';
import { SessionResource } from '@transforms/session';
import { ListItem } from '@components/Sessions';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface StageProps extends Omit<DataEntryProps, 'onUpdate'> {
  data?: StageResource;
  onUpdate: (values: any, done: (refetch?: () => void) => void) => void;
}

export function Stage({ ...restProps }: StageProps) {
  const [stageSessions, setStageSessions] = useState<SessionResource[] | undefined>();
  const [newSessions, setNewSessions] = useState<SessionResource[]>();
  const { data: sessions } = useSessions({ page: 1, limit: 999 });
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sessions?.items && restProps.data) {
      const filteredStageSessions = sessions.items.filter(
        (session) => session.stage?.id === restProps.data?.id,
      );
      setStageSessions(filteredStageSessions);
    }
  }, [sessions?.items, restProps.data]);

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Sessions';
      }
    }
  }, [listRef.current?.innerHTML]);

  const addSession = (sessionId: string) => {
    const session = sessions?.items?.find(
      (singleSession) => singleSession.id === sessionId,
    ) as SessionResource;
    let newStageSessions = stageSessions ? [...stageSessions, session] : [session];
    newStageSessions = newStageSessions.filter(
      (item, pos) => newStageSessions.indexOf(item) === pos,
    );
    setStageSessions(newStageSessions);
    setNewSessions((prevState) => {
      if (prevState) return [...prevState, session];
      return [session];
    });
  };

  const removeSession = (sessionId: string) => {
    setStageSessions(stageSessions?.filter((session) => session.id !== sessionId));
    setNewSessions((prevState) => prevState?.filter((session) => session.id !== sessionId));
  };

  const onCancel = () => {
    setStageSessions(undefined);
    restProps.onCancel();
  };

  const onUpdate = (values: any, done: () => void) => {
    restProps.onUpdate(
      { ...values, sessions: newSessions?.map(({ id }) => id) ?? [] },
      async (refetch) => {
        refetch?.();
        done();
      },
    );
  };

  const onCreate = (values: any, done: () => void) => {
    restProps.onCreate({ ...values, sessions: newSessions?.map(({ id }) => id) ?? [] }, done);
  };

  const renderSessionOptions = () => (
    <>
      {sessions?.items.map((session) => (
        <Select.Option value={session.id} key={session.id}>
          {session.title}
        </Select.Option>
      ))}
    </>
  );

  const renderSessions = () => (
    <div ref={listRef}>
      <List
        itemLayout='horizontal'
        dataSource={stageSessions}
        renderItem={(session) => (
          <ListItem session={session} onDelete={removeSession} showTags={false} deletesFromStage />
        )}
      />
    </div>
  );

  return (
    <DataEntry
      {...restProps}
      onCancel={onCancel}
      onUpdate={onUpdate}
      onCreate={onCreate}
      name='Stage'
    >
      <Form.Item
        name='name'
        label='Stage Title'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Stage Title' />
      </Form.Item>
      <Space direction='vertical' style={{ width: '100%', marginBottom: '5px' }}>
        <Typography>Add Session(s)</Typography>
        <Select style={{ width: '100%' }} onChange={addSession}>
          {renderSessionOptions()}
        </Select>
      </Space>
      {renderSessions()}
    </DataEntry>
  );
}
