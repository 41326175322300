import { Form, Input, Select, message } from 'antd';

import { AdminResource } from '@transforms/admin';
import { EventResource } from '@transforms/event';
import { OrganisationResource } from '@transforms/organisation';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface AdminProps extends DataEntryProps {
  data?: AdminResource;
  events?: EventResource[];
  setSelectedEvent?: (event: string) => void;
  adminType?: 'admin' | 'organisationAdmin' | 'superAdmin';
  organisations?: OrganisationResource[];
}

export function Admin(props: AdminProps) {
  const { adminType, organisations, events } = props;

  const isOrganisationAdmin = adminType === 'organisationAdmin';
  const isEventAdmin = adminType === 'admin';

  const errorHandler = (error: Error) => {
    switch ((error as any).response.body?.message || (error as any).response.body?.error) {
      case 'vle_error_already_admin': {
        message.error('An admin already exists for this email.');
        break;
      }
      case 'vle_error_already_event_admin': {
        message.error('An event admin already exists for this email.');
        break;
      }
      case 'vle_error_already_organisation_admin': {
        message.error('An organization admin already exists for this email.');
        break;
      }
      case 'vle_error_already_super_admin': {
        message.error('A super admin already exists for this email.');
        break;
      }
      default: {
        break;
      }
    }
  };

  let nameModal = 'Event Admin';

  switch (adminType) {
    case 'organisationAdmin':
      nameModal = 'Organization Admin';
      break;
    case 'superAdmin':
      nameModal = 'Super Admin';
      break;

    default:
      break;
  }

  const { setSelectedEvent } = props;

  return (
    <DataEntry {...{ ...props }} name={nameModal} errorHandler={errorHandler}>
      <p>
        This person will receive an invitation to set up their own admin account via email. Note, if
        a username/password combination is entered incorrectly 5 times, the account will be locked.
      </p>
      <Form.Item
        name='email'
        label={<b>Email Address</b>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='email123@email.com' />
      </Form.Item>
      {isOrganisationAdmin && !!organisations?.length && (
        <Form.Item
          name='organisationId'
          label='Organization'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={organisations.map((organisation) => ({
              value: organisation.id,
              label: organisation.name,
            }))}
            placeholder='Organization'
          />
        </Form.Item>
      )}
      {isEventAdmin && (
        <Form.Item
          name='eventId'
          label='Event'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={events?.map((event) => ({
              value: event.id,
              label: event.name,
            }))}
            placeholder='Event'
            onSelect={(value) => {
              const eventId: string = value as string;
              if (value) {
                setSelectedEvent?.(eventId);
              }
            }}
          />
        </Form.Item>
      )}
    </DataEntry>
  );
}
