import { useQuery } from 'react-query';
import queryString from 'query-string';

import { useAuth } from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';

export const useAudits = (pagination: { page: number; limit: number }) => {
  const { isSuperAdmin, accessToken } = useAuth();
  const query = queryString.stringify({ ...pagination });

  return useQuery(
    ['audits'],
    async () => {
      const { body } = await fetch(`/audits?${query}`, { token: accessToken });

      return body;
    },
    { enabled: isSuperAdmin },
  );
};

export default {
  useAudits,
};
