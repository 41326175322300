import { useQuery, useMutation, useQueryClient } from 'react-query';
import queryString from 'query-string';
import pluralize from 'pluralize';

import useAuth from '@hooks/auth/useAuth';
import { useEventId } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import fetch, { APIError } from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import { MetaResource } from '@transforms/meta';

export const useInit = () => {
  const { accessToken, authFailureCallback } = useAuth();
  const { selectedLanguage } = useLanguage();
  const eventId = useEventId();

  return { token: accessToken, authFailureCallback, language: selectedLanguage, eventId };
};

export const useResource = <T>(
  resourceName: string,
  resourceId: string,
  transformFunction: (data: unknown) => T,
  options: {
    path?: string;
  } = {},
) => {
  const { token, authFailureCallback, language, eventId } = useInit();
  const { path } = options;
  const resourceNamePlural = pluralize(resourceName);

  return useQuery<T, APIError>([resourceName, eventId, resourceId, language], async () => {
    const { body } = await fetch(
      languageUrl(`/events/${eventId}/${path ?? resourceNamePlural}/${resourceId}`, language),
      {
        token,
      },
      authFailureCallback,
    );

    return transformFunction(body);
  });
};

export const useResources = <T, S = { items: T[]; meta: MetaResource }>(
  resourceName: string,
  transformFunction: (data: unknown) => S,
  options: {
    pagination?: { page: number; limit: number };
    sorter?: {
      field?: string;
      order?: 'ascend' | 'descend';
    };
    search?: string;
    path?: string;
  } = {},
) => {
  const { token, authFailureCallback, language, eventId } = useInit();
  const { pagination, search, path, sorter } = options;
  const query = queryString.stringify({
    ...pagination,
    sortField: sorter?.field,
    sortOrder: sorter?.order,
    search: search || undefined,
  });
  const resourceNamePlural = pluralize(resourceName);

  return useQuery<S, APIError>(
    [
      resourceNamePlural,
      eventId,
      pagination?.page,
      pagination?.limit,
      sorter?.field,
      sorter?.order,
      language,
      search,
      path,
    ].filter(Boolean),
    async () => {
      const { body } = await fetch(
        languageUrl(`/events/${eventId}/${path ?? resourceNamePlural}?${query}`, language),
        {
          token,
        },
        authFailureCallback,
      );

      return transformFunction(body);
    },
    {
      keepPreviousData: !!pagination,
      enabled: !!eventId,
    },
  );
};

export const useCreateResource = <T>(
  resourceName: string,
  options: {
    path?: string;
  } = {},
) => {
  const queryClient = useQueryClient();
  const { token, authFailureCallback, language, eventId } = useInit();
  const { path } = options;
  const resourceNamePlural = pluralize(resourceName);

  return useMutation(
    (values: T) => {
      return fetch(
        languageUrl(`/events/${eventId}/${path ?? resourceNamePlural}`, language),
        {
          method: 'POST',
          body: values,
          token,
        },
        authFailureCallback,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceNamePlural);
      },
    },
  );
};

export const useUpdateResource = <T>(
  resourceName: string,
  resourceId?: string,
  options: {
    path?: string;
  } = {},
) => {
  const queryClient = useQueryClient();
  const { token, authFailureCallback, language, eventId } = useInit();
  const { path } = options;
  const resourceNamePlural = pluralize(resourceName);

  return useMutation(
    (values: T) => {
      return fetch(
        languageUrl(`/events/${eventId}/${path ?? resourceNamePlural}/${resourceId}`, language),
        {
          method: 'PUT',
          body: values,
          token,
        },
        authFailureCallback,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceNamePlural);
      },
    },
  );
};

export const useDeleteResource = (
  resourceName: string,
  options: {
    path?: string;
    token?: string;
    authFailureCallback?: (request: Request | string, init: any) => void;
    eventId?: string;
  } = {},
) => {
  const queryClient = useQueryClient();
  const { token, authFailureCallback, eventId } = useInit();
  const { path } = options;
  const resourceNamePlural = pluralize(resourceName);

  return useMutation(
    (id: string) => {
      return fetch(
        `/events/${eventId}/${path ?? resourceNamePlural}/${id}`,
        {
          method: 'DELETE',
          token,
        },
        authFailureCallback,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceNamePlural);
      },
    },
  );
};

export default {
  useResource,
  useResources,
  useCreateResource,
  useUpdateResource,
  useDeleteResource,
};
