import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useAuth } from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';

export const useAcademyPage = () => {
  const { accessToken } = useAuth();

  return useQuery(['academy-page'], async () => {
    const { body } = await fetch(`/academy-page`, { token: accessToken });
    return body;
  });
};

export const useUpdateAcademyPage = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(
    (values: any) => {
      return fetch(`/academy-page`, {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('academy-page');
      },
    },
  );
};

export default {
  useAcademyPage,
  useUpdateAcademyPage,
};
