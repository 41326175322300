import { Form, Input } from 'antd';

import { TagResource } from '@transforms/tag';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface TagProps extends DataEntryProps {
  data?: TagResource;
}

export function Tag({ ...restProps }: TagProps) {
  return (
    <DataEntry {...restProps} name='Interest Tag'>
      <b>How to Use Interests</b>
      <div>
        All <strong>Interest Tags</strong> must have a <strong>Key</strong> (internal code) and{' '}
        <strong>Label</strong> (display tag). The <strong>Key</strong> is used internally to
        determine which value (data entered) to display. The <strong>Label</strong> will be
        displayed to the left of the submitted answer (underneath the attendee's name) and can be
        localized per language (if this event is multilingual).
        <br />
        <br />
        Use a short <strong>Key</strong> without any punctuation or symbols (ex. if{' '}
        <strong>Label</strong> is "Select your favorite animals [at least three]." use "animal").
        Note, only the <strong>Label</strong> can be translated per language; the{' '}
        <strong>Key</strong> must always remain the same unique identifier.
      </div>
      <br />
      <Form.Item name='key' label={<b>Key</b>} rules={[{ required: true }]}>
        <Input placeholder='Key' />
      </Form.Item>
      <Form.Item name='label' label={<b>Label</b>} rules={[{ required: true }]}>
        <Input placeholder='Label' />
      </Form.Item>
    </DataEntry>
  );
}
