import { useCallback, useEffect, useState } from 'react';
import {
  Layout,
  Divider,
  Button,
  Space,
  Popconfirm,
  Input,
  Select,
  Checkbox,
  Switch,
  Row,
  message,
  Col,
} from 'antd';

import queryString from 'query-string';
import dayjs from 'dayjs';

import { MailLayoutType, MailResource, MailTargetGroup, MailType } from '@transforms/mail';

import { useAuth } from '@hooks/auth/useAuth';
import { useCurrentEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { rewrite } from '@utils/fetch';
import { stripHtml } from '@utils/html';
import { breakText } from '@utils/breakText';
import { LazyModal } from '@components/LazyModal';

import { ImageUpload } from '@components/Upload';
import { RichTextEditor } from '@components/RichTextEditor';

import Handlebars from './Handlebars';
import * as Mails from './mails';
import { MailNowModal } from './MailNowModal';
import { TestMailModal } from './TestMailModal';

export * from './mails';

export type MailProps = {
  mail?: MailResource;
  mailData?: Mails.Mail;
  value?: string;
  eventId: string;
  sendWelcomeEmail?: boolean | null;
  onDelete?: (mailId: string) => void;
  onFinish?: (
    values: Omit<MailResource, 'layout'> & {
      sendWelcomeEmail: boolean;
      sendMagicLinkOnApproval: boolean;
      layout?: Omit<MailResource['layout'], 'imageUrl'> & { image: File | string | null };
    },
  ) => Promise<void>;
};

export default function Mail({
  mail,
  mailData,
  eventId,
  sendWelcomeEmail,
  onDelete,
  onFinish,
}: MailProps) {
  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { isSuperAdmin, organisation } = useAuth();

  const availableTargetGroups = Mails.targetGroups.filter((group) =>
    mailData && mailData.type === MailType.EVENT_REMINDER
      ? group.key !== MailTargetGroup.ATTENDEES
      : true,
  );

  const [submittingSave, setSubmittingSave] = useState(false);
  const [submittingDelete, setSubmittingDelete] = useState(false);
  const [days, setDays] = useState(mail?.properties?.days ?? 1);
  const [time, setTime] = useState(
    mail?.properties?.time ? dayjs(mail.properties.time) : dayjs().add(1, 'hour').startOf('hour'),
  );
  const [rawTime, setRawTime] = useState(time.format('HH:mm'));
  const [updatedText, setUpdatedText] = useState(mail?.value || '');
  const [updatedSubject, setUpdatedSubject] = useState(mail?.subject || mailData?.subject || '');
  const [useLayout, setUseLayout] = useState<boolean>(mail?.useLayout || false);
  const [layoutType, setLayoutType] = useState<MailLayoutType>(
    (mail?.layout?.type as MailLayoutType | undefined) || MailLayoutType.LEFT_IMAGE,
  );
  const [layoutText, setLayoutText] = useState(mail?.layout?.text || '');
  const [updatedSenderName, setUpdatedSenderName] = useState(
    mail?.senderName || mailData?.senderName || '',
  );
  const [updatedUseScheduling, setUseScheduling] = useState(
    mail?.properties?.useScheduling === false,
  );
  const [updatedSendWelcomeEmail, setSendWelcomeEmail] = useState(Boolean(sendWelcomeEmail));
  const [updatedCalendarButton, setCalendarButton] = useState(
    Boolean(mail?.properties.addToCalendar),
  );
  const [updatedSendMagicLinkOnApproval, setUpdatedSendMagicLinkOnApproval] = useState(
    Boolean(event?.sendMagicLinkOnApproval),
  );
  const [showMailNowModal, setShowMailNowModal] = useState<boolean>(false);
  const [showTestMailModal, setShowTestMailModal] = useState<boolean>(false);
  const [targetGroup, setTargetGroup] = useState(
    (mail?.targetGroup ?? availableTargetGroups[0].key) as MailTargetGroup,
  );
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(mail?.layout?.imageUrl ?? null);

  const [handlebar, setHandlebar] = useState<null | string>();

  useEffect(() => {
    if (typeof mail?.value === 'string') {
      setUpdatedText(mail.value);
    }

    if (typeof mail?.layout?.imageUrl === 'string') {
      setImagePreview(mail.layout.imageUrl);
    }

    if (typeof mail?.layout?.text === 'string') {
      setLayoutText(mail.layout.text);
    }

    if (typeof mail?.senderName === 'string') {
      setUpdatedSenderName(mail.senderName);
    }

    if (typeof mail?.subject === 'string') {
      setUpdatedSubject(mail.subject);
    }
  }, [mail]);

  const handleCloseMailNow = useCallback(() => setShowMailNowModal(false), []);

  if (!mail || !mailData) return null;

  const pageDisabled = mail.type === MailType.EVENT_WELCOME && !updatedSendWelcomeEmail;
  const handleGetICS = async () => {
    window.location.href = rewrite(`/events/${eventId}/mails/ics/test?lang=${selectedLanguage}`);
  };
  const handleGetOutlookICS = async () => {
    window.location.href = rewrite(
      `/events/${eventId}/mails/ics/test?outlook=true&lang=${selectedLanguage}`,
    );
  };

  const handleGetGoogleCal = () => {
    if (!event) return;

    // +1 day is compensation for default calendar offset
    const endDate = dayjs(event.endsAt).add(1, 'day').format('YYYYMMDD');
    const startDate = dayjs(event.startsAt).format('YYYYMMDD');
    const description = stripHtml(event.description);

    const goToEventLink = `${window.location.protocol}://${window.location.host}/events/${event.id}/emails`;
    const goToEventText = (() => {
      switch (selectedLanguage) {
        case 'nl':
          return 'Persoonlijke link naar het evenement:';
        case 'nl_BE':
          return 'Persoonlijke link naar het evenement:';
        case 'de':
          return 'Persönlicher Link zur Veranstaltung:';
        case 'fr':
          return "Lien personnel vers l'événement:";
        case 'es':
          return 'Enlace personal al evento:';
        case 'it':
          return "Link personale all'evento:";
        default:
          return 'Personal link to the event:';
      }
    })();

    window.open(
      `https://www.google.com/calendar/render?${queryString.stringify({
        action: 'TEMPLATE',
        text: event.name,
        details: `${description}<br/><br/><br/>${goToEventText} <a href="${goToEventLink}">${goToEventLink}</a><br/><br/>`,
        dates: `${startDate}/${endDate}`,
        allday: true,
      })}`,
      '_blank',
    );
  };

  const handleDelete = async () => {
    if (!mail) return;

    setSubmittingDelete(true);
    await onDelete?.(mail.id);
    setSubmittingDelete(false);
  };

  const onSave = async () => {
    if (days < 1) {
      message.error('Please select 1 or more days');
      return;
    }

    if (updatedUseScheduling && !time.isValid()) {
      message.error('Please enter a valid scheduled time');
      return;
    }

    setSubmittingSave(true);

    await onFinish?.(
      mail.type === MailType.EVENT_WELCOME && updatedSendWelcomeEmail === false
        ? {
            ...mail,
            layout: {
              ...mail.layout,
              image: mail.layout.imageUrl ?? null,
            },
            sendWelcomeEmail: updatedSendWelcomeEmail,
            sendMagicLinkOnApproval: updatedSendMagicLinkOnApproval,
          }
        : {
            ...mail,
            value: updatedText,
            subject: updatedSubject,
            senderName: updatedSenderName,
            sendWelcomeEmail: updatedSendWelcomeEmail,
            sendMagicLinkOnApproval: updatedSendMagicLinkOnApproval,
            targetGroup,
            layout: useLayout
              ? {
                  type: layoutType,
                  text: layoutText,
                  image:
                    // eslint-disable-next-line no-nested-ternary
                    image instanceof File
                      ? image
                      : imagePreview?.startsWith('data:image')
                      ? null
                      : imagePreview,
                }
              : undefined,
            properties: {
              ...mail.properties,
              days: days ?? mail.properties?.days,
              time: (!updatedUseScheduling ? time.toISOString() : null) ?? mail.properties?.time,
              useScheduling: !updatedUseScheduling ?? mail.properties?.useScheduling,
              addToCalendar: updatedCalendarButton ?? mail.properties?.addToCalendar,
            },
          },
    );
    setSubmittingSave(false);
  };

  const scheduledTimeCET = updatedUseScheduling
    ? undefined
    : dayjs(mail.type === MailType.EVENT_REMINDER ? event?.startsAt : event?.endsAt)
        .add(mail.type === MailType.EVENT_REMINDER ? days * -1 : days, 'days')
        .set('hours', time.get('hours'))
        .set('minutes', time.get('minutes'))
        .tz('CET')
        .format('dddd DD MMMM YYYY|HH:mm')
        .split('|');

  const delayedTimeCET = dayjs(mail.delayedDate).tz('CET').format('HH:mm');

  return (
    <Layout.Content style={{ padding: '20px', backgroundColor: 'white' }}>
      <h1>{mailData.title}</h1>
      <p>{breakText(mailData.description)}</p>

      {(mail.type === MailType.EVENT_REMINDER || mail.type === MailType.EVENT_FOLLOWUP) && (
        <>
          <Row justify='space-between'>
            <b>
              {mail.type === MailType.EVENT_FOLLOWUP
                ? 'Days after end event date to notify (1 or more days)'
                : 'Delivery Date (at least 1 day before event starts)'}
            </b>
            <Checkbox
              checked={updatedUseScheduling}
              onChange={({ target }) => {
                setUseScheduling(target.checked);
                if (!time.isValid()) {
                  const date = dayjs().add(1, 'hour').startOf('hour');
                  setTime(date);
                  setRawTime(date.format('HH:mm'));
                }
              }}
              style={{ marginRight: '-8px' }}
            >
              <b>Disable Auto Send</b>
            </Checkbox>
          </Row>
          {days < 1 && (
            <div>
              <h5 style={{ fontWeight: 500, color: 'red' }}>This is a required field</h5>
            </div>
          )}
          <Row style={{ marginTop: '8px' }}>
            <Input
              value={days}
              disabled={updatedUseScheduling}
              onChange={({ target }) => setDays(target.value)}
              type='number'
              min={1}
              style={{ flex: 1, width: 'unset' }}
            />
            <span style={{ padding: '6px 12px 0' }}>at</span>
            <Input
              value={rawTime}
              disabled={updatedUseScheduling}
              onChange={({ target }) => {
                setRawTime(target.value);
                setTime(
                  dayjs()
                    .set('hours', Number.parseInt(target.value.split(':')[0], 10))
                    .set('minutes', Number.parseInt(target.value.split(':')[1], 10)),
                );
              }}
              type='time'
              style={{
                flex: 1,
                width: 'unset',
                paddingBottom: 0,
                border: time.isValid() || updatedUseScheduling ? undefined : '1px solid red',
              }}
            />
          </Row>
          {!updatedUseScheduling || mail.delayedDate ? (
            <Col style={{ margin: '15px 0' }}>
              <Row>
                {!updatedUseScheduling &&
                  !!scheduledTimeCET &&
                  (time.isValid() ? (
                    <>
                      The email will be sent on&nbsp;
                      <b>{scheduledTimeCET[0]}</b>&nbsp;at&nbsp;
                      <b>{scheduledTimeCET[1]} CET</b>
                    </>
                  ) : (
                    <>
                      The email&nbsp;<b>will not</b>&nbsp;be sent. Please enter a valid time.
                    </>
                  ))}
              </Row>
              {!!mail.delayedDate && (
                <Row>
                  The email will be sent &nbsp;<b>today</b>&nbsp;at&nbsp;
                  <b>{delayedTimeCET}&nbsp;CET</b>
                </Row>
              )}
            </Col>
          ) : null}
        </>
      )}

      {mail.type !== MailType.EVENT_FOLLOWUP ? (
        <>
          {/* TODO: registration confirmed add login link button? */}
          {/* TODO: add delivery date ? */}

          <Row>
            <Checkbox
              checked={updatedCalendarButton}
              onChange={({ target }) => setCalendarButton(target.checked)}
              style={{ flex: 1, marginTop: '8px' }}
            >
              <b>Include "Add to calendar" button</b>
            </Checkbox>
            <b style={{ margin: '8px 8px -8px 0' }}>Preview</b>
            <Button onClick={handleGetICS} style={{ margin: '4px 0 -4px' }}>
              ICS
            </Button>
            <Button onClick={handleGetGoogleCal} style={{ margin: '4px 8px -4px' }}>
              Google Cal
            </Button>
            <Button onClick={handleGetOutlookICS} style={{ margin: '4px 0 -4px' }}>
              Outlook
            </Button>
          </Row>
        </>
      ) : null}

      {mail.type === MailType.MAGIC_LINK && (
        <Checkbox
          checked={updatedSendMagicLinkOnApproval}
          onChange={({ target }) => setUpdatedSendMagicLinkOnApproval(target.checked)}
          style={{ flex: 1, marginTop: '8px' }}
        >
          <div>
            <div style={{ fontWeight: 'bold' }}>Send login link on approval</div>
            <div>
              Send a login link email when; A.) An event admin approves an attendee in the attendees
              overview, B.) An attendee with a whitelisted email domain registers, or C.) When
              auto-approved is enabled.
            </div>
          </div>
        </Checkbox>
      )}

      {(mailData.hasTargetGroup ||
        mail.type === MailType.EVENT_REMINDER ||
        mail.type === MailType.EVENT_FOLLOWUP) && <Divider key='d1' />}

      {mailData.hasTargetGroup && (
        <>
          <b>Target Group</b>
          <Select
            style={{ marginTop: '8px', marginBottom: '24px', width: '100%' }}
            filterOption={false}
            value={targetGroup}
            onChange={setTargetGroup}
          >
            {availableTargetGroups.map((data) => (
              <Select.Option value={data.key} key={data.key}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </>
      )}

      {mail.type === MailType.EVENT_WELCOME && (
        <Checkbox
          checked={updatedSendWelcomeEmail}
          onChange={({ target }) => setSendWelcomeEmail(target.checked)}
          style={{ marginTop: '8px', marginLeft: '0' }}
        >
          <b>Send Automatically (Upon Registration)</b>
        </Checkbox>
      )}

      <Divider key='d2' />

      <b>Sender</b>
      <Input
        placeholder='Sender'
        style={{ marginBottom: '20px', marginTop: '8px' }}
        value={updatedSenderName ?? ''}
        onChange={(evt) => setUpdatedSenderName(evt.target.value)}
        disabled={pageDisabled}
      />

      <b>Subject</b>
      <Input
        placeholder='Subject'
        style={{ marginBottom: '20px', marginTop: '8px' }}
        value={updatedSubject ?? ''}
        onChange={(evt) => setUpdatedSubject(evt.target.value)}
        disabled={pageDisabled}
      />

      <Handlebars disabled={pageDisabled} onUpdateText={(hbar) => setHandlebar(hbar)} />
      <RichTextEditor
        minHeight={250}
        placeholder={mailData.placeholder}
        value={updatedText}
        onChange={(value) => setUpdatedText(value || '')}
        insertHandleBar={handlebar}
        clearHandleBar={() => setHandlebar(null)}
        disabled={pageDisabled}
        maxLength={1000}
      />

      <div>
        <Space style={{ marginTop: '24px' }}>
          <b>Add Layout</b>
          <Switch
            title='Add Layout'
            defaultChecked={useLayout}
            onChange={(val) => setUseLayout(val)}
            disabled={pageDisabled}
          />
        </Space>
        {useLayout && (
          <div>
            <br />
            <h3>Image Alignment</h3>
            <Select
              style={{ marginTop: '8px', marginBottom: '24px', width: '100%' }}
              filterOption={false}
              value={layoutType}
              onChange={setLayoutType}
              disabled={pageDisabled}
            >
              <Select.Option value={MailLayoutType.LEFT_IMAGE}>Left</Select.Option>
              <Select.Option value={MailLayoutType.RIGHT_IMAGE}>Right</Select.Option>
              <Select.Option value={MailLayoutType.FULL_WIDTH_IMAGE}>Full Width</Select.Option>
            </Select>
            {/* TODO: add preview? */}
            {layoutType === MailLayoutType.LEFT_IMAGE && (
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ maxWidth: '230px' }}>
                  <ImageUpload
                    initialPreview={imagePreview}
                    onChange={setImage}
                    onChangePreview={setImagePreview}
                    onRemove={() => setImage(null)}
                    textOverwrite='The maximum file size is 5 MB and must be at least 250x200 pixels in either JPG or PNG formats.'
                    disabled={pageDisabled}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: '1rem' }}>
                  <RichTextEditor
                    minHeight={250}
                    placeholder={mailData.placeholder}
                    value={layoutText}
                    onChange={(value) => setLayoutText(value || '')}
                    maxLength={1000}
                    disabled={pageDisabled}
                  />
                </div>
              </div>
            )}
            {layoutType === MailLayoutType.RIGHT_IMAGE && (
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <RichTextEditor
                    minHeight={250}
                    placeholder={mailData.placeholder}
                    value={layoutText}
                    onChange={(value) => setLayoutText(value || '')}
                    maxLength={1000}
                    disabled={pageDisabled}
                  />
                </div>
                <div style={{ maxWidth: '230px' }}>
                  <ImageUpload
                    initialPreview={imagePreview}
                    onChange={setImage}
                    onChangePreview={setImagePreview}
                    onRemove={() => setImage(null)}
                    textOverwrite='The maximum file size is 5 MB and must be at least 250x200 pixels in either JPG or PNG formats.'
                    disabled={pageDisabled}
                  />
                </div>
              </div>
            )}
            {layoutType === MailLayoutType.FULL_WIDTH_IMAGE && (
              <div>
                <ImageUpload
                  initialPreview={imagePreview}
                  onChange={setImage}
                  onChangePreview={setImagePreview}
                  onRemove={() => setImage(null)}
                  textOverwrite='The maximum file size is 5 MB and must be at least 500x200 pixels in either JPG or PNG formats.'
                  disabled={pageDisabled}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <Space style={{ marginTop: '24px', float: 'right' }}>
        <LazyModal open={showTestMailModal}>
          {(open) => (
            <TestMailModal
              visible={open}
              eventId={eventId}
              mail={{
                ...mail,
                value: updatedText || '',
                subject: updatedSubject || '',
                senderName: updatedSenderName || '',
                targetGroup,
                layout: useLayout
                  ? {
                      type: layoutType,
                      text: layoutText,
                      imageUrl: imagePreview,
                    }
                  : undefined,
                properties: {
                  ...mail.properties,
                  days: days ?? mail.properties?.days,
                  time: time ?? mail.properties?.time,
                  useScheduling: updatedUseScheduling ?? mail.properties?.useScheduling,
                  addToCalendar: updatedCalendarButton ?? mail.properties?.addToCalendar,
                },
              }}
              onClose={() => setShowTestMailModal(false)}
            />
          )}
        </LazyModal>
        <LazyModal open={showMailNowModal}>
          {(open) => (
            <MailNowModal
              visible={open}
              eventId={eventId}
              mail={mail}
              onClose={handleCloseMailNow}
            />
          )}
        </LazyModal>
        <Button
          block
          onClick={() => setShowTestMailModal(true)}
          disabled={pageDisabled || submittingSave || submittingDelete}
        >
          Send Preview
        </Button>
        {(mail.type === MailType.EVENT_REMINDER || mail.type === MailType.EVENT_FOLLOWUP) && (
          <Popconfirm
            title='Are you sure you are ready to send your mail? Any unsaved changes will not be used.'
            onConfirm={() => {
              setShowMailNowModal(true);
            }}
            okText='Continue'
            cancelText='Cancel'
          >
            <Button block disabled={pageDisabled || submittingSave || submittingDelete}>
              Send Immediately
            </Button>
          </Popconfirm>
        )}

        {onDelete && (isSuperAdmin || organisation) && !mailData.alwaysEnabled && (
          <Popconfirm
            title='Are you sure you want to delete this mail? /n This can not be undone!'
            onConfirm={() => handleDelete()}
            okText='Delete'
            cancelText='Cancel'
          >
            <Button type='primary' danger disabled={submittingSave} loading={submittingDelete}>
              Delete Mail
            </Button>
          </Popconfirm>
        )}
        <Button
          type='primary'
          onClick={onSave}
          loading={submittingSave}
          disabled={submittingDelete}
        >
          Save
        </Button>
      </Space>
    </Layout.Content>
  );
}
