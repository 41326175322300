import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

import './assets/fonts/sofia-pro/sofia-pro.css';
import 'antd/dist/antd.less';
import './styles/vars.css';
import './styles/global.css';

import CookieConsent from '@components/CookieConsent';

import { queryClient } from '@utils/queryClient';
import { LanguageProvider } from '@hooks/useTranslations';

import Container from '@components/Container';
import Events from '@pages/events';
import Academy from '@pages/academy';
import Admins from '@pages/admins';
import Analytics from '@pages/analytics';
import Organisations from '@pages/organisations';
import EditAcademyPage from '@pages/academy/edit';
import AcceptInvitation from '@pages/auth/accept-invitation/[invitationToken]';
import ForgotPassword from '@pages/auth/forgot-password';
import ResetPassword from '@pages/auth/reset-password/[resetToken]';
import Login from '@pages/auth/login';
import Verify from '@pages/auth/verify';
import NewOrganisationID from '@pages/organisations/[organisationId]';
import NewOrganisation from '@pages/organisations/new';
import EditOrganisation from '@pages/organisations/[organisationId]/edit';
import Event from '@pages/events/[eventId]';
import EventAnalytics from '@pages/events/[eventId]/analytics';
import Attendees from '@pages/events/[eventId]/attendees';
import ControlPanel from '@pages/events/[eventId]/controlpanel';
import CustomFields from '@pages/events/[eventId]/custom-fields';
import Emails from '@pages/events/[eventId]/emails';
import EventInfo from '@pages/events/[eventId]/info';
import PageTemplates from '@pages/events/[eventId]/page-templates';
import Roles from '@pages/events/[eventId]/roles';
import Sessions from '@pages/events/[eventId]/sessions';
import Speakers from '@pages/events/[eventId]/speakers';
import Stages from '@pages/events/[eventId]/stages';
import Tags from '@pages/events/[eventId]/tags';
import Theme from '@pages/events/[eventId]/theme';
import Preferences from '@pages/events/[eventId]/settings/preferences';
import Editors from '@pages/events/[eventId]/settings/editors';
import SettingsAccess from '@pages/events/[eventId]/settings/access';
import Pages from '@pages/events/[eventId]/pages/all';
import InitialPages from '@pages/events/[eventId]/pages/initial';
import HostAnnouncements from '@pages/events/[eventId]/messages/host-announcements';
import RegistrationComplete from '@pages/events/[eventId]/messages/registration-complete';
import Jobs from '@pages/jobs';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

function App() {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      if (!location.pathname.includes('events/'))
        document.getElementById('beacon-container')?.remove();
    };

    return handleRouteChange;
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <Routes>
          <Route element={<Container />}>
            {/* Global routes */}
            <Route path='/' element={<Events />} />
            <Route path='/events' element={<Events />} />
            <Route path='/academy' element={<Academy />} />
            <Route path='/admins' element={<Admins />} />
            <Route path='/analytics' element={<Analytics />} />
            <Route path='/organisations' element={<Organisations />} />

            {/* Academy routes */}
            <Route path='/academy/edit' element={<EditAcademyPage />} />

            {/* Auth routes */}
            <Route path='/auth/accept-invitation/:invitationToken' element={<AcceptInvitation />} />
            <Route path='/auth/reset-password/:resetToken' element={<ResetPassword />} />
            <Route path='/auth/forgot-password' element={<ForgotPassword />} />
            <Route path='/auth/login' element={<Login />} />
            <Route path='/auth/verify' element={<Verify />} />

            {/* Org routes */}
            <Route path='/organisations/new' element={<NewOrganisation />} />
            <Route path='/organisations/:organisationId' element={<NewOrganisationID />} />
            <Route path='/organisations/:organisationId/edit' element={<EditOrganisation />} />

            {/* Event routes */}
            <Route path='/events/:eventId' element={<Event />} />
            <Route path='/events/:eventId/analytics' element={<EventAnalytics />} />
            <Route path='/events/:eventId/attendees' element={<Attendees />} />
            <Route path='/events/:eventId/controlpanel' element={<ControlPanel />} />
            <Route path='/events/:eventId/custom-fields' element={<CustomFields />} />
            <Route path='/events/:eventId/emails' element={<Emails />} />
            <Route path='/events/:eventId/info' element={<EventInfo />} />
            <Route path='/events/:eventId/page-templates' element={<PageTemplates />} />
            <Route path='/events/:eventId/roles' element={<Roles />} />
            <Route path='/events/:eventId/sessions' element={<Sessions />} />
            <Route path='/events/:eventId/speakers' element={<Speakers />} />
            <Route path='/events/:eventId/stages' element={<Stages />} />
            <Route path='/events/:eventId/tags' element={<Tags />} />
            <Route path='/events/:eventId/theme' element={<Theme />} />

            {/* Event setting routes */}
            <Route path='/events/:eventId/settings/access' element={<SettingsAccess />} />
            <Route path='/events/:eventId/settings/editors' element={<Editors />} />
            <Route path='/events/:eventId/settings/preferences' element={<Preferences />} />

            {/* Event pages routes */}
            <Route path='/events/:eventId/pages/all' element={<Pages />} />
            <Route path='/events/:eventId/pages/initial' element={<InitialPages />} />

            {/* Event messages routes */}
            <Route
              path='/events/:eventId/messages/host-announcements'
              element={<HostAnnouncements />}
            />
            <Route
              path='/events/:eventId/messages/registration-complete'
              element={<RegistrationComplete />}
            />

            {/* Jobs routes */}
            <Route path='/jobs' element={<Jobs />} />
          </Route>
        </Routes>
        <CookieConsent />
        <ReactQueryDevtools initialIsOpen={false} />
      </LanguageProvider>
    </QueryClientProvider>
  );
}

export default App;
