import React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Table, Tag, Tooltip } from 'antd';
import type { ColumnGroupType } from 'antd/lib/table';

import type { Admin, AdminResource } from '@transforms/admin';

interface AdminsTableProps {
  event?: boolean;
  organisation?: boolean;
  loading?: boolean;
  admins?: AdminResource[];
  onEdit: (admin: AdminResource) => void;
  onDelete?: (admin: AdminResource) => void;
  onDeleteText?: React.ReactNode | string;
  setSorter: (sorter: { field?: keyof Admin; order?: 'ascend' | 'descend' }) => void;
  setAdminTableModal?: (admin: AdminResource) => void;
  setSelectedAdmin?: (admin: AdminResource) => void;
}

export function AdminsTable({
  event,
  organisation,
  loading,
  admins,
  onEdit,
  onDelete,
  onDeleteText,
  setSelectedAdmin,
  setSorter,
  setAdminTableModal,
}: AdminsTableProps) {
  return (
    <Table
      loading={loading}
      dataSource={admins}
      style={{ marginTop: 10 }}
      onChange={(_, __, sort: any) => setSorter(sort)}
      columns={
        (
          [
            event && {
              title: 'Event',
              key: 'event',
              sorter: true,
              dataIndex: 'assignments',
              render: (assignments) => (
                <>
                  <Link to={`/events/${assignments?.[0]?.id}/attendees`}>
                    {assignments?.[0]?.name}
                  </Link>

                  {assignments.length - 1 > 0 ? ` + ` : null}

                  <a
                    onClick={() => {
                      if (admins && setAdminTableModal) {
                        const selectedAdmin = admins.find(
                          // @ts-ignore
                          (admin) => admin.assignments === assignments,
                        );
                        if (selectedAdmin) {
                          setAdminTableModal(selectedAdmin);
                        }
                      }
                    }}
                  >
                    {assignments.length - 1 > 0 ? `${assignments.length - 1} more` : null}
                  </a>
                </>
              ),
            },
            organisation && {
              title: 'Organization',
              key: 'organisation',
              sorter: true,
              dataIndex: 'organisations',
              render: (organisations) => (
                <Link to={`/organisations/${organisations?.[0]?.id}`}>
                  {organisations?.[0]?.name}
                </Link>
              ),
            },
            admins && {
              title: 'Email',
              key: 'email',
              sorter: true,
              dataIndex: 'email',
            },
            {
              title: 'Status',
              key: 'status',
              sorter: true,
              dataIndex: 'invitationAcceptedAt',
              render: (acceptedAt) => (
                <Tag color={acceptedAt ? 'success' : 'processing'}>
                  {acceptedAt ? 'Active' : 'Invitation not yet accepted'}
                </Tag>
              ),
            },
            {
              title: '2FA Enabled',
              key: '2fa',
              sorter: true,
              dataIndex: 'totpEnabled',
              render: (enabled) => (
                <Tag color={enabled ? 'success' : 'error'}>{enabled ? 'Yes' : 'No'}</Tag>
              ),
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              align: 'right',
              width: 226,
              render: (_: any, admin: AdminResource) => [
                <Tooltip title='Edit' key='edit'>
                  <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(admin)} />
                </Tooltip>,
                onDelete && (
                  <>
                    <Divider key='d1' type='vertical' />
                    <Popconfirm
                      title={onDeleteText ?? 'Are you sure you want to delete this admin?'}
                      key='delete'
                      placement='topRight'
                      onVisibleChange={() => setSelectedAdmin && setSelectedAdmin(admin)}
                      onConfirm={() => onDelete(admin)}
                    >
                      <Button shape='circle' icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </>
                ),
              ],
            },
          ] as Array<boolean | ColumnGroupType<AdminResource>>
        ).filter(Boolean) as ColumnGroupType<AdminResource>[]
      }
    />
  );
}
