import React, { useEffect, useState } from 'react';
import { Divider, Form, FormInstance, Radio } from 'antd';

import { ImageUpload } from '@components/Upload';

import type { PageProps } from './props';

const LENGTH_OF_IMAGES = 13;

interface Props extends PageProps {
  form: FormInstance;
}

const canvasOptions = [
  { label: 'Trends', value: 'TRENDS' },
  { label: 'Vision', value: 'VISION' },
];

function fixedLengthArray<T extends any[]>(array?: T, length = LENGTH_OF_IMAGES): T {
  if (!array) return Array(length).fill(null) as T;
  const result = array.slice(0, length);
  if (result.length === length) return result as T;
  // eslint-disable-next-line no-plusplus
  while (result.length < length) {
    result.push(null);
  }
  return result as T;
}

export function Canvas({ form, page }: Props) {
  const [type, setType] = useState<string | undefined>(page?.parameters?.type);
  const [imagePreviews, setImagePreviews] = useState<Array<string | null>>(
    fixedLengthArray(page?.parameters?.images, LENGTH_OF_IMAGES),
  );

  useEffect(() => {
    if (!page?.parameters?.images) return;
    setImagePreviews(page.parameters.images.slice(0, LENGTH_OF_IMAGES));
  }, [page?.parameters?.images, setImagePreviews]);

  return (
    <>
      <Form.Item name={['parameters', 'type']} label='Type' style={{ fontWeight: 'bold' }} required>
        <Radio.Group
          options={canvasOptions}
          optionType='button'
          buttonStyle='solid'
          onChange={(option) => {
            setType(option.target.value);
          }}
        />
      </Form.Item>
      {page.id !== 'new' && type === 'TRENDS' && (
        <>
          <b>The maximum file size is 5 MB and they need to be JPG or PNG files.</b>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            {imagePreviews.map((_, index) => (
              <Form.Item
                key={index.toString()}
                name={['parameters', 'images', index]}
                label={`Image ${index + 1}`}
                style={{ maxWidth: '204px' }}
              >
                <ImageUpload
                  noRequirements
                  initialPreview={imagePreviews[index]}
                  onChangePreview={(preview) =>
                    setImagePreviews((previews) =>
                      preview !== previews[index]
                        ? [...previews.slice(0, index), preview, ...previews.slice(index + 1)]
                        : previews,
                    )
                  }
                  onRemove={() =>
                    form.setFields([{ name: ['parameters', 'images', index], value: null }])
                  }
                />
              </Form.Item>
            ))}
          </div>
        </>
      )}
      <Divider key='d1' />
    </>
  );
}
