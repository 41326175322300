import { useMemo, useEffect, useState } from 'react';
import { Layout, Divider, Button, Form, Space, Popconfirm } from 'antd';

import useAuth from '@hooks/auth/useAuth';
import { PageResource, PageTemplate } from '@transforms/page';
import type { UserRoleResource } from '@transforms/userRole';

import { PageProps } from './props';
import { GeneralInfo } from './GeneralInfo';
import { IFrame } from './IFrame';
import { Canvas } from './Canvas';
import { Live } from './Live';
import { VideoOnDemand } from './VideoOnDemand';
import { VirtualVenue } from './VirtualVenue';
import BreakoutPage from './BreakoutPage';
import { Basic } from './Basic';

export default function Page({
  page,
  title,
  language,
  onFinish,
  onFinishFailed,
  onDelete,
}: Omit<PageProps, 'page'> & {
  page: Omit<PageResource, 'roles'> & { roles?: UserRoleResource[] };
}) {
  const [form] = Form.useForm();
  const { isSuperAdmin, organisation } = useAuth();

  const parsedPage = useMemo(
    () => ({
      ...page,
      roles: page.roles?.map((role) => role.id),
    }),
    [page],
  );
  const [updatedPage, setUpdatedPage] = useState(parsedPage);

  useEffect(() => {
    form.setFieldsValue(parsedPage);
  }, [form, parsedPage]);

  const renderPageTemplate = useMemo(() => {
    switch (updatedPage?.template) {
      case PageTemplate.ATTENDEES || PageTemplate.SCHEDULE:
        return null;
      case PageTemplate.CANVAS:
        return <Canvas form={form} page={updatedPage} />;
      case PageTemplate.IFRAME:
        return <IFrame />;
      case PageTemplate.LIVE:
        return <Live page={updatedPage} />;
      case PageTemplate.VIDEO_ON_DEMAND:
        return <VideoOnDemand page={updatedPage} language={language} />;
      case PageTemplate.VIRTUAL_VENUE:
        return <VirtualVenue />;
      case PageTemplate.BREAKOUT:
        return <BreakoutPage page={updatedPage} language={language} />;
      case PageTemplate.BASIC:
        return <Basic />;
      default:
        return null;
    }
  }, [form, updatedPage, language]);

  if (!page) return null;

  return (
    <Layout.Content style={{ padding: '20px', backgroundColor: 'white' }}>
      <h1>{title || updatedPage.title}</h1>

      <Divider key='d1' />

      <Form
        form={form}
        layout='vertical'
        initialValues={parsedPage}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={(changed, current) =>
          setUpdatedPage({ ...parsedPage, ...current, ...changed })
        }
        key={updatedPage.id}
        validateMessages={{
          required: 'This is a required field.',
        }}
      >
        <GeneralInfo page={updatedPage} />
        <Divider key='d2' />
        {renderPageTemplate}

        <Space style={{ float: 'right' }}>
          {onDelete && (isSuperAdmin || organisation) && (
            <Popconfirm
              title={
                <div className='message-box-content-container'>
                  <div className='message-box-content'>
                    <h3>Confirm Deletion!</h3>
                    <span>
                      Are you sure you want to delete this entire page and all its data? Note, this
                      cannot be undone!
                    </span>
                  </div>
                </div>
              }
              onConfirm={() => onDelete(updatedPage.id)}
              okText='Delete'
              cancelText='Cancel'
            >
              <Button type='primary' danger style={{ marginRight: 10 }}>
                Delete
              </Button>
            </Popconfirm>
          )}
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </Space>
      </Form>
    </Layout.Content>
  );
}
