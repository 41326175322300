import { useQuery, useQueryClient, useMutation } from 'react-query';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import useAuth from '@hooks/auth/useAuth';
import transform, { VideoCall } from '@transforms/videoCall';

export const useVideoCall = (eventId?: string, videoCallId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['videoCall', eventId, videoCallId, language], async () => {
    if (!eventId) throw new Error('No event');
    if (!videoCallId) throw new Error('No video call');

    const { body } = await fetch(
      languageUrl(`/events/${eventId}/video-calls/${videoCallId}`, language),
      {
        token: accessToken,
      },
    );

    return transform.one(body);
  });
};

export const useVideoCalls = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['videoCalls', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(languageUrl(`/events/${eventId}/video-calls`, language), {
        token: accessToken,
      });

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useCreateVideoCall = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: VideoCall) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/video-calls`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pageItemCategories');
        queryClient.invalidateQueries('videoCalls');
      },
    },
  );
};

export const useUpdateVideoCall = (eventId?: string, videoCallId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: VideoCall) => {
      if (!eventId) throw new Error('No event');
      if (!videoCallId) throw new Error('No video call');

      return fetch(languageUrl(`/events/${eventId}/video-calls/${videoCallId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pageItemCategories');
        queryClient.invalidateQueries('videoCalls');
      },
    },
  );
};

export const useDeleteVideoCall = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (videoCallId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/video-calls/${videoCallId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pageItemCategories');
        queryClient.invalidateQueries('videoCalls');
      },
    },
  );
};

export const useUploadVideoCallImage = (eventId?: string) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    ({ videoCallId, image, lang }: { videoCallId: string; image: File | Blob; lang?: string }) => {
      if (!eventId) throw new Error('No event');

      const body = new FormData();
      body.append('image', image);

      return fetch(languageUrl(`/events/${eventId}/video-calls/${videoCallId}/image`, lang), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pageItemCategories');
        queryClient.invalidateQueries('videoCalls');
      },
    },
  );
};
