import { List, Button, Tooltip, Popconfirm, Avatar } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { SpeakerResource } from '@transforms/speaker';

export function ListItem({
  speaker,
  onDuplicate,
  onEdit,
  onDelete,
}: {
  speaker: SpeakerResource;
  onDuplicate?: (user: SpeakerResource) => void;
  onEdit?: (user: SpeakerResource) => void;
  onDelete?: (id: string) => void;
}) {
  return (
    <List.Item
      key={speaker.id}
      actions={[
        onDuplicate && (
          <Tooltip title='Duplicate' key='duplicate'>
            <Button shape='circle' icon={<CopyOutlined />} onClick={() => onDuplicate(speaker)} />
          </Tooltip>
        ),
        onEdit && (
          <Tooltip title='Edit' key='edit'>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(speaker)} />
          </Tooltip>
        ),
        onDelete && (
          <Popconfirm
            title='Are you sure you want to delete this speaker from this event?'
            onConfirm={() => onDelete(speaker.id)}
            okText='Delete'
            cancelText='Cancel'
            key='delete'
          >
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      ].filter(Boolean)}
    >
      <List.Item.Meta
        title={speaker.name}
        avatar={speaker.imageUrl && <Avatar src={speaker.imageUrl} />}
      />
    </List.Item>
  );
}
