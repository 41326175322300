import React from 'react';
import { Divider, Form, Input, Switch } from 'antd';

import { Link } from 'react-router-dom';

import { RichTextEditor } from '@components/RichTextEditor';
import { useCurrentEvent } from '@hooks/useEvents';

export function Basic() {
  const { data: event } = useCurrentEvent();
  return (
    <>
      <h2>Basic Content Settings</h2>
      <Form.Item style={{ fontWeight: 'bold' }} name={['parameters', 'subtitle']} label='Subtitle'>
        <Input placeholder='Subtitle' />
      </Form.Item>
      <Form.Item
        name={['parameters', 'description']}
        label={<div style={{ fontWeight: 'bold' }}>Description</div>}
      >
        <RichTextEditor
          placeholder='A short description or message for this page.'
          disableFont
          disableColor
          minHeight={90}
          maxLength={1000}
        />
      </Form.Item>
      <Form.Item
        style={{ fontWeight: 'bold' }}
        name={['parameters', 'iFrameUrl']}
        label='iFrame URL'
      >
        <Input placeholder='The URL (web address) to embed.' />
      </Form.Item>
      <Divider key='d1' />
      <h3>Public Chat</h3>
      <p>
        Toggle on to activate or off to deactivate public chat on this page. Note, you can enable or
        disable public chat entirely from the{' '}
        <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link>.
      </p>

      <Form.Item
        name='groupChatEnabled'
        style={{ fontWeight: 'bold' }}
        label='Enabled'
        valuePropName='checked'
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name={['parameters', 'chatEnabled']}
        style={{ fontWeight: 'bold' }}
        label='Expanded by Default'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </>
  );
}
