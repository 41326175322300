import { Modal, Table } from 'antd';
import { ColumnGroupType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import { EventResource } from '@transforms/event';
import { Admin, AdminResource } from '@transforms/admin';

export interface AdminTableProps {
  data: AdminResource;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setSorter: (sorter: { field?: keyof Admin; order?: 'ascend' | 'descend' }) => void;
}

export function AdminTable(props: AdminTableProps) {
  const { visible, setVisible, data, setSorter } = props;

  const assignments: Omit<EventResource, 'organisation'>[] = data?.assignments || [];

  return (
    <Modal
      visible={visible}
      title='View Admin'
      cancelText='Cancel'
      onCancel={() => setVisible(false)}
      forceRender
    >
      <Table
        dataSource={assignments}
        style={{ marginTop: 10 }}
        onChange={(_, __, sort: any) => setSorter(sort)}
        columns={
          (
            [
              assignments &&
                ({
                  title: 'Event',
                  key: 'event',
                  sorter: true,
                  dataIndex: 'id',
                  render: (id: string) => {
                    return (
                      <Link to={`/events/${id}/settings/editors`}>
                        {assignments.find((assignment) => assignment.id === id)?.name}
                      </Link>
                    );
                  },
                } as any), // this is required because dataIndex is somehow not a property here
            ] as Array<boolean | ColumnGroupType<Omit<EventResource, 'organisation'>>>
          ).filter(Boolean) as ColumnGroupType<Omit<EventResource, 'organisation'>>[]
        }
      />
    </Modal>
  );
}
