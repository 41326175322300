import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  PageHeader,
  Layout,
  Card,
  Col,
  Row,
  Select,
  Calendar,
  Tag,
  Tabs,
  Table,
  Modal,
  Space,
  Descriptions,
  Button,
} from 'antd';
import {
  ScheduleOutlined,
  UserOutlined,
  WarningTwoTone,
  CheckCircleTwoTone,
  AlignLeftOutlined,
  EditOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import type { OptionData } from 'rc-select/lib/interface';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { UserResource } from '@transforms/user';
import { EventResource } from '@transforms/event';
import type { UserRoleResource } from '@transforms/userRole';
import { SessionWithLocationVisit } from '@transforms/locationVisit';
// import { useLocationVisits } from '@hooks/useLocationVisits';
import { useOrganisations } from '@hooks/useOrganisations';
import { useProtected } from '@hooks/useProtected';
// import { useSessions } from '@hooks/useSessions';
import { useAuth } from '@hooks/auth/useAuth';
import { useEvents } from '@hooks/useEvents';
import { useAudits } from '@hooks/useAudits';
import { useUsers } from '@hooks/useUsers';
import {
  useAnalytics,
  useInsightsAnalytics,
  useAttendeeAnalytics,
  useExportAnalytics,
  useSessionsAnalytics,
  // useVideosAnalytics,
  // usePlatformAnalytics,
} from '@hooks/useAnalytics';
import {
  AnalyticsGeneralAttendee,
  AnalyticsHeader,
  // AnalyticsPlatform,
  AnalyticsQuickInsights,
  AnalyticsSessions,
  // AnalyticsVideos,
} from '@components/Analytics';

export default function Analytics() {
  const { isSuperAdmin, organisation } = useAuth();
  useProtected({ checkSuperAdmin: !organisation });
  const quickInsightsRef = React.createRef<HTMLDivElement>();
  const analyticsRef = React.createRef<HTMLDivElement>();
  const attendeeAnalyticsRef = React.createRef<HTMLDivElement>();
  const sessionAnalyticsRef = React.createRef<HTMLDivElement>();

  const startDate = useRef(new Date());
  const [interval, createInterval] = useState<ReturnType<typeof setInterval>>();

  // #region Extra
  const { exportAnalytics } = useExportAnalytics();
  const { data: organisations } = useOrganisations({ page: 1, limit: 99999 });

  const [selectedOrganisation, setSelectedOrganisation] = useState(
    organisation?.id ?? (isSuperAdmin ? '__ALL__' : '__NONE__'),
  );
  // #endregion

  // #region Analytics
  const [dates, setDates] = useState<{ startDate: Date | string; endDate: Date | string }>({
    startDate: dayjs().subtract(1, 'year').toISOString(),
    endDate: new Date(),
  });

  const handleDateChange = () => (vals: any) => {
    setDates({
      startDate: new Date(vals[0]).toISOString(),
      endDate: new Date(vals[1]).toISOString(),
    });
  };

  const selectedOrg = selectedOrganisation !== '__NONE__' ? selectedOrganisation : undefined;
  const { data: analytics } = useAnalytics(selectedOrg);

  const { data: attendeeAnalytics } = useAttendeeAnalytics(
    selectedOrg,
    dates.startDate,
    dates.endDate,
    10,
  );
  const { data: sessionAnalytics } = useSessionsAnalytics(
    selectedOrg,
    dates.startDate,
    dates.endDate,
    10,
  );
  // const { data: videoAnalytics } = useVideosAnalytics(
  //   selectedOrg,
  //   dates.startDate,
  //   dates.endDate,
  //   10,
  // );
  // const { data: platformAnalytics } = usePlatformAnalytics(
  //   selectedOrg,
  //   dates.startDate,
  //   dates.endDate,
  //   10,
  // );

  // const { data: sessions } = useSessions({ page: 1, limit: 999 });
  // const { data: locationVisits } = useLocationVisits(eventId, dates.startDate, dates.endDate);
  const { data: users, refetch: refetchUsers } = useUsers(
    { page: 1, limit: 999 },
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  );

  // Live data
  const { data: analyticsLive, refetch: refetchAnalyticsLive } = useAnalytics(
    selectedOrg,
    startDate.current,
    undefined,
    10,
  );
  const { data: insightAnalytics, refetch: refetchInsights } = useInsightsAnalytics(
    selectedOrg,
    startDate.current,
    undefined,
    10,
  );

  const mostCommonRole = useMemo(
    () =>
      users?.items
        .filter(
          (attendee) =>
            dayjs(attendee.acceptedAt!).isAfter(dates.startDate) &&
            dayjs(attendee.acceptedAt!).isBefore(dates.endDate),
        )
        .map((attendee: UserResource) => attendee.role)
        .reduce<UserRoleResource | null>(
          (a, b, _, arr) =>
            arr.filter((val) => val === a).length >= arr.filter((val) => val === b).length
              ? a ?? null
              : b ?? null,
          null,
        ),
    [dates.endDate, dates.startDate, users?.items],
  );

  const mostCommonTags = useMemo(() => {
    const tagCounts = users?.items
      .filter(
        (attendee) =>
          dayjs(attendee.acceptedAt!).isAfter(dates.startDate) &&
          dayjs(attendee.acceptedAt!).isBefore(dates.endDate),
      )
      .map((attendee) => attendee.tags)
      .flat()
      .reduce((acc, tag) => {
        // @ts-ignore
        if (acc[tag.label]) {
          // @ts-ignore
          acc[tag.label] += 1;
        } else {
          // @ts-ignore
          acc[tag.label] = 1;
        }
        return acc;
      }, {});

    if (tagCounts) {
      const tagCountsArray = Object.keys(tagCounts).map((key) => ({
        label: key,
        // @ts-ignore
        count: tagCounts[key],
      }));

      return tagCountsArray;
    }

    return [];
  }, [dates.endDate, dates.startDate, users?.items]);

  const mostCommonLanguage = useMemo(
    () =>
      users?.items
        .filter(
          (attendee) =>
            dayjs(attendee.acceptedAt!).isAfter(dates.startDate) &&
            dayjs(attendee.acceptedAt!).isBefore(dates.endDate),
        )
        .map((attendee: UserResource) => attendee.language)
        .reduce<string | null>(
          (a, b, _, arr) =>
            arr.filter((val) => val === a).length >= arr.filter((val) => val === b).length
              ? a ?? null
              : b ?? null,
          null,
        ),
    [dates.endDate, dates.startDate, users?.items],
  );

  const sessionWithPageClicks = (): SessionWithLocationVisit[] => {
    // TODO:
    // if (sessions && locationVisits) {
    //   const sessionPageUrls: any = sessions.items.map((session) => {
    //     const sessionPageUrl = session.url?.split('page=')[1];

    //     const locationVisit = locationVisits.pages.find(
    //       (visit) => visit.page.id === sessionPageUrl,
    //     );

    //     return { ...session, locationVisit };
    //   });

    //   return sessionPageUrls;
    // }
    return [];
  };
  // #endregion

  // #region Calendar
  const { data: events } = useEvents();

  const RenderHeader = ({ value, onChange }: any) => {
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let index = 0; index < 12; index += 1) {
      current.month(index);
      months.push(localeData.monthsShort(current));
      /* @ts-expect-error - No need for value here */
      monthOptions.push(<Select.Option key={index}>{months[index]}</Select.Option>);
    }

    const month = value.month();

    const year = value.year();
    const options = [];
    for (let index = year - 10; index < year + 10; index += 1) {
      options.push(
        <Select.Option key={index} value={index} className='year-item'>
          {index}
        </Select.Option>,
      );
    }
    return (
      <Row gutter={8} justify='end' style={{ marginBottom: '1.5rem' }}>
        <Col>
          <Select
            size='middle'
            dropdownMatchSelectWidth={false}
            className='my-year-select'
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
            value={String(year)}
          >
            {options}
          </Select>
        </Col>
        <Col>
          <Select
            size='middle'
            dropdownMatchSelectWidth={false}
            value={String(month)}
            onChange={(selectedMonth) => {
              const newValue = value.clone();
              newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            }}
          >
            {monthOptions}
          </Select>
        </Col>
      </Row>
    );
  };

  const EventCalendar = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [event, setEvent] = useState<EventResource | undefined>(undefined);

    const dateCellRender = (value: any) => {
      const eventsOnDate: Array<any> | undefined = events?.filter(
        (e) =>
          // eslint-disable-next-line no-underscore-dangle
          dayjs(value._d).isSame(e.startsAt, 'day') ||
          // eslint-disable-next-line no-underscore-dangle
          dayjs(value._d).isSame(e.endsAt, 'day') ||
          // eslint-disable-next-line no-underscore-dangle
          dayjs(value._d).isBetween(e.startsAt, e.endsAt),
      );

      if (Array.isArray(eventsOnDate) && eventsOnDate.length > 0) {
        return eventsOnDate.map((e) => (
          <Tag
            color={e.active ? 'green' : 'red'}
            style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', margin: '0' }}
            onClick={() => {
              setEvent(e);
              setVisible(true);
            }}
            key={e.id}
          >
            <strong>{e.name}</strong>
            <br />
            <UserOutlined /> {e.maxUsers || <>&infin;</>}
          </Tag>
        ));
      }

      return null;
    };

    return (
      <>
        <h2>Event calendar</h2>
        <Card>
          <Calendar
            dateCellRender={dateCellRender}
            mode='month'
            headerRender={({ value, onChange }) => (
              <RenderHeader onChange={onChange} value={value} />
            )}
          />
          {!!event && (
            <Modal
              visible={visible}
              centered
              onCancel={() => setVisible(false)}
              title={event.name}
              width={594}
              footer={
                <Button type='primary' icon={<EditOutlined />} href={`/events/${event.id}/info`}>
                  Edit event
                </Button>
              }
            >
              <Descriptions column={2}>
                <Descriptions.Item
                  label={
                    <Space>
                      <ScheduleOutlined />
                      Starts at
                    </Space>
                  }
                >
                  {dayjs(event.startsAt).format('DD-MM-YYYY').toString()}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Space>
                      <ScheduleOutlined />
                      Ends at
                    </Space>
                  }
                >
                  {dayjs(event.endsAt).format('DD-MM-YYYY').toString()}
                </Descriptions.Item>
                <Descriptions.Item
                  span={24}
                  label={
                    <Space>
                      <UserOutlined />
                      Max attendees
                    </Space>
                  }
                >
                  {event?.maxUsers || <>&infin;</>}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Space>
                      <AlignLeftOutlined />
                      Description
                    </Space>
                  }
                >
                  {event.description ? parse(event.description) : ''}
                </Descriptions.Item>
              </Descriptions>
            </Modal>
          )}
        </Card>
      </>
    );
  };
  // #endregion

  // #region Logs
  const { data: audits } = useAudits({ page: 1, limit: 99999 });

  const columns = [
    {
      title: 'Status',
      dataIndex: 'statusCode',
      render: (code: string) =>
        code === '200' ? (
          <CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: '16px' }} />
        ) : (
          <WarningTwoTone twoToneColor='#ff4d4f' style={{ fontSize: '16px' }} />
        ),
    },
    {
      title: 'Type',
      dataIndex: 'auditedEntity',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },
    {
      title: 'User',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Organization',
      dataIndex: 'organisation',
    },
    {
      title: 'Date time',
      dataIndex: 'createdAt',
      render: (date: string) => new Date(date).toUTCString(),
    },
  ];
  // #endregion

  useEffect(() => {
    createInterval(
      setInterval(() => {
        refetchUsers();
        refetchInsights();
        refetchAnalyticsLive();
      }, 10_000),
    );
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pdfExporting, setPdfExporting] = useState(false);

  const onExportPdf = async () => {
    setPdfExporting(true);

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      unit: 'pt',
    });

    const quickInsightsContent = quickInsightsRef.current;
    const analyticsContent = analyticsRef.current;
    const attendeeAnalyticsContent = attendeeAnalyticsRef.current;
    const sessionAnalyticsContent = sessionAnalyticsRef.current;

    if (quickInsightsContent) {
      await html2canvas(quickInsightsContent as HTMLElement, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        const { width, height } = pdf.getImageProperties(img);
        const aspectRatio = height / width;

        pdf.addImage(img, 'JPEG', 20, 20, 555, 555 * aspectRatio);
      });
    }

    if (analyticsContent) {
      await html2canvas(analyticsContent as HTMLElement, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        const { width, height } = pdf.getImageProperties(img);
        const aspectRatio = height / width;

        if (quickInsightsContent) pdf.addPage();
        pdf.addImage(img, 'JPEG', 20, 20, 555, 555 * aspectRatio);
      });
    }

    if (attendeeAnalyticsContent) {
      await html2canvas(attendeeAnalyticsContent as HTMLElement, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        const { width, height } = pdf.getImageProperties(img);
        const aspectRatio = height / width;

        if (quickInsightsContent || analyticsContent) pdf.addPage();
        pdf.addImage(img, 'JPEG', 20, 20, 555, 555 * aspectRatio);
      });
    }

    if (sessionAnalyticsContent) {
      await html2canvas(sessionAnalyticsContent as HTMLElement, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        const { width, height } = pdf.getImageProperties(img);
        const aspectRatio = height / width;

        if (quickInsightsContent || analyticsContent || attendeeAnalyticsContent) pdf.addPage();
        pdf.addImage(img, 'JPEG', 20, 20, 555, 555 * aspectRatio);
      });
    }

    pdf.save();
    setPdfExporting(false);
  };

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader
        title='Analytics'
        extra={
          isSuperAdmin
            ? [
                <b key='scope-title'>Scope:</b>,
                <Select
                  key='scope'
                  placeholder='Select organisation or scope'
                  value={selectedOrganisation}
                  style={{ width: '160px' }}
                  options={
                    [
                      isSuperAdmin
                        ? {
                            value: '__ALL__',
                            label: <span className='priority-option'>All events</span>,
                          }
                        : false,
                      {
                        value: '__NONE__',
                        label: <span className='priority-option'>No organisation</span>,
                      },
                      ...(organisations?.items?.map((org) => ({
                        value: org.id,
                        label: <span style={{ fontWeight: 'normal' }}>{org.name}</span>,
                      })) ?? []),
                    ].filter(Boolean) as OptionData[]
                  }
                  onChange={(value) => setSelectedOrganisation(value)}
                />,
                <Button key='csv' type='primary' onClick={() => exportAnalytics()}>
                  Download CSV
                </Button>,
                <>
                  {pdfExporting ? (
                    <Button style={{ width: '127px' }}>
                      <LoadingOutlined />
                    </Button>
                  ) : (
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={onExportPdf}
                      style={{ width: '127px' }}
                    >
                      Export PDF
                    </Button>
                  )}
                </>,
              ]
            : undefined
        }
      />

      <Tabs defaultActiveKey='analytics'>
        <Tabs.TabPane tab='Analytics' key='analytics'>
          <>
            {analytics && insightAnalytics && (
              <div ref={quickInsightsRef} id='quickInsights'>
                <AnalyticsQuickInsights
                  analytics={analyticsLive}
                  insights={insightAnalytics}
                  attendees={users?.items || []}
                  sessionWithPageClicks={sessionWithPageClicks}
                />
              </div>
            )}
            {analytics && (
              <div ref={analyticsRef} id='analytics'>
                <AnalyticsHeader
                  title={organisations?.items.find(({ id }) => id === selectedOrg)?.name || ''}
                  startDate={dates.startDate}
                  endDate={dates.endDate}
                  analytics={analytics}
                  handleDateChange={handleDateChange()}
                />
              </div>
            )}
            {attendeeAnalytics && (
              <div ref={attendeeAnalyticsRef} id='attendeeAnalytics'>
                <AnalyticsGeneralAttendee
                  mostCommonRole={mostCommonRole}
                  mostCommonTags={mostCommonTags}
                  mostCommonLanguage={mostCommonLanguage || 'en'}
                  generalAttendee={attendeeAnalytics}
                />
              </div>
            )}
            {/* {platformAnalytics && (
              <AnalyticsPlatform
                platforms={platformAnalytics?.platform}
                virtualVenues={platformAnalytics?.virtualVenue}
              />
            )} */}
            {sessionAnalytics && (
              <div ref={sessionAnalyticsRef} id='sessionAnalytics'>
                <AnalyticsSessions
                  sessionsAttendance={sessionAnalytics?.attendance || []}
                  sessionsScheduled={sessionAnalytics?.scheduled}
                />
              </div>
            )}
            {/* {videoAnalytics && <AnalyticsVideos sessions={videoAnalytics?.watched || []} />} */}
          </>
        </Tabs.TabPane>

        <Tabs.TabPane tab='Calendar' key='calendar'>
          <EventCalendar />
        </Tabs.TabPane>

        {isSuperAdmin && (
          <Tabs.TabPane tab='Logs' key='logs'>
            <h2>Audits</h2>
            <Table rowKey='id' dataSource={audits?.items} columns={columns} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Layout.Content>
  );
}
