import { useCallback, useEffect, useState } from 'react';
import { Form, Select, Popconfirm, Button, Card, Space, Tag, message } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import DatePicker from '@components/DatePicker';
import { EventLayout } from '@components/Layout';

import { Event } from '@transforms/event';
import { usePages } from '@hooks/usePages';
import { useCurrentEvent, useUpdateEvent } from '@hooks/useEvents';

export default function InitialPages() {
  const { data: event } = useCurrentEvent();
  const { mutate: updateEvent } = useUpdateEvent(event?.id);
  const { data: pages } = usePages();
  const [initialPages, setInitialPages] = useState<Array<{ startDate: Date; pageId?: string }>>([]);

  useEffect(
    () =>
      setInitialPages((current) => {
        if (current.length === 0 && event?.initialPages) {
          return event.initialPages;
        }

        return current;
      }),
    [event],
  );

  const onUpdateEvent = (values: Event) => {
    updateEvent({ ...event, ...values, initialPages });
    message.success({
      content: (
        <div className='message-box-content-container'>
          <div className='message-box-content'>
            <h3>Event Updated!</h3>
            <span>Your changes have been successfully updated and saved!</span>
          </div>
        </div>
      ),
      className: 'message-box',
    });
  };

  const addInitialPage = useCallback(
    () =>
      setInitialPages((current) => [
        ...current,
        {
          startDate: new Date(),
          pageId: pages?.[0]?.id,
        },
      ]),
    [setInitialPages, pages],
  );

  const updateInitialPage = (index: number, startDate: Date, pageId?: string) =>
    setInitialPages((current) => [
      ...current.slice(0, index),
      { startDate, pageId },
      ...current.slice(index + 1),
    ]);

  const deleteInitialPage = (index: number) =>
    setInitialPages((current) => [...current.slice(0, index), ...current.slice(index + 1)]);

  const renderInitialPageSelect = (
    <Space direction='vertical' style={{ width: '100%' }}>
      <h1>Initial Pages</h1>
      <p>
        Schedule which page attendees will see first (when accessing via their personal login link).
        Note, the page whose date is closest before the current timestamp will be used.
      </p>
      <p>
        Tip: You can schedule multiple pages per day; however; there can only be one initial page
        for all attendees which means you cannot set different initial pages for different user
        roles.
      </p>
      {initialPages.length > 0 && (
        <Card>
          <Space direction='vertical'>
            {initialPages
              .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
              .map((initialPage, index) => (
                <Space key={`${initialPage.startDate}.${initialPage.pageId}`}>
                  {/* @ts-ignore */}
                  <DatePicker
                    showTime
                    allowClear={false}
                    value={dayjs(initialPage.startDate)}
                    onChange={(value) =>
                      updateInitialPage(
                        index,
                        value?.toDate() || initialPage.startDate,
                        initialPage.pageId,
                      )
                    }
                  />
                  <Select
                    style={{ minWidth: 200 }}
                    value={initialPage.pageId}
                    onChange={(value) => updateInitialPage(index, initialPage.startDate, value)}
                  >
                    {pages?.map((page) => (
                      <Select.Option value={page.id} key={page.id}>
                        {page.title}
                      </Select.Option>
                    ))}
                  </Select>
                  <Popconfirm
                    title={
                      <div className='message-box-content-container'>
                        <div className='message-box-content'>
                          <h3>Confirm Deletion!</h3>
                          <span>
                            Are you sure you want to delete this initial page? Note, this cannot be
                            undone!
                          </span>
                        </div>
                      </div>
                    }
                    onConfirm={() => deleteInitialPage(index)}
                    okText='Delete'
                    cancelText='Cancel'
                  >
                    <Button danger shape='circle' icon={<DeleteOutlined />} />
                  </Popconfirm>
                  {event?.initialPageId === initialPage.pageId && (
                    <Tag color='green'>currently active</Tag>
                  )}
                </Space>
              ))}
          </Space>
        </Card>
      )}
      <Button type='dashed' block icon={<PlusOutlined />} onClick={addInitialPage}>
        Add Initial Page
      </Button>
      <Button htmlType='submit' type='primary' style={{ float: 'right', marginTop: '20px' }}>
        Save
      </Button>
    </Space>
  );

  return (
    <EventLayout>
      <Form layout='vertical' initialValues={event} onFinish={onUpdateEvent}>
        {renderInitialPageSelect}
      </Form>
    </EventLayout>
  );
}
