import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import transform, { Translation, TranslationResource } from '@transforms/translation';

import { useCurrentEvent } from './useEvents';

export const useTranslation = (eventId?: string, id?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['customField', eventId, id], async () => {
    if (!eventId) throw new Error('No event');
    if (!id) throw new Error('No translation');

    const { body } = await fetch(`/events/${eventId}/translations/${id}`, { token: accessToken });

    return transform.one(body);
  });
};

export const useTranslations = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['translations', eventId], async () => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(`/events/${eventId}/translations`, { token: accessToken });

    return transform.many(body);
  });
};

export const useObjectTranslations = (objectType: string, eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['translations', objectType, eventId], async () => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(`/events/${eventId}/translations/object-type`, {
      method: 'POST',
      body: { objectType },
      token: accessToken,
    });

    return transform.many(body);
  });
};

export const useCreateTranslation = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Translation) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/translations`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('translations');
      },
    },
  );
};

export const useUpdateTranslation = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: TranslationResource) => {
      if (!eventId) throw new Error('No event');

      const { id, ...restValues } = values;
      return fetch(`/events/${eventId}/translations/${id}`, {
        method: 'PUT',
        body: { ...restValues },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('translations');
      },
    },
  );
};

export const useDeleteTranslation = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/translations/${id}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('translations');
      },
    },
  );
};

export const languages = {
  nl: { label: 'Dutch', value: 'nl' },
  en: { label: 'English', value: 'en' },
  nl_BE: { label: 'Flemisch', value: 'nl_BE' },
  de: { label: 'German', value: 'de' },
  fr: { label: 'French', value: 'fr' },
  es: { label: 'Spanish', value: 'es' },
  it: { label: 'Italian', value: 'it' },
};

export type Language = keyof typeof languages;

export const useUpsertTranslation = (eventId?: string) => {
  const { data: translations } = useTranslations(eventId);
  const { mutateAsync: deleteTranslation } = useDeleteTranslation(eventId);
  const { mutateAsync: updateTranslation } = useUpdateTranslation(eventId);
  const { mutateAsync: createTranslation } = useCreateTranslation(eventId);

  const upsertTranslation = useCallback(
    async (translation: Omit<TranslationResource, 'id'>) => {
      // NOTE: update existing translations &/or create new ones
      const existing = translations?.find(
        (transl) =>
          transl.iso === translation.iso &&
          transl.objectColumn === translation.objectColumn &&
          transl.objectId === translation.objectId &&
          transl.objectType === translation.objectType,
      );

      if (existing) {
        if (!translation.value) {
          await deleteTranslation(existing.id);
        } else if (translation.value !== existing.value) {
          await updateTranslation({
            id: existing.id,
            ...translation,
          });
        }
      } else if (translation.value) {
        await createTranslation(translation);
      }
    },
    [translations, deleteTranslation, updateTranslation, createTranslation],
  );

  return upsertTranslation;
};

interface LanguageContextData {
  readonly selectedLanguage: Language;
  readonly setSelectedLanguage: (language: Language) => void;
}

const LanguageContext = createContext<LanguageContextData>({
  selectedLanguage: 'en',
  setSelectedLanguage: () => undefined,
});

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>('en');
  const { data: event } = useCurrentEvent(selectedLanguage);

  useEffect(() => {
    if (event?.languages && !event.languages.includes(selectedLanguage)) {
      setSelectedLanguage(event.languages[0] as Language);
    }
  }, [event, selectedLanguage, setSelectedLanguage]);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage() {
  return useContext(LanguageContext);
}

export default {
  languages,
  useTranslation,
  useTranslations,
  useCreateTranslation,
  useUpdateTranslation,
  useDeleteTranslation,
  useLanguage,
};
