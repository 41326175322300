import React, { useEffect, useState } from 'react';
import { MoreOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Alert, Button, Divider, message } from 'antd';

import { ListItem } from '@components/CustomFields';
import reorder from '@utils/reorder';
import { EventResource } from '@transforms/event';
import { CustomFieldResource } from '@transforms/customField';
import { useDeleteCustomField, useUpdateCustomFieldsPositions } from '@hooks/useCustomFields';

interface Props {
  readonly customFields?: CustomFieldResource[];
  readonly event?: EventResource;
  readonly setSelectedField: (value?: CustomFieldResource | undefined) => void;
  readonly setOpenFieldModal: (value?: boolean) => void;
}
export default function DragDropCustomFields({
  customFields,
  event,
  setSelectedField,
  setOpenFieldModal,
}: Props) {
  const [initialDraggableItems, setInitialDraggableItems] = useState(customFields);
  const [draggableItems, setDraggableItems] = useState(customFields);

  const { mutate: deleteCustomField } = useDeleteCustomField(event?.id);
  const { mutate: updateCustomFieldsPositions } = useUpdateCustomFieldsPositions(event?.id);

  const [isDragged, setIsDragged] = useState<boolean>(false);

  useEffect(() => {
    setInitialDraggableItems(customFields);
    setDraggableItems(customFields);
  }, [customFields]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sorted = reorder(draggableItems, result.source.index, result.destination.index);
    setDraggableItems(sorted);
    setIsDragged(true);
  };

  const getListItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    index: number,
  ) => {
    const defaultStyles = {
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      cursor: 'inherit',
    };

    // eslint-disable-next-line no-nested-ternary
    const borderTop = isDragging ? '1px solid #f0f0f0' : index > 0 ? '1px solid #f0f0f0' : '';
    const borderBottom = isDragging ? '1px solid #f0f0f0' : '';

    return { ...draggableStyle, ...defaultStyles, borderTop, borderBottom };
  };

  const onDeleteCustomField = (id: string) => {
    deleteCustomField(id);
  };

  const onOpenCustomFieldModal = (customField?: CustomFieldResource) => {
    if (customField) {
      setSelectedField(customField);
    } else {
      setSelectedField(undefined);
    }
    setOpenFieldModal(true);
  };

  const undoReorder = () => {
    setDraggableItems(initialDraggableItems);
    setIsDragged(false);
  };

  const saveReoder = () => {
    if (!draggableItems) {
      message.error('Unable to update order');
      return;
    }

    updateCustomFieldsPositions(
      draggableItems.map((tag, index) => [tag.id, index + 1]),
      {
        onSuccess: () => {
          message.success({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Order Updated!</h3>
                  <span>Your changes have been successfully updated and saved!</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });

          setInitialDraggableItems(draggableItems);
          setIsDragged(false);
        },
        onError: () => {
          message.error({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Update Failed!</h3>
                  <span>An error occured while saving your changes. Please try again.</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        },
      },
    );
  };

  return (
    <>
      {isDragged && (
        <div style={{ maxWidth: '300px', marginBottom: '20px' }}>
          <Alert type='warning' message='Order has been changed!' showIcon />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Button block icon={<UndoOutlined />} onClick={undoReorder}>
              Undo
            </Button>
            <Divider key='d1' type='vertical' />
            <Button block type='primary' icon={<SaveOutlined />} onClick={saveReoder}>
              Save
            </Button>
          </div>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='custom-fields'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems?.map((customField, index) => (
                <Draggable key={customField.id} draggableId={customField.id} index={index}>
                  {(providedDeep, snapshot) => (
                    <div
                      {...providedDeep.draggableProps}
                      {...providedDeep.dragHandleProps}
                      ref={providedDeep.innerRef}
                      style={getListItemStyle(
                        snapshot.isDragging,
                        providedDeep.draggableProps.style,
                        index,
                      )}
                    >
                      <MoreOutlined style={{ marginRight: '10px', cursor: 'grab' }} />
                      <ListItem
                        customField={customField}
                        onDelete={onDeleteCustomField}
                        onEdit={() => onOpenCustomFieldModal(customField)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
