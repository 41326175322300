import React from 'react';
import { List, Button, Tooltip, Popconfirm } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { BreakoutRoomResource } from '@transforms/breakoutRoom';
import { usePages } from '@hooks/usePages';

export default function ListItem({
  breakoutRoom,
  onDuplicate,
  onDelete,
  onEdit,
}: {
  breakoutRoom: BreakoutRoomResource;
  onDuplicate?: (breakoutRoom: BreakoutRoomResource) => void;
  onDelete?: (id: string) => void;
  onEdit?: (breakoutRoom: BreakoutRoomResource) => void;
}) {
  const { data: rawPages } = usePages();

  const renderPage = (url?: string) => {
    if (!url?.length) return '';
    const pageWithId = rawPages?.find((page) => page.id === url)?.title ?? '';
    if (pageWithId?.length) return pageWithId;
    if (url.startsWith('?page='))
      return rawPages?.find((page) => page.id === url.split('=')[1])?.title ?? '';
    return (
      <a href={url} target='_blank' rel='noreferrer'>
        {url}
      </a>
    );
  };

  return (
    <List.Item
      key={breakoutRoom.title}
      actions={[
        onDuplicate && (
          <Tooltip title='Duplicate' key='duplicate'>
            <Button
              shape='circle'
              icon={<CopyOutlined />}
              onClick={() => onDuplicate(breakoutRoom)}
            />
          </Tooltip>
        ),
        onEdit && (
          <Tooltip title='Edit' key='edit'>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(breakoutRoom)} />
          </Tooltip>
        ),
        onDelete && (
          <Popconfirm
            title='Are you sure you want to delete this breakout room?'
            onConfirm={() => onDelete(breakoutRoom.id)}
            okText='Delete'
            cancelText='Cancel'
            key='delete'
          >
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      ].filter(Boolean)}
    >
      <List.Item.Meta
        title={breakoutRoom.title}
        description={
          <>
            <b>{breakoutRoom.subtitle}</b>
            <p>{renderPage(breakoutRoom.url ?? '')}</p>
            {breakoutRoom.position && <p>Position {breakoutRoom.position}</p>}
            {breakoutRoom.imageUrl && (
              <img alt='' style={{ maxWidth: '100pt' }} src={breakoutRoom.imageUrl} />
            )}
          </>
        }
      />
    </List.Item>
  );
}
