import { useEffect } from 'react';
import { Divider, Button, Form, PageHeader, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { General } from '@components/Event';
import { EventLayout } from '@components/Layout';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import { useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent, useUpdateEvent } from '@hooks/useEvents';
import { Event } from '@transforms/event';

export default function EventInfo() {
  const [form] = Form.useForm();
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const { mutateAsync: updateEvent } = useUpdateEvent(event?.id, selectedLanguage);

  useEffect(() => {
    form.setFieldsValue(event);
  }, [form, event]);

  const onFinish = async ({ dates, ...restValues }: Event & { dates: Dayjs[] }) => {
    await updateEvent({
      ...event,
      ...restValues,
      ...(dates ? { startsAt: dates[0].toJSON(), endsAt: dates[1].toJSON() } : undefined),
    });
    message.success({
      content: (
        <div className='message-box-content-container'>
          <div className='message-box-content'>
            <h3>Event Updated!</h3>
            <span>Your changes have been successfully updated and saved!</span>
          </div>
        </div>
      ),
      className: 'message-box',
    });
  };

  return (
    <EventLayout>
      <PageHeader title='General' extra={<EventLanguageSelect />} />
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          ...event,
          language: selectedLanguage,
          dates: [dayjs(event?.startsAt), dayjs(event?.endsAt)],
        }}
      >
        <Divider key='d1' />
        <General />
        <Divider key='d2' />
        <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
          Save
        </Button>
      </Form>
    </EventLayout>
  );
}
