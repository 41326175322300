import parse, { Attributes } from '@utils/parse';
import { transformEnumArray } from '@utils/transformArray';

export enum UserRolePermission {
  HOST_CHAT = 'HOST_CHAT',
  HIDE_USERS = 'HIDE_USERS',
  SEND_GROUP_CHAT = 'SEND_GROUP_CHAT',
  CREATE_PRIVATE_CHAT = 'CREATE_PRIVATE_CHAT',
  CREATE_PRIVATE_VIDEO_CALLS = 'CREATE_PRIVATE_VIDEO_CALLS',
  VIDEO_CALL_HOST = 'VIDEO_CALL_HOST',
}

export const parseUserRole = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    name: attributes.string('name'),
    color: attributes.string('color'),
    visible: attributes.boolean('visible'),
    attendeesInSidebar: attributes.boolean('attendeesInSidebar'),
    default: attributes.optional.boolean('default'),
    userCount: attributes.optional.number('userCount'),
    pageCount: attributes.optional.number('pageCount'),
    sessionCount: attributes.optional.number('sessionCount'),
    breakoutCount: attributes.optional.number('breakoutCount'),
    permissions: attributes.custom('permissions', transformEnumArray(UserRolePermission)),
  };
};

export function one(data: any) {
  return parse.one(data, parseUserRole);
}

export function many(data: any) {
  return parse.many(data, parseUserRole);
}

export type UserRoleResource = ReturnType<typeof one>;
export type UserRole = Omit<UserRoleResource, 'id'>;

export default { one, many };
