export function Pill({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        backgroundColor: '#EEE8FF',
        borderRadius: '1rem',
        padding: '0.3rem 2rem',
        fontWeight: 'normal',
        color: ' #1d39c4',
        maxWidth: 'fit-content',
        height: '1.6rem',
        lineHeight: '1rem', // 1.6 rem height - 0.3 rem padding top/bottom
        fontSize: '0.8125rem',
        display: 'grid',
        placeItems: 'center',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
