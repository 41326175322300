/* eslint-disable @typescript-eslint/no-explicit-any */

import get from 'lodash/get';

export function attribute(object: any, path: string) {
  return get(object, path);
}

export function compact(object: any) {
  const clone = { ...object };
  Object.keys(clone).forEach((prop) => {
    if (Object.prototype.hasOwnProperty.call(clone, prop) && clone[prop] === undefined) {
      delete clone[prop];
    }
  });

  return clone;
}

export function safely<Wrapped extends () => any>(func: Wrapped): ReturnType<Wrapped> | undefined {
  try {
    return func();
  } catch (error: any) {
    if (error.isAPIParseError) return undefined;
    throw error;
  }
}

export function optional<Wrapped extends (...args: any[]) => any>(
  func: Wrapped,
): (...args: Parameters<Wrapped>) => ReturnType<Wrapped> | undefined | null {
  return (...args: Parameters<Wrapped>) => safely(() => func(...args));
}
