import { useEffect, useState, useMemo } from 'react';
import { RefetchOptions } from 'react-query';
import {
  Button,
  Col,
  Divider,
  Drawer as AntDrawer,
  Form,
  Input,
  Row,
  Select,
  message,
  Switch,
  Modal,
} from 'antd';
import { DrawerProps as AntDrawerProps } from 'antd/lib/drawer';
import { WarningOutlined } from '@ant-design/icons';

import { ImageUpload } from '@components/Upload';
import { PreviewAvatarModal } from '@components/Modals';

import { ATTENDANCE_TYPE, UserResource } from '@transforms/user';
import { CustomFieldType } from '@transforms/customField';
import type { UserRoleResource } from '@transforms/userRole';

import { useCurrentEvent } from '@hooks/useEvents';
import { useCustomFields } from '@hooks/useCustomFields';
import { languages } from '@hooks/useTranslations';
import { useUploadUserImage } from '@hooks/useUsers';

type DrawerProps = AntDrawerProps & {
  user?: UserResource;
  roles?: UserRoleResource[];
  onClose: () => void;
  showMagicLink?: () => void;
  refetch?: (options?: RefetchOptions) => any;
  onFinish: (values: UserResource, shouldClose?: boolean) => Promise<boolean> | void;
};

export function Drawer({
  user,
  roles,
  onClose,
  showMagicLink,
  refetch,
  onFinish,
  visible,
  ...restProps
}: DrawerProps) {
  const [imagePreview, setImagePreview] = useState<string | null>(user?.avatarUrl ?? null);
  const [showPreviewAvatarModal, setShowPreviewAvatarModal] = useState<boolean>(false);

  const [form] = Form.useForm();
  const { data: event } = useCurrentEvent();
  const { mutateAsync: uploadUserImage } = useUploadUserImage();
  const { data: customFields, isLoading: customFieldsIsLoading } = useCustomFields(event?.id);

  const parsedUser = useMemo(
    () => ({
      ...user,
      subscribed: !user?.unsubscribed,
      roleId: user?.role?.id,
      fields:
        customFields?.reduce(
          (result, customField) => ({
            ...result,
            [customField.id]:
              user?.fields?.find((field) => field.customFieldId === customField.id)?.value || '',
          }),
          {},
        ) || {},
    }),
    [customFields, user],
  );

  useEffect(() => {
    if (visible) {
      if (parsedUser) {
        form.resetFields();
        form.setFieldsValue(parsedUser);
        setImagePreview(parsedUser?.avatarUrl ?? null);
      }
    }
  }, [visible, parsedUser, form]);

  if (customFieldsIsLoading) return null;

  const cancelButtonClicked = () => {
    form.resetFields();
    onClose();
  };

  const onDismiss = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        width: 'fit-content',
        icon: <WarningOutlined />,
        title: 'Unsaved Changes!',
        content: (
          <>
            <p>There are unsaved changes! </p>
            <p>Are you sure you want to exit without saving?</p>
            <p>
              Choose 'Cancel' to return to the editor and save changes.
              <br />
              Choose 'Discard' to exit without saving changes. Note, any unsaved changes will be
              lost.
            </p>
          </>
        ),
        cancelText: 'Cancel',
        onCancel: () => {},
        okText: 'Discard',
        onOk: cancelButtonClicked,
        okButtonProps: {
          danger: true,
        },
      });
    } else {
      cancelButtonClicked();
    }
  };

  const onSubmit = async (values: UserResource & { subscribed: boolean; avatar?: File | null }) => {
    await onFinish({
      ...values,
      avatarUrl: imagePreview ? undefined : null,
      unsubscribed: !values.subscribed,
    });

    form.resetFields();
    if (values.avatar) {
      try {
        if (!parsedUser.id) throw new Error('vle_error_no_user_id');
        await uploadUserImage({ userId: parsedUser.id, image: values.avatar });
      } catch {
        message.error('Unable to update user avatar');
      }
    }
  };

  const buttons = (
    <>
      <Button htmlType='submit' type='primary' onClick={form.submit}>
        {user ? '     Update Attendee' : '     Add Attendee'}
      </Button>
    </>
  );

  const footer = (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Button onClick={() => cancelButtonClicked()} style={{ marginRight: 10 }}>
        Cancel
      </Button>
      {buttons}
    </div>
  );

  const avatarView = (
    <Form.Item name='avatar' label='Avatar'>
      <ImageUpload
        initialPreview={imagePreview}
        onChangePreview={setImagePreview}
        onRemove={() => form.setFields([{ name: 'avatar', value: null }])}
        textOverwrite={[
          'The maximum file size is 5 MB and must be at least 300x300 pixels in either JPG or PNG formats.',
        ]}
        extraButtons={
          <>
            <Button disabled={!imagePreview} onClick={() => setShowPreviewAvatarModal(true)}>
              Preview
            </Button>
            <PreviewAvatarModal
              visible={showPreviewAvatarModal}
              user={
                user
                  ? { ...user, avatarUrl: imagePreview }
                  : ({ fullName: 'John Doe', avatarUrl: imagePreview } as UserResource)
              }
              onCancel={() => setShowPreviewAvatarModal(false)}
            />
          </>
        }
      />
    </Form.Item>
  );

  const firstAndLastName = (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name='firstName'
          label={<div style={{ fontWeight: 'bold' }}>First Name</div>}
          rules={[{ required: true }]}
        >
          <Input placeholder='John' />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name='lastName'
          label={<div style={{ fontWeight: 'bold' }}>Last Name</div>}
          rules={[{ required: true }]}
        >
          <Input placeholder='Doe' />
        </Form.Item>
      </Col>
    </Row>
  );

  const email = (
    <Col span={12}>
      <Form.Item
        style={{ fontWeight: 'bold' }}
        name='email'
        label={<div style={{ fontWeight: 'bold' }}>Email Address</div>}
        rules={[{ type: 'email', required: true }]}
      >
        <Input placeholder='email123@email.com' />
      </Form.Item>
    </Col>
  );

  const userRole = (
    <Col span={12}>
      <Form.Item
        name='roleId'
        label={<div style={{ fontWeight: 'bold' }}>User Role</div>}
        rules={[{ required: true }]}
      >
        <Select
          filterOption={(input, option) =>
            option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder='Select a user role.'
          options={
            roles?.map((role) => ({
              value: role.id,
              label: role.name,
            })) ?? []
          }
        />
      </Form.Item>
    </Col>
  );

  const attendance = (
    <Col span={12}>
      <Form.Item
        name='attendance'
        label={<div style={{ fontWeight: 'bold' }}>Attendance</div>}
        rules={[{ required: true }]}
      >
        <Select
          placeholder='Please choose the type'
          options={ATTENDANCE_TYPE.map((item) => ({
            value: item.key,
            label: item.value,
          }))}
        />
      </Form.Item>
    </Col>
  );

  const language = (
    <Col span={12}>
      <Form.Item
        name='language'
        label={<div style={{ fontWeight: 'bold' }}>Language</div>}
        rules={[{ required: true }]}
      >
        <Select
          placeholder='Select a default language.'
          options={Object.values(languages).filter(({ value }) =>
            event?.languages?.includes(value),
          )}
        />
      </Form.Item>
    </Col>
  );

  const boothIds = (
    <Col span={12}>
      <Form.Item name='boothIds' label={<div style={{ fontWeight: 'bold' }}>Virtual Booth</div>}>
        <Select mode='tags' allowClear placeholder='Virtual Booth' />
      </Form.Item>
    </Col>
  );

  const unsubscribe = (
    <Col span={12}>
      <Form.Item
        name='subscribed'
        label={<b>Subscribed to emails</b>}
        initialValue
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </Col>
  );

  const renderCustomFields =
    !customFields || customFields?.length === 0 ? null : (
      <>
        <Divider key='d1' />
        <h2>Custom Fields</h2>
        {customFields?.map((customField) => (
          <Col span={12} key={customField.id}>
            <Form.Item
              name={['fields', customField.id]}
              label={<b>{customField.label}</b>}
              rules={[{ required: customField.required, message: `This is a required field` }]}
            >
              {customField.type === CustomFieldType.INPUT ? (
                <Input placeholder={customField.label} />
              ) : (
                <Select allowClear filterOption={false} placeholder={customField.placeholder}>
                  {customField.options?.map((option) => (
                    <Select.Option key={option.key} value={option.key}>
                      {typeof option.label === 'string'
                        ? option.label
                        : option.label?.en ?? option.label?.[event!.defaultLanguage] ?? ''}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        ))}
      </>
    );

  return (
    <AntDrawer
      title={user ? 'Update Attendee' : 'Add New Attendee'}
      width={720}
      bodyStyle={{ paddingBottom: 80 }}
      footer={footer}
      onClose={onDismiss}
      visible={visible}
      destroyOnClose
      {...restProps}
    >
      <Form
        layout='vertical'
        validateMessages={{
          required: 'This is a required field.',
        }}
        onFinish={(values) => {
          onSubmit({
            ...values,
            fields: Object.keys(values.fields || {})
              ?.map((id) => ({
                value: values.fields[id],
                customFieldId: id,
              }))
              .filter((field) => field.value) as UserResource['fields'],
          });
        }}
        initialValues={parsedUser}
        key={user?.id}
        form={form}
      >
        {avatarView}
        {firstAndLastName}
        {email}
        {userRole}
        {attendance}
        {language}
        {boothIds}
        {unsubscribe}
        {renderCustomFields}
        <Divider key='d2' />
      </Form>
    </AntDrawer>
  );
}
