import { useCallback, useEffect, useState } from 'react';
import { Button, Space, Upload as AntUpload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/lib/upload';

import useAuth from '@hooks/auth/useAuth';

interface Props extends Omit<UploadProps, 'onChange'> {
  initialPreview?: string | null;
  extraButtons?: JSX.Element | null;
  extraRequirements?: string | JSX.Element | null | Array<string | JSX.Element | null>;
  noRequirements?: boolean;
  onUpload?: (image: string) => void;
  onRemove?: () => void;
  onChange?: (file: File | null) => void;
  onChangePreview?: (preview: string | null) => void;
  value?: any; // don't use this prop, this is just for antd definitions
  textOverwrite?: string | JSX.Element | null | Array<string | JSX.Element | null>;
}

export function ImageUpload({
  name = 'image',
  initialPreview = null,
  extraButtons,
  extraRequirements,
  noRequirements = false,
  onUpload,
  onRemove,
  onChange,
  onChangePreview,
  value,
  textOverwrite,
  ...props
}: Props) {
  const { refresh } = useAuth();
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState('');
  const [imagePreview, setImagePreview] = useState<string | null>(initialPreview);

  useEffect(() => {
    onChangePreview?.(imagePreview);
  }, [imagePreview, onChangePreview]);

  const onRemoveClick = useCallback(() => {
    setImagePreview(null);
    setImageError('');
    onRemove?.();
  }, [onRemove]);

  const beforeUpload = useCallback(
    async (file: RcFile): Promise<string | false> => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isCorrectSize = file.size / 1024 / 1024 < 5;

      if (!isJpgOrPng && !isCorrectSize) {
        setImageError(
          'You can only upload a file smaller than 5 MB and it must be a JPG or PNG file.',
        );
        return false;
      }

      if (!isJpgOrPng) {
        setImageError('You can only upload a JPG or PNG file.');
        return false;
      }

      if (!isCorrectSize) {
        setImageError('You can only upload a file smaller than 5 MB.');
        return false;
      }

      try {
        setLoading(true);
        setImageError('');

        const preview = await new Promise<string>((resolve, reject) => {
          const img = document.createElement('img');
          img.style.display = 'none';
          img.onerror = (error) => {
            img.remove();
            reject(error);
          };
          img.onload = () => {
            img.remove();
            resolve(reader.result as string);
          };

          const reader = new FileReader();
          reader.onerror = (error) => {
            reader.abort();
            reject(error);
          };
          reader.onload = () => {
            img.src = reader.result as string;
            document.body.append(img);
          };
          reader.readAsDataURL(file);
        });

        await refresh();

        // make sure Form.Item wrappers only have the File and not the complete object
        onChange?.(file ?? null);

        setImagePreview(preview);
      } catch (error) {
        setImageError('You can only upload a valid image file.');
      }

      setLoading(false);

      // prevent upload by Ant Design component
      return false;
    },
    [refresh, onChange],
  );

  return (
    <div>
      <Space align='start'>
        <div style={{ position: 'relative' }}>
          <AntUpload
            name={name}
            multiple={false}
            showUploadList={false}
            accept='.jpg,.jpeg,.png'
            listType='picture-card'
            beforeUpload={beforeUpload as any}
            {...props}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt='uploadImage'
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
            {loading && (
              <div
                style={{
                  position: 'absolute',
                  top: 'calc(50% - 4px)', // this is to account for 8px margin on <AntUpload />
                  left: 'calc(50% - 4px)', // this is to account for 8px margin on <AntUpload />
                  transform: 'translate(-50%, -50%)',
                  width: 32,
                  height: 32,
                  padding: 6,
                  borderRadius: '100%',
                  backgroundColor: '#d9d9d9',
                }}
              >
                <LoadingOutlined style={{ fontSize: 20 }} />
              </div>
            )}
          </AntUpload>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
          <Button danger disabled={!imagePreview} onClick={onRemoveClick}>
            Remove
          </Button>
          {extraButtons}
        </div>
      </Space>
      {!!imageError && <p style={{ color: 'red', margin: 0 }}>{imageError}</p>}
      {!noRequirements && !textOverwrite && (
        <div>
          The maximum file size is 5 MB and must be at least 380x600 pixels in either JPG or PNG
          formats. <br />
          Tip: There is a gradient filter overlay on this thumbnail to make the text legible.
          {extraRequirements && (
            <>
              <br />
              {Array.isArray(extraRequirements)
                ? extraRequirements.map((line, index) => [line, <br key={index.toString()} />])
                : extraRequirements}
            </>
          )}
        </div>
      )}
      {!!textOverwrite && (
        <div>
          <br />
          {Array.isArray(textOverwrite)
            ? textOverwrite.map((line, index) => [line, <br key={index.toString()} />])
            : textOverwrite}
        </div>
      )}
    </div>
  );
}
