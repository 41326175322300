import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Layout, Menu, message, Tooltip } from 'antd';
import {
  LogoutOutlined,
  LockOutlined,
  CopyOutlined,
  ApartmentOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';

import { useCurrentEvent } from '@hooks/useEvents';
import useAuth from '@hooks/auth/useAuth';
import { useLogout } from '@hooks/auth/useLogout';
import { TFA as TFAModal } from '@components/Modals';
import { useLanguage } from '@hooks/useTranslations';
import { protocol } from '@utils/protocol';
import type { OrganisationResource } from '@transforms/organisation';

import headerStyles from '../../styles/Header.module.css';

export default function Header() {
  const { selectedLanguage } = useLanguage();
  const location = useLocation();
  const [openTFAModal, setOpenTFAModal] = useState(false);

  const { user, isAuthorized, isSuperAdmin, organisation, selectOrganisation, isEventAdmin } =
    useAuth();
  const logout = useLogout();

  const { data: event } = useCurrentEvent(selectedLanguage);

  const editableEvent = event && !event.isPageTemplate;

  const onOpenTFAModal = () => {
    setOpenTFAModal(true);
  };

  const onCloseTFAModal = () => {
    setOpenTFAModal(false);
  };

  const copyEventLink = () => {
    if (event) {
      const success = copy(`${protocol}://${event.slug}.${process.env.REACT_APP_APP_URL}`);

      if (success) {
        message.success('Event link copied to clipboard');
      } else {
        message.error('Could not copy event link');
      }
    }
  };

  const openEventInNewTab = () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `${protocol}://${event?.slug}.${process.env.REACT_APP_APP_URL}`;
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  if (!isAuthorized) return null;

  const showOrganisations = user && user.organisations && user.organisations.length >= 1;

  return (
    <Layout.Header style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
      <Link to='/events'>
        <div style={{ cursor: 'pointer', width: '200px', height: '64px', padding: '7px 21px' }}>
          <img
            alt='VLE Logo'
            src='/vle_logo_white_transparent.png'
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              verticalAlign: 'top',
            }}
          />
        </div>
      </Link>
      <Menu
        theme='dark'
        mode='horizontal'
        selectedKeys={[location.pathname]}
        style={{ display: 'flex', flex: 1 }}
      >
        <Menu.Item key='/events'>
          <Link to='/events'>{location.pathname === '/events' ? 'Events' : 'All Events'}</Link>
        </Menu.Item>
        {editableEvent && event && (
          <>
            <Menu.Item key='event-links' className={headerStyles.noHover}>
              <b style={{ color: 'white' }}>{event?.name}</b>
              <Tooltip title='Copy event link to clipboard' color='blue'>
                <Button
                  size='small'
                  shape='circle'
                  icon={<CopyOutlined style={{ marginRight: '0' }} />}
                  style={{ marginLeft: '12px' }}
                  onClick={copyEventLink}
                />
              </Tooltip>
              <Tooltip title='View event in new browser tab' color='blue'>
                <Button
                  size='small'
                  shape='circle'
                  icon={<EyeOutlined style={{ marginRight: '0' }} />}
                  style={{ marginLeft: '12px' }}
                  onClick={openEventInNewTab}
                />
              </Tooltip>
            </Menu.Item>
          </>
        )}
        {!editableEvent && isSuperAdmin && (
          <Menu.Item key='/organisations'>
            <Link to='/organisations'>Organizations</Link>
          </Menu.Item>
        )}
        {!editableEvent && (isSuperAdmin || isEventAdmin) && (
          <Menu.Item key='/academy'>
            <Link to='/academy'>Support</Link>
          </Menu.Item>
        )}
        {!editableEvent && (isSuperAdmin ? true : !!organisation) && (
          <Menu.Item key='/analytics'>
            <Link to='/analytics'>Analytics</Link>
          </Menu.Item>
        )}
        {!editableEvent && (isSuperAdmin || organisation) && (
          <Menu.Item key='/admins'>
            <Link to='/admins'>Admins</Link>
          </Menu.Item>
        )}
        {showOrganisations && (
          <Menu.SubMenu
            style={{ marginLeft: 'auto' }}
            title={organisation?.name ?? 'No organization'}
            icon={<ApartmentOutlined />}
          >
            <Menu.Item key='/organisation' onClick={() => selectOrganisation(null)}>
              No organization
            </Menu.Item>
            {user.organisations!.map((org) => (
              <Menu.Item
                key={org.id}
                onClick={() => selectOrganisation(org as OrganisationResource)}
              >
                {org.name}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}
        <Menu.SubMenu
          key='2fa'
          title={user?.email}
          style={{ marginLeft: showOrganisations ? undefined : 'auto' }}
        >
          <Menu.Item key='tfa' onClick={() => onOpenTFAModal()} icon={<LockOutlined />}>
            2FA
          </Menu.Item>
          <Menu.Item key='logout' onClick={() => logout()} icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
      <TFAModal visible={openTFAModal} onCancel={onCloseTFAModal} />
    </Layout.Header>
  );
}
