import React, { useCallback, useMemo, useState } from 'react';
import { Form, Input, Switch, Select, Alert, FormInstance } from 'antd';
import debounce from 'lodash/debounce';
import { Dayjs } from 'dayjs';

import useAuth from '@hooks/auth/useAuth';
import { useTemplateEvents } from '@hooks/useEvents';
import { useInfiniteOrganisations } from '@hooks/useOrganisations';
import { OrganisationResource } from '@transforms/organisation';
import { EventResource, Event as iEvent } from '@transforms/event';
import DatePicker from '@components/DatePicker';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface EventProps extends DataEntryProps {
  data?: EventResource;
  hasConflict?: string;
  failedValidation?: boolean;
  currentOrganisationId?: string;
}

export function Event({
  hasConflict = undefined,
  failedValidation = false,
  onCreate,
  currentOrganisationId,
  ...restProps
}: EventProps) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [form] = Form.useForm();
  const beforeOnCreate = (
    values: iEvent & { dates: Dayjs[]; templateId?: string },
    done: () => void,
  ) => {
    const { dates, organisationId, templateId, slug, ...restValues } = values;
    const activeRange = form.getFieldValue('activeRange');

    onCreate(
      {
        ...restValues,
        slug: slug.toLowerCase(),
        activeFrom: activeRange ? activeRange[0] : dates[0],
        activeTill: activeRange ? activeRange[1] : dates[1],
        startsAt: dates[0],
        endsAt: dates[1],
        organisationId: organisationId === '__NONE__' ? null : organisationId,
        templateId: templateId === '__NONE__' ? null : templateId,
      },
      done,
    );
  };

  const showValidationMessage = () => {
    if (failedValidation) return 'Please fill in the slug with only lowercase letters';
    if (hasConflict) return 'An event with this slug already exists';
    return undefined;
  };

  return (
    <DataEntry {...restProps} form={form} onCreate={beforeOnCreate} name='event'>
      <TemplateFields form={form} currentOrganisationId={currentOrganisationId} />
      <Form.Item
        name='name'
        label='Name'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='name' />
      </Form.Item>
      <Form.Item
        name='description'
        label='Description'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea placeholder='description' showCount maxLength={1000} />
      </Form.Item>
      <Form.Item
        name='slug'
        label='slug'
        rules={[
          {
            required: true,
          },
        ]}
        validateStatus={hasConflict === 'vle_error_slug_already_exist' ? 'error' : undefined}
        help={showValidationMessage()}
      >
        <Input placeholder='slug' prefix='https://' suffix={`.${process.env.REACT_APP_APP_URL}`} />
      </Form.Item>
      <Form.Item
        name='dates'
        label='Event Dates'
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/* @ts-ignore */}
        <DatePicker.RangePicker placeholder={['Start Date', 'End Date']} />
      </Form.Item>
      <Form.Item name='active' label='Event Active' valuePropName='checked' initialValue={isActive}>
        <Switch onChange={() => setIsActive(!isActive)} />
      </Form.Item>

      {isActive && (
        <Form.Item
          name='activeRange'
          label='Active Dates'
          extra='Determine the range in which an event is active. Outside of these dates, the event will automatically be made inactive.'
          initialValue={form.getFieldValue('dates')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {/* @ts-ignore */}
          <DatePicker.RangePicker placeholder={['Start Date', 'End Date']} />
        </Form.Item>
      )}

      {hasConflict === 'vle_error_active_event_limit_exceeded' && (
        <Alert
          message='Active Event Limit exceeded'
          type='error'
          showIcon
          description="You can't add any more active events to this organisation"
        />
      )}
      {hasConflict === 'vle_error_event_limit_exceeded' && (
        <Alert
          message='Event Limit exceeded'
          type='error'
          showIcon
          description="You can't add any more events to this organisation"
        />
      )}
    </DataEntry>
  );
}

function TemplateFields({
  form,
  currentOrganisationId,
}: {
  form: FormInstance<any>;
  currentOrganisationId?: string;
}) {
  const { isSuperAdmin } = useAuth();
  const { data: templateEvents } = useTemplateEvents();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrg, _setSelectedOrg] = useState(
    isSuperAdmin ? '__NONE__' : currentOrganisationId ?? '__NONE__',
  );
  const {
    data: rawOrganisations,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteOrganisations(searchTerm);

  const setSelectedOrg = useCallback(
    (org: string) => {
      _setSelectedOrg((prev) => {
        if (prev !== org) form.setFieldsValue({ templateId: '__NONE__' });
        return org;
      });
    },
    [_setSelectedOrg, form],
  );

  const organisations = useMemo(() => {
    const paginatedOrganisations: OrganisationResource[] = [];
    rawOrganisations?.pages?.map((organisationData) => {
      return paginatedOrganisations.push(...organisationData.items);
    });

    return paginatedOrganisations;
  }, [rawOrganisations]);

  const templates = useMemo(
    () =>
      templateEvents?.filter(
        (template) =>
          template.organisationId === (selectedOrg === '__NONE__' ? undefined : selectedOrg),
      ) ?? [],
    [templateEvents, selectedOrg],
  );

  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  const onScroll = (event: any) => {
    const { target } = event;
    if (!isFetching && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (hasNextPage) {
        fetchNextPage();
      }
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const renderOrganisationOptions = () => {
    return (
      <>
        <Select.Option value='__NONE__' key='__NONE__'>
          None
        </Select.Option>
        {organisations
          ?.filter((organisation) => organisation.eventTemplatesEnabled)
          .map((organisation) => (
            <Select.Option value={organisation.id} key={organisation.id}>
              {organisation.name}
            </Select.Option>
          ))}
      </>
    );
  };

  const renderTemplateOptions = () => {
    return (
      <>
        <Select.Option value='__NONE__' key='__NONE__'>
          None
        </Select.Option>
        {templates.map((template) => (
          <Select.Option value={template.id} key={template.id}>
            {template.name}
          </Select.Option>
        ))}
      </>
    );
  };

  return (
    <>
      {isSuperAdmin && (
        <Form.Item name='organisationId' label='Organization' initialValue='__NONE__'>
          <Select
            style={{ width: '100%' }}
            showSearch
            onSearch={onSearch}
            onPopupScroll={onScroll}
            filterOption={false}
            onSelect={(id) => setSelectedOrg(id?.toString()!)}
          >
            {renderOrganisationOptions()}
          </Select>
        </Form.Item>
      )}
      {organisations.find((org) => org.id === selectedOrg)?.eventTemplatesEnabled &&
        templates.length > 0 && (
          <Form.Item name='templateId' label='Template' initialValue='__NONE__'>
            <Select
              style={{ width: '100%' }}
              showSearch
              onSearch={onSearch}
              onPopupScroll={onScroll}
              filterOption={false}
            >
              {renderTemplateOptions()}
            </Select>
          </Form.Item>
        )}
    </>
  );
}
