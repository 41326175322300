import { useEffect, useState } from 'react';
import { Modal, Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';

export interface DataEntryProps {
  visible: boolean;
  errorHandler?: (ex: Error) => void;
  onCreate: (values: any, done: (ex?: Error) => void) => void;
  onUpdate: (values: any, done: (ex?: Error) => void, shouldClose?: boolean) => void;
  onCancel: () => void;
  name?: string;
  data?: any;
  children?: React.ReactNode;
  form?: FormInstance<any>;
}

export function DataEntry({
  visible,
  errorHandler,
  onCreate,
  onUpdate,
  onCancel,
  name,
  data,
  children,
  form,
}: DataEntryProps) {
  const isUpdating = !!data;

  const [internalForm] = Form.useForm(form);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      if (data) {
        internalForm.setFieldsValue(data);
      } else {
        internalForm.resetFields();
      }
    }
  }, [visible, data, internalForm]);

  const cancelButtonClicked = () => {
    internalForm.resetFields();
    onCancel();
  };

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    if (internalForm?.isFieldsTouched?.()) {
      if (target.className !== 'ant-btn' && target.parentElement?.className !== 'ant-btn') {
        Modal.confirm({
          width: 'fit-content',
          icon: <WarningOutlined />,
          title: 'Unsaved Changes!',
          content: (
            <>
              <p>There are unsaved changes! </p>
              <p>Are you sure you want to exit without saving?</p>
              <p>
                Choose 'Cancel' to return to the editor and save changes.
                <br />
                Choose 'Discard' to exit without saving changes. Note, any unsaved changes will be
                lost.
              </p>
            </>
          ),
          cancelText: 'Cancel',
          onCancel: () => {},
          okText: 'Discard',
          onOk: cancelButtonClicked,
          okButtonProps: {
            danger: true,
          },
        });
        return;
      }
    }
    cancelButtonClicked();
  };

  return (
    <Modal
      visible={visible}
      title={isUpdating ? `Update ${name}` : `Add New ${name}`}
      // TODO: check if this is okay everywhere
      okText={isUpdating ? `Update ${name}` : `Add ${name}`}
      cancelText='Cancel'
      onOk={() => {
        internalForm.validateFields().then((values: any) => {
          setLoading(true);
          try {
            (isUpdating ? onUpdate : onCreate)(values, (error) => {
              if (!error) internalForm.resetFields();
              if (error) {
                if (errorHandler) errorHandler(error);
                else message.error('An error occurred');
              }
              setLoading(false);
            });
          } catch {
            setLoading(false);
          }
        });
      }}
      okButtonProps={{
        disabled: loading,
      }}
      onCancel={handleCancel}
      forceRender
    >
      <Form
        layout='vertical'
        initialValues={data}
        key={data?.id}
        form={internalForm}
        validateMessages={{
          required: 'This is a required field.',
        }}
      >
        {children}
      </Form>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
          opacity: loading ? 1 : 0,
          transition: 'opacity 0.5s ease',
          pointerEvents: loading ? 'all' : 'none',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingOutlined style={{ color: '#1990FF', fontSize: 30 }} />
      </div>
    </Modal>
  );
}
