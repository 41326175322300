import parse, { Attributes } from '@utils/parse';
import { transformStringArray } from '@utils/transformArray';
import { getUserId } from '@hooks/auth/useAuth';

import parseMeta from './meta';
import { parseTag } from './tag';
import { parseCustomField } from './customField';
import { parseSession } from './session';
import { parseUserRole } from './userRole';

export enum CallStatus {
  AVAILABLE = 'AVAILABLE',
  IN_CALL = 'IN_CALL',
  DO_NOT_DISTURB = 'DO_NOT_DISTURB',
}

export enum AttendanceType {
  ONLINE = 'ONLINE',
  ONSITE = 'ONSITE',
}

export const ATTENDANCE_TYPE = [
  { key: AttendanceType.ONLINE, value: 'Online' },
  { key: AttendanceType.ONSITE, value: 'Onsite' },
];

export const getAttendanceValue = (attendance: string) =>
  ATTENDANCE_TYPE.find((atype) => atype.key === attendance)?.value ?? attendance;

export const parseTags = (attributes: Attributes) => {
  return {
    ...attributes.custom('tag', (tag: any) => {
      return parse.one(tag, parseTag);
    }),
  };
};

export const parseFields = (attributes: Attributes) => {
  return {
    value: attributes.string('value'),
    ...attributes.custom('customField', (customField: any) => {
      const parsedField = parse.one(customField, parseCustomField);
      return {
        key: parsedField.key,
        label: parsedField.label,
        required: parsedField.required,
        customFieldId: parsedField.id,
      };
    }),
  };
};

const parseUserSpeakers = (attributes: Attributes) => {
  return {
    id: attributes.string('id'),
    sessions: attributes.custom('sessions', (sessions) => {
      return parse.many(sessions, parseSession);
    }),
  };
};

export const parseUser = (attributes: Attributes) => {
  const id = attributes.id('id');
  const firstName = attributes.optional.string('firstName');
  const lastName = attributes.optional.string('lastName');
  return {
    id,
    email: attributes.optional.string('email'),
    callStatus: attributes.enum<typeof CallStatus>('callStatus', CallStatus),
    role: attributes.optional.custom('role', (role: any) => {
      return parse.one(role, parseUserRole);
    }),
    avatarUrl: attributes.optional.string('avatarUrl'),
    firstName,
    lastName,
    fullName: firstName && lastName && `${firstName} ${lastName}`,
    attendance: attributes.enum<typeof AttendanceType>('attendance', AttendanceType),
    language: attributes.optional.string('language'),
    tags: attributes.custom('tags', (tags: any) => {
      return parse.many(tags, parseTags);
    }),
    fields: attributes.custom('fields', (fields: any) => {
      return parse.many(fields, parseFields);
    }),
    company: attributes.optional.string('company'),
    function: attributes.optional.string('jobtitle'),
    linkedIn: attributes.optional.string('linkedin'),
    videoLink: attributes.optional.string('videolink'),
    boothIds: attributes.optional.custom('boothIds', transformStringArray),
    lastLoginAt: attributes.optional.date('lastLoginAt'),
    lastVisitAt: attributes.optional.date('lastVisitAt'),
    lastActivityAt: attributes.optional.date('lastActivityAt'),
    loginToken: attributes.optional.string('loginToken'),
    acceptedAt: attributes.optional.date('acceptedAt'),
    privacyPolicyAcceptedAt: attributes.optional.date('privacyPolicyAcceptedAt'),
    isOnline: attributes.optional.boolean('active') || false,
    isMe: getUserId() === id,
    accepted: attributes.optional.boolean('accepted'),
    privacyPolicyAccepted: attributes.optional.boolean('privacyPolicyAccepted'),
    unsubscribed: attributes.optional.boolean('unsubscribed'),
    speakers: attributes.optional.custom('speakers', (speakers) => {
      return parse.many(speakers, parseUserSpeakers);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseUser);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseUser),
    meta: parseMeta(data.meta),
  };
}

export type UserResource = ReturnType<typeof one>;
export type User = Omit<UserResource, 'id'>;

export default { one, many };
