import parse, { Attributes } from '@utils/parse';

export const parseCustomFieldOption = (attributes: Attributes) => {
  return {
    key: attributes.string('key'),
    label: attributes.custom('label', (data) => data as string | Record<string, string>),
  };
};

export function one(data: any) {
  return parse.one(data, parseCustomFieldOption);
}

export function many(data: any) {
  return parse.many(data, parseCustomFieldOption);
}

export type CustomFieldOption = ReturnType<typeof one>;

export default { one, many };
