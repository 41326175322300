import { useQuery, useMutation, useQueryClient } from 'react-query';

import transform, { Video } from '@transforms/video';
import transformCategory, { VideoCategory } from '@transforms/videoCategory';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import { eventVideoImageEndpoint } from '@utils/uploadEndpoints';

export const useVideo = (eventId: string, videoId: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['video', eventId, videoId, language], async () => {
    const { body } = await fetch(languageUrl(`/events/${eventId}/videos/${videoId}`, language), {
      token: accessToken,
    });

    return transform.one(body);
  });
};

export const useVideos = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['videos', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(languageUrl(`/events/${eventId}/videos/admin`, language), {
        token: accessToken,
      });

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useCreateVideo = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Video) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/videos`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
        queryClient.invalidateQueries('videos');
      },
    },
  );
};

export const useDuplicateVideo = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/videos/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
        queryClient.invalidateQueries('videos');
      },
    },
  );
};

export const useUpdateVideo = (eventId?: string, videoId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Video) => {
      if (!eventId) throw new Error('No event');
      if (!videoId) throw new Error('No video');

      return fetch(languageUrl(`/events/${eventId}/videos/${videoId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
        queryClient.invalidateQueries('videos');
      },
    },
  );
};

export const useUploadVideoImage = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ videoId, image }: { videoId: string; image: File | Blob }) => {
      if (!eventId) throw new Error('No event');

      const body = new FormData();
      body.append('image', image);

      return fetch(eventVideoImageEndpoint(eventId, videoId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
        queryClient.invalidateQueries('videos');
      },
    },
  );
};

export const useDeleteVideo = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (videoId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/videos/${videoId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
        queryClient.invalidateQueries('videos');
      },
    },
  );
};

export const useVideoCategory = (eventId?: string, videoCategoryId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['videoCategory', eventId, videoCategoryId, language], async () => {
    if (!eventId) throw new Error('No event');
    if (!videoCategoryId) throw new Error('No video category');

    const { body } = await fetch(
      languageUrl(`/events/${eventId}/videos/categories/${videoCategoryId}`, language),
      {
        token: accessToken,
      },
    );

    return transformCategory.one(body);
  });
};

export const useVideoCategories = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['videoCategories', eventId, language], async () => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(languageUrl(`/events/${eventId}/videos/categories`, language), {
      token: accessToken,
    });

    return transformCategory.many(body);
  });
};

export const usePageVideoCategories = (eventId?: string, pageId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['videoCategories', eventId, pageId, language], async () => {
    if (!eventId) throw new Error('No event');
    if (!pageId) throw new Error('No page');

    const { body } = await fetch(
      languageUrl(`/events/${eventId}/pages/${pageId}/videos/admin`, language),
      {
        token: accessToken,
      },
    );

    return transformCategory.many(body);
  });
};

export const useCreateVideoCategory = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: VideoCategory) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/videos/categories`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
      },
    },
  );
};

export const useDuplicateVideoCategory = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/videos/categories/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
        queryClient.invalidateQueries('videos');
      },
    },
  );
};

export const useUpdateVideoCategory = (
  eventId?: string,
  videoCategoryId?: string,
  language?: string,
) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: VideoCategory) => {
      if (!eventId) throw new Error('No event');

      return fetch(
        languageUrl(`/events/${eventId}/videos/categories/${videoCategoryId}`, language),
        {
          method: 'PUT',
          body: values,
          token: accessToken,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
      },
    },
  );
};

export const useDeleteVideoCategory = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (videoCategoryId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/videos/categories/${videoCategoryId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('videoCategories');
      },
    },
  );
};

export default {
  useVideo,
  useVideos,
  useVideoCategory,
  useVideoCategories,
  usePageVideoCategories,
  useCreateVideo,
  useUpdateVideo,
  useDeleteVideo,
  useCreateVideoCategory,
  useUpdateVideoCategory,
  useDeleteVideoCategory,
};
