import React, { useEffect, useState } from 'react';
import { MoreOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Alert, Button, Divider, message } from 'antd';

import { ListItem } from '@components/CustomFields';
import { MandatoryFieldModal } from '@components/Modals/MandatoryField';

import reorder from '@utils/reorder';
import { EventResource } from '@transforms/event';
import { MandatoryField } from '@transforms/mandatoryField';
import { useUpdateEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { useMandatoryFields } from '@hooks/useMandatoryField';

interface Props {
  readonly event?: EventResource;
}
type MandatoryFieldDragDrop = MandatoryField & {
  position: number | undefined | null;
  label: string | undefined | null;
};

export default function DragDropMandatoryFields({ event }: Props) {
  const { selectedLanguage } = useLanguage();
  const defaultFieldOrder: MandatoryField[] = [
    { id: '0', key: 'firstname' },
    { id: '1', key: 'lastname' },
    { id: '2', key: 'email' },
    { id: '3', key: 'language' },
    { id: '4', key: 'attendance' },
  ];

  const filters = [
    ...(event?.languages && event.languages.length < 2 ? ['language'] : []),
    ...(!event?.attendanceEnabled ? ['attendance'] : []),
  ];

  const [initialDraggableItems, setInitialDraggableItems] = useState<
    MandatoryFieldDragDrop[] | null | undefined
  >((event?.mandatoryFieldOrder || defaultFieldOrder) as MandatoryFieldDragDrop[]);

  const [draggableItems, setDraggableItems] = useState<MandatoryFieldDragDrop[] | null | undefined>(
    ((event?.mandatoryFieldOrder || defaultFieldOrder) as MandatoryFieldDragDrop[])?.map(
      (field, index) => ({
        ...field,
        position: index,
      }),
    ),
  );

  const moveFilteredItemsToBack = (list: MandatoryFieldDragDrop[]) => {
    if (filters) {
      filters.forEach((filter) =>
        list.push(
          list.splice(
            list.findIndex((field) => field.key === filter),
            1,
          )[0],
        ),
      );
    }
    return list;
  };

  const [openMandatoryFieldModal, setOpenMandatoryFieldModal] = useState(false);
  const [selectedMandatoryField, setSelectedMandatoryField] = useState<
    MandatoryField | undefined
  >();

  const { mutate: updateEvent } = useUpdateEvent(event?.id);
  const { data: mandatoryFieldTranslations } = useMandatoryFields(event?.id, selectedLanguage);

  const [isDragged, setIsDragged] = useState<boolean>(false);

  useEffect(() => {
    const translatedItems = draggableItems?.map((field) => ({
      ...field,
      label:
        mandatoryFieldTranslations?.find((translation) => translation.key === field.key)?.label ??
        field.label,
    }));
    setDraggableItems(translatedItems);
    setInitialDraggableItems(translatedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatoryFieldTranslations, setDraggableItems]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sorted: any = reorder(draggableItems, result.source.index, result.destination.index);
    setDraggableItems(sorted);
    setIsDragged(true);
  };

  const getListItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    index: number,
  ) => {
    const defaultStyles = {
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      cursor: 'inherit',
    };

    // eslint-disable-next-line no-nested-ternary
    const borderTop = isDragging ? '1px solid #f0f0f0' : index > 0 ? '1px solid #f0f0f0' : '';
    const borderBottom = isDragging ? '1px solid #f0f0f0' : '';

    return { ...draggableStyle, ...defaultStyles, borderTop, borderBottom };
  };

  const onOpenCustomFieldModal = (mandatoryField?: MandatoryField) => {
    if (mandatoryField) {
      setSelectedMandatoryField(mandatoryField);
    } else {
      setSelectedMandatoryField(undefined);
    }
    setOpenMandatoryFieldModal(true);
  };

  const onCloseCustomFieldModal = () => {
    setOpenMandatoryFieldModal(false);
    setSelectedMandatoryField(undefined);
  };

  const onUpdateCustomField = (values: MandatoryFieldDragDrop, done: (err?: Error) => void) => {
    const oldPos: number | undefined =
      draggableItems?.find((field) => field.key === values.key)?.position ?? undefined;
    const sorted: MandatoryFieldDragDrop[] = reorder(
      draggableItems,
      oldPos,
      values.position ?? undefined,
    );

    const orderdItems = sorted?.map((field, index: number) => ({
      ...field,
      position: index,
    }));

    setDraggableItems(orderdItems);
    setInitialDraggableItems(orderdItems);

    updateEvent(
      {
        ...event!,
        mandatoryFieldOrder: moveFilteredItemsToBack(orderdItems).map(
          ({ label, position, ...field }) => field,
        ),
      },
      {
        onSuccess: async () => {
          onCloseCustomFieldModal();
          done();
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  const undoReorder = () => {
    setDraggableItems(initialDraggableItems);
    setIsDragged(false);
  };

  const saveReoder = () => {
    if (!draggableItems) {
      message.error('Unable to update order');
      return;
    }
    const orderdItems = draggableItems.map((tag, index: number) => ({
      ...tag,
      position: index,
    }));
    setInitialDraggableItems(orderdItems);
    setDraggableItems(orderdItems);

    updateEvent(
      {
        ...event!,
        mandatoryFieldOrder: moveFilteredItemsToBack(orderdItems).map(
          ({ label, position, ...field }) => field,
        ),
      },
      {
        onSuccess: () => {
          message.success({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Order Updated!</h3>
                  <span>Your changes have been successfully updated and saved!</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        },
        onError: () => {
          message.error({
            content: (
              <div className='message-box-content-container'>
                <div className='message-box-content'>
                  <h3>Update Failed!</h3>
                  <span>An error occured while saving your changes. Please try again.</span>
                </div>
              </div>
            ),
            className: 'message-box',
          });
        },
      },
    );
    setIsDragged(false);
  };

  return (
    <>
      {isDragged && (
        <div style={{ maxWidth: '300px', marginBottom: '20px' }}>
          <Alert type='warning' message='Order has been changed!' showIcon />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Button block icon={<UndoOutlined />} onClick={undoReorder}>
              Undo
            </Button>
            <Divider key='d1' type='vertical' />
            <Button block type='primary' icon={<SaveOutlined />} onClick={saveReoder}>
              Save
            </Button>
          </div>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='mandatory-fields'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems
                ?.filter((field) => !filters.includes(field.key))
                ?.map((field: MandatoryField, index: number) => (
                  <Draggable key={field.id} draggableId={field.id} index={index}>
                    {(providedDeep, snapshot) => (
                      <div
                        {...providedDeep.draggableProps}
                        {...providedDeep.dragHandleProps}
                        ref={providedDeep.innerRef}
                        style={getListItemStyle(
                          snapshot.isDragging,
                          providedDeep.draggableProps.style,
                          index,
                        )}
                      >
                        <MoreOutlined style={{ marginRight: '10px', cursor: 'grab' }} />
                        <ListItem
                          mandatory
                          customField={field}
                          onEdit={() => onOpenCustomFieldModal(field)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <MandatoryFieldModal
        visible={openMandatoryFieldModal}
        data={selectedMandatoryField}
        onCancel={onCloseCustomFieldModal}
        onCreate={() => null}
        onUpdate={onUpdateCustomField}
      />
    </>
  );
}
