import { useCallback } from 'react';

import fetch from '@utils/fetch';
import { writeRefreshToken } from '@utils/auth';
import transformToken from '@transforms/token';
import transform from '@transforms/admin';
import type { OrganisationResource } from '@transforms/organisation';

import { tokens, useAuth } from './useAuth';

export function useLogin() {
  const { setIsAuthorized, setOrganisation, setTfaRequired, setUser, setError } = useAuth();

  return useCallback(
    async (
      email: string,
      password: string,
      recaptchaToken: string,
    ): Promise<{ isAuthorized: boolean; tfaRequired: boolean }> => {
      try {
        const response = await fetch('/auth/login', {
          method: 'POST',
          body: { email, password, recaptchaToken },
        });

        const tokenTransform = transformToken(response.body);
        tokens.accessToken = tokenTransform.accessToken;
        tokens.refreshToken = tokenTransform.refreshToken;

        if (tokenTransform.tfaRequired) {
          setError(undefined);
          setIsAuthorized(false);
          setTfaRequired(true);
          setUser(undefined);
          return {
            isAuthorized: false,
            tfaRequired: true,
          };
        }

        writeRefreshToken(tokens.refreshToken);

        try {
          const userResponse = await fetch('/admins/me', { token: tokens.accessToken });
          const userTransform = transform.one(userResponse.body);

          setError(undefined);
          setIsAuthorized(true);
          setUser(userTransform);

          const firstOrganisation = userTransform.organisations?.[0];
          if (firstOrganisation) {
            localStorage.setItem('organisation', firstOrganisation.id);
            setOrganisation(firstOrganisation as OrganisationResource);
          } else {
            localStorage.removeItem('organisation');
            setOrganisation(undefined);
          }
        } catch (err) {
          setError(err);
        }

        return {
          isAuthorized: true,
          tfaRequired: false,
        };
      } catch (err) {
        setError(err);
        return {
          isAuthorized: false,
          tfaRequired: false,
        };
      }
    },
    [setIsAuthorized, setOrganisation, setTfaRequired, setUser, setError],
  );
}
