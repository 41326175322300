import { useEffect } from 'react';
import { Form, Drawer as AntDrawer, Col, Row, Button, Input, Divider, message, Modal } from 'antd';
import { DrawerProps as AntDrawerProps } from 'antd/lib/drawer';
import { WarningOutlined } from '@ant-design/icons';

import { AdminResource } from '@transforms/admin';
import { useResendInvitation } from '@hooks/useAdmins';

type DrawerProps = AntDrawerProps & {
  user?: AdminResource;
  eventId?: string;
  onClose: () => void;
  onFinish: (values: AdminResource) => void;
};

export function Drawer({ user, eventId, onClose, onFinish, visible, ...restProps }: DrawerProps) {
  const [form] = Form.useForm();

  const resendInvitation = useResendInvitation(eventId!);

  useEffect(() => {
    if (visible) {
      if (user) {
        form.resetFields();
        form.setFieldsValue(user);
      }
    }
  }, [visible, user, form]);

  const cancelButtonClicked = () => {
    form.resetFields();
    onClose();
  };

  const onDismiss = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        width: 'fit-content',
        icon: <WarningOutlined />,
        title: 'Unsaved Changes!',
        content: (
          <>
            <p>There are unsaved changes! </p>
            <p>Are you sure you want to exit without saving?</p>
            <p>
              Choose 'Cancel' to return to the editor and save changes.
              <br />
              Choose 'Discard' to exit without saving changes. Note, any unsaved changes will be
              lost.
            </p>
          </>
        ),
        cancelText: 'Cancel',
        onCancel: () => {},
        okText: 'Discard',
        onOk: cancelButtonClicked,
        okButtonProps: {
          danger: true,
        },
      });
    } else {
      cancelButtonClicked();
    }
  };

  const onSubmit = (values: AdminResource) => {
    onFinish(values);
    message.success('Editor saved');
    onClose();
  };

  const resendInvite = async () => {
    if (!user?.id) return message.error('Failed to send invitation');

    try {
      await resendInvitation(user.id);
      return message.success('Invitation sent successfully');
    } catch {
      return message.error('Failed to send invitation');
    }
  };

  const saveButton = (
    <>
      <Button htmlType='submit' type='primary' onClick={form.submit}>
        Save Changes
      </Button>
      {!!user?.id && !!eventId && (
        <Button htmlType='button' type='primary' style={{ marginLeft: 10 }} onClick={resendInvite}>
          Resend Invitation
        </Button>
      )}
    </>
  );

  const footer = (
    <Row
      style={{
        textAlign: 'right',
        float: 'right',
      }}
    >
      <Button onClick={() => cancelButtonClicked()} style={{ marginRight: 10 }}>
        Cancel
      </Button>
      {saveButton}
    </Row>
  );

  const email = (
    <Col span={12}>
      <Form.Item
        name='email'
        label='Email Address'
        rules={[{ required: true, message: 'Attendee Email Address' }]}
      >
        <Input placeholder='Please enter email address' />
      </Form.Item>
    </Col>
  );

  return (
    <>
      <AntDrawer
        title={user ? 'Edit Profile' : 'Create a new account'}
        width={720}
        bodyStyle={{ paddingBottom: 80 }}
        footer={footer}
        onClose={onDismiss}
        visible={visible}
        {...restProps}
      >
        <Form
          layout='vertical'
          onFinish={(values) => onSubmit(values)}
          initialValues={user}
          form={form}
          key={user?.id}
        >
          {email}
          <Divider key='d1' />
        </Form>
      </AntDrawer>
    </>
  );
}
