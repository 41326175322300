/* eslint-disable camelcase */
/* eslint-disable security/detect-object-injection */
/* eslint-disable import/extensions */
/* eslint-disable global-require */

import countries from 'i18n-iso-countries';

export interface Country {
  key: string;
  label: string | Record<string, string> | { [key: string]: string };
}

export const countryList = (): Country[] => {
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));

  const countriesListFr = Object.fromEntries(Object.entries(countries.getNames('fr')).sort());
  const countriesListEn = Object.fromEntries(Object.entries(countries.getNames('en')).sort());
  const countriesListDe = Object.fromEntries(Object.entries(countries.getNames('de')).sort());
  const countriesListEs = Object.fromEntries(Object.entries(countries.getNames('es')).sort());
  const countriesListIt = Object.fromEntries(Object.entries(countries.getNames('it')).sort());
  const countriesListNl = Object.fromEntries(Object.entries(countries.getNames('nl')).sort());

  const formatCountries = () => {
    const list: Country[] = [];
    Object.keys(countriesListEn).forEach((country) => {
      list.push({
        key: country.toLowerCase().replace(/\s+/g, '-'),
        label: {
          de: countriesListDe[country],
          en: countriesListEn[country],
          fr: countriesListFr[country],
          es: countriesListEs[country],
          it: countriesListIt[country],
          nl: countriesListNl[country],
          nl_BE: countriesListNl[country],
        },
      });
    });

    return list;
  };
  return formatCountries();
};
