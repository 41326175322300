import React from 'react';
import { List, Button, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';

import { protocol } from '@utils/protocol';
import { EventResource } from '@transforms/event';
import { VideoCallResource } from '@transforms/videoCall';

export default function ListItem({
  event,
  videoCall,
  onDelete,
  onEdit,
}: {
  event?: EventResource;
  videoCall: VideoCallResource;
  onDelete?: (id: string) => void;
  onEdit?: (videoCall: VideoCallResource) => void;
}) {
  return (
    <List.Item
      key={videoCall.title}
      actions={[
        <Tooltip title='Copy URL to clipboard' key='1'>
          <Button
            shape='circle'
            icon={<LinkOutlined />}
            onClick={() =>
              copy(
                `${protocol}://${event?.slug}.${process.env.REACT_APP_APP_URL}/?videoCall=${videoCall.id}`,
              )
            }
          />
        </Tooltip>,
        <>
          {onEdit && (
            <Tooltip title='Edit' key='2'>
              <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(videoCall)} />
            </Tooltip>
          )}
        </>,
        <>
          {onDelete && (
            <Popconfirm
              title='Are you sure you want to delete this video call?'
              onConfirm={() => onDelete(videoCall.id)}
              okText='Delete'
              cancelText='Cancel'
              key='3'
            >
              <Button shape='circle' icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </>,
      ]}
    >
      <List.Item.Meta
        title={videoCall.title}
        description={
          <>
            {videoCall.description && <b>{videoCall.description}</b>}
            {videoCall.position && <p>Position {videoCall.position}</p>}
            <p>
              {dayjs(videoCall.startDate).format('YYYY-MM-DD HH:mm')}
              {' - '}
              {dayjs(videoCall.endDate).format('YYYY-MM-DD HH:mm')}
            </p>
            <p>Video: {videoCall.video ? 'enabled' : 'disabled'}</p>
            <p>Audio: {videoCall.audio ? 'enabled' : 'disabled'}</p>
            <p>Screen sharing: {videoCall.screenShare ? 'enabled' : 'disabled'}</p>
            <p>Chat: {videoCall.chat ? 'enabled' : 'disabled'}</p>
            {videoCall.maxUsers && <p>Maximum participants: {videoCall.maxUsers}</p>}
            {videoCall.roles && videoCall.roles.length > 0 && (
              <p>Allowed roles: {videoCall.roles.map((role) => role.name).join(', ')}</p>
            )}
            {videoCall.imageUrl && (
              <img alt='' style={{ maxWidth: '100pt' }} src={videoCall.imageUrl} />
            )}
          </>
        }
      />
    </List.Item>
  );
}
