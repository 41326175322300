import React from 'react';
import { Modal } from 'antd';

import { Event } from '@transforms/event';

import styles from '../../styles/ThemePreview.module.css';

type PreviewBackgroundModalProps = {
  visible: boolean;
  event: Partial<Event> | undefined;
  onCancel(): void;
};

export function PreviewBackground({ visible, event, onCancel }: PreviewBackgroundModalProps) {
  const primaryColor = `#${event?.themePrimaryColor?.replace('#', '')}` ?? '#FEDDC6';
  const secondaryColor = `#${event?.themeSecondaryColor?.replace('#', '')}` ?? '#000';
  const backgroundColor = `#${event?.themeBackgroundColor?.replace('#', '')}` ?? '#0D0A26';

  const DesktopContent = () => (
    <>
      <div className={styles.desktopSidebar} style={{ backgroundColor }}>
        <div className={styles.desktopSidebarItemContainer}>
          {[...Array(7)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.desktopSidebarItem}
              style={{ backgroundColor: primaryColor }}
            />
          ))}
        </div>
      </div>
      <div className={styles.desktopNavbar} style={{ backgroundColor }}>
        <div className={styles.desktopLogo} style={{ backgroundColor: primaryColor }} />
        <div className={styles.desktopMenuItemContainer}>
          {[...Array(7)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.desktopMenuItem}
              style={{ backgroundColor: index === 1 ? secondaryColor : primaryColor }}
            />
          ))}
        </div>
      </div>
      <div
        className={styles.background}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${
            event?.themeBackgroundUrl ? `url('${event.themeBackgroundUrl}')` : backgroundColor
          }`,
          backgroundSize: 'cover',
        }}
      />
    </>
  );

  const MobileContent = () => (
    <>
      <div className={styles.mobileNavbar} style={{ backgroundColor }}>
        <div className={styles.mobileMenuItem} style={{ backgroundColor: primaryColor }} />
        <div className={styles.mobileLogo}>{event?.name ?? 'Event'}</div>
        <div className={styles.mobileMenuItem} style={{ backgroundColor: primaryColor }} />
      </div>
      <div
        className={styles.background}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${
            event?.themeBackgroundUrl ? `url('${event.themeBackgroundUrl}')` : backgroundColor
          }`,
          backgroundSize: 'cover',
        }}
      />
      <div className={styles.mobileBottomBar}>
        <div className={styles.mobileBottomBarItemContainer}>
          {[...Array(5)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.mobileBottomBarItem}
            />
          ))}
        </div>
      </div>
    </>
  );

  return (
    <Modal title='Preview' visible={visible} onCancel={onCancel} footer={null} width='1240px'>
      <h6 className={styles.title}>4K Display</h6>
      <div className={styles.desktopWrapper}>
        <div className={styles.desktopContainer}>
          <DesktopContent />
        </div>
      </div>
      <h6 className={styles.title}>MacBook Air</h6>
      <div className={styles.macBookWrapper}>
        <div className={styles.macBookContainer}>
          <DesktopContent />
        </div>
      </div>
      <h6 className={styles.title}>iPad 8</h6>
      <div className={styles.tabletWrapper}>
        <div className={styles.tabletContainer}>
          <DesktopContent />
        </div>
      </div>
      <h6 className={styles.title}>iPhone 13</h6>
      <div className={styles.mobileWrapper}>
        <div className={styles.mobileContainer}>
          <MobileContent />
        </div>
      </div>
    </Modal>
  );
}
