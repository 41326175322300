import { Form, Input } from 'antd';
import React from 'react';

import { PageItemCategoryResource } from '@transforms/pageItemCategory';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface BreakoutCategoryProps extends DataEntryProps {
  data?: PageItemCategoryResource;
}

export function BreakoutCategory({ ...props }: BreakoutCategoryProps) {
  return (
    <DataEntry {...props} name='Breakout Category'>
      <Form.Item name='title' label={<b>Title</b>} rules={[{ required: true }]}>
        <Input placeholder='Title' />
      </Form.Item>
      <Form.Item name='position' label={<b>Position</b>}>
        <Input type='number' />
      </Form.Item>
    </DataEntry>
  );
}
