import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PageHeader,
  Layout,
  Form,
  Input,
  InputNumber,
  Switch,
  Button,
  Select,
  message,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageUpload } from '@components/Upload';
import { useProtected } from '@hooks/useProtected';
import {
  useOrganisation,
  useUpdateOrganisation,
  useUploadOrganisationImage,
} from '@hooks/useOrganisations';
import { PageTemplate } from '@transforms/page';
import { OrganisationResource } from '@transforms/organisation';

export default function EditOrganisation() {
  useProtected({ checkSuperAdmin: true });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { data: organisation } = useOrganisation(organisationId!);
  const { mutate: updateOrganisation } = useUpdateOrganisation(organisationId!);
  const { mutateAsync: uploadOrganisationImage } = useUploadOrganisationImage(organisationId!);

  const onUpdateOrganisation = useCallback(
    ({
      sessionsEnabled,
      ...values
    }: OrganisationResource & { sessionsEnabled?: boolean; image?: File | Blob }) => {
      updateOrganisation(
        {
          ...values,
          imageUrl: values.image ? undefined : null,
          enabledPageTemplates:
            sessionsEnabled ??
            organisation?.enabledPageTemplates?.includes(PageTemplate.SCHEDULE) ??
            false
              ? [...(values.enabledPageTemplates ?? []), PageTemplate.SCHEDULE]
              : values.enabledPageTemplates,
        },
        {
          onSuccess: async () => {
            try {
              if (organisationId! && values.image) {
                try {
                  await uploadOrganisationImage(values.image);
                } catch (error) {
                  message.error('An error occurred');
                  navigate('/organisations');
                  return;
                }
              }

              message.success('Organization saved');
              navigate('/organisations');
            } catch {
              message.error('An error occurred');
              navigate('/organisations');
            }
          },
        },
      );
    },
    [
      updateOrganisation,
      organisation?.enabledPageTemplates,
      organisationId,
      navigate,
      uploadOrganisationImage,
    ],
  );

  const parsedData = useMemo(
    () =>
      organisation
        ? {
            ...organisation,
            chatEnabled: organisation.chatEnabled ?? false,
            privateChatEnabled: organisation.privateChatEnabled ?? false,
            hostChatEnabled: organisation.hostChatEnabled ?? false,
            videoCallsEnabled: organisation.videoCallsEnabled ?? false,
            registrationEnabled: organisation.registrationEnabled ?? false,
            eventTemplatesEnabled: organisation.eventTemplatesEnabled ?? false,
            sessionsEnabled:
              organisation.enabledPageTemplates?.includes(PageTemplate.SCHEDULE) ?? false,
            enabledPageTemplates:
              organisation.enabledPageTemplates?.filter(
                (template) => template !== PageTemplate.SCHEDULE,
              ) ?? [],
          }
        : undefined,
    [organisation],
  );

  if (!organisation) return null;

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader title='Edit organization' />
      <Form
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 9 }}
        initialValues={parsedData}
        onFinish={onUpdateOrganisation}
      >
        <Form.Item name='image' label='Image'>
          <ImageUpload
            initialPreview={organisation.imageUrl}
            onRemove={() => form.setFields([{ name: 'image', value: null }])}
          />
        </Form.Item>
        <Form.Item name='name' label='Name'>
          <Input placeholder='Name' />
        </Form.Item>
        <Form.Item name='internalName' label='Internal name'>
          <Input placeholder='Internal name' />
        </Form.Item>
        <Form.Item name='contactName' label='Contact name'>
          <Input placeholder='Contact name' />
        </Form.Item>
        <Form.Item name='contactEmail' label='Contact email' rules={[{ type: 'email' }]}>
          <Input placeholder='Contact email' />
        </Form.Item>
        <Form.Item name='salesforceNumber' label='Salesforce number'>
          <Input placeholder='Salesforce number' />
        </Form.Item>
        <Form.Item name='companyDetails' label='Company details'>
          <Input.TextArea placeholder='Company details' />
        </Form.Item>
        <Form.Item name='subscriptionDetails' label='Subscription details'>
          <Input placeholder='Subscription details' />
        </Form.Item>
        <EventLimits organisation={organisation} />
        <Form.Item
          name='userLimit'
          label='Attendee limit per event'
          rules={[{ type: 'number', min: 1 }]}
        >
          <InputNumber placeholder='Attendee limit per event' min={1} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='enabledPageTemplates' label='Page types enabled'>
          <Select mode='multiple' placeholder='What page templates should be enabled' allowClear>
            <Select.Option value={PageTemplate.ATTENDEES}>Attendees</Select.Option>
            <Select.Option value={PageTemplate.IFRAME}>iFrame</Select.Option>
            <Select.Option value={PageTemplate.LIVE}>Live</Select.Option>
            <Select.Option value={PageTemplate.VIDEO_ON_DEMAND}>Video On Demand</Select.Option>
            <Select.Option value={PageTemplate.VIRTUAL_VENUE}>Virtual Venue</Select.Option>
            <Select.Option value={PageTemplate.BASIC}>Basic Content Page</Select.Option>
            <Select.Option value={PageTemplate.BREAKOUT}>Break Out Page</Select.Option>
            <Select.Option value={PageTemplate.CANVAS}>Canvas</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='chatEnabled' valuePropName='checked' label='Public chat enabled'>
          <Switch />
        </Form.Item>
        <Form.Item name='privateChatEnabled' valuePropName='checked' label='Private chat enabled'>
          <Switch />
        </Form.Item>
        <Form.Item name='hostChatEnabled' valuePropName='checked' label='Host chat enabled'>
          <Switch />
        </Form.Item>
        <Form.Item
          name='videoCallsEnabled'
          valuePropName='checked'
          label='Private video calls enabled'
        >
          <Switch />
        </Form.Item>
        <Form.Item name='sessionsEnabled' valuePropName='checked' label='Sessions enabled'>
          <Switch />
        </Form.Item>
        <Form.Item name='registrationEnabled' valuePropName='checked' label='Registration enabled'>
          <Switch />
        </Form.Item>
        <Form.Item
          name='eventTemplatesEnabled'
          valuePropName='checked'
          label='Event templates enabled'
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Update organization
          </Button>
        </Form.Item>
      </Form>
    </Layout.Content>
  );
}

function EventLimits({ organisation }: { organisation: OrganisationResource }) {
  const [limit, setLimit] = useState(organisation.eventLimit);
  const [activeLimit, setActiveLimit] = useState(organisation.activeEventLimit);

  useEffect(() => {
    setLimit(organisation.eventLimit);
    setActiveLimit(organisation.activeEventLimit);
  }, [organisation.eventLimit, organisation.activeEventLimit]);

  return (
    <>
      <Form.Item
        name='eventLimit'
        label='Event limit'
        rules={[
          {
            type: 'number',
            min: activeLimit ?? 1,
            message: 'Event limit must be greater than 0 and the active event limit',
          },
        ]}
      >
        <InputNumber
          min={1}
          style={{ width: '100%' }}
          placeholder='Event limit'
          onChange={(value) => setLimit(value)}
        />
      </Form.Item>
      <Form.Item
        name='activeEventLimit'
        label='Active event limit'
        rules={[
          {
            type: 'number',
            min: 1,
            ...(limit ? { max: limit } : {}),
            message: 'Active event limit must be greater than 0 and less than event limit',
          },
        ]}
      >
        <InputNumber
          min={1}
          style={{ width: '100%' }}
          placeholder='Active event limit'
          onChange={(value) => setActiveLimit(value)}
        />
      </Form.Item>
    </>
  );
}
