import { useMemo } from 'react';
import { Col, Divider, Row } from 'antd';
import { PhoneFilled, TeamOutlined, WechatFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Pill } from '@components/Pill';
import { UserResource } from '@transforms/user';
import { SessionWithLocationVisit } from '@transforms/locationVisit';

import { InfoCard } from './InfoCard';
import { AvatarRow } from './AvatarRow';
import { StreamingCard } from './StreamingCard';

export function AnalyticsQuickInsights({
  analytics,
  insights,
  attendees,
  sessionWithPageClicks,
}: {
  analytics: any;
  insights: any;
  attendees: UserResource[];
  sessionWithPageClicks: () => SessionWithLocationVisit[];
}) {
  const filteredAttendees = attendees.filter(
    (attendee) =>
      typeof attendee.avatarUrl === 'string' &&
      dayjs().isBefore(dayjs(attendee.privacyPolicyAcceptedAt).add(1, 'week')),
  );

  const sessions = useMemo(() => sessionWithPageClicks(), [sessionWithPageClicks]);
  const hasNoInfoCards =
    !analytics?.users.online &&
    !insights?.virtualVenues.points.length &&
    !insights?.sessions.points.length &&
    !insights?.videoCalls.breakout.points.length &&
    !insights?.videoCalls.private.points.length;

  if (hasNoInfoCards && !sessions?.length && !filteredAttendees?.length) {
    return null;
  }

  const liveSessions = sessions.filter((session: SessionWithLocationVisit) => {
    const now = new Date();
    const start = new Date(session.startsAt);
    const end = new Date(session.endsAt);
    return dayjs(start).isBefore(now) && dayjs(end).isAfter(now);
  });

  return (
    <>
      <Row>
        {/* SECTION HEADER */}
        <Col span={24}>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <h1
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                marginBottom: '1rem',
                marginRight: '1rem',
              }}
            >
              Quick Insights
            </h1>
            <Pill>LIVE</Pill>
          </Row>
        </Col>
      </Row>

      {/* INFOCARDS */}
      {!hasNoInfoCards && (
        <>
          <Row gutter={[42, 24]}>
            <Col span={4.8}>
              <InfoCard
                title='Online'
                value={analytics?.users.online}
                icon={<TeamOutlined />}
                noGraph
              />
            </Col>

            <Col span={4.8}>
              <InfoCard
                title='In Virtual Venue'
                value={insights?.virtualVenues}
                icon={<TeamOutlined />}
              />
            </Col>

            <Col span={4.8}>
              <InfoCard
                title='In Session'
                value={insights?.sessions}
                icon={<TeamOutlined />}
                inSession
              />
            </Col>

            <Col span={4.8}>
              <InfoCard
                title='In Breakout (Video Call)'
                value={insights?.videoCalls.breakout}
                icon={<WechatFilled />}
              />
            </Col>

            <Col span={4.8}>
              <InfoCard
                title='In Call'
                value={insights?.videoCalls.private}
                icon={<PhoneFilled />}
              />
            </Col>
          </Row>
          <br />
          <br />
        </>
      )}

      {/* STREAMING CARDS */}
      {sessions?.length ? (
        <>
          <Row>
            <Col span={24}>
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <h1
                  style={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    marginBottom: '0',
                    marginRight: '1rem',
                  }}
                >
                  Streaming Now
                </h1>
                <Pill>LIVE</Pill>
              </Row>
            </Col>
          </Row>
          <br />

          {!!liveSessions.length && (
            <Row gutter={[42, 24]}>
              {liveSessions.map((session: SessionWithLocationVisit) => (
                <Col key={session.id} span={8}>
                  <StreamingCard session={session} />
                </Col>
              ))}
            </Row>
          )}
          <br />
          <br />
        </>
      ) : null}

      {/* ATTENDEES */}
      {filteredAttendees?.length > 0 ? (
        <>
          <h2 style={{ fontWeight: 'bold' }}>Just joined</h2>
          <br />
          <Row
            style={{
              justifyContent: filteredAttendees.length > 15 ? 'space-around' : 'flex-start',
            }}
          >
            <AvatarRow attendees={filteredAttendees} />
          </Row>
        </>
      ) : null}

      <Divider />
    </>
  );
}
