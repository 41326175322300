import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, Menu, message } from 'antd';
import { DownOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { EventLayout } from '@components/Layout';

import {
  useEventAnalytics,
  useEventGeneralAttendeeAnalytics,
  useEventInsightsAnalytics,
  useEventPlatformAnalytics,
  useEventSessionsAnalytics,
  useEventVideosAnalytics,
  useExportAnalytics,
} from '@hooks/useAnalytics';
import { useLocationVisits } from '@hooks/useLocationVisits';
import {
  AnalyticsGeneralAttendee,
  AnalyticsHeader,
  AnalyticsPlatform,
  AnalyticsQuickInsights,
  AnalyticsSessions,
  AnalyticsVideos,
} from '@components/Analytics';
import { useCurrentEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { useUsers } from '@hooks/useUsers';
import { useSessions } from '@hooks/useSessions';
import { usePrintEventPage } from '@hooks/usePrint';
import { UserResource } from '@transforms/user';
import { SessionWithLocationVisit } from '@transforms/locationVisit';
import type { UserRoleResource } from '@transforms/userRole';

export default function Analytics() {
  const { selectedLanguage } = useLanguage();
  const { eventId } = useParams<{ eventId: string }>();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const printPage = usePrintEventPage(eventId!);
  const htmlContainer = React.createRef<HTMLDivElement>();

  const startDate = useRef(new Date());
  const [interval, createInterval] = useState<ReturnType<typeof setInterval>>();

  const { exportEventAnalytics, exportLocationVisits, exportVideoAnalytics } = useExportAnalytics();

  const { data: users, refetch: refetchUsers } = useUsers(
    { page: 1, limit: 999 },
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  );
  const { data: sessions } = useSessions({ page: 1, limit: 999 });
  const [dates, setDates] = useState<{ startDate: Date | string; endDate: Date | string }>({
    startDate: event?.activeFrom!,
    endDate: event?.activeTill!,
  });
  const { data: analytics } = useEventAnalytics(eventId!, dates.startDate, dates.endDate, 10);
  const { data: generalAttendee } = useEventGeneralAttendeeAnalytics(
    eventId!,
    dates.startDate,
    dates.endDate,
    10,
  );
  const { data: videos } = useEventVideosAnalytics(eventId!, dates.startDate, dates.endDate, 10);
  const { data: analyticsSessions } = useEventSessionsAnalytics(
    eventId!,
    dates.startDate,
    dates.endDate,
    10,
  );
  const { data: platforms } = useEventPlatformAnalytics(
    eventId!,
    dates.startDate,
    dates.endDate,
    10,
  );

  const { data: locationVisits } = useLocationVisits(eventId!, dates.startDate, dates.endDate);

  // Live data
  const { data: analyticsLive, refetch: refetchAnalyticsLive } = useEventAnalytics(
    eventId!,
    startDate.current,
    undefined,
    10,
  );
  const { data: insights, refetch: refetchInsights } = useEventInsightsAnalytics(
    eventId!,
    startDate.current,
    undefined,
    10,
  );

  useEffect(() => {
    createInterval(
      setInterval(() => {
        refetchUsers();
        refetchInsights();
        refetchAnalyticsLive();
      }, 10_000),
    );
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (event) {
      setDates({
        startDate: event.activeFrom,
        endDate: event.activeTill,
      });
    }
  }, [event]);

  const [pdfExporting, setPdfExporting] = useState(false);
  const onExportPdf = async () => {
    if (htmlContainer.current) {
      setPdfExporting(true);
      const { innerHTML, offsetWidth, offsetHeight } = htmlContainer.current;
      printPage(innerHTML, offsetWidth, offsetHeight)
        .then((body) => {
          const blob = new Blob([body], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${event?.name}_analytics_export.pdf`;
          link.click();
          setPdfExporting(false);
          link.remove();
        })
        .catch((error) => {
          setPdfExporting(false);
          message.error('Unable to export analytics to PDF.');
          console.error('Analytics/PDFExport', error);
        });
    }
  };

  const mostCommonRole = useMemo(
    () =>
      users?.items
        .filter(
          (attendee) =>
            dayjs(attendee.acceptedAt!).isAfter(dates.startDate) &&
            dayjs(attendee.acceptedAt!).isBefore(dates.endDate),
        )
        .map((attendee: UserResource) => attendee.role)
        .reduce<UserRoleResource | null | undefined>(
          (a, b, _, arr) =>
            arr.filter((val) => val?.id === a?.id).length >=
            arr.filter((val) => val?.id === b?.id).length
              ? a
              : b,
          null,
        ),
    [dates.endDate, dates.startDate, users?.items],
  );

  const mostCommonTags = useMemo(() => {
    const tagCounts = users?.items
      .filter(
        (attendee) =>
          dayjs(attendee.acceptedAt!).isAfter(dates.startDate) &&
          dayjs(attendee.acceptedAt!).isBefore(dates.endDate),
      )
      .map((attendee) => attendee.tags)
      .flat()
      .reduce((acc, tag) => {
        // @ts-ignore
        if (acc[tag.label]) {
          // @ts-ignore
          acc[tag.label] += 1;
        } else {
          // @ts-ignore
          acc[tag.label] = 1;
        }
        return acc;
      }, {});

    if (tagCounts) {
      const tagCountsArray = Object.keys(tagCounts).map((key) => ({
        label: key,
        // @ts-ignore
        count: tagCounts[key],
      }));

      return tagCountsArray;
    }

    return [];
  }, [dates.endDate, dates.startDate, users?.items]);

  const mostCommonLanguage = useMemo(
    () =>
      users?.items
        .filter(
          (attendee) =>
            dayjs(attendee.acceptedAt!).isAfter(dates.startDate) &&
            dayjs(attendee.acceptedAt!).isBefore(dates.endDate),
        )
        .map((attendee: UserResource) => attendee.language)
        .reduce<string | null | undefined>(
          (a, b, _, arr) =>
            arr.filter((val) => val === a).length >= arr.filter((val) => val === b).length ? a : b,
          null,
        ),
    [dates.endDate, dates.startDate, users?.items],
  );

  const sessionWithPageClicks = (): SessionWithLocationVisit[] => {
    if (sessions && locationVisits) {
      const sessionPageUrls: any = sessions.items.map((session) => {
        const sessionPageUrl = session.url?.split('page=')[1];

        const locationVisit = locationVisits.pages.find(
          (visit) => visit.page.id === sessionPageUrl,
        );

        return { ...session, locationVisit };
      });

      return sessionPageUrls;
    }
    return [];
  };

  const handleDateChange = () => (vals: any) => {
    setDates({
      startDate: new Date(vals[0]).toISOString(),
      endDate: new Date(vals[1]).toISOString(),
    });
  };

  if (!event) {
    return null;
  }

  return (
    <EventLayout
      title='Analytics'
      extra={
        <>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='csv-analytics' onClick={() => exportEventAnalytics(eventId!)}>
                  Analytics
                </Menu.Item>
                <Menu.Item key='csv-visits' onClick={() => exportLocationVisits(eventId!)}>
                  Platform Distribution
                </Menu.Item>
                <Menu.Item key='video-visits' onClick={() => exportVideoAnalytics(eventId!)}>
                  Video Analytics
                </Menu.Item>
              </Menu>
            }
          >
            <Button type='primary'>
              Download CSV <DownOutlined />
            </Button>
          </Dropdown>
          {pdfExporting ? (
            <Button style={{ width: '127px' }}>
              <LoadingOutlined />
            </Button>
          ) : (
            <Button icon={<DownloadOutlined />} onClick={onExportPdf} style={{ width: '127px' }}>
              Export PDF
            </Button>
          )}
        </>
      }
    >
      <div ref={htmlContainer} style={{ padding: '0 40px 40px' }}>
        <Menu
          style={{ width: 256 }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode='inline'
        />
        {!!analyticsLive && !!insights && (
          <AnalyticsQuickInsights
            analytics={analyticsLive}
            insights={insights}
            attendees={users?.items || []}
            sessionWithPageClicks={sessionWithPageClicks}
          />
        )}
        <AnalyticsHeader
          title={event?.name || ''}
          startDate={dates.startDate}
          endDate={dates.endDate}
          analytics={analytics}
          handleDateChange={handleDateChange()}
        />
        <AnalyticsGeneralAttendee
          mostCommonRole={mostCommonRole}
          mostCommonTags={mostCommonTags}
          mostCommonLanguage={mostCommonLanguage || 'en'}
          generalAttendee={generalAttendee}
        />
        <AnalyticsPlatform
          platforms={platforms?.platform}
          virtualVenues={platforms?.virtualVenue}
        />
        <AnalyticsSessions
          sessionsAttendance={analyticsSessions?.attendance || []}
          sessionsScheduled={analyticsSessions?.scheduled}
        />
        <AnalyticsVideos sessions={videos?.watched || []} />
      </div>
    </EventLayout>
  );
}
