import { useEffect, useState } from 'react';
import { Button, Input, Modal, message, Form } from 'antd';

import { MailResource } from '@transforms/mail';
import { useTestMail } from '@hooks/useMails';
import { validateEmail } from '@utils/validateEmail';
import { useLanguage } from '@hooks/useTranslations';

export const TestMailModal = ({
  visible,
  eventId,
  mail,
  onClose,
}: {
  visible: boolean;
  eventId: string;
  mail: Omit<MailResource, 'layout'> & {
    layout?: Omit<MailResource['layout'], 'imageUrl'> & { image?: File | null };
  };
  onClose(): void;
}) => {
  const { selectedLanguage } = useLanguage();
  const { mutateAsync: testMail } = useTestMail(eventId);
  const [recipient, setRecipient] = useState<string>('');
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const sendTestMail = async () => {
    const isValidEmail = validateEmail(recipient);
    if (mail.value === '') {
      setConfirmationVisible(true);
    } else if (isValidEmail) {
      await testMail({ values: mail, recipient, selectedLanguage });
      message.success('Test mail has been sent.');
      setRecipient('');
      onClose();
    } else {
      message.error('Please use a valid emailaddress');
    }
  };

  const handleCancel = () => {
    setConfirmationVisible(false);
    setRecipient('');
    onClose();
  };

  const handleSendMail = async () => {
    const isValidEmail = validateEmail(recipient);
    if (isValidEmail) {
      await testMail({ values: mail, recipient, selectedLanguage });
      message.success('Test mail has been sent.');
      setRecipient('');
      onClose();
      setConfirmationVisible(false);
    } else {
      message.error('Please use a valid emailaddress');
    }
  };

  useEffect(() => {
    if (!visible) return;
    setTimeout(() => document.getElementById('test-mail-input')?.focus(), 250);
  }, [visible]);

  return (
    <>
      <Modal
        title='Send Preview Email'
        visible={visible}
        onCancel={() => {
          handleCancel();
        }}
        footer={null}
      >
        <Form onFinish={sendTestMail}>
          Note, the handlebars <b>{'{{ first_name }}'}</b> and <b>{'{{ last_name }}'}</b> will be
          filled with a placeholder name, 'John Doe'.
          <br />
          <br />
          <b>Recipient</b>
          <Input.Group compact style={{ marginTop: '5px' }}>
            <Input
              placeholder='email123@email.com'
              id='test-mail-input'
              style={{ width: 'calc(100% - 65px)' }}
              allowClear
              onChange={(e) => setRecipient(e.target.value)}
              value={recipient}
            />
            <Button type='primary' htmlType='submit'>
              Send
            </Button>
          </Input.Group>
        </Form>
      </Modal>
      <Modal
        title='Send test mail'
        visible={confirmationVisible}
        onCancel={() => {
          handleCancel();
        }}
        footer={null}
      >
        <Form onFinish={handleSendMail}>
          <i>
            Are you sure you want to send a test mail to <b>{recipient}</b> with an empty body?
          </i>
          <br />
          <Input.Group compact style={{ marginTop: '5px' }}>
            <Button type='primary' htmlType='submit'>
              Send
            </Button>
          </Input.Group>
        </Form>
      </Modal>
    </>
  );
};
