import parse, { Attributes } from '@utils/parse';

import parseMeta from './meta';

export const parseJob = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    jobId: attributes.id('jobId'),
    type: attributes.string('type'),
    runAt: attributes.date('runAt'),
    lockedAt: attributes.optional.date('lockedAt'),
    data: attributes.optional.object('data'),
  };
};

export const parseData = (attributes: Attributes) => {
  return {
    ...parseJob(attributes),
  };
};

export function one(data: any) {
  return parse.one(data, parseData);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseData),
    meta: parseMeta(data.meta),
  };
}

export type JobResource = ReturnType<typeof one>;
export type Job = Omit<JobResource, 'id'>;

export default { one, many };
