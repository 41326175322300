import { List, Button, Tooltip, Popconfirm, Tag } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { VideoResource } from '@transforms/video';

export default function ListItem({
  video,
  onDuplicate,
  onDelete,
  onEdit,
}: {
  video: VideoResource;
  onDuplicate?: (video: VideoResource) => void;
  onDelete?: (id: string) => void;
  onEdit?: (video: VideoResource) => void;
}) {
  return (
    <List.Item
      key={video.title}
      actions={[
        <Tag key='videoType' color='warning'>
          {video.type}
        </Tag>,
        onDuplicate && (
          <Tooltip title='Duplicate' key='duplicate'>
            <Button shape='circle' icon={<CopyOutlined />} onClick={() => onDuplicate(video)} />
          </Tooltip>
        ),
        onEdit && (
          <Tooltip title='Edit' key='edit'>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(video)} />
          </Tooltip>
        ),
        onDelete && (
          <Popconfirm
            title='Are you sure you want to delete this video?'
            onConfirm={() => onDelete(video.id)}
            okText='Delete'
            cancelText='Cancel'
            key='delete'
          >
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      ].filter(Boolean)}
    >
      <List.Item.Meta
        title={video.title}
        description={
          <>
            <p>{video.subtitle}</p>
            <p>Position:{video.position}</p>
            {video.imageUrl && <img src={video.imageUrl} alt='' style={{ maxWidth: '100pt' }} />}
          </>
        }
      />
    </List.Item>
  );
}
