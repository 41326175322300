import React, { useState, useEffect } from 'react';
import { Form, Drawer, Button, Divider, message, Select, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { usePageTemplateEvent } from '@hooks/useEvents';
import { PageResource, PageTemplate } from '@transforms/page';
import { usePages } from '@hooks/usePages';

import { GeneralInfo } from './GeneralInfo';
import { IFrame } from './IFrame';
import { Live } from './Live';
import { VideoOnDemand } from './VideoOnDemand';
import { VirtualVenue } from './VirtualVenue';
import { Basic } from './Basic';
import BreakoutPage from './BreakoutPage';
import { Canvas } from './Canvas';

export interface AddNewPageDrawerProps {
  templatesEnabled: boolean;
  visible: boolean;
  toggleDrawer: () => void;
  onFinish: (values: PageResource & { dates: any }) => void;
}

function AddNewPageDrawer({
  templatesEnabled,
  toggleDrawer,
  visible,
  onFinish,
}: AddNewPageDrawerProps) {
  const [form] = Form.useForm();
  const [selectedPageType, setSelectedPageType] = useState('');
  const [formValues, setFormValues] = useState({});
  const { data: pageTemplateEvent } = usePageTemplateEvent();
  const { data: pages } = usePages(pageTemplateEvent?.id);
  const newPage: any = {
    id: 'new',
    enabled: false,
    visibleInMenu: false,
  } as Pick<PageResource, 'id' | 'enabled' | 'visibleInMenu'>;

  useEffect(() => {
    setSelectedPageType(form.getFieldValue('template'));
  }, [form, formValues]);

  const renderPageTemplate = () => {
    switch (selectedPageType) {
      case PageTemplate.ATTENDEES || PageTemplate.SCHEDULE:
        return null;
      case PageTemplate.CANVAS:
        return <Canvas form={form} page={newPage} />;
      case PageTemplate.IFRAME:
        return <IFrame />;
      case PageTemplate.LIVE:
        return <Live page={newPage} />;
      case PageTemplate.VIDEO_ON_DEMAND:
        return <VideoOnDemand page={newPage} />;
      case PageTemplate.VIRTUAL_VENUE:
        return <VirtualVenue />;
      case PageTemplate.BREAKOUT:
        return <BreakoutPage page={newPage} />;
      case PageTemplate.BASIC:
        return <Basic />;
      default:
        return null;
    }
  };

  const cancelButtonClicked = () => {
    form.resetFields();
    toggleDrawer();
  };

  const onDismiss = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        width: 'fit-content',
        icon: <WarningOutlined />,
        title: 'Unsaved Changes!',
        content: (
          <>
            <p>There are unsaved changes! </p>
            <p>Are you sure you want to exit without saving?</p>
            <p>
              Choose 'Cancel' to return to the editor and save changes.
              <br />
              Choose 'Discard' to exit without saving changes. Note, any unsaved changes will be
              lost.
            </p>
          </>
        ),
        cancelText: 'Cancel',
        onCancel: () => {},
        okText: 'Discard',
        onOk: cancelButtonClicked,
        okButtonProps: {
          danger: true,
        },
      });
    } else {
      cancelButtonClicked();
    }
  };

  const onSubmit = (values: PageResource & { dates: any }) => {
    onFinish(values);
    form.resetFields();
    message.success('Created page');
  };

  const copyValues = (selectedPage: PageResource) => {
    const { id, ...data } = selectedPage;
    const fields = Object.keys(data).map((key) => {
      return { name: key, value: (data as any)[key] };
    });
    form.setFields(fields);
  };

  const renderTemplateOptions = () => {
    return (
      <>
        {pages?.map((template) => (
          <Select.Option value={template.id} key={template.id}>
            {template.title}
          </Select.Option>
        ))}
      </>
    );
  };

  const templateOptions = () => {
    return (
      <Form.Item name='templateId' label='Template'>
        <Select
          style={{ width: '100%' }}
          allowClear
          filterOption={false}
          onChange={(pageId) => {
            const selectedPage = pages?.find((page) => page.id === pageId);
            if (selectedPage) {
              copyValues(selectedPage);
            }
          }}
        >
          {renderTemplateOptions()}
        </Select>
      </Form.Item>
    );
  };

  return (
    <Drawer
      title='Add New Page'
      width='50%'
      onClose={onDismiss}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={() => cancelButtonClicked()} style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button htmlType='submit' onClick={form.submit} type='primary'>
            Add Page
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        name='new-page'
        layout='vertical'
        initialValues={newPage}
        onFinish={(values) => onSubmit(values)}
        onValuesChange={(values) => setFormValues(values)}
        validateMessages={{
          required: 'This is a required field.',
        }}
      >
        {templatesEnabled && (pages?.length ?? 0) > 0 && templateOptions()}
        <GeneralInfo page={newPage} />
        <Divider key='d1' />
        {renderPageTemplate()}
      </Form>
    </Drawer>
  );
}

export default AddNewPageDrawer;
