import { List, Button, Tooltip, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { TagResource } from '@transforms/tag';

export function ListItem({
  tag,
  onEdit,
  onDelete,
}: {
  tag: TagResource;
  onEdit?: (user: TagResource) => void;
  onDelete?: (id: string) => void;
}) {
  return (
    <List.Item
      style={{ width: '100%' }}
      key={tag.id}
      actions={[
        <>
          {onEdit && (
            <Tooltip title='Edit' key='edit'>
              <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(tag)} />
            </Tooltip>
          )}
        </>,
        <>
          {onDelete && (
            <Popconfirm
              title='Are you sure you want to delete this tag from this event?'
              onConfirm={() => onDelete(tag.id)}
              okText='Delete'
              cancelText='Cancel'
              key='delete'
            >
              <Button shape='circle' icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </>,
      ]}
    >
      <List.Item.Meta title={<div>{tag.label}</div>} description={<Space>{tag.key}</Space>} />
    </List.Item>
  );
}
