import { useQuery } from 'react-query';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import transform from '@transforms/mandatoryFieldTranslation';

import useAuth from './auth/useAuth';

export const useMandatoryFields = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['mandatoryFields', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(
        languageUrl(`/events/${eventId}/custom-fields/translations`, language),
        {
          token: accessToken,
        },
      );
      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};
export default {
  useMandatoryFields,
};
