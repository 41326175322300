import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import Header from '@components/Header';

const Container = () => {
  return (
    <div className='wrapper'>
      <Header />
      <Layout className='layout'>
        <Outlet />
      </Layout>
    </div>
  );
};

export default Container;
