import { useCallback, useEffect, useState } from 'react';
import { Checkbox, message, Modal, Select, Table, Tag } from 'antd';

import type { PageTemplate } from '@transforms/page';
import type { UserRoleResource } from '@transforms/userRole';

export interface PageRole {
  id: string;
  title: string;
  template: PageTemplate;
  roleIds: string[];
}

export interface PageModalProps {
  visible: boolean;
  onOk: (data: PageRole[]) => void;
  onCancel: () => void;
  roles: UserRoleResource[];
  roleId: string;
  pageRoles?: PageRole[];
  defaultRole?: UserRoleResource;
}

export function PageModal({
  visible: modalVisible,
  onOk,
  onCancel,
  roles,
  roleId,
  pageRoles,
  defaultRole,
}: PageModalProps) {
  const [updatedPageRoles, setUpdatedPageRoles] = useState(pageRoles);

  const role = roles.find(({ id }) => id === roleId);

  useEffect(() => {
    setUpdatedPageRoles((prev) => [
      ...(prev ?? []),
      ...(pageRoles?.filter(({ id }) => !prev?.some((page) => page.id === id)) ?? []),
    ]);
  }, [pageRoles, roleId]);

  const updatePageRoles = useCallback(
    (page: PageRole) => {
      setUpdatedPageRoles((prev) => {
        const prevPageRoles = prev?.find(({ id }) => page.id === id)?.roleIds ?? [];
        const removeOrAdd = prevPageRoles.includes(roleId);
        if (removeOrAdd && prevPageRoles.length - 1 === 0) {
          message.error(
            `A page needs at least 1 role, please add another role to this page first. If you delete this role, the page will be re-assigned to the default role${
              defaultRole ? ` "${defaultRole.name}"` : ''
            }.`,
            10,
          );
          return prev;
        }

        // By default clear array to allow all roles
        const roleIds = [];
        if (removeOrAdd) {
          // Remove role from breakout
          // @ts-ignore
          roleIds.push(...prevPageRoles.filter((id) => id !== roleId));
        } else if (prevPageRoles.length === 0) {
          // Add all roles except this one
          // @ts-ignore
          roleIds.push(...roles.map(({ id }) => id).filter((id) => id !== roleId));
        } else if (prevPageRoles.length + 1 !== roles.length) {
          // Add role to breakout
          // @ts-ignore
          roleIds.push(...prevPageRoles, roleId);
        }

        return [
          ...prev!.filter(({ id }) => page.id !== id),
          {
            id: page.id,
            title: page.title,
            template: page.template,
            roleIds,
          },
        ];
      });
    },
    [defaultRole, roleId, roles],
  );

  return (
    <Modal
      width={750}
      visible={modalVisible}
      title='Pages with Role'
      okText='Ok'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => onOk(updatedPageRoles ?? [])}
    >
      <Table
        loading={!updatedPageRoles}
        dataSource={updatedPageRoles?.sort((a, b) => a.id.localeCompare(b.id))}
        pagination={{
          pageSize: 10,
          total: updatedPageRoles?.length ?? 0,
        }}
        columns={[
          {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: 'Template',
            key: 'template',
            dataIndex: 'template',
            render: (template) => <Tag>{template}</Tag>,
          },
          {
            title: `Has ${role?.name ? `"${role.name}" ` : ''}Role`,
            key: 'hasRole',
            dataIndex: 'roleId',
            render: (_, page) => {
              const updatedPageRole = updatedPageRoles?.find(({ id }) => page.id === id);
              return (
                <Checkbox
                  checked={
                    updatedPageRole?.roleIds.includes(roleId) ||
                    updatedPageRole?.roleIds.length === 0
                  }
                  onClick={() => updatePageRoles(page)}
                >
                  {roles.map((data) => (
                    <Select.Option value={data.id} key={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Checkbox>
              );
            },
          },
        ]}
      />
    </Modal>
  );
}
