import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import useAuth from '@hooks/auth/useAuth';
import transform, { Event, EventResource } from '@transforms/event';
import fetch, { APIError } from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import {
  eventBackgroundEndpoint,
  eventFaviconEndpoint,
  eventLogoEmailEndpoint,
  eventLogoEndpoint,
} from '@utils/uploadEndpoints';

export const useEvent = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  const { data, error, isFetching, isLoading } = useQuery<EventResource | undefined, APIError>(
    ['event', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');
      const { body } = await fetch(languageUrl(`/events/${eventId}/admin`, language), {
        token: accessToken,
      });
      return transform.one(body);
    },
    { enabled: !!eventId },
  );

  return { data, isFetching, error, isLoading };
};

export const useCurrentEvent = (language?: string) => {
  const { eventId } = useParams<{ eventId?: string }>();
  return useEvent(eventId, language);
};

export const useEventId = () => {
  const { data } = useCurrentEvent();

  return data?.id;
};

export const useEventTemplate = () => {
  const { data } = useCurrentEvent();

  return { isTemplate: data?.isTemplate || false, isEvent: !data?.isTemplate };
};

export const useEvents = () => {
  const { accessToken } = useAuth();

  return useQuery<EventResource[], APIError>('events', async () => {
    const { body } = await fetch('/events', { token: accessToken });

    return transform.many(body);
  });
};

export const useArchivedEvents = () => {
  const { accessToken, isSuperAdmin, organisation } = useAuth();

  return useQuery<EventResource[], APIError>(
    'events/archive',
    async () => {
      const { body } = await fetch('/events/archive', { token: accessToken });

      return transform.many(body);
    },
    { enabled: isSuperAdmin || !!organisation },
  );
};

export const useTemplateEvents = () => {
  const { accessToken, isSuperAdmin, organisation } = useAuth();

  return useQuery<EventResource[], APIError>(
    'events/template',
    async () => {
      const { body } = await fetch('/events/template', { token: accessToken });

      return transform.many(body);
    },
    { enabled: isSuperAdmin || !!organisation?.eventTemplatesEnabled },
  );
};

export const useCreateEvent = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Event) => {
      return fetch(`/events`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
      },
    },
  );
};

export const useCreateTemplateFromEvent = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (name: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/template`, {
        method: 'POST',
        body: { name },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
      },
    },
  );
};

export const useUpdateEvent = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Event) => {
      if (!eventId) throw new Error('No event');

      // eslint-disable-next-line no-param-reassign
      (values as any).rolesAllowed = values.rolesAllowed?.map((role) =>
        typeof role === 'string' ? role : role.id,
      );

      return fetch(languageUrl(`/events/${eventId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries(['event', eventId, language]);
        // message.success('Successfully updated event');
      },
    },
  );
};

export const useArchiveEvent = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (eventId: string) => {
      return fetch(`/events/${eventId}/archive`, { method: 'PUT', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
        queryClient.invalidateQueries('events/template');
      },
    },
  );
};

export const useUnarchiveEvent = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (eventId: string) => {
      return fetch(`/events/${eventId}/unarchive`, { method: 'PUT', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
        queryClient.invalidateQueries('events/template');
      },
    },
  );
};

export const useDuplicateEvent = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (eventId: string) => {
      return fetch(`/events/${eventId}/duplicate`, { method: 'POST', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/template');
      },
    },
  );
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (eventId: string) => {
      return fetch(`/events/${eventId}`, { method: 'DELETE', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
        queryClient.invalidateQueries('events/template');
      },
    },
  );
};

export const usePageTemplateEvent = (language?: string) => {
  const { accessToken, isAuthorized, isSuperAdmin } = useAuth();

  return useQuery<EventResource | undefined, APIError>(
    ['event', language],
    async () => {
      const { body } = await fetch(languageUrl(`/events/page-template-event`, language), {
        token: accessToken,
      });

      return transform.one(body);
    },
    {
      enabled: isAuthorized && isSuperAdmin,
    },
  );
};

export const useUploadBackgroundImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ eventId, image }: { eventId: string; image: File }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventBackgroundEndpoint(eventId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
      },
    },
  );
};

export const useUploadLogoImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ eventId, image }: { eventId: string; image: File }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventLogoEndpoint(eventId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
      },
    },
  );
};

export const useUploadLogoEmailImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ eventId, image }: { eventId: string; image: File }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventLogoEmailEndpoint(eventId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
      },
    },
  );
};

export const useUploadFaviconImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ eventId, image }: { eventId: string; image: File }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventFaviconEndpoint(eventId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events');
        queryClient.invalidateQueries('events/archive');
      },
    },
  );
};
