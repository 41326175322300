import { useState, useEffect } from 'react';
import { Form, Select, Input, Switch, Space, Alert, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import DatePicker from '@components/DatePicker';
import { useCurrentEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { PageTemplate } from '@transforms/page';
import useAuth from '@hooks/auth/useAuth';
import { useRoles } from '@hooks/useRoles';

import type { PageProps } from './props';

export function GeneralInfo({ page }: PageProps) {
  const { data: event } = useCurrentEvent();
  const { isSuperAdmin, organisation } = useAuth();
  const { selectedLanguage } = useLanguage();
  const { data: roles } = useRoles(selectedLanguage, event?.id);
  const [warnings, setWarnings] = useState<string[]>([]);

  useEffect(() => {
    const warns: string[] = [];

    if (page?.visibleTill) {
      if (dayjs(page?.visibleTill).isBefore(dayjs(Date.now()))) {
        warns.push('Current date is later than the end date.');
      }
    }
    if (!page?.enabled) {
      warns.push('The page is disabled.');
    }
    if ((page?.roles?.length ?? 0) > 0) {
      warns.push(
        `The page is only enabled for attendees with the following roles: ${page?.roles
          ?.map((role) => roles?.find((translated) => translated.id === role)?.name ?? 'Role')
          .join(', ')}.`,
      );
    }

    setWarnings(warns);
  }, [page.visibleTill, page.enabled, page.roles, roles]);

  // const menuPosition = (
  //   <Row>
  //     <Form.Item name='menuPosition' label={<div style={{ fontWeight: 'bold' }}>Meu </div>}>
  //       <Input type='number' />
  //     </Form.Item>
  //   </Row>
  // );

  const availabilityPicker = (
    <>
      <Form.Item
        name='dates'
        label={
          <Space direction='vertical'>
            <div style={{ fontWeight: 'bold' }}>Availability (in CET)</div>
            <div>
              Determine the timeframe in which this page is active. Note, the page will
              automatically be made inactive outside this date range.
            </div>
            {/* {page?.visibleFrom &&
              page?.visibleTill &&
              dayjs().isBetween(page?.visibleFrom, page.visibleTill) && (
                <Tag color='green'>In Date Range</Tag>
              )} */}
            {/* {(page.visibleFrom === undefined || page.visibleTill === undefined) && (
              <Tag color='green'>In Date Range</Tag>
            )} */}
            {page?.visibleTill && dayjs(page?.visibleTill).isBefore(dayjs(Date.now())) && (
              <Tooltip title='The current date is later than the end date. Change the end date to make the page visible'>
                <ExclamationCircleOutlined style={{ color: '#faad14' }} />
              </Tooltip>
            )}
          </Space>
        }
      >
        {/* @ts-ignore */}
        <DatePicker.RangePicker
          placeholder={['Start Date', 'End Date']}
          showTime
          defaultValue={
            page?.visibleFrom && page?.visibleTill
              ? [dayjs(page?.visibleFrom), dayjs(page?.visibleTill)]
              : null
          }
        />
      </Form.Item>
    </>
  );

  const menuSwitches = (
    <>
      <Form.Item
        name='visibleInMenu'
        label={
          <Space direction='vertical'>
            <div style={{ fontWeight: 'bold' }}>Visibility (in menu)</div>
            <div>
              Determine whether this page is displayed in the topbar menu. Note, visibility does not
              affect availability (ex. a page can be active, but invisible).
            </div>
          </Space>
        }
        valuePropName='checked'
      >
        {page && <Switch defaultChecked={page.visibleInMenu || undefined} />}
      </Form.Item>
      <Form.Item
        name='enabled'
        label={
          <>
            <span style={{ marginRight: 8, fontWeight: 'bold' }}>Enabled</span>
            {!page?.enabled && (
              <Tooltip title='This page is currently disabled. To make this page active, enable the toggle. Note, this setting does not affect visibility in the top menubar.'>
                <ExclamationCircleOutlined style={{ color: '#faad14' }} />
              </Tooltip>
            )}
          </>
        }
        valuePropName='checked'
      >
        {page && <Switch defaultChecked={page.enabled} />}
      </Form.Item>
    </>
  );

  const urlSlug = (
    <Form.Item
      name='slug'
      className='required-label-title-only'
      label={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Page URL</div>
          <div>
            The URL name (web address) for this page. Note, only the SLUG (the text displayed after
            <a>.virtual-live-event.com/pages/</a>) can be customized.
          </div>

          <div>
            <b>Tip</b>: Only certain characters are allowed (lowercase letters A-Z; numbers 0-9; and
            dash "-").
          </div>
        </div>
      }
      rules={[
        {
          required: true,
          message: 'This is a required field',
        },
      ]}
    >
      <Input
        placeholder='custom-page-url'
        addonBefore={`${event?.slug}.virtual-live-event.com/pages/`}
      />
    </Form.Item>
  );

  const pageTitle = (
    <Form.Item
      name='title'
      label={<div style={{ fontWeight: 'bold' }}>Page Title</div>}
      rules={[
        {
          required: true,
          message: 'This is a required field',
        },
      ]}
    >
      <Input placeholder='Page Title' />
    </Form.Item>
  );

  const userRoles = (
    <Form.Item
      name='roles'
      label={
        <>
          <span style={{ marginRight: 8, fontWeight: 'bold' }}>User Role Access</span>
          {(page?.roles?.length ?? 0) > 0 && (
            <Tooltip title='The page is only visible for the selected roles'>
              <ExclamationCircleOutlined style={{ color: '#faad14' }} />
            </Tooltip>
          )}
        </>
      }
    >
      <Select
        filterOption={(input, option) =>
          option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={roles?.map((role) => ({
          value: role.id,
          label: role.name,
        }))}
        mode='multiple'
        placeholder='Limit access to these specific user roles.'
      />
    </Form.Item>
  );

  const getPageTypeOptions = (
    <>
      {event && event.organisation && event.organisation.enabledPageTemplates ? (
        event.organisation.enabledPageTemplates
          .sort((a, b) => a.localeCompare(b))
          .map((pageTemplate) => {
            switch (pageTemplate) {
              case PageTemplate.ATTENDEES:
                return <Select.Option value={PageTemplate.ATTENDEES}>Attendees</Select.Option>;
              case PageTemplate.BASIC:
                return <Select.Option value={PageTemplate.BASIC}>Basic Content</Select.Option>;
              case PageTemplate.BREAKOUT:
                return <Select.Option value={PageTemplate.BREAKOUT}>Break Out</Select.Option>;
              case PageTemplate.CANVAS:
                return <Select.Option value={PageTemplate.CANVAS}>Canvas</Select.Option>;
              case PageTemplate.IFRAME:
                return <Select.Option value={PageTemplate.IFRAME}>iFrame</Select.Option>;
              case PageTemplate.LIVE:
                return <Select.Option value={PageTemplate.LIVE}>Live</Select.Option>;
              case PageTemplate.SCHEDULE:
                return <Select.Option value={PageTemplate.SCHEDULE}>Schedule</Select.Option>;

              case PageTemplate.VIDEO_ON_DEMAND:
                return (
                  <Select.Option value={PageTemplate.VIDEO_ON_DEMAND}>
                    Video On Demand
                  </Select.Option>
                );
              case PageTemplate.VIRTUAL_VENUE:
                return (
                  <Select.Option value={PageTemplate.VIRTUAL_VENUE}>Virtual Venue</Select.Option>
                );

              default:
                return <></>;
            }
          })
      ) : (
        <>
          <Select.Option value={PageTemplate.ATTENDEES}>Attendees</Select.Option>
          <Select.Option value={PageTemplate.BASIC}>Basic Content</Select.Option>
          <Select.Option value={PageTemplate.BREAKOUT}>Breakout</Select.Option>
          <Select.Option value={PageTemplate.CANVAS}>Canvas</Select.Option>
          <Select.Option value={PageTemplate.IFRAME}>iFrame</Select.Option>
          <Select.Option value={PageTemplate.LIVE}>Live</Select.Option>
          <Select.Option value={PageTemplate.SCHEDULE}>Schedule</Select.Option>
          <Select.Option value={PageTemplate.VIDEO_ON_DEMAND}>Video On Demand</Select.Option>
          <Select.Option value={PageTemplate.VIRTUAL_VENUE}>Virtual Venue</Select.Option>
        </>
      )}
    </>
  );

  const pageType = (
    <Form.Item
      name='template'
      label={<div style={{ fontWeight: 'bold' }}>Page Type</div>}
      rules={[
        {
          required: true,
          message: 'this is a required field',
        },
      ]}
    >
      <Select placeholder='Select a page type.'>{getPageTypeOptions}</Select>
    </Form.Item>
  );

  const alertDescription = (
    <>
      Friendly reminder that some attendees may not see this page because:
      <ul style={{ marginTop: '1em' }}>
        {warnings.map((msg) => {
          return <li key={msg}>{msg}</li>;
        })}
      </ul>
    </>
  );

  return (
    <>
      {warnings.length > 0 && (
        <Alert
          message={<b>Warning</b>}
          description={alertDescription}
          type='warning'
          style={{ marginBottom: '1em' }}
          showIcon
        />
      )}
      {availabilityPicker}
      {/* {menuPosition} */}
      {menuSwitches}
      {userRoles}
      {pageType}
      {(isSuperAdmin || organisation) && urlSlug}
      {pageTitle}
    </>
  );
}
