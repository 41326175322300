import parse, { Attributes } from '@utils/parse';

import { SessionResource } from './session';

export const parseLocationVisit = (attributes: Attributes) => {
  return {
    booths: attributes.custom('booths', (booths: any[]) => {
      return parse.many(booths, parseBoothVisit);
    }),
    pages: attributes.custom('pages', (pages: any[]) => {
      return parse.many(pages, parsePageVisit);
    }),
  };
};

const parseBoothVisit = (attributes: Attributes) => {
  return {
    count: attributes.number('count'),
    boothId: attributes.string('boothId'),
  };
};

const parsePageVisit = (attributes: Attributes) => {
  return {
    count: attributes.number('count'),
    page: attributes.custom('page', (page: any) => {
      return parse.one(page, parsePage);
    }),
    video: attributes.optional.custom('video', (video: any) => {
      return parse.one(video, parseVideo);
    }),
  };
};

const parsePage = (attributes: Attributes) => {
  return {
    id: attributes.string('id'),
    slug: attributes.string('slug'),
    title: attributes.string('title'),
    template: attributes.string('template'),
  };
};

const parseVideo = (attributes: Attributes) => {
  return {
    id: attributes.string('id'),
    title: attributes.string('title'),
  };
};

export function one(data: any) {
  return parse.one(data, parseLocationVisit);
}

export function many(data: any) {
  return parse.many(data, parseLocationVisit);
}

export type PageVisit = ReturnType<typeof parsePageVisit>;

export type LocationVisitResource = ReturnType<typeof one>;

export interface SessionWithLocationVisit extends SessionResource {
  locationVisit: PageVisit;
}

export default { one, many };
