import React, { useState, useCallback } from 'react';
import { PageHeader, Layout, Tabs, Button, Input } from 'antd';
import type { SorterResult } from 'antd/lib/table/interface';

import { LazyModal } from '@components/LazyModal';
import { AdminsTable, Drawer } from '@components/Admins';
import { Admin as AdminModal, AdminTable as AdminTableModal } from '@components/Modals';

import useAuth from '@hooks/auth/useAuth';
import { useEvents } from '@hooks/useEvents';
import { useProtected } from '@hooks/useProtected';
import { useOrganisations } from '@hooks/useOrganisations';
import {
  useAdmins,
  useCreateSuperAdmin,
  useUpdateSuperAdmin,
  useDeleteSuperAdmin,
  useCreateOrganisationAdmin,
  useDeleteOrganisationAdmin,
  useCreateAdmin,
} from '@hooks/useAdmins';
import { Admin, AdminResource, AdminRole } from '@transforms/admin';

export default function Admins() {
  useProtected();
  const { isSuperAdmin } = useAuth();
  const { data: events } = useEvents();

  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [openOrganisationAdminModal, setOpenOrganisationAdminModal] = useState(false);
  const [openEventAdminModal, setOpenEventAdminModal] = useState(false);
  const [openAdminTableModal, setOpenAdminTableModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [selectedAdmin, setSelectedAdmin] = useState<AdminResource | undefined>();
  const [selectedEvent, setSelectedEvent] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<string | undefined>('event-admins');

  const [searchTerm, setSearchTerm] = useState('');
  const [sorter, setSorter] = useState<SorterResult<Admin>>({});

  const { isLoading, data: admins } = useAdmins(
    {
      page: 1,
      limit: 99,
      sorter,
    },
    searchTerm,
  );
  const { data: organisations } = useOrganisations({
    page: 1,
    limit: 99,
  });

  const { mutate: createAdmin } = useCreateSuperAdmin();
  const { mutate: updateAdmin } = useUpdateSuperAdmin(selectedAdmin?.id);
  const { mutate: deleteAdmin } = useDeleteSuperAdmin();

  const { mutate: createEventAdmin } = useCreateAdmin(selectedEvent);

  const { mutate: createOrganisationAdmin } = useCreateOrganisationAdmin();
  const { mutate: deleteOrganisationAdmin } = useDeleteOrganisationAdmin();

  const toggleAdminModal = useCallback(
    () => setOpenAdminModal((state) => !state),
    [setOpenAdminModal],
  );

  const toggleOrganisationAdminModal = useCallback(
    () => setOpenOrganisationAdminModal((state) => !state),
    [setOpenOrganisationAdminModal],
  );

  const toggleEventAdminModal = useCallback(
    () => setOpenEventAdminModal((state) => !state),
    [setOpenEventAdminModal],
  );

  const onAdminSearch = (value: string) => {
    setSearchTerm(value);
  };

  const onTabChange = (value: string) => {
    setSelectedTab(value);
    setSearchTerm('');
    setSorter({});
  };

  const onOpenAdminModal = (admin?: AdminResource) => {
    if (admin) {
      setSelectedAdmin(admin);
    } else {
      setSelectedAdmin(undefined);
    }
    setOpenAdminModal(true);
  };

  const onOpenOrganisationAdminModal = (admin?: AdminResource) => {
    if (admin) {
      setSelectedAdmin(admin);
    } else {
      setSelectedAdmin(undefined);
    }
    setOpenOrganisationAdminModal(true);
  };

  const onOpenEventAdminModal = (admin?: AdminResource) => {
    if (admin) {
      setSelectedAdmin(admin);
    } else {
      setSelectedAdmin(undefined);
    }
    setOpenEventAdminModal(true);
  };

  const onCloseAdminModal = () => {
    setOpenAdminModal(false);
    setSelectedAdmin(undefined);
  };

  const onCloseOrganisationAdminModal = () => {
    setOpenOrganisationAdminModal(false);
    setSelectedAdmin(undefined);
  };

  const onCloseEventAdminModal = () => {
    setOpenEventAdminModal(false);
    setSelectedAdmin(undefined);
  };

  const onOpenDrawer = (user: AdminResource) => {
    setSelectedAdmin(user);
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setSelectedAdmin(undefined);
  };

  const onAfterCloseDrawer = (open: boolean) => {
    if (open) return;

    setSelectedAdmin(undefined);
  };

  const onCreateAdmin = (values: { email: string }, done: (err?: Error) => void) => {
    createAdmin(values, {
      onSuccess: () => {
        toggleAdminModal();
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onUpdateAdmin = (values: Admin, done: (err?: Error) => void) => {
    updateAdmin(values, {
      onSuccess: () => {
        setOpenAdminModal(false);
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onCreateOrganisationAdmin = (
    values: { email: string; organisationId: string },
    done: (err?: Error) => void,
  ) => {
    createOrganisationAdmin(values, {
      onSuccess: () => {
        toggleOrganisationAdminModal();
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onCreateEventAdmin = (values: Admin, done: (err?: Error) => void) => {
    createEventAdmin(values, {
      onSuccess: () => {
        toggleEventAdminModal();
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const renderExtra = () => {
    switch (selectedTab) {
      case 'super-admins':
        return [
          <Input.Search
            placeholder='Search'
            allowClear
            onSearch={onAdminSearch}
            style={{ width: 200 }}
            key='searchAdmins'
          />,
          <Button key='menu' type='primary' onClick={() => onOpenAdminModal()}>
            Add Super Admin
          </Button>,
        ];
      case 'organisation-admins':
        return [
          <Input.Search
            placeholder='Search'
            allowClear
            onSearch={onAdminSearch}
            style={{ width: 200 }}
            key='searchAdmins'
          />,
          isSuperAdmin && (
            <Button key='menu' type='primary' onClick={() => onOpenOrganisationAdminModal()}>
              Add Organization Admin
            </Button>
          ),
        ];
      default:
        return [
          <Input.Search
            placeholder='Search'
            allowClear
            onSearch={onAdminSearch}
            style={{ width: 200 }}
            key='searchEvents'
          />,
          isSuperAdmin && (
            <Button key='menu' type='primary' onClick={() => onOpenEventAdminModal()}>
              Add Event Admin
            </Button>
          ),
        ];
    }
  };

  const superAdmins = admins?.items?.filter(
    (admin: AdminResource) => admin?.role === AdminRole.SUPER_ADMIN,
  );
  const orgAdmins = admins?.items?.filter(
    (admin: AdminResource) =>
      admin?.role !== AdminRole.SUPER_ADMIN && (admin?.organisations?.length ?? 0) > 0,
  );
  const eventAdmins = admins?.items?.filter(
    (admin: AdminResource & { assignments: any[] }) =>
      admin?.role !== AdminRole.SUPER_ADMIN && (admin?.assignments.length ?? 0) > 0,
  );

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader title='Admins' extra={renderExtra()} />
      <Tabs defaultActiveKey='event-admins' onChange={onTabChange}>
        <Tabs.TabPane tab='Event Admins' key='event-admins'>
          <LazyModal open={selectedTab === 'event-admins'}>
            {() => (
              <AdminsTable
                event
                loading={isLoading}
                admins={eventAdmins}
                onEdit={onOpenDrawer}
                setSorter={setSorter}
                setSelectedAdmin={(admin: AdminResource) => setSelectedAdmin(admin)}
                onDeleteText={
                  <span>
                    Are you sure you want to delete this event admin from{' '}
                    <strong>{`${
                      // @ts-ignore
                      selectedAdmin?.assignments?.length > 1 ? 'all events?' : 'this event?'
                    }`}</strong>
                  </span>
                }
                setAdminTableModal={(admin: AdminResource) => {
                  setSelectedAdmin(admin);
                  setOpenAdminTableModal(true);
                }}
                onDelete={(admin: AdminResource) => {
                  if (admin && admin.assignments) {
                    deleteAdmin(admin.id);
                    setSelectedAdmin(undefined);
                  }
                }}
              />
            )}
          </LazyModal>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Organization Admins' key='organisation-admins'>
          <LazyModal open={selectedTab === 'organisation-admins'}>
            {() => (
              <AdminsTable
                organisation
                loading={isLoading}
                admins={orgAdmins}
                onEdit={onOpenDrawer}
                setSorter={setSorter}
                onDelete={(admin) =>
                  isSuperAdmin &&
                  deleteOrganisationAdmin({
                    organisationId: admin.organisations![0].id,
                    adminId: admin.id,
                  })
                }
                onDeleteText='Are you sure you want to remove this organization admin from this organization?'
              />
            )}
          </LazyModal>
        </Tabs.TabPane>
        {isSuperAdmin && (
          <Tabs.TabPane tab='Super Admins' key='super-admins'>
            <LazyModal open={selectedTab === 'super-admins'}>
              {() => (
                <AdminsTable
                  loading={isLoading}
                  admins={superAdmins}
                  onEdit={onOpenDrawer}
                  setSorter={setSorter}
                  onDelete={(admin) => deleteAdmin(admin.id)}
                  onDeleteText='Are you sure you want to delete this super admin?'
                />
              )}
            </LazyModal>
          </Tabs.TabPane>
        )}
      </Tabs>

      <Drawer
        visible={openDrawer}
        user={selectedAdmin}
        eventId={undefined}
        onClose={onCloseDrawer}
        onFinish={(values) => onUpdateAdmin(values, () => null)}
        afterVisibleChange={(visible) => onAfterCloseDrawer(visible)}
      />
      <AdminModal
        visible={openAdminModal}
        data={selectedAdmin}
        adminType='superAdmin'
        onCancel={onCloseAdminModal}
        onCreate={onCreateAdmin}
        onUpdate={onUpdateAdmin}
      />
      <AdminModal
        visible={openEventAdminModal}
        data={selectedAdmin}
        adminType='admin'
        onCancel={onCloseEventAdminModal}
        onCreate={onCreateEventAdmin}
        onUpdate={onUpdateAdmin}
        events={events}
        setSelectedEvent={setSelectedEvent}
      />
      <AdminModal
        visible={openOrganisationAdminModal}
        data={selectedAdmin}
        adminType='organisationAdmin'
        onCancel={onCloseOrganisationAdminModal}
        onCreate={onCreateOrganisationAdmin}
        onUpdate={onUpdateAdmin}
        organisations={organisations?.items}
      />
      <AdminTableModal
        visible={openAdminTableModal}
        setVisible={setOpenAdminTableModal}
        data={selectedAdmin!}
        setSorter={setSorter!}
      />
    </Layout.Content>
  );
}
