import { Col, Divider, Row } from 'antd';
import { TeamOutlined, StarOutlined } from '@ant-design/icons';

import { SessionResource } from '@transforms/session';

import LargeCard, { LargeCardViewType } from './LargeCard';

export function AnalyticsSessions({
  sessionsAttendance,
  sessionsScheduled,
}: {
  sessionsAttendance: any;
  sessionsScheduled: any;
}) {
  return (
    <>
      <h2
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          lineHeight: '1.2',
        }}
      >
        Sessions
      </h2>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <LargeCard
            icon={<TeamOutlined />}
            title='Attendance'
            subtitle='Sessions visited by attendees'
            viewType={LargeCardViewType.DynamicList}
            columns={[
              { key: 'title', title: 'Session', type: 'session' },
              {
                key: 'percentage',
                title: 'Percentage',
                type: 'percentage',
                tooltip:
                  'Percentage of attendees who attended the session relative to the total number of attendees on the platform',
              },
              {
                key: 'attendees',
                title: 'Attendees',
                type: 'attendees',
                tooltip: 'Number of attendees who attended the page of the session at least once',
              },
            ]}
            data={sessionsAttendance?.map(
              (session: SessionResource & { visitCount: number; amountOfUsers: number }) => ({
                title: session.title,
                attendees: session.visitCount,
                percentage: Math.round(session.amountOfUsers * 100) / 100,
                icon: session.imageUrl,
              }),
            )}
          />
        </Col>
        <Col span={24}>
          <LargeCard
            icon={<StarOutlined />}
            title='Scheduled'
            subtitle='Sessions added to personal agenda'
            viewType={LargeCardViewType.DynamicList}
            columnSizes={[8, 3, 3, 3, 3]}
            columns={[
              { key: 'title', title: 'Session', type: 'session' },
              {
                key: 'percentage',
                title: 'Percentage',
                type: 'percentage',
                tooltip:
                  'Percentage of attendees who scheduled the session relative to the total number of attendees on the platform',
              },
              { key: 'attendees', title: 'Attendees', type: 'attendees' },
              {
                key: 'percentageAttended',
                title: 'Percentage Attended',
                type: 'percentageAttended',
                tooltip:
                  'Percentage of attendees who attended the session relative to the number of attendees who scheduled the session, not the total number of attendees on the platform',
              },
              { key: 'attended', title: 'Attended', type: 'attended' },
            ]}
            data={sessionsScheduled?.map(
              (
                session: SessionResource & {
                  addCount: number;
                  amountOfUsers: number;
                  uniqueUserVisits: number;
                },
              ) => ({
                title: session.title,
                percentage: Math.round(session.amountOfUsers * 100) / 100,
                attendees: session.addCount,
                percentageAttended: Math.round((session.uniqueUserVisits / session.addCount) * 100),
                attended: session.uniqueUserVisits,
                icon: session.imageUrl,
              }),
            )}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
}
