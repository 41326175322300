import React from 'react';
import { PageHeader, Layout, Row, Col, Descriptions, Card, Statistic, Avatar } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import { useProtected } from '@hooks/useProtected';
import { useOrganisation } from '@hooks/useOrganisations';

export default function NewOrganisation() {
  useProtected();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { isLoading, data: organisation } = useOrganisation(organisationId!);

  if (isLoading || !organisation) return null;

  return (
    <Layout.Content className='site-layout-content'>
      <Link to='/organisations'>
        <p
          className='organisation-row'
          style={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#001628',
            transition: 'color 0.2s ease-out',
          }}
        >
          <ArrowLeftOutlined style={{ fontWeight: 800 }} />
          <span style={{ marginLeft: '10px' }}>Back to Organizations</span>
        </p>
      </Link>
      <PageHeader
        title={
          <p>
            <Avatar size={80} src={organisation.imageUrl} />
            <h2 style={{ display: 'inline-block', marginLeft: 10 }}>{organisation.name}</h2>
          </p>
        }
      />
      <Descriptions>
        <Descriptions.Item prefixCls='' label={<b>Contact Name: </b>}>
          {organisation.contactName}
        </Descriptions.Item>
        <Descriptions.Item prefixCls='' label={<b>Contact Email: </b>}>
          {organisation.contactEmail}
        </Descriptions.Item>
        <Descriptions.Item prefixCls='' label={<b>Saleforce Number: </b>}>
          {organisation.salesforceNumber}
        </Descriptions.Item>
        <Descriptions.Item prefixCls='' label={<b>Company Details: </b>}>
          {organisation.companyDetails}
        </Descriptions.Item>
        <Descriptions.Item prefixCls='' label={<b>Subscription Details: </b>} span={2}>
          {organisation.subscriptionDetails}
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={16}>
        {organisation.eventCount != null && (
          <Col span={8}>
            <Card>
              <Statistic
                title='Events'
                value={organisation.eventCount}
                suffix={`/ ${organisation.eventLimit ?? 'Unlimited'}`}
              />
            </Card>
          </Col>
        )}
        {organisation.activeEventCount != null && (
          <Col span={8}>
            <Card>
              <Statistic
                title='Active Events'
                value={organisation.activeEventCount}
                suffix={`/ ${organisation.activeEventLimit ?? 'Unlimited'}`}
              />
            </Card>
          </Col>
        )}
        {organisation.userCount != null && (
          <Col span={8}>
            <Card>
              <Statistic
                title='Active Users'
                value={organisation.userCount}
                suffix={`/ ${organisation.userLimit ?? 'Unlimited'}`}
              />
            </Card>
          </Col>
        )}
      </Row>
    </Layout.Content>
  );
}
