import { Input, Form, Row } from 'antd';

import DatePicker from '@components/DatePicker';
import { RichTextEditor } from '@components/RichTextEditor';
import { stripHtml } from '@utils/html';

const MAX_DESCRIPTION_LENGTH = 1000;

export function General() {
  const validateDescription = (value: string, callback: (error?: string) => void) => {
    if (stripHtml(value).length > MAX_DESCRIPTION_LENGTH) {
      callback('The event description is too long.');
      return;
    }

    callback();
  };

  const eventNameInput = (
    <Form.Item
      key='eventName'
      name='name'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Event Name</div>
        </div>
      }
      rules={[{ required: true }]}
    >
      <Input maxLength={100} placeholder='The name of the event' />
    </Form.Item>
  );

  const eventDescriptionInput = (
    <Form.Item
      key='eventDesc'
      name='description'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Description</div>
          <div>
            This description will be displayed on the landing page as well as within the "Add to
            Calendar" item that is attached to the login link email.
          </div>
        </div>
      }
      rules={[
        {
          validator: (_, value, callback) => validateDescription(value, callback),
        },
      ]}
    >
      <RichTextEditor
        maxLength={MAX_DESCRIPTION_LENGTH}
        placeholder='A short description for this event.'
      />
    </Form.Item>
  );

  const renderOrganiserInput = (
    <Form.Item
      key='organiser'
      name='organiser'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Support Name</div>
        </div>
      }
    >
      <Input placeholder='The support person (or department) to contact for this event.' />
    </Form.Item>
  );

  const renderOrganiserContactInput = (
    <Form.Item
      key='organiserContact'
      name='organiserContact'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Support Contact</div>
        </div>
      }
      rules={[
        {
          required: true,
          type: 'email',
          message: 'Fill in an email address that attendees can use for support.',
        },
      ]}
    >
      <Input placeholder='The email address of the support person (or department).' />
    </Form.Item>
  );

  const renderPrivacyPolicyUrlInput = (
    <Form.Item
      key='privacyPolicyUrl'
      name='privacyPolicyUrl'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Privacy Policy URL</div>
        </div>
      }
      rules={[{ required: true }]}
    >
      <Input placeholder='The URL (web address) of the privacy policy for this event.' />
    </Form.Item>
  );

  const eventDatesInput = (
    <Row key='dates'>
      <Form.Item
        name='dates'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Event Dates</div>
            <div>
              Determine the timeframe in which this event will take place. Note, this setting is for
              reference only and does not influence platform availability.
            </div>
          </div>
        }
      >
        {/* @ts-ignore */}
        <DatePicker.RangePicker showTime placeholder={['Start Date', 'End Date']} />
      </Form.Item>
    </Row>
  );

  return (
    <>
      {[
        eventNameInput,
        eventDescriptionInput,
        renderOrganiserInput,
        renderOrganiserContactInput,
        renderPrivacyPolicyUrlInput,
        eventDatesInput,
      ]}
    </>
  );
}
