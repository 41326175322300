import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import queryString from 'query-string';

import fetch from '@utils/fetch';
import { organisationImageEndpoint } from '@utils/uploadEndpoints';
import transform, { Organisation } from '@transforms/organisation';
import { useAuth } from '@hooks/auth/useAuth';

export const useOrganisation = (organisationId: string) => {
  const { accessToken } = useAuth();

  return useQuery(['organisations', organisationId], async () => {
    const { body } = await fetch(`/organisations/${organisationId}`, { token: accessToken });

    return transform.one(body);
  });
};

export const useOrganisations = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: { field?: keyof Organisation; order?: 'ascend' | 'descend' };
  },
  search?: string,
) => {
  const { accessToken } = useAuth();
  const query = queryString.stringify({
    ...pagination,
    sortField: sorter?.field,
    sortOrder: sorter?.order,
    search: search || undefined,
  });

  return useQuery(
    ['organisations', search, sorter?.field, sorter?.order, pagination?.page, pagination?.limit],
    async () => {
      const { body } = await fetch(`/organisations?${query}`, { token: accessToken });

      return transform.many(body);
    },
  );
};

export const useInfiniteOrganisations = (search?: string) => {
  const { accessToken } = useAuth();

  return useInfiniteQuery(
    ['organisations', search].filter(Boolean),
    async ({ pageParam = 1 }) => {
      const query = queryString.stringify({
        search: search || undefined,
        page: pageParam,
        limit: '30',
      });
      const { body } = await fetch(`/organisations?${query}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) return undefined;
        return lastPage.meta.currentPage + 1;
      },
    },
  );
};

export const useCreateOrganisation = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Organisation) => {
      return fetch(`/organisations`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('organisations');
      },
    },
  );
};

export const useCreateSuperOrganisation = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: { email: string }) => {
      return fetch('/organisations', {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('superOrganisations');
      },
    },
  );
};

export const useUpdateOrganisation = (organisationId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Organisation) => {
      return fetch(`/organisations/${organisationId}`, {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('organisations');
      },
    },
  );
};

export const useDeleteOrganisation = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (organisationId: string) => {
      return fetch(`/organisations/${organisationId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('organisations');
      },
    },
  );
};

export const useUploadOrganisationImage = (organisationId: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (image: File | Blob) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(organisationImageEndpoint(organisationId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('organisations');
      },
    },
  );
};

export default {
  useOrganisation,
  useOrganisations,
  useInfiniteOrganisations,
  useCreateOrganisation,
  useUpdateOrganisation,
  useDeleteOrganisation,
  useUploadOrganisationImage,
};
