import { useEffect, useState, useRef } from 'react';
import { Layout, PageHeader, Divider } from 'antd';

import { useLocation } from 'react-router-dom';

import Sidebar from '@components/Sidebar';

import useProtected from '@hooks/useProtected';
import useAuth from '@hooks/auth/useAuth';
import { useCurrentEvent } from '@hooks/useEvents';
import useIsInViewport from '@hooks/useIsInViewport';
import { initHelpScoutBeacon } from '@utils/helpScout';

export type EventLayoutProps = {
  children?: React.ReactNode;
  title?: React.ReactNode | string;
  extra?: React.ReactNode;
};

const ScollerContent = ({ children, title, extra }: EventLayoutProps) => {
  const { data: event } = useCurrentEvent();
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(ref);
  const [scrollY, setScrollY] = useState<number>(0);

  useEffect(() => {
    const onScroll = () => setScrollY(window.pageYOffset);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const beacon = document.getElementsByClassName('BeaconFabButtonFrame')[0] as HTMLElement;
    if (beacon) {
      if (scrollY > 0 && isInViewport) {
        beacon.classList.add('help-scout-transition-up');
        beacon.classList.remove('help-scout-transition-down');
      } else {
        beacon.classList.add('help-scout-transition-down');
        beacon.classList.remove('help-scout-transition-up');
      }
    }
  }, [scrollY, isInViewport]);

  return (
    <>
      <Layout.Sider width={215}>
        <Sidebar />
      </Layout.Sider>
      {event && (
        <Layout.Content className='site-layout-content'>
          {title && (
            <>
              <PageHeader title={title} extra={extra} />
              <Divider key='d1' />
            </>
          )}
          {children}
          <div className='help-scout-beacon-scroll-indicator' ref={ref} />
        </Layout.Content>
      )}
    </>
  );
};

export function EventLayout(props: EventLayoutProps) {
  useProtected();
  const location = useLocation();
  const { isEventAdmin, organisation } = useAuth();

  useEffect(() => {
    initHelpScoutBeacon(
      (isEventAdmin && !organisation
        ? process.env.REACT_APP_HELP_SCOUT_EVENT_ADMIN_BEACON_ID
        : undefined) || process.env.REACT_APP_HELP_SCOUT_SUPER_ADMIN_BEACON_ID,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this once on every navigate action
  }, [location]);

  return <ScollerContent {...props} />;
}
