import parse, { Attributes } from '@utils/parse';

export const parseMandatoryFieldTranslation = (attributes: Attributes) => {
  return {
    key: attributes.string('key'),
    label: attributes.string('label'),
  };
};

export function one(data: any) {
  return parse.one(data, parseMandatoryFieldTranslation);
}

export function many(data: any) {
  return parse.many(data, parseMandatoryFieldTranslation);
}

export type MandatoryFieldTranslation = ReturnType<typeof one>;

export default { one, many };
