import { createContext, useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';

import transformToken from '@transforms/token';
import transform, { AdminResource, AdminRole } from '@transforms/admin';
import transformTFASetup, { TFASetupResource } from '@transforms/tfaSetup';
import type { OrganisationResource } from '@transforms/organisation';

import fetch, { APIRequestInit } from '@utils/fetch';
import { readRefreshToken, writeRefreshToken } from '@utils/auth';

let userId: string | undefined;

export const getUserId = () => {
  return userId;
};

export type Tokens = {
  accessToken: string | undefined;
  refreshToken: string | undefined;
};

export const tokens: Tokens = {
  accessToken: undefined,
  refreshToken: readRefreshToken(),
};

export function getTokens() {
  return tokens;
}

export function setTokens(data: Tokens) {
  if (data.accessToken) tokens.accessToken = data.accessToken;
  if (data.refreshToken) tokens.refreshToken = data.refreshToken;
}

export function getAccessToken() {
  return tokens.accessToken;
}

const getCurrentOrganisation = (organisations: OrganisationResource[]) => {
  return organisations.find(
    (organisation) => organisation.id === localStorage.getItem('organisation'),
  );
};

export const useProvideAuth = () => {
  const [error, setError] = useState<any | undefined>();
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [user, setUser] = useState<AdminResource>();
  const [organisation, setOrganisation] = useState<OrganisationResource>();
  const [tfaRequired, setTfaRequired] = useState<boolean>(false);
  const [tfaSetup, setTfaSetup] = useState<TFASetupResource | undefined>();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refresh().finally(() => {
      setIsInitializing(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userId = user?.id;
    if (user?.role) {
      setIsSuperAdmin(user.role === AdminRole.SUPER_ADMIN);
    }
  }, [user]);

  const authFailureCallback = async (request: Request | string, init: APIRequestInit) => {
    const token = readRefreshToken();
    try {
      const response = await fetch(`/auth/refresh`, {
        method: 'POST',
        body: { refreshToken: token },
      });

      const tokenTransform = transformToken(response.body);
      tokens.accessToken = tokenTransform.accessToken;
      tokens.refreshToken = tokenTransform.refreshToken;
      writeRefreshToken(tokenTransform.refreshToken);
      return fetch(request, { ...init, token: tokenTransform.accessToken });
    } catch (err) {
      writeRefreshToken();
      throw err;
    }
  };

  const refresh = async () => {
    if (!tokens.refreshToken) return;

    try {
      const response = await fetch('/auth/refresh', {
        method: 'POST',
        body: { refreshToken: tokens.refreshToken },
      });

      const tokenTransform = transformToken(response.body);
      tokens.accessToken = tokenTransform.accessToken;
      tokens.refreshToken = tokenTransform.refreshToken;

      if (tokenTransform.tfaRequired) {
        setIsAuthorized(false);
        setTfaRequired(true);
        setUser(undefined);
        setOrganisation(undefined);
        return;
      }

      writeRefreshToken(tokens.refreshToken);

      try {
        const userResponse = await fetch('/admins/me', { token: tokens.accessToken });
        const userTransform = transform.one(userResponse.body);

        setIsAuthorized(true);
        setUser(userTransform);
        setOrganisation(
          getCurrentOrganisation(userTransform.organisations as OrganisationResource[]),
        );
      } catch (err) {
        setError(err);
      }
    } catch (err) {
      setError(err);
    }
  };

  const acceptInvitation = async (invitationToken: string, password: string) => {
    try {
      await fetch('/admins/accept-invitation', {
        method: 'POST',
        body: { invitationToken, password },
      });
      setError(undefined);
    } catch (err) {
      setError(err);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await fetch('/admins/forgot-password', {
        method: 'POST',
        body: { email },
      });
      setError(undefined);
    } catch (err) {
      setError(err);
    }
  };

  const resetPassword = async (resetToken: string, password: string) => {
    try {
      await fetch('/admins/reset-password', {
        method: 'POST',
        body: { requestPasswordToken: resetToken, password },
      });
      setError(undefined);
    } catch (err) {
      setError(err);
    }
  };

  const setupTFA = async () => {
    try {
      const response = await fetch('/auth/totp-setup', {
        method: 'POST',
        token: tokens.accessToken,
      });

      const data = transformTFASetup(response.body);
      setTfaSetup(data);
      setError(undefined);
    } catch (err) {
      setError(err);
    }
  };

  const enableTFA = async (code: string) => {
    try {
      await fetch('/auth/totp-enable', {
        method: 'POST',
        token: tokens.accessToken,
        body: { code },
      });

      setTfaSetup(undefined);

      try {
        const userResponse = await fetch('/admins/me', { token: tokens.accessToken });
        const userTransform = transform.one(userResponse.body);

        setError(undefined);
        setIsAuthorized(true);
        setUser(userTransform);
        setOrganisation(
          getCurrentOrganisation(userTransform.organisations as OrganisationResource[]),
        );
      } catch (err) {
        setError(err);
      }
    } catch (err) {
      setError(err);
    }
  };

  const cancelTFASetup = async () => {
    setTfaSetup(undefined);
  };

  const verifyTFA = async (code: string) => {
    try {
      const response = await fetch('/auth/totp-verify', {
        method: 'POST',
        token: tokens.accessToken,
        body: { code },
      });

      const tokenTransform = transformToken(response.body);
      tokens.accessToken = tokenTransform.accessToken;
      tokens.refreshToken = tokenTransform.refreshToken;
      writeRefreshToken(tokenTransform.refreshToken);

      try {
        const userResponse = await fetch('/admins/me', { token: tokens.accessToken });
        const userTransform = transform.one(userResponse.body);

        setError(undefined);
        setTfaRequired(false);
        setIsAuthorized(true);
        setUser(userTransform);
        setOrganisation(
          getCurrentOrganisation(userTransform.organisations as OrganisationResource[]),
        );
      } catch (err) {
        setError(err);
      }
    } catch (err) {
      setError(err);
    }
  };

  const disableTFA = async () => {
    try {
      await fetch('/auth/totp-disable', {
        method: 'DELETE',
        token: tokens.accessToken,
      });

      try {
        const userResponse = await fetch('/admins/me', { token: tokens.accessToken });
        const userTransform = transform.one(userResponse.body);

        setError(undefined);
        setIsAuthorized(true);
        setUser(userTransform);
        setOrganisation(
          getCurrentOrganisation(userTransform.organisations as OrganisationResource[]),
        );
      } catch (err) {
        setError(err);
      }
    } catch (err) {
      setError(err);
    }
  };

  const selectOrganisation = async (org: Partial<OrganisationResource> | null) => {
    if (org) {
      localStorage.setItem('organisation', org.id!);
    } else {
      localStorage.removeItem('organisation');
    }
    window.location.reload();
  };

  return {
    isInitializing,
    isAuthorized,
    isSuperAdmin,
    isEventAdmin: !isSuperAdmin,
    user,
    organisation,
    error,
    accessToken: tokens.accessToken,
    tfaSetup,
    tfaRequired,
    refresh,
    acceptInvitation,
    forgotPassword,
    resetPassword,
    setupTFA,
    cancelTFASetup,
    enableTFA,
    disableTFA,
    verifyTFA,
    authFailureCallback,
    setIsAuthorized,
    setIsInitializing,
    setUser,
    setOrganisation,
    setTfaRequired,
    setError,
    selectOrganisation,
  };
};

const authContext = createContext<ReturnType<typeof useProvideAuth>>({
  isInitializing: false,
  isAuthorized: false,
  isSuperAdmin: false,
  isEventAdmin: true,
  user: undefined,
  organisation: undefined,
  error: undefined,
  accessToken: undefined,
  tfaRequired: false,
  tfaSetup: undefined,
  refresh: async () => undefined,
  acceptInvitation: async () => undefined,
  forgotPassword: async () => undefined,
  resetPassword: async () => undefined,
  setupTFA: async () => undefined,
  cancelTFASetup: async () => undefined,
  enableTFA: async () => undefined,
  disableTFA: async () => undefined,
  verifyTFA: async () => undefined,
  authFailureCallback: () => undefined as any,
  setIsAuthorized: () => undefined,
  setIsInitializing: () => undefined,
  setUser: () => undefined,
  setOrganisation: () => undefined,
  setTfaRequired: () => undefined,
  setError: () => undefined,
  selectOrganisation: async () => undefined,
});

export const useAuth = () => {
  return useContext(authContext);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();

  if (auth.isInitializing)
    return (
      <div
        style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Spin size='large' />
      </div>
    );

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export default useAuth;
