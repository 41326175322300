import { useMutation, useQuery, useQueryClient } from 'react-query';

import transform, { BreakoutRoom } from '@transforms/breakoutRoom';
import transformCategory, { PageItemCategory } from '@transforms/pageItemCategory';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import useAuth from '@hooks/auth/useAuth';

export const useBreakoutRooms = (eventId?: string, pageId?: string, language?: string) => {
  const { accessToken } = useAuth();
  return useQuery(['breakoutRooms', eventId, language], async () => {
    if (pageId === 'new') return [];
    const { body } = await fetch(
      languageUrl(`/events/${eventId}/pages/${pageId}/breakout-rooms`, language),
      {
        token: accessToken,
      },
    );
    return transform.many(body);
  });
};

export const useCreateBreakoutRoom = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (values: BreakoutRoom & { pageId: string }) => {
      return fetch(languageUrl(`/events/${eventId}/breakout-rooms`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRooms');
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useDuplicateBreakoutRoom = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/breakout-rooms/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRooms');
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useUpdateBreakoutRoom = (
  eventId?: string,
  breakoutRoomId?: string,
  language?: string,
) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (values: BreakoutRoom & { pageId: string }) => {
      return fetch(languageUrl(`/events/${eventId}/breakout-rooms/${breakoutRoomId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRooms');
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useDeleteBreakoutRoom = (eventId?: string) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return fetch(`/events/${eventId}/breakout-rooms/${id}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRooms');
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useUploadBreakoutRoomImage = (eventId?: string) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      breakoutRoomId,
      image,
      lang,
    }: {
      breakoutRoomId: string;
      image: File | Blob;
      lang?: string;
    }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(languageUrl(`/events/${eventId}/breakout-rooms/${breakoutRoomId}/image`, lang), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRooms');
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const usePageBreakoutRoomCategories = (
  eventId: string,
  pageId: string,
  language?: string,
) => {
  const { accessToken } = useAuth();

  return useQuery(['breakoutRoomCategories', eventId, pageId, language], async () => {
    const { body } = await fetch(
      languageUrl(`/events/${eventId}/pages/${pageId}/breakout-rooms`, language),
      {
        token: accessToken,
      },
    );

    return transformCategory.many(body);
  });
};

export const useCreateBreakoutRoomCategory = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: PageItemCategory) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/breakout-rooms/categories`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useDuplicateBreakoutRoomCategory = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/breakout-rooms/categories/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRooms');
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useUpdateBreakoutRoomCategory = (
  eventId?: string,
  breakoutRoomCategoryId?: string,
  language?: string,
) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: PageItemCategory) => {
      if (!eventId) throw new Error('No event');

      return fetch(
        languageUrl(
          `/events/${eventId}/breakout-rooms/categories/${breakoutRoomCategoryId}`,
          language,
        ),
        {
          method: 'PUT',
          body: values,
          token: accessToken,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};

export const useDeleteBreakoutRoomCategory = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (breakoutRoomCategoryId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/breakout-rooms/categories/${breakoutRoomCategoryId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('breakoutRoomCategories');
        queryClient.invalidateQueries('pageItemCategories');
      },
    },
  );
};
