import { Col, Divider, Row } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

import LargeCard, { LargeCardViewType } from './LargeCard';

export function AnalyticsVideos({ sessions }: { sessions: any[] }) {
  return (
    <>
      <h2
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          lineHeight: '1.2',
        }}
      >
        Videos
      </h2>

      <Row>
        <Col span={24}>
          <LargeCard
            icon={<TeamOutlined />}
            title='Watched'
            viewType={LargeCardViewType.DynamicList}
            columns={[
              { key: 'title', title: 'Title', type: 'session' },
              {
                key: 'uniqueViewers',
                title: 'Unique Viewers',
                type: 'uniqueViewers',
                tooltip: 'Unique attendees who have viewed a video at least once',
              },
              {
                key: 'totalViewers',
                title: 'Total Views',
                type: 'totalViewers',
                tooltip: 'Total views per video',
              },
              {
                key: 'avgPlaytime',
                title: 'Avg. Viewing Time',
                type: 'avgPlaytime',
                tooltip: 'Average viewing time per attendee',
              },
              {
                key: 'totalPlaytime',
                title: 'Total Viewing Time',
                type: 'totalPlaytime',
                tooltip: 'Total viewing time of all attendees combined',
              },
              {
                key: 'duration',
                title: 'Video Duration',
                type: 'duration',
                tooltip: 'Total length of the video (currently, only VOD duration is supported)',
              },
            ]}
            columnSizes={[6, 3, 3, 4, 4, 4]}
            data={sessions.map((session) => ({
              title: `${session.type === 'video' ? 'VOD' : 'Live'}: "${session.title}"`,
              type: session.type,
              uniqueViewers: session.uniqueWatchCount,
              totalViewers: session.watchCount,
              avgPlaytime: session.avgPlaytime,
              totalPlaytime: session.totalPlaytime,
              duration: session.type !== 'video' ? null : session.duration,
            }))}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
}
