import React, { useCallback, useMemo, useState } from 'react';
import { Form, Input, Select, Switch, Divider, Button } from 'antd';

import { usePages } from '@hooks/usePages';
import { BreakoutRoomResource } from '@transforms/breakoutRoom';
import { useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent } from '@hooks/useEvents';
import { useRoles } from '@hooks/useRoles';
import { ImageUpload } from '@components/Upload';

import { DataEntry, DataEntryProps } from './DataEntry';
import { PreviewBreakout } from './PreviewBreakout';

export interface BreakoutProps extends DataEntryProps {
  data?: BreakoutRoomResource;
}

export function Breakout({ data, onCreate, onUpdate, ...props }: BreakoutProps) {
  const [form] = Form.useForm();
  const parsedData = useMemo(
    () =>
      data && {
        ...data,
        redirectDestination: data?.url?.startsWith('http') ? 'external' : 'platform',
        page: !data?.url?.startsWith('http') ? data?.url : undefined,
        externalUrl: data?.url?.startsWith('http') ? data?.url : undefined,
        roles: data?.roles?.map((role) => role.id),
      },
    [data],
  );

  const { data: pages } = usePages();
  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { data: roles } = useRoles(selectedLanguage, event?.id);

  const [imagePreview, setImagePreview] = useState<string | null>(data?.imageUrl ?? null);
  const [showPreviewBreakoutModal, setShowPreviewBreakoutModal] = useState<boolean>(false);

  const validateUrl = useCallback(
    (value: string, field: string, callback: (error?: string) => void) => {
      if (!value) {
        switch (field) {
          case 'page':
            callback('You must select a page.');
            return;
          default:
            callback('You must enter a valid external link.');
            return;
        }
      }

      callback();
    },
    [],
  );

  const onCreateCallback = useCallback(
    (values: any, done: () => void) =>
      onCreate({ ...values, imageUrl: imagePreview ? undefined : null }, done),
    [onCreate, imagePreview],
  );

  const onUpdateCallback = useCallback(
    (values: any, done: () => void) =>
      onUpdate({ ...values, imageUrl: imagePreview ? undefined : null }, done),
    [onUpdate, imagePreview],
  );

  return (
    <DataEntry
      {...props}
      data={parsedData}
      name='Breakout Room (Redirect)'
      form={form}
      onCreate={onCreateCallback}
      onUpdate={onUpdateCallback}
    >
      Redirect attendees to an external video conference tool (like a Zoom or Teams meeting) or
      existing platform page.
      <br />
      <br />
      <Form.Item name='position' label={<b>Position</b>}>
        <Input type='number' />
      </Form.Item>
      <Form.Item name='roles' label={<b>User Role Access</b>}>
        <Select
          filterOption={(input, option) =>
            option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={
            roles?.map((role) => ({
              value: role.id,
              label: role.name,
            })) ?? []
          }
          mode='multiple'
          placeholder='Limit access to these specific user roles.'
        />
      </Form.Item>
      <Form.Item
        name='title'
        label={<strong>Breakout Room Title</strong>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Breakout Room Title' />
      </Form.Item>
      <Form.Item
        name='subtitle'
        label={<strong>Breakout Room Subtitle</strong>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Breakout Room Subtitle' />
      </Form.Item>
      <Form.Item name='image' label={<b>Image</b>}>
        <ImageUpload
          initialPreview={imagePreview}
          onChangePreview={setImagePreview}
          onRemove={() => form.setFields([{ name: 'image', value: null }])}
          textOverwrite={[
            'The maximum file size is 5 MB and must be at least 380x600 pixels in either JPG or PNG formats.',
            'Tip: There is a gradient filter overlay on this thumbnail to make the text legible.',
          ]}
          extraButtons={
            <>
              <Button disabled={!imagePreview} onClick={() => setShowPreviewBreakoutModal(true)}>
                Preview
              </Button>
              <PreviewBreakout
                visible={showPreviewBreakoutModal}
                formData={form.getFieldsValue()}
                imagePreview={imagePreview}
                onCancel={() => setShowPreviewBreakoutModal(false)}
              />
            </>
          }
        />
      </Form.Item>
      <Divider />
      <Form.Item
        name='redirectDestination'
        label={<b>Redirect Destination</b>}
        rules={[{ required: true }]}
      >
        <Select
          placeholder='Select a redirect destination'
          options={[
            { value: 'external', label: 'External Link URL' },
            { value: 'platform', label: 'Platform Page' },
          ]}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.redirectDestination !== currentValues.redirectDestination
        }
        noStyle
      >
        {({ getFieldValue }) => {
          switch (getFieldValue('redirectDestination')) {
            case 'external':
              return (
                <>
                  <Form.Item
                    name='externalUrl'
                    label={<b>External Link URL (ex. Zoom or Teams meeting)</b>}
                    rules={[
                      {
                        type: 'url',
                        message:
                          'The external link is invalid. (Be sure to add the "https://" prefix)',
                      },
                      {
                        validator: (_, value, callback) =>
                          validateUrl(value, 'externalUrl', callback),
                      },
                    ]}
                  >
                    <Input placeholder='The URL (web address) for redirect.' />
                  </Form.Item>
                  <Form.Item
                    name='addUserId'
                    label={<b>Add userid to External Link URL</b>}
                    valuePropName='checked'
                  >
                    <Switch />
                  </Form.Item>
                </>
              );
            case 'platform':
              return (
                <Form.Item
                  name='page'
                  label={<b>Platform Page</b>}
                  rules={[
                    {
                      validator: (_, value, callback) => validateUrl(value, 'page', callback),
                    },
                  ]}
                >
                  <Select
                    placeholder='Select a platform page for redirect.'
                    options={pages?.map((page) => ({
                      value: `?page=${page.id}`,
                      label: page.title,
                    }))}
                    allowClear
                  />
                </Form.Item>
              );
            default:
              return <></>;
          }
        }}
      </Form.Item>
    </DataEntry>
  );
}
