import decode, { JwtPayload } from 'jwt-decode';
import cookie from 'js-cookie';

export const REFRESH_COOKIE = '_vle_admin_refresh';
export const CONSENT_COOKIE = '_vle_admin_consent';

const secure =
  // eslint-disable-next-line security/detect-unsafe-regex
  !/(localhost)(:\d+)?$/.test(window.location.host) && window.location.protocol === 'http:';

export function getTokenExpiresAt(token: string) {
  const decoded = decode<JwtPayload>(token);
  const expiresAt = new Date(0);

  if (
    decoded &&
    typeof decoded === 'object' &&
    (typeof decoded.exp === 'string' || typeof decoded.exp === 'number')
  ) {
    const exp = typeof decoded.exp === 'string' ? parseInt(decoded.exp, 10) : decoded.exp;
    expiresAt.setUTCSeconds(exp);
  }

  return expiresAt.getTime();
}

export function getTokenExpiresIn(token: string) {
  const expiresAt = getTokenExpiresAt(token);
  const expiresIn = Math.abs(expiresAt - new Date().getTime());
  return Math.ceil(expiresIn / (1000 * 60 * 60 * 24));
}

// Refresh token persistence
export const writeRefreshToken = (token?: string) => {
  if (token) {
    cookie.set(REFRESH_COOKIE, token, {
      expires: getTokenExpiresIn(token),
      sameSite: 'strict',
      secure: window.location.hostname !== 'localhost',
    });
  } else {
    cookie.remove(REFRESH_COOKIE);
  }
};

export const readRefreshToken = () => cookie.get(REFRESH_COOKIE);

export const writeConsent = (status: 'accepted' | 'declined') => {
  cookie.set(CONSENT_COOKIE, status, {
    sameSite: secure ? 'None' : 'Lax',
    secure,
  });
};

export const readConsent = () => cookie.get(CONSENT_COOKIE);
