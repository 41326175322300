import { useQuery, useMutation, useQueryClient } from 'react-query';

import transform, { Announcement } from '@transforms/announcement';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';

export const useLastAnnouncement = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['announcement', eventId, 'last'],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(`/events/${eventId}/announcements/last`, { token: accessToken });
      return transform.one(body);
    },
    { enabled: !!eventId },
  );
};

export const useAnnouncements = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['announcements', eventId],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(`/events/${eventId}/announcements`, { token: accessToken });

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useCreateAnnouncement = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Announcement) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/announcements`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('announcement');
      },
    },
  );
};

export const useDeleteAnnouncement = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (announcementId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/announcements/${announcementId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.removeQueries('announcement');
      },
    },
  );
};

export default {
  useLastAnnouncement,
  useAnnouncements,
  useCreateAnnouncement,
  useDeleteAnnouncement,
};
