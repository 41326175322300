import { useQuery, useMutation, useQueryClient } from 'react-query';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import useAuth from '@hooks/auth/useAuth';
import { useInit } from '@hooks/useResources';
import transform, { Page } from '@transforms/page';

export const usePage = (eventId?: string, pageId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['page', eventId, pageId], async () => {
    if (!eventId) throw new Error('No event');
    if (!pageId) throw new Error('No page');

    const { body } = await fetch(`/events/${eventId}/pages/${pageId}`, { token: accessToken });

    return transform.one(body);
  });
};

export const usePages = (customEventId?: string) => {
  const { eventId, language } = useInit();
  const { accessToken } = useAuth();

  return useQuery(
    ['pages', customEventId, eventId, language],
    async () => {
      const { body } = await fetch(
        languageUrl(`/events/${customEventId ?? eventId}/pages`, language),
        {
          token: accessToken,
        },
      );

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useCreatePage = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Page) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/pages`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pages');
      },
    },
  );
};

export const useDuplicatePage = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/pages/${id}/duplicate`, language), {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pages');
      },
    },
  );
};

export const useUpdatePage = (eventId?: string, pageId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Page) => {
      if (!eventId) throw new Error('No event');
      if (!pageId) throw new Error('No page');

      return fetch(languageUrl(`/events/${eventId}/pages/${pageId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pages');
      },
    },
  );
};

export const useUpdatePagePositions = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (body: Array<[string, number]>) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/pages/positions`, {
        method: 'POST',
        token: accessToken,
        body,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pages');
      },
    },
  );
};

export const useDeletePage = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (pageId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/pages/${pageId}`, { method: 'DELETE', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pages');
      },
    },
  );
};

export const usePushToPage = (eventId?: string) => {
  const { accessToken } = useAuth();

  const pushToPage = (pageId?: string, userRoles?: string[]) => {
    if (!eventId) throw new Error('No event');
    if (!pageId) throw new Error('No page');

    return fetch(`/events/${eventId}/pages/${pageId}/push`, {
      method: 'POST',
      token: accessToken,
      body: { userRoles },
    });
  };

  return { pushToPage };
};

export const usePushSlido = (eventId?: string) => {
  const { accessToken } = useAuth();

  const pushSlido = (pageId?: string) => {
    if (!eventId) throw new Error('No event');
    if (!pageId) throw new Error('No page');

    return fetch(`/events/${eventId}/pages/${pageId}/openslido`, {
      method: 'POST',
      token: accessToken,
    });
  };

  return { pushSlido };
};

export const useUploadCanvasImage = (eventId?: string) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    ({ pageId, imageIndex, image }: { pageId: string; imageIndex: number; image: File | Blob }) => {
      if (!eventId) throw new Error('No event');
      if (!pageId) throw new Error('No page');
      if (typeof imageIndex !== 'number') throw new Error('No image index');

      const body = new FormData();
      body.append('image', image);
      return fetch(`/events/${eventId}/pages/${pageId}/image/${imageIndex}`, {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pages');
      },
    },
  );
};

export default {
  usePage,
  usePages,
  useCreatePage,
  useDuplicatePage,
  useUpdatePage,
  useUpdatePagePositions,
  useDeletePage,
};
