import parse, { Attributes } from '@utils/parse';

export const parseUserSession = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    reminder: attributes.optional.boolean('reminder'),
  };
};

export function one(data: any) {
  return parse.one(data, parseUserSession);
}

export function many(data: any) {
  return parse.many(data, parseUserSession);
}

export type UserSessionResource = ReturnType<typeof one>;
export type UserSession = Omit<UserSessionResource, 'id'>;

export default { one, many };
