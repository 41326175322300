import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card, Form, Input, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import useAuth from '@hooks/auth/useAuth';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [disableButton, setDisableButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  useEffect(() => {
    if (auth.isAuthorized) navigate('/');
  }, [auth.isAuthorized, navigate]);

  useEffect(() => {
    if (auth.error) message.error('Please double check your login credentials and try again.');
  }, [auth.error]);

  const onFinish = (values: any) => {
    auth.forgotPassword(values.email).then(() => {
      if (!auth.error) {
        setSuccessMessage('An e-mail has been send to reset your password');
        message.success('An e-mail has been send to reset your password');
      }
    });
  };

  return (
    <Row justify='center' align='middle' style={{ marginTop: '2em' }}>
      <Col span={6}>
        <Card title='Reset password' headStyle={{ textAlign: 'center' }}>
          <Form
            initialValues={{ email: '' }}
            onFinish={(values) => {
              setDisableButton(true);
              onFinish(values);
            }}
          >
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
              hasFeedback
            >
              <Input prefix={<MailOutlined />} type='email' placeholder='Email Address' />
            </Form.Item>

            {successMessage && <p>{successMessage}</p>}

            <Form.Item>
              <Button type='primary' htmlType='submit' block disabled={disableButton}>
                Request password reset
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
