import { useCallback, useEffect, useState } from 'react';
import { Avatar, Checkbox, message, Modal, Select, Table } from 'antd';

import type { UserRoleResource } from '@transforms/userRole';

export enum BreakoutType {
  ROOM = 'ROOM',
  VIDEO_CALL = 'VIDEO_CALL',
}

export interface BreakoutRole {
  id: string;
  type: BreakoutType;
  title?: string;
  imageUrl: string | null;
  roleIds: string[];
}

export interface BreakoutModalProps {
  visible: boolean;
  onOk: (data: BreakoutRole[]) => void;
  onCancel: () => void;
  roles: UserRoleResource[];
  roleId: string;
  breakoutRoles?: BreakoutRole[];
  defaultRole?: UserRoleResource;
}

export function BreakoutModal({
  visible: modalVisible,
  onOk,
  onCancel,
  roles,
  roleId,
  breakoutRoles,
  defaultRole,
}: BreakoutModalProps) {
  const [updatedBreakoutRoles, setUpdatedBreakoutRoles] = useState(breakoutRoles);

  const role = roles.find(({ id }) => id === roleId);

  useEffect(() => {
    setUpdatedBreakoutRoles((prev) => [
      ...(prev ?? []),
      ...(breakoutRoles?.filter(({ id }) => !prev?.some((breakout) => breakout.id === id)) ?? []),
    ]);
  }, [breakoutRoles, roleId]);

  const updateBreakoutRole = useCallback(
    (breakout: BreakoutRole) => {
      setUpdatedBreakoutRoles((prev) => {
        const prevBreakoutRoles = prev?.find(({ id }) => breakout.id === id)?.roleIds ?? [];
        const removeOrAdd = prevBreakoutRoles.includes(roleId);
        if (removeOrAdd && prevBreakoutRoles.length - 1 === 0) {
          message.error(
            `A breakout needs at least 1 role, please add another role to this breakout first. If you delete this role, the breakout will be re-assigned to the default role${
              defaultRole ? ` "${defaultRole.name}"` : ''
            }.`,
            10,
          );
          return prev;
        }

        // By default clear array to allow all roles
        const roleIds = [];
        if (removeOrAdd) {
          // Remove role from breakout
          roleIds.push(...prevBreakoutRoles.filter((id) => id !== roleId));
        } else if (prevBreakoutRoles.length === 0) {
          // Add all roles except this one
          roleIds.push(...roles.map(({ id }) => id).filter((id) => id !== roleId));
        } else if (prevBreakoutRoles.length + 1 !== roles.length) {
          // Add role to breakout
          roleIds.push(...prevBreakoutRoles, roleId);
        }

        return [
          ...prev!.filter(({ id }) => breakout.id !== id),
          {
            id: breakout.id,
            type: breakout.type,
            title: breakout.title,
            imageUrl: breakout.imageUrl,
            roleIds,
          },
        ];
      });
    },
    [defaultRole, roleId, roles],
  );

  return (
    <Modal
      width={750}
      visible={modalVisible}
      title='Breakouts with Role'
      okText='Ok'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => onOk(updatedBreakoutRoles ?? [])}
    >
      <Table
        loading={!updatedBreakoutRoles}
        dataSource={updatedBreakoutRoles?.sort((a, b) => a.id.localeCompare(b.id))}
        pagination={{
          pageSize: 10,
          total: updatedBreakoutRoles?.length ?? 0,
        }}
        columns={[
          {
            title: 'Image',
            key: 'image',
            dataIndex: 'imageUrl',
            width: 40,
            render: (image) => <Avatar size={40} src={image} />,
          },
          {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            render: (type) => {
              switch (type) {
                case BreakoutType.ROOM:
                  return 'Room';
                case BreakoutType.VIDEO_CALL:
                  return 'Video Call';
                default:
                  return 'Breakout';
              }
            },
          },
          {
            title: `Has ${role?.name ? `"${role.name}" ` : ''}Role`,
            key: 'hasRole',
            dataIndex: 'roleId',
            render: (_, breakout) => {
              const updatedBreakoutRole = updatedBreakoutRoles?.find(
                ({ id }) => breakout.id === id,
              );
              return (
                <Checkbox
                  checked={
                    updatedBreakoutRole?.roleIds.includes(roleId) ||
                    updatedBreakoutRole?.roleIds.length === 0
                  }
                  onClick={() => updateBreakoutRole(breakout)}
                >
                  {roles.map((data) => (
                    <Select.Option value={data.id} key={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Checkbox>
              );
            },
          },
        ]}
      />
    </Modal>
  );
}
