import { useState } from 'react';
import { Modal, Input } from 'antd';

export interface DeleteAllModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export function DeleteAllModal({ visible, onOk, onCancel }: DeleteAllModalProps) {
  const [value, setValue] = useState('');

  const handleCancel = () => {
    setValue('');
    onCancel();
  };

  const handleOk = () => {
    setValue('');
    onOk();
  };

  return (
    <Modal
      visible={visible}
      title='Confirm Deletion!'
      okText='Delete'
      cancelText='Cancel'
      onCancel={handleCancel}
      onOk={value === 'DELETE' ? handleOk : undefined}
      okType='danger'
      okButtonProps={{ disabled: value !== 'DELETE' }}
    >
      <p>
        Are you sure you want to <strong>permanently</strong> delete all attendees and any
        associated data? Note, this cannot be undone! <br />
        <br />
        Type &quot;DELETE&quot; below to confirm this action.
      </p>
      <Input required value={value} onChange={(e) => setValue(e.target.value)} />
    </Modal>
  );
}

export default DeleteAllModal;
