import { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Modal, Select, Table } from 'antd';

import { useUsers } from '@hooks/useUsers';
import { useDebounce } from '@hooks/useDebounce';
import { LazyModal } from '@components/LazyModal';
import type { UserResource } from '@transforms/user';
import type { UserRoleResource } from '@transforms/userRole';

import { DefaultRoleWarningModal } from './DefaultRoleWarningModal';

export interface AttendeeRole {
  id: string;
  roleId: string | null;
  avatarUrl: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UserModalProps {
  visible: boolean;
  onOk: (data: AttendeeRole[]) => void;
  onCancel: () => void;
  roles: UserRoleResource[];
  roleId: string;
  allRoles?: AttendeeRole[];
  attendeeRoles?: AttendeeRole[];
}

export function UserModal({
  visible: modalVisible,
  onOk,
  onCancel,
  roles,
  roleId,
  allRoles,
  attendeeRoles,
}: UserModalProps) {
  const [page, setPage] = useState(1);
  const [focus, setFocus] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [updatedAttendeeRoles, setUpdatedAttendeeRoles] = useState(attendeeRoles);
  const [defaultRoleWarningUser, setDefaultRoleWarningUser] = useState<UserResource | null>(null);
  const { data: users } = useUsers({ page, limit: 20 }, searchTerm);
  const [allUsers, setAllUsers] = useState(users?.items ?? []);

  useEffect(() => {
    setAllUsers((prev) =>
      !focus && !searchTerm
        ? users?.items ?? []
        : [
            ...prev.filter(({ id }) => !users?.items.some((user) => user.id === id)),
            ...(users?.items ?? []),
          ],
    );
  }, [focus, searchTerm, users?.items]);

  useEffect(() => {
    setUpdatedAttendeeRoles((prev) => [
      ...(prev ?? []),
      ...(attendeeRoles
        ?.filter(({ id }) => !prev?.some((attendee) => attendee.id === id))
        .map((attendee) => ({
          id: attendee.id,
          roleId: attendee.roleId ?? null,
          avatarUrl: attendee.avatarUrl ?? null,
          firstName: attendee.firstName ?? null,
          lastName: attendee.lastName ?? null,
        })) ?? []),
    ]);
  }, [attendeeRoles, roleId]);

  const updateUserRoles = useCallback((user: AttendeeRole, newRoleId: string) => {
    setUpdatedAttendeeRoles((prev) => [
      ...prev!.filter((attendee) => attendee.id !== user.id),
      {
        id: user.id,
        roleId: newRoleId,
        avatarUrl: user.avatarUrl ?? null,
        firstName: user.firstName ?? null,
        lastName: user.lastName ?? null,
      },
    ]);
  }, []);

  // TODO: use `useDebounce` hook globally in long term
  const onSearch = useDebounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  const onScroll = useCallback(
    (event: any) => {
      if (event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
        if (users?.meta.currentPage !== users?.meta.totalPages) {
          setPage((prev) => prev + 1);
        }
      }
    },
    [users?.meta.currentPage, users?.meta.totalPages],
  );

  const defaultRole = useMemo(() => roles?.find((role) => role.default)?.id, [roles]);

  const assignUser = useCallback(
    (user: UserResource) => {
      setDefaultRoleWarningUser(null);
      setUpdatedAttendeeRoles((prev) => [
        ...(prev?.filter(({ id }) => id !== user.id) ?? []),
        {
          id: user.id,
          roleId,
          avatarUrl: user.avatarUrl ?? null,
          firstName: user.firstName ?? null,
          lastName: user.lastName ?? null,
        },
      ]);
    },
    [roleId],
  );

  const onSelect = useCallback(
    (userId: string) => {
      const user = users?.items.find(({ id }) => id === userId);
      if (!user) throw new Error('User not found');
      const prevRole = allRoles?.find(({ id }) => id === userId)?.roleId;
      if (!prevRole) throw new Error('User not found');
      if (!defaultRole) throw new Error('No default role');
      if (prevRole !== defaultRole) {
        setDefaultRoleWarningUser(user);
      } else {
        assignUser(user);
      }
    },
    [users?.items, allRoles, defaultRole, assignUser],
  );

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setSearchTerm('');
    setPage(1);
    setFocus(false);
  }, []);

  return (
    <Modal
      width={750}
      visible={modalVisible}
      title={`Attendees with Role "${roles.find((role) => role.id === roleId)?.name ?? ''}"`}
      okText='Ok'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => onOk(updatedAttendeeRoles ?? [])}
    >
      <b>Add Attendee</b>
      <Select
        showSearch
        onSearch={onSearch}
        onSelect={onSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        onPopupScroll={onScroll}
        filterOption={false}
        className='hidden-value'
        style={{ width: '100%', margin: '5px 0 20px' }}
      >
        {allUsers
          .filter((user) => !updatedAttendeeRoles?.some(({ id }) => user.id === id))
          .filter(
            (user) =>
              // this is how the backend filters, so we need to do the same
              // first name + last name searching together is not supported
              user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              user.email?.toLowerCase().includes(searchTerm.toLowerCase()),
          )
          .map((user) => (
            <Select.Option key={user.id} value={user.id}>
              <Avatar size={40} src={user.avatarUrl} style={{ marginRight: '10px' }} />
              {[user.firstName, user.lastName].filter(Boolean).join(' ')}
            </Select.Option>
          ))}
      </Select>
      <b>Current Attendees</b>
      <Table
        loading={!updatedAttendeeRoles}
        dataSource={updatedAttendeeRoles?.sort((a, b) => a.id.localeCompare(b.id))}
        style={{ marginTop: '5px' }}
        pagination={{
          pageSize: 10,
          total: updatedAttendeeRoles?.length ?? 0,
        }}
        columns={[
          {
            title: 'Profile Picture',
            key: 'avatar',
            dataIndex: 'avatarUrl',
            width: 40,
            render: (image) => <Avatar size={40} src={image} />,
          },
          {
            title: 'Name',
            key: 'name',
            dataIndex: 'fullName',
            render: (_, user) =>
              [user.firstName, user.lastName].filter(Boolean).join(' ') || 'Unknown User',
          },
          {
            title: 'Role',
            key: 'role',
            dataIndex: 'role',
            width: 300,
            render: (_, user) => (
              <Select
                style={{ width: '100%' }}
                filterOption={false}
                value={
                  updatedAttendeeRoles?.find((attendee) => attendee.id === user.id)?.roleId ||
                  'Unknown Role'
                }
                onChange={(newRoleId) => updateUserRoles(user, newRoleId)}
              >
                {roles.map((data) => (
                  <Select.Option value={data.id} key={data.id}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
        ]}
      />
      <LazyModal open={!!defaultRoleWarningUser}>
        {(open) => (
          <DefaultRoleWarningModal
            visible={open}
            user={defaultRoleWarningUser!}
            onOk={() => assignUser(defaultRoleWarningUser!)}
            onCancel={() => setDefaultRoleWarningUser(null)}
          />
        )}
      </LazyModal>
    </Modal>
  );
}
