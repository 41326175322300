import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message, Table, Avatar, Tooltip, Divider, Popconfirm, Tag } from 'antd';
import debounce from 'lodash/debounce';
import dayjs, { Dayjs } from 'dayjs';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';

import { PageTemplate } from '@transforms/page';
import { SpeakerResource } from '@transforms/speaker';
import type { UserRoleResource } from '@transforms/userRole';
import { Session, SessionResource } from '@transforms/session';

import { EventLayout } from '@components/Layout';
import { Session as SessionModal } from '@components/Modals';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import {
  useSessions,
  useCreateSession,
  useUpdateSession,
  useDeleteSession,
  useUploadSessionImage,
  useDuplicateSession,
} from '@hooks/useSessions';
import { usePages } from '@hooks/usePages';
import { useStages } from '@hooks/useStages';
import { useSpeakers } from '@hooks/useSpeakers';
import { useCurrentEvent } from '@hooks/useEvents';

export default function Sessions() {
  const navigate = useNavigate();
  const { data: event } = useCurrentEvent();
  const { data: rawPages } = usePages();
  const listRef = useRef<HTMLDivElement>(null);

  if (
    event &&
    event.organisation &&
    !event.organisation.enabledPageTemplates?.includes(PageTemplate.SCHEDULE)
  ) {
    navigate('/');
  }

  const [sorter, setSorter] = useState<{
    field?: keyof SessionResource;
    order?: 'ascend' | 'descend';
  }>({});

  const [openSessionModal, setOpenSessionModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState<SessionResource | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { isLoading, data: sessions, refetch } = useSessions({ ...pagination, sorter }, searchTerm);
  const { mutate: createSession } = useCreateSession();
  const { mutate: updateSession } = useUpdateSession(selectedSession?.id);
  const { mutate: deleteSession } = useDeleteSession();
  const { mutateAsync: duplicateSession } = useDuplicateSession(event?.id);
  const { mutateAsync: uploadSessionImage } = useUploadSessionImage();
  const { data: speakers } = useSpeakers({ page: 1, limit: 999 });
  const { data: stages } = useStages({ page: 1, limit: 999 });

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Sessions';
      }
    }
  }, [listRef.current?.innerHTML]);

  const onOpenSessionModal = (session?: SessionResource) => {
    if (session) {
      setSelectedSession(session);
    } else {
      setSelectedSession(undefined);
    }
    setOpenSessionModal(true);
  };

  const onCloseSessionModal = () => {
    setOpenSessionModal(false);
    setSelectedSession(undefined);
  };

  const onCreateSession = (
    values: Session & {
      dates: Dayjs[];
      image?: File | Blob;
      page?: string;
      externalUrl?: string;
    },
    done: (err?: Error) => void,
  ) => {
    const { dates, page, externalUrl, ...restValues } = values;
    createSession(
      {
        ...restValues,
        url: page || externalUrl,
        startsAt: dates[0].toISOString(),
        endsAt: dates[1].toISOString(),
      },
      {
        onSuccess: async (val) => {
          if (values.image) {
            try {
              await uploadSessionImage({ sessionId: val?.body?.id, image: values.image });
            } catch (error) {
              done(error as Error);
              return;
            }
          }

          onCloseSessionModal();
          done();
        },
      },
    );
  };

  const onUpdateSession = (
    values: Session & { dates: Dayjs[]; image?: File | Blob; page?: string; externalUrl?: string },
    done: (err?: Error) => void,
    shouldClose = true,
  ) => {
    const { dates, page, externalUrl, onDemandUrl, ...restValues } = values;
    updateSession(
      {
        ...restValues,
        url: page || externalUrl,
        onDemandUrl: onDemandUrl ?? null,
        startsAt: dates[0].toISOString(),
        endsAt: dates[1].toISOString(),
      },
      {
        onSuccess: async () => {
          if (selectedSession?.id && values.image) {
            try {
              await uploadSessionImage({ sessionId: selectedSession?.id, image: values.image });
            } catch (error) {
              done(error as Error);
              return;
            }
          }

          refetch();
          if (shouldClose) onCloseSessionModal();
          done();
        },
      },
    );
  };

  const onDeleteSession = (id: string) => {
    deleteSession(id);
  };

  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, 500);

  const onDuplicateSession = async (session?: SessionResource) => {
    if (!session?.id) return;
    await duplicateSession(session.id);
    message.success('Session duplicated, make sure to check content translations');
  };

  const renderSpeakers = (speakersToRender: SpeakerResource[]) => {
    if (!speakersToRender?.length) return '';
    return speakersToRender.length === 1 ? (
      speakersToRender[0].name
    ) : (
      <Tooltip
        title={speakersToRender.map((speaker) => speaker.name).join(', ')}
        key='roles'
      >{`${speakersToRender.length} speakers`}</Tooltip>
    );
  };

  const renderPage = (url?: string) => {
    if (!url?.length) return '';
    const pageWithId = rawPages?.find((page) => page.id === url)?.title ?? '';
    if (pageWithId?.length) return pageWithId;
    if (url.startsWith('?page='))
      return rawPages?.find((page) => page.id === url.split('=')[1])?.title ?? '';
    return (
      <a href={url} target='_blank' rel='noreferrer'>
        {url}
      </a>
    );
  };

  const renderRoles = (roles?: UserRoleResource[]) => {
    if (!roles?.length) return '';
    if (roles.length === 1) return <Tag>{roles[0]?.name ?? 'Role'}</Tag>;
    return (
      <Tooltip title={roles.map((role) => role.name ?? 'Role').join(', ')} key='roles'>
        <Tag>{`${roles.length} roles`}</Tag>
      </Tooltip>
    );
  };

  return (
    <EventLayout
      title='Sessions'
      extra={[
        <Input.Search
          placeholder='Search'
          allowClear
          onChange={(e) => onSearch(e.target.value)}
          style={{ width: 200 }}
          key='search'
        />,
        <Button key='menu' type='primary' onClick={() => onOpenSessionModal()}>
          Add Session
        </Button>,
        <EventLanguageSelect
          key='language-select'
          style={{ marginRight: 10 }}
          onUpdate={refetch}
        />,
      ]}
    >
      Here you can add <b>Sessions</b> and assign{' '}
      <Link to={`/events/${event?.id}/speakers`}> Speakers</Link> and{' '}
      <Link to={`/events/${event?.id}/stages`}> Stages</Link>.
      <br />
      <br />
      <div ref={listRef}>
        <Table
          loading={isLoading}
          dataSource={sessions?.items.map((speaker) => ({ ...speaker, key: speaker.id }))}
          onChange={(_, __, sort: any) => setSorter(sort)}
          pagination={{
            pageSize: sessions?.meta.itemsPerPage || 10,
            total: sessions?.meta.totalItems,
            onChange: (page, pageSize) => {
              setPagination({ page, limit: pageSize || 10 });
            },
          }}
          columns={[
            {
              title: 'Image',
              key: 'image',
              dataIndex: 'imageUrl',
              width: 40,
              render: (image) => <Avatar size={40} src={image} />,
            },
            {
              title: 'Title',
              key: 'title',
              sorter: true,
              dataIndex: 'title',
            },
            {
              title: 'Date & Time',
              key: 'date',
              sorter: true,
              dataIndex: 'startsAt',
              render: (_, session) =>
                `${dayjs(session.startsAt).format('ddd D MMM, HH:mm')} - ${dayjs(
                  session.endsAt,
                ).format('HH:mm')}`,
            },
            {
              title: 'Reminder Time',
              key: 'reminderTime',
              dataIndex: 'reminderTime',
              width: 150,
              sorter: true,
              render: (reminderTime, session) =>
                reminderTime
                  ? dayjs(session.startsAt?.toLocaleString())
                      .subtract(reminderTime, 'minutes')
                      .tz('CET', true)
                      .format('HH:mm')
                  : '',
            },
            {
              title: 'Stage',
              key: 'stage',
              dataIndex: 'stage',
              sorter: true,
              render: (stage) => stage?.name ?? '',
            },

            {
              title: 'Speaker(s)',
              key: 'speakers',
              dataIndex: 'speakers',
              render: renderSpeakers,
            },
            {
              title: 'Roles (Permitted)',
              key: 'roles',
              dataIndex: 'roles',
              render: renderRoles,
            },
            {
              title: 'Mandatory',
              key: 'mandatory',
              dataIndex: 'mandatory',
              width: 125,
              sorter: true,
              render: (enabled) => (enabled ? 'Yes' : 'No'),
            },
            {
              title: 'Highlighted',
              key: 'highlighted',
              dataIndex: 'highlighted',
              width: 130,
              sorter: true,
              render: (enabled) => (enabled ? 'Yes' : 'No'),
            },
            {
              title: 'Page',
              key: 'page',
              dataIndex: 'url',
              render: renderPage,
            },
            {
              title: 'On Demand Page',
              key: 'onDemandPage',
              dataIndex: 'onDemandUrl',
              render: renderPage,
            },

            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              align: 'right',
              width: 170,
              render: (_, speaker) => [
                <Tooltip title='Duplicate' key='duplicate'>
                  <Button
                    shape='circle'
                    icon={<CopyOutlined />}
                    onClick={() => onDuplicateSession(speaker)}
                  />
                </Tooltip>,
                <Divider key='d1' type='vertical' />,
                <Tooltip title='Edit' key='edit'>
                  <Button
                    shape='circle'
                    icon={<EditOutlined />}
                    onClick={() => onOpenSessionModal(speaker)}
                  />
                </Tooltip>,
                <Divider key='d2' type='vertical' />,
                <Popconfirm
                  title={
                    <div className='message-box-content-container'>
                      <div className='message-box-content'>
                        <h3>Confirm Deletion!</h3>
                        <span>
                          Are you sure you want to delete this session and any associated data?
                          Note, this cannot be undone!
                        </span>
                      </div>
                    </div>
                  }
                  onConfirm={() => onDeleteSession(speaker.id)}
                  okText='Delete'
                  cancelText='Cancel'
                  key='delete'
                  okButtonProps={{
                    danger: true,
                  }}
                >
                  <Button shape='circle' icon={<DeleteOutlined />} danger />
                </Popconfirm>,
              ],
            },
            {
              title: 'Location',
              key: 'location',
              sorter: true,
              dataIndex: 'location',
              render: (location) =>
                `${location.charAt(0).toUpperCase()}${location.slice(1).toLowerCase()}`,
            },
          ]}
        />
      </div>
      {/* FIXME: unsaved changes on no changes */}
      {openSessionModal && (
        <SessionModal
          visible={openSessionModal}
          data={selectedSession}
          speakers={speakers?.items}
          stages={stages?.items}
          onCancel={onCloseSessionModal}
          onCreate={onCreateSession}
          onUpdate={onUpdateSession}
        />
      )}
    </EventLayout>
  );
}
