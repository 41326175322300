import parse, { Attributes } from '@utils/parse';

import { parseUserRole } from './userRole';

export enum PageTemplate {
  ATTENDEES = 'ATTENDEES',
  IFRAME = 'IFRAME',
  LIVE = 'LIVE',
  VIDEO_ON_DEMAND = 'VIDEO_ON_DEMAND',
  VIRTUAL_VENUE = 'VIRTUAL_VENUE',
  BREAKOUT = 'BREAKOUT',
  BASIC = 'BASIC',
  SCHEDULE = 'SCHEDULE',
  CANVAS = 'CANVAS',
}

export const parsePage = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    slug: attributes.string('slug'),
    title: attributes.string('title'),
    template: attributes.enum<typeof PageTemplate>('template', PageTemplate),
    parameters: attributes.object('parameters'),
    roles: attributes.custom('roles', (roles: any) => {
      return parse.many(roles, parseUserRole);
    }),
    enabled: attributes.boolean('enabled'),
    visibleInMenu: attributes.boolean('visibleInMenu'),
    visibleFrom: attributes.optional.date('visibleFrom'),
    visibleTill: attributes.optional.date('visibleTill'),
    menuPosition: attributes.optional.number('menuPosition') || null,
    groupChatEnabled: attributes.boolean('groupChatEnabled'),
    clapHandsEnabled: attributes.optional.boolean('clapHandsEnabled'),
  };
};

export function one(data: any) {
  return parse.one(data, parsePage);
}

export function many(data: any) {
  return parse.many(data, parsePage);
}

export type PageResource = ReturnType<typeof one>;
export type Page = Omit<PageResource, 'id'>;

export default { one, many };
