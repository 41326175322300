import dayjs from 'dayjs';
import React, { useCallback, useState, useMemo } from 'react';
import { Button, Divider, Form, Input, Select, Switch } from 'antd';

import { VideoCallResource } from '@transforms/videoCall';
import TimePicker from '@components/DatePicker';
import { useCurrentEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { useRoles } from '@hooks/useRoles';
import { ImageUpload } from '@components/Upload';

import { DataEntry, DataEntryProps } from './DataEntry';
import { PreviewBreakout } from './PreviewBreakout';

export interface VideoCallProps extends DataEntryProps {
  data?: VideoCallResource;
}

export function VideoCall({ data, onCreate, onUpdate, ...props }: VideoCallProps) {
  const [form] = Form.useForm();
  const parsedData = useMemo(
    () =>
      !data
        ? undefined
        : {
            ...data,
            roles: data?.roles?.map((role) => role.id),
            dates:
              data?.startDate && data?.endDate
                ? [dayjs(data?.startDate), dayjs(data?.endDate)]
                : null,
          },
    [data],
  );

  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { data: roles } = useRoles(selectedLanguage, event?.id);
  const [imagePreview, setImagePreview] = useState<string | null>(data?.imageUrl ?? null);
  const [showPreviewBreakoutModal, setShowPreviewBreakoutModal] = useState<boolean>(false);

  const onCreateCallback = useCallback(
    (values: any, done: () => void) =>
      onCreate({ ...values, imageUrl: imagePreview ? undefined : null }, done),
    [onCreate, imagePreview],
  );

  const onUpdateCallback = useCallback(
    (values: any, done: () => void) =>
      onUpdate({ ...values, imageUrl: imagePreview ? undefined : null }, done),
    [onUpdate, imagePreview],
  );

  return (
    <DataEntry
      {...props}
      data={parsedData}
      name='Breakout Room (Video Call)'
      form={form}
      onCreate={onCreateCallback}
      onUpdate={onUpdateCallback}
    >
      Use the integrated video conference tool.
      <br />
      <br />
      <Form.Item name='position' label={<b>Position</b>}>
        <Input type='number' />
      </Form.Item>
      <Form.Item name='roles' label={<b>User Role Access</b>}>
        <Select
          filterOption={(input, option) =>
            option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={roles?.map((role) => ({
            value: role.id,
            label: role.name,
          }))}
          mode='multiple'
          placeholder='Limit access to these specific user roles.'
        />
      </Form.Item>
      <Form.Item name='maxUsers' label={<strong>Maximum participants</strong>}>
        <Input type='number' />
      </Form.Item>
      <Form.Item
        name='title'
        label={<strong>Breakout Room Title</strong>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Title' />
      </Form.Item>
      <Form.Item
        name='description'
        label={<strong>Breakout Room Subtitle</strong>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Subtitle' />
      </Form.Item>
      <Form.Item name='image' label={<b>Image</b>}>
        <ImageUpload
          initialPreview={imagePreview}
          onChangePreview={setImagePreview}
          onRemove={() => form.setFields([{ name: 'image', value: null }])}
          textOverwrite={[
            'The maximum file size is 5 MB and must be at least 600x380 pixels in either JPG or PNG formats.',
            'Tip: There is a gradient filter overlay on this thumbnail to make the text legible.',
          ]}
          extraButtons={
            <>
              <Button disabled={!imagePreview} onClick={() => setShowPreviewBreakoutModal(true)}>
                Preview
              </Button>
              <PreviewBreakout
                visible={showPreviewBreakoutModal}
                formData={form.getFieldsValue()}
                imagePreview={imagePreview}
                onCancel={() => setShowPreviewBreakoutModal(false)}
              />
            </>
          }
        />
      </Form.Item>
      <Divider key='d1' />
      <h2>Video Call Settings</h2>
      <Form.Item
        name='dates'
        className='required-label-title-only'
        label={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>Video Call Time (in CET)</b>

            <p>
              Schedule an internal video call at a specific time and date. Note, the call will
              automatically be made inactive outside this timeframe.
            </p>
          </div>
        }
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/* @ts-ignore */}
        <TimePicker.RangePicker showTime placeholder={['Start Date', 'End Date']} />
      </Form.Item>
      <Form.Item
        name='video'
        label={<b>Turn on camera of participants by default</b>}
        valuePropName='checked'
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name='audio'
        label={<b>Turn on microphone of participants by default</b>}
        valuePropName='checked'
      >
        <Switch defaultChecked />
      </Form.Item>
      <Form.Item
        name='screenShare'
        label={<b>Allow participants to share their screen</b>}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Form.Item name='chat' label={<b>Allow participants to chat</b>} valuePropName='checked'>
        <Switch />
      </Form.Item>
    </DataEntry>
  );
}
