import { List, Button, Tooltip, Popconfirm, Space, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { isUndefined } from 'lodash';

import { CustomFieldResource } from '@transforms/customField';
import { MandatoryField } from '@transforms/mandatoryField';

export function ListItem({
  customField,
  onEdit,
  onDelete,
  mandatory,
}: {
  customField: CustomFieldResource | MandatoryField;
  onEdit?: (user: CustomFieldResource | MandatoryField) => void;
  onDelete?: (id: string) => void;
  mandatory?: boolean;
}) {
  return (
    <List.Item
      key={customField.id}
      style={{ width: '100%' }}
      actions={[
        <>
          {onEdit && (
            <Tooltip title='Edit' key='edit'>
              <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(customField)} />
            </Tooltip>
          )}
        </>,
        <>
          {onDelete && isUndefined(mandatory) && (
            <Popconfirm
              title='Are you sure you want to delete this custom field from this event?'
              onConfirm={() => onDelete(customField.id)}
              okText='Delete'
              cancelText='Cancel'
              key='delete'
            >
              <Button shape='circle' icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </>,
      ]}
    >
      <List.Item.Meta
        title={<div>{(customField as CustomFieldResource).label}</div>}
        description={
          <Space>
            {customField.key}
            {(customField as CustomFieldResource).required && (
              <div>
                <Tag color='green'>Required</Tag>
              </div>
            )}
            {(customField as CustomFieldResource).private && (
              <div>
                <Tag color='blue'>Private</Tag>
              </div>
            )}
          </Space>
        }
      />
    </List.Item>
  );
}
