import { useNavigate } from 'react-router-dom';

import useProtected from '@hooks/useProtected';
import useAuth from '@hooks/auth/useAuth';
import { useCurrentEvent } from '@hooks/useEvents';

export default function Event() {
  useProtected();
  const navigate = useNavigate();
  const { isInitializing, isAuthorized } = useAuth();
  const { data: event } = useCurrentEvent();

  if (!isInitializing && isAuthorized && event) navigate(`/events/${event?.id}/info`);

  return null;
}
