import React, { useState, useEffect } from 'react';
import { Button, message, Select } from 'antd';

import { EventLayout } from '@components/Layout';
import { CustomField as CustomFieldModal } from '@components/Modals';

import { useCurrentEvent } from '@hooks/useEvents';
import {
  useCustomFields,
  useCreateCustomField,
  useUpdateCustomField,
} from '@hooks/useCustomFields';
import { useLanguage } from '@hooks/useTranslations';

import { CustomFieldOption } from '@transforms/customFieldOption';
import { CustomField, CustomFieldResource } from '@transforms/customField';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';
import { countryList } from '@utils/countries';

import DragDropCustomFields from './custom-fields/dragDropCustomfields';
import DragDropMandatoryFields from './custom-fields/dragDropMandatoryfields';

export default function CustomFields() {
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);

  const countries = countryList();

  const [customFieldOptions, setCustomFieldOptions] = useState<CustomFieldOption[]>();
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomFieldResource | undefined>();

  const { data: customFields } = useCustomFields(event?.id, selectedLanguage);
  const { mutate: createCustomField } = useCreateCustomField(event?.id, selectedLanguage);
  const { mutate: updateCustomField } = useUpdateCustomField(
    event?.id,
    selectedCustomField?.id,
    selectedLanguage,
  );

  const [draggableItems, setDraggableItems] = useState(customFields);

  const onOpenCustomFieldModal = (customField?: CustomFieldResource) => {
    if (customField) {
      setSelectedCustomField(customField);
    } else {
      setSelectedCustomField(undefined);
    }
    setOpenCustomFieldModal(true);
  };

  const onCloseCustomFieldModal = () => {
    setOpenCustomFieldModal(false);
    setSelectedCustomField(undefined);
  };

  const onCreateCustomField = (values: CustomField, done: (err?: Error) => void) => {
    const options =
      customFieldOptions?.map((option) => ({
        key: option.key,
        label: { [selectedLanguage]: option.label as string },
      })) ?? [];

    const position = { position: values.position || null };
    createCustomField(
      { ...values, ...position, options, general: false },
      {
        onSuccess: async () => {
          onCloseCustomFieldModal();
          done();
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  const onUpdateCustomField = (values: CustomField, done: (err?: Error) => void) => {
    const options =
      customFieldOptions?.map((option) => ({
        key: option.key,
        label: { [selectedLanguage]: option.label as string },
      })) ?? [];

    const position = { position: values.position || null };
    updateCustomField(
      { ...values, ...position, options },
      {
        onSuccess: async () => {
          onCloseCustomFieldModal();
          done();
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  useEffect(() => {
    setDraggableItems(customFields);
  }, [customFields]);

  const handleAddPredefinedCustomField = (value: string) => {
    if (value === 'country') {
      const position = draggableItems?.length || 0;

      const countryCustomFieldValues: Omit<CustomField, 'options'> & {
        options: any;
      } = {
        key: 'country-select',
        label: 'Select a country',
        type: 'DROPDOWN',
        placeholder: 'Netherlands',
        options: countries,
        position,
        required: false,
        private: false,
        general: true,
      };

      createCustomField(
        { ...countryCustomFieldValues, position, options: countryCustomFieldValues.options },
        {
          onSuccess: async () => {
            message.success('Custom field added');
          },
          onError: () => {
            message.error('Unable to create custom field');
          },
        },
      );
    }
    if (value === 'company') {
      const position = draggableItems?.length || 0;
      const companyCustomFieldValues: Omit<CustomField, 'options'> & {
        options: any;
      } = {
        key: 'company',
        label: 'What is your company name?',
        placeholder: 'Company name',
        type: 'INPUT',
        options: undefined,
        position,
        required: false,
        private: false,
        general: true,
      };

      createCustomField(
        { ...companyCustomFieldValues, position, options: companyCustomFieldValues.options },
        {
          onSuccess: async () => {
            message.success('Custom field added');
          },
          onError: () => {
            message.error('Unable to create custom field');
          },
        },
      );
    }
    if (value === 'phone') {
      const position = draggableItems?.length || 0;
      const phoneCustomFieldValues: Omit<CustomField, 'options'> & {
        options: any;
      } = {
        key: 'phone',
        label: 'What is your phone number?',
        placeholder: '+31 6 12345678',
        type: 'INPUT',
        options: undefined,
        position,
        required: false,
        private: false,
        general: true,
      };

      createCustomField(
        { ...phoneCustomFieldValues, position, options: phoneCustomFieldValues.options },
        {
          onSuccess: async () => {
            message.success('Custom field added');
          },
          onError: () => {
            message.error('Unable to create custom field');
          },
        },
      );
    }
  };

  return (
    <EventLayout
      title='Attendee Fields'
      extra={[
        <Button key='menu' type='primary' onClick={() => onOpenCustomFieldModal()}>
          Add Custom Field
        </Button>,
        <EventLanguageSelect key='language-select' style={{ marginRight: 10 }} />,
        <Select
          key='predefinedCustomFieldSelect'
          style={{ minWidth: '150px' }}
          value='Add General Field'
          onChange={handleAddPredefinedCustomField}
          placeholder='Select Language'
          options={[
            { label: 'Country', value: 'country' },
            { label: 'Company', value: 'company' },
            { label: 'Phone', value: 'phone' },
          ]}
        />,
      ]}
    >
      <div>
        Add mandatory or optional data fields to collect and display additional attendee
        information. Create custom open-ended questions and dropdown selections or choose from our
        list of <strong>General Fields </strong>(Country, Company, Phone ). Custom field answers can
        be pre-filled via attendee list CSV import. They will appear after the required event fields
        (Name and Email) during the onboarding process and are editable from the attendee's user
        profile.
      </div>
      <br />
      <h3>Mandatory Fields</h3>
      <DragDropMandatoryFields event={event} />
      <br />
      <h3>Custom Fields</h3>
      <DragDropCustomFields
        customFields={draggableItems?.map((item, index) => ({
          ...item,
          position: item.position || index + 1,
        }))}
        event={event}
        setOpenFieldModal={() => setOpenCustomFieldModal(true)}
        setSelectedField={(selectedField) => setSelectedCustomField(selectedField)}
      />

      <CustomFieldModal
        visible={openCustomFieldModal}
        data={selectedCustomField}
        onCancel={onCloseCustomFieldModal}
        onCreate={onCreateCustomField}
        onUpdate={onUpdateCustomField}
        onDropdownOptionsUpdate={setCustomFieldOptions}
      />
    </EventLayout>
  );
}
