export const eventLogoEndpoint = (eventId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/logo`;

export const eventVideoImageEndpoint = (eventId: string, videoId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/video/${videoId}`;

export const eventSessionImageEndpoint = (eventId: string, sessionId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/session/${sessionId}`;

export const eventSpeakerImageEndpoint = (eventId: string, speakerId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/speaker/${speakerId}`;

export const eventLogoEmailEndpoint = (eventId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/logo-email`;

export const eventBackgroundEndpoint = (eventId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/background`;

export const eventFaviconEndpoint = (eventId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/images/favicon`;

export const userImageEndpoint = () => `${process.env.REACT_APP_API_URL}/users/image`;

export const eventUserImageEndpoint = (userId: string) =>
  `${process.env.REACT_APP_API_URL}/users/${userId}/image`;

export const csvUploadEndpoint = (eventId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/users/csv/import`;

export const breakoutImageEndpoint = (eventId: string, breakoutId: string) =>
  `${process.env.REACT_APP_API_URL}/events/${eventId}/breakout-rooms/${breakoutId}/image`;

export const organisationImageEndpoint = (organisationId: string) =>
  `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/image`;

export default {
  eventLogoEndpoint,
  eventLogoEmailEndpoint,
  eventBackgroundEndpoint,
  eventFaviconEndpoint,
  userImageEndpoint,
  eventUserImageEndpoint,
  csvUploadEndpoint,
  breakoutImageEndpoint,
  organisationImageEndpoint,
};
