import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Form, Input, Button, Tooltip, Select, Switch, Divider, Row, Col } from 'antd';
import { MenuOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DropResult,
} from 'react-beautiful-dnd';

import { CustomFieldOption } from '@transforms/customFieldOption';
import { CustomFieldResource, CustomFieldType, DropdownOption } from '@transforms/customField';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface CustomFieldProps extends DataEntryProps {
  data?: CustomFieldResource;
  onDropdownOptionsUpdate: React.Dispatch<React.SetStateAction<CustomFieldOption[] | undefined>>;
}

export function CustomField({ data, onDropdownOptionsUpdate, ...props }: CustomFieldProps) {
  const [type, setType] = useState<string>(CustomFieldType.INPUT);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (!data) return;
    setType(data.type ?? CustomFieldType.INPUT);
    if (data.options) setOptions(data.options.map((option) => ({ ...option, id: nanoid() })));
  }, [data]);

  useEffect(() => {
    onDropdownOptionsUpdate?.(options as CustomFieldOption[]);
  }, [onDropdownOptionsUpdate, options]);

  const onDragEnd = (res: DropResult) => {
    if (!res.destination) return;
    const result = [...options];
    const [removed] = result.splice(res.source.index, 1);
    result.splice(res.destination.index, 0, removed);
    setOptions(result);
  };

  const addOption = () => {
    setOptions([...options, { id: nanoid() }]);
  };

  const updateOption = (id: string, key: string, value: string) => {
    const result = [...options];
    const index = result.findIndex((option) => option.id === id);
    const [removed] = result.splice(index, 1);
    result.splice(index, 0, {
      ...removed,
      [key]: value,
    });
    setOptions(result);
  };

  const removeOption = (id: string) => {
    setOptions(options.filter((option) => option.id !== id));
  };

  return (
    <DataEntry {...props} data={data} name='Custom Field'>
      <b>How to use Custom Fields</b>
      <div>
        <br />
        All <strong>Custom Fields</strong> must have a <strong>Key</strong> (internal code) and{' '}
        <strong>Label</strong> (display tag). The <strong>Key</strong> is used internally to
        determine which value (data entered) to display. The <strong>Label</strong> will be
        displayed to the left of the submitted answer (underneath the attendee's name) and can be
        localized per language (if this event is multilingual).
        <br />
        <br />
        Use a short <strong>Key</strong> without any punctuation or symbols (ex. if{' '}
        <strong>Label</strong> is "What is your job title?" use "jobtitle"). Note, only the{' '}
        <strong>Label</strong> can be translated per language; the Key must always remain the same
        unique identifier.
        <br />
        <br />
        Tip: Use the <strong>Key</strong> "linkedin" or "videolink" to create clickable items on the
        attendee's profile (page and card).
        <br />
        <br />
        If <strong>Required</strong> is toggled on, it becomes a mandatory field. If{' '}
        <strong>Private</strong> is toggled on, it will not be publicly displayed to other
        attendees.
      </div>
      <br />
      <Form.Item name='key' label='Key' rules={[{ required: true }]}>
        <Input placeholder='Key' />
      </Form.Item>
      <Form.Item name='label' label='Label' rules={[{ required: true }]}>
        <Input placeholder='Label' />
      </Form.Item>
      <Form.Item name='placeholder' label='Placeholder' rules={[{ required: false }]}>
        <Input placeholder='Placeholder' />
      </Form.Item>
      {type === CustomFieldType.DROPDOWN && (
        <>
          <Row align='middle' justify='space-between' wrap style={{ marginBottom: 10 }}>
            <b>Options</b>
            <Tooltip title='Add an extra option' key='add'>
              <Button type='primary' onClick={addOption}>
                Add Option
              </Button>
            </Tooltip>
          </Row>
          <Row>
            <p style={{ marginLeft: 24 }}>Key (non-translatable)</p>
            <p style={{ marginLeft: 64 }}>Label</p>
          </Row>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='type' type='group'>
              {(dropProvided: DroppableProvided) => (
                <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                  {options.map((option, index) => (
                    <Draggable
                      key={option.id?.toString?.()}
                      draggableId={`type-${option.id}`}
                      index={index}
                    >
                      {(dragProvided: DraggableProvided) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <Row align='middle' wrap={false}>
                            <MenuOutlined />
                            <Input
                              value={option.key}
                              style={{ marginLeft: 10 }}
                              onChange={(e) => updateOption(option.id, 'key', e.target.value)}
                            />
                            <Input
                              value={option.label as string}
                              style={{ marginLeft: 10 }}
                              onChange={(e) => updateOption(option.id, 'label', e.target.value)}
                            />
                            <div style={{ marginLeft: 10 }}>
                              <Tooltip title='Remove option' key='remove'>
                                <Button
                                  danger
                                  type='primary'
                                  icon={<DeleteOutlined />}
                                  onClick={() => removeOption(option.id)}
                                />
                              </Tooltip>
                            </div>
                          </Row>
                          <div style={{ height: 10 }} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
      <Divider key='d1' />
      <Form.Item name='type' label='Type' rules={[{ required: true }]}>
        <Select onChange={(value) => (value ? setType(value.toString()) : null)}>
          <option key={CustomFieldType.INPUT}>Input</option>
          <option key={CustomFieldType.DROPDOWN}>Dropdown</option>
        </Select>
      </Form.Item>
      <Form.Item name='position' label='Position'>
        <Input type='number' />
      </Form.Item>
      <Row>
        <Col span={6}>
          <Form.Item name='required' label='Required' initialValue={false} valuePropName='checked'>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='private' label='Private' initialValue={false} valuePropName='checked'>
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </DataEntry>
  );
}
