import d3js from 'd3';

type ColorLegend =
  | string
  | {
      color: string;
      text?: string;
      textColor?: string;
    };

interface Props {
  legendSpacing?: number;
  selectedColor?: string;
  selectedTextColor?: string;
  smallDiameter?: number;
  mediumDiameter?: number;
  fontSizeFactor?: number;
  duration?: number;
  delay?: number;
  fixedDomain?: { min: number; max: number };
  tooltip?: boolean;
  tooltipProps?: Array<{ css: string; prop: string; display?: string }>;
  tooltipFunc?: (domNode: Node, d: d3js.layout.pack.Node, tooltipColor: string) => void;
  onClick?: (d: d3js.layout.pack.Node) => void;
  legend?: boolean;
  colorLegend?: ColorLegend[];
  data: Array<{
    _id: string; // unique id (required)
    value: number; // used to determine relative size of bubbles (required)
    displayText: string; // will use _id if undefined
    colorValue: number; // used to determine color
    selected: boolean; // if true will use selectedColor/selectedTextColor for circle/text
  }>;
}

export class ReactBubbleChartD3 {
  /* eslint-disable lines-between-class-members */
  legendSpacing?: number;
  selectedColor?: string;
  selectedTextColor?: string;
  smallDiameter: number;
  mediumDiameter: number;
  fontSizeFactor?: number;
  duration: number;
  delay: number;
  svg: d3js.Selection<any>;
  html: d3js.Selection<any>;
  legend: d3js.Selection<any>;
  tooltip: d3js.Selection<any>;
  diameter?: number;
  bubble?: d3js.layout.Pack<d3js.layout.pack.Node>;
  createLegend?: boolean;
  colorRange?: string[];
  textColorRange?: string[];
  colorLegend?: ColorLegend[];
  createTooltip?: boolean;
  tooltipProps?: Array<{ css: string; prop: string; display?: string }>;
  tooltipFunc?: (domNode: Node, d: d3js.layout.pack.Node, tooltipColor: string) => void;
  /* eslint-enable lines-between-class-members */

  constructor(element: HTMLElement, props: Props = { data: [] }) {
    this.legendSpacing = typeof props.legendSpacing === 'number' ? props.legendSpacing : 3;
    this.selectedColor = props.selectedColor;
    this.selectedTextColor = props.selectedTextColor;
    this.smallDiameter = props.smallDiameter || 40;
    this.mediumDiameter = props.mediumDiameter || 115;
    this.fontSizeFactor = props.fontSizeFactor;
    this.duration = props.duration === undefined ? 500 : props.duration;
    this.delay = props.delay === undefined ? 7 : props.delay;

    // create an <svg> and <html> element - store a reference to it for later
    this.svg = d3js
      .select(element)
      .append('svg')
      .attr('class', 'bubble-chart-d3')
      .style('overflow', 'visible');
    this.html = d3js
      .select(element)
      .append('div')
      .attr('class', 'bubble-chart-text')
      .style('position', 'absolute')
      .style('left', 0) // center horizontally
      .style('right', 0)
      .style('margin-left', 'auto')
      .style('margin-right', 'auto');
    this.legend = d3js
      .select(element)
      .append('svg')
      .attr('class', 'bubble-legend')
      .style('overflow', 'visible')
      .style('position', 'absolute');
    this.tooltip = this.html
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('border-radius', '5px')
      .style('border', '3px solid')
      .style('padding', '5px')
      .style('z-index', 500);
    // create legend and update
    this.adjustSize(element);
    this.update(element, props);
  }

  /**
   * Set this.diameter and this.bubble, also size this.svg and this.html
   */
  adjustSize(element: HTMLElement) {
    // helper values for positioning
    this.diameter = Math.min(element.offsetWidth, element.offsetHeight);
    const top = Math.max((element.offsetHeight - this.diameter) / 2, 0);
    // center some stuff vertically
    this.svg
      .attr('width', this.diameter)
      .attr('height', this.diameter)
      .style('position', 'relative')
      .style('top', `${top}px`); // center vertically
    this.html
      .style('width', `${this.diameter}px`)
      .style('height', `${this.diameter}px`)
      .style('top', `${top}px`); // center vertically;

    // create the bubble layout that we will use to position our bubbles\
    /* @ts-ignore */
    this.bubble = d3js.layout.pack().sort(null).size([this.diameter, this.diameter]).padding(3);
  }

  /**
   * Create and configure the legend
   */
  configureLegend(element: HTMLElement, props: Props) {
    this.createLegend = props.legend;
    // for each color in the legend, remove any existing, then
    // create a g and set its transform
    this.legend.selectAll('.legend-key').remove();
    if (!this.createLegend) return;

    const legendRectSize = Math.min(
      /* @ts-ignore */
      (element.offsetHeight - 20 - (this.colorLegend!.length - 1) * this.legendSpacing!) /
        /* @ts-ignore */
        this.colorLegend!.length,
      18,
    );
    const legendHeight =
      /* @ts-ignore */
      this.colorLegend!.length * (legendRectSize + this.legendSpacing!) - this.legendSpacing!;
    this.legend
      .style('height', `${legendHeight}px`)
      .style('width', `${legendRectSize}px`)
      .style('top', `${(element.offsetHeight - legendHeight) / 2}px`)
      .style('left', `${60}px`);

    const legendKeys = this.legend
      .selectAll('.legend-key')
      /* @ts-ignore */
      .data(this.colorLegend!)
      .enter()
      .append('g')
      .attr('class', 'legend-key')
      .attr('transform', (d, i) => {
        const height = legendRectSize + this.legendSpacing!;
        const vert = i * height;
        return `translate(${0},${vert})`;
      });

    // for each <g> create a rect and have its color... be the color
    legendKeys
      .append('rect')
      .attr('width', legendRectSize)
      .attr('height', legendRectSize)
      .style('fill', (c: any) => c.color)
      .style('stroke', (c: any) => c.color);

    // add necessary labels to the legend
    legendKeys
      .append('text')
      .attr('x', legendRectSize + 2)
      .attr('y', legendRectSize - 4)
      .text((c: any) => c.text);
  }

  /**
   * Create and configure the tooltip
   */
  configureTooltip(_: HTMLElement, props: Props) {
    this.createTooltip = props.tooltip;
    this.tooltipFunc = props.tooltipFunc;
    // remove all existing divs from the tooltip
    this.tooltip.selectAll('div').remove();
    // intialize the styling
    this.tooltip.style('display', 'none');
    if (!this.createTooltip) return;

    // normalize the prop formats
    this.tooltipProps = (props.tooltipProps || []).map((tp) =>
      typeof tp === 'string' ? { css: tp, prop: tp, display: tp } : tp,
    );
    // create a div for each of the tooltip props
    // eslint-disable-next-line no-restricted-syntax
    for (const { css } of this.tooltipProps) {
      this.tooltip.append('div').attr('class', css);
    }
  }

  /**
   * This is where the magic happens.
   * Update the tooltip and legend.
   * Set up and execute transitions of existing bubbles to new size/location/color.
   * Create and initialize new bubbles.
   * Remove old bubbles.
   * Maintain consistencies between this.svg and this.html
   */
  update(element: HTMLElement, props: Props) {
    this.adjustSize(element);
    // initialize color legend values and color range values
    // color range is just an array of the hex values
    // color legend is an array of the color/text objects
    const colorLegend = props.colorLegend || [];
    this.colorRange = colorLegend.map((c: ColorLegend) => (typeof c === 'string' ? c : c.color));
    this.colorLegend = colorLegend
      .slice(0)
      .reverse()
      .map((c) => (typeof c === 'string' ? { color: c } : c)) as any;
    this.textColorRange = colorLegend.map((c: any) =>
      typeof c === 'string' ? '#000000' : c.textColor || '#000000',
    );
    this.configureLegend(element, props);
    this.configureTooltip(element, props);

    const { data } = props;
    if (!data) return;

    const fontFactor = this.fontSizeFactor;
    const { duration } = this;
    const { delay } = this;

    // define a color scale for our colorValues
    const color = d3js.scale
      .quantize()
      .domain([
        props.fixedDomain ? props.fixedDomain.min : d3js.min(data, (d) => (d as any).colorValue),
        props.fixedDomain ? props.fixedDomain.max : d3js.max(data, (d) => (d as any).colorValue),
      ])
      .range(this.colorRange as string[]);

    // define a color scale for text town
    const textColor = d3js.scale
      .quantize()
      .domain([
        props.fixedDomain ? props.fixedDomain.min : d3js.min(data, (d) => (d as any).colorValue),
        props.fixedDomain ? props.fixedDomain.max : d3js.max(data, (d) => (d as any).colorValue),
      ])
      .range(this.textColorRange as string[]);

    // generate data with calculated layout values
    const nodes = this.bubble!.nodes((data.length ? { children: data } : data) as any).filter(
      (d) => d.depth,
    ); // filter out the outer bubble

    // assign new data to existing DOM for circles and labels
    // eslint-disable-next-line no-underscore-dangle
    const circles = this.svg.selectAll('circle').data(nodes, (d) => `g${(d as any)._id}`);
    // eslint-disable-next-line no-underscore-dangle
    const labels = this.html.selectAll('.bubble-label').data(nodes, (d) => `g${(d as any)._id}`);

    // update - this is created before enter.append. it only applies to updating nodes.
    // create the transition on the updating elements before the entering elements
    // because enter.append merges entering elements into the update selection
    // for circles we transition their transform, r, and fill
    /* @ts-ignore */
    circles
      .on('mouseover', this.tooltipMouseOver.bind(this, color, element))
      .transition()
      .duration(duration)
      .delay((d, i) => i * delay)
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .attr('r', (d) => d.r)
      .style('opacity', 1)
      .style('fill', (d) =>
        (d as any).selected ? this.selectedColor : color((d as any).colorValue),
      );
    // for the labels we transition their height, width, left, top, and color
    /* @ts-ignore */
    labels
      .on('mouseover', this.tooltipMouseOver.bind(this, color, element))
      .transition()
      .duration(duration)
      .delay((_, i) => i * delay)
      .style('height', (d) => `${2 * d.r!}px`)
      .style('width', (d) => `${2 * d.r!}px`)
      .style('left', (d) => `${d.x! - d.r!}px`)
      .style('top', (d) => `${d.y! - d.r!}px`)
      .style('opacity', 1)
      .style('color', (d) =>
        (d as any).selected ? this.selectedTextColor : textColor((d as any).colorValue),
      )
      .attr('class', (d) => {
        let size;
        if (2 * d.r! < this.smallDiameter) size = 'small';
        else if (2 * d.r! < this.mediumDiameter) size = 'medium';
        else size = 'large';
        return `bubble-label ${size}`;
      })
      // we can pass in a fontSizeFactor here to set the label font-size as a factor of its corresponding circle's radius; this overrides CSS font-size styles set with the small, medium and large classes
      .style('font-size', (d) => (fontFactor ? `${fontFactor * d.r!}px` : null));

    // enter - only applies to incoming elements (once emptying data)
    if (nodes.length) {
      // initialize new circles
      /* @ts-ignore */
      circles
        .enter()
        .append('circle')
        .on('mouseover', this.tooltipMouseOver.bind(this, color, element))
        .attr('transform', (d) => `translate(${d.x},${d.y})`)
        .attr('r', 0)
        .attr('class', (d) => (d.children ? 'bubble' : 'bubble leaf'))
        /* @ts-ignore */
        .style('fill', (d) => (d.selected ? this.selectedColor : color(d.colorValue)))
        .transition()
        .duration(duration * 1.2)
        .attr('transform', (d) => `translate(${d.x},${d.y})`)
        .attr('r', (d) => d.r)
        .style('opacity', 1);
      // intialize new labels
      /* @ts-ignore */
      labels
        .enter()
        .append('div')
        .attr('class', (d) => {
          let size;
          if (2 * d.r! < this.smallDiameter) size = 'small';
          else if (2 * d.r! < this.mediumDiameter) size = 'medium';
          else size = 'large';
          return `bubble-label ${size}`;
        })
        // eslint-disable-next-line no-underscore-dangle
        .text((d: any) => d.displayText || d._id)
        .on('click', (d) => {
          (d3js.event as Event).stopPropagation();
          props.onClick?.(d);
        })
        .on('mouseover', this.tooltipMouseOver.bind(this, color, element))
        .on('mouseout', this.tooltipMouseOut.bind(this))
        .style('position', 'absolute')
        .style('height', (d) => `${2 * d.r!}px`)
        .style('width', (d) => `${2 * d.r!}px`)
        .style('left', (d) => `${d.x! - d.r!}px`)
        .style('top', (d) => `${d.y! - d.r!}px`)
        .style('color', (d) =>
          (d as any).selected ? this.selectedTextColor : textColor((d as any).colorValue),
        )
        .style('opacity', 0)
        .transition()
        .duration(duration * 1.2)
        .style('opacity', 1)
        .style('font-size', (d) => (fontFactor ? `${fontFactor * d.r!}px` : null));
    }

    // exit - only applies to... exiting elements
    // for circles have them shrink to 0 as they're flying all over
    circles
      .exit()
      .on('mouseover', this.tooltipMouseOver.bind(this, color, element))
      .transition()
      .duration(duration)
      .attr('transform', (d) => {
        const dy = d.y! - this.diameter! / 2;
        const dx = d.x! - this.diameter! / 2;
        const theta = Math.atan2(dy, dx);
        const destX = (this.diameter! * (1 + Math.cos(theta))) / 2;
        const destY = (this.diameter! * (1 + Math.sin(theta))) / 2;
        return `translate(${destX},${destY})`;
      })
      .attr('r', 0)
      .remove();
    // for text have them fade out as they're flying all over
    labels
      .exit()
      .on('mouseover', this.tooltipMouseOver.bind(this, color, element))
      .transition()
      .duration(duration)
      .style('top', (d) => {
        const dy = d.y! - this.diameter! / 2;
        const dx = d.x! - this.diameter! / 2;
        const theta = Math.atan2(dy, dx);
        const destY = (this.diameter! * (1 + Math.sin(theta))) / 2;
        return `${destY}px`;
      })
      .style('left', (d) => {
        const dy = d.y! - this.diameter! / 2;
        const dx = d.x! - this.diameter! / 2;
        const theta = Math.atan2(dy, dx);
        const destX = (this.diameter! * (1 + Math.cos(theta))) / 2;
        return `${destX}px`;
      })
      .style('opacity', 0)
      .style('width', 0)
      .style('height', 0)
      .remove();
  }

  /**
   * On mouseover of a bubble, populate the tooltip with that elements info
   * (if this.createTooltip is true of course)
   */
  tooltipMouseOver(
    color: d3js.scale.Quantize<any>,
    element: HTMLElement,
    d: d3js.layout.pack.Node,
  ) {
    console.log('mouseover');
    if (!this.createTooltip) return;
    // eslint-disable-next-line no-restricted-syntax
    for (const { css, prop, display } of this.tooltipProps!) {
      this.tooltip
        .select(`.${css}`)
        .html((display ? `${display}: ` : '') + d[prop as keyof d3js.layout.pack.Node]);
    }
    // Fade the popup fill mixing the shape fill with 80% white
    const fill = color((d as any).colorValue);
    const backgroundColor = d3js.rgb(
      d3js.rgb(fill).r + 0.8 * (255 - d3js.rgb(fill).r),
      d3js.rgb(fill).g + 0.8 * (255 - d3js.rgb(fill).g),
      d3js.rgb(fill).b + 0.8 * (255 - d3js.rgb(fill).b),
    );
    this.tooltip.style('display', 'block');

    const tooltipNode = this.tooltip.node() as HTMLElement;
    if (this.tooltipFunc) {
      this.tooltipFunc(tooltipNode, d, fill);
    }
    const width = tooltipNode.offsetWidth + 1; // +1 for rounding reasons
    const height = tooltipNode.offsetHeight;
    const buffer = 5;

    // calculate where the top is going to be. ideally it is
    // (d.y - height/2) which'll put the tooltip in the middle of the bubble.
    // we need to account for if this'll put it out of bounds.
    let top;
    // if it goes above the bounds, have the top be the buffer
    if (d.y! - height < 0) {
      top = buffer;
      // if it goes below the bounds, have its buttom be a buffer length away
    } else if (d.y! + height / 2 > element.offsetHeight) {
      top = element.offsetHeight - height - buffer;
      // otherwise smack this bad boy in the middle of its bubble
    } else {
      top = d.y! - height / 2;
    }

    // calculate where the left is going to be. ideally it is
    // (d.x + d.r + buffer) which will put the tooltip to the right
    // of the bubble. we need to account for the case where this puts
    // the tooltip out of bounds.
    let left;
    // if there's room to put it on the right of the bubble, do so
    if (d.x! + d.r! + width + buffer < element.offsetWidth) {
      left = d.x! + d.r! + buffer;
      // if there's room to put it on the left of the bubble, do so
    } else if (d.x! - d.r! - width - buffer > 0) {
      left = d.x! - d.r! - width - buffer;
      // otherwise put it on the right part of its container
    } else {
      left = element.offsetWidth - width - buffer;
    }

    this.tooltip
      .style('background-color', backgroundColor.toString())
      .style('border-color', fill)
      .style('width', `${width}px`)
      .style('left', `${left}px`)
      .style('top', `${top}px`);
  }

  /**
   * On tooltip mouseout, hide the tooltip.
   */
  tooltipMouseOut() {
    if (!this.createTooltip) return;
    this.tooltip.style('display', 'none').style('width', '').style('top', '').style('left', '');
  }

  /** Any necessary cleanup */
  // eslint-disable-next-line class-methods-use-this
  destroy() {}
}
