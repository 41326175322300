import React from 'react';
import {
  EyeOutlined,
  MessageFilled,
  PhoneFilled,
  StarOutlined,
  TeamOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';

import { Pill } from '@components/Pill';
import type { UserRoleResource } from '@transforms/userRole';

import { InfoCard } from './InfoCard';

export function AnalyticsGeneralAttendee({
  generalAttendee,
  mostCommonRole,
  mostCommonTags,
  mostCommonLanguage,
}: {
  generalAttendee: any;
  mostCommonRole?: UserRoleResource | null;
  mostCommonTags: { label: string; count: number }[];
  mostCommonLanguage: string;
}) {
  const renderMostCommonLanguage = () => {
    switch (mostCommonLanguage) {
      case 'en':
        return 'English';
      case 'es':
        return 'Spanish';
      case 'nl':
        return 'Dutch';
      case 'de':
        return 'German';
      default:
        return 'English';
    }
  };
  return (
    <Row align='top'>
      {/* SECTION HEADER */}
      <Col span={12}>
        <Col span={18}>
          <Row
            style={{
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <h1
              style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                maxWidth: '80%',
                margin: '0',
                lineHeight: '1.2',
              }}
            >
              The General Attendee
            </h1>

            {mostCommonRole ? (
              <Pill style={{ height: '2.4rem', fontWeight: 'bold', marginLeft: '1rem' }}>
                {mostCommonRole.name}
              </Pill>
            ) : null}
          </Row>
          <h2
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: 'grey',
              maxWidth: '90%',
              margin: '0',
              lineHeight: '1',
              marginTop: '0.8rem',
            }}
          >
            The average attendee&apos;s usage across the platform
          </h2>
          <br />
          <Row style={{ justifyContent: 'space-evenly' }}>
            {mostCommonTags.slice(0, 3).map((tag) => {
              return (
                <Pill
                  key={tag.label}
                  style={{
                    flexGrow: 1,
                    height: '2.4rem',
                    background: 'white',
                    color: 'grey',
                    border: '1px solid grey',
                    fontWeight: 'bold',
                    maxWidth: 'unset',
                    margin: '0 1rem',
                  }}
                >
                  {`${tag.label} (${tag.count})`}
                </Pill>
              );
            })}
          </Row>
          <br />
          <div>
            <Row style={{}}>
              <Col span={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <b style={{ fontSize: '1.3rem' }}>Lanugage</b>

                  <WechatOutlined style={{ fontSize: '3.5rem' }} />
                  <span>{renderMostCommonLanguage()}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Col>
      <Col span={12}>
        {/* INFOCARDS */}
        <Row gutter={[42, 24]}>
          <Col span={12}>
            <InfoCard
              title='Avg. Sessions Added'
              value={generalAttendee?.sessions.added}
              icon={<StarOutlined />}
              isAverage
              averagePercentage
              percentage={generalAttendee?.sessions.added.average / generalAttendee?.sessions.total}
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Avg. Sessions Attended'
              value={generalAttendee?.sessions.attended}
              icon={<TeamOutlined />}
              isAverage
              averagePercentage
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Avg. Private Messages Sent'
              value={generalAttendee?.messages.private}
              icon={<MessageFilled />}
              isAverage
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Avg. Public Messages Sent'
              value={generalAttendee?.messages.group}
              icon={<WechatOutlined />}
              isAverage
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Avg. Video Calls Made'
              value={generalAttendee?.videoCalls.total}
              icon={<PhoneFilled />}
              isAverage
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Avg. Time Video Calling'
              value={generalAttendee?.videoCalls.time}
              icon={<PhoneFilled />}
              isAverage
              isDuration
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Avg. Video View Time'
              value={generalAttendee?.viewTime}
              icon={<EyeOutlined />}
              isAverage
              isDuration
            />
          </Col>
        </Row>
      </Col>
      <Divider />
    </Row>
  );
}
