import React from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd';

import { Event } from '@transforms/event';

import styles from '../../styles/ThemePreview.module.css';

type PreviewEmailLogoModalProps = {
  visible: boolean;
  event: Partial<Event> | undefined;
  onCancel(): void;
};

export function PreviewEmailLogo({ visible, event, onCancel }: PreviewEmailLogoModalProps) {
  return (
    <Modal title='Preview' visible={visible} onCancel={onCancel} footer={null} width='900px'>
      <h6 className={styles.title}>Login Link Email (Cropped, 100% scale)</h6>
      <div className={styles.emailLogoPreviewContainer}>
        <div className={styles.emailLogoPreview}>
          {!!event?.themeLogoEmailUrl && (
            <img
              src={event.themeLogoEmailUrl}
              alt='Login Link Email Logo'
              className={styles.emailLogoPreviewLogo}
            />
          )}
          <h2 className={styles.emailLogoPreviewTitle}>
            Dear John,
            <br />
            here is your login link to join
            <br />
            {event?.name ?? 'Event'}
          </h2>
          <p className={styles.emailLogoPreviewText}>
            Hi John!
            <br />
            <br />
            You're invited for {event?.name ?? 'Event'}, please contact{' '}
            <a>{event?.organiserContact ?? 'john.doe@virtual-live-event.com'}</a>.
            <br />
            <br />
            {event?.name ?? 'Event'} starts at{' '}
            {dayjs(event?.startsAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        </div>
        <div className={styles.emailLogoPreviewRest} />
      </div>
    </Modal>
  );
}
