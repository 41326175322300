import { useQuery, useMutation, useQueryClient } from 'react-query';

import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import transform, { CustomField } from '@transforms/customField';

export const useCustomField = (eventId?: string, customFieldId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['customField', eventId, customFieldId], async () => {
    if (!eventId) throw new Error('No event');
    if (!customFieldId) throw new Error('No custom field');

    const { body } = await fetch(`/events/${eventId}/custom-fields/${customFieldId}`, {
      token: accessToken,
    });

    return transform.one(body);
  });
};

export const useCustomFields = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['customFields', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(languageUrl(`/events/${eventId}/custom-fields`, language), {
        token: accessToken,
      });

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useCreateCustomField = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: CustomField) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/custom-fields`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customFields');
      },
    },
  );
};

export const useUpdateCustomField = (
  eventId?: string,
  customFieldId?: string,
  language?: string,
) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: CustomField) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/custom-fields/${customFieldId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customFields');
      },
    },
  );
};

export const useUpdateCustomFieldsPositions = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (body: Array<[string, number]>) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/custom-fields/positions`, {
        method: 'POST',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customFields');
      },
    },
  );
};

export const useDeleteCustomField = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (customFieldId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/custom-fields/${customFieldId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customFields');
      },
    },
  );
};

export default {
  useCustomField,
  useCustomFields,
  useCreateCustomField,
  useUpdateCustomField,
  useUpdateCustomFieldsPositions,
  useDeleteCustomField,
};
