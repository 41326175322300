import { Modal, Form, Input, Button } from 'antd';
import QRCode from 'qrcode.react';

import { useState, useEffect } from 'react';

import useAuth from '@hooks/auth/useAuth';
import { showErrorResponse } from '@utils/showError';

export interface TFAProps {
  visible: boolean;
  onCancel: () => void;
}

export function TFA({ visible, onCancel }: TFAProps) {
  const [form] = Form.useForm<{ code: string }>();
  const { user, setupTFA, disableTFA, error, tfaSetup, cancelTFASetup, enableTFA } = useAuth();
  const [isTotpEnabled, setIsTotpEnabled] = useState(false);

  useEffect(() => {
    setIsTotpEnabled(user?.totpEnabled || false);
  }, [user?.totpEnabled]);

  useEffect(() => {
    if (error) showErrorResponse(error.response);
  }, [error]);

  const enableTotp = () => {
    setupTFA();
  };

  const disableTotp = () => {
    disableTFA();
    form.resetFields();
  };

  return (
    <Modal
      visible={visible}
      title='2FA Settings'
      okText={tfaSetup ? 'Confirm' : 'Ok'}
      cancelText='Cancel'
      onOk={async () => {
        if (!tfaSetup) {
          onCancel();
        } else {
          form.validateFields().then(({ code }) => {
            enableTFA(code);
          });
        }
      }}
      onCancel={() => {
        cancelTFASetup();
        form.resetFields();
        onCancel();
      }}
      forceRender
    >
      {!isTotpEnabled && !tfaSetup && (
        <Button onClick={() => enableTotp()} type='primary'>
          Enable 2FA
        </Button>
      )}
      {isTotpEnabled && (
        <Button onClick={() => disableTotp()} type='primary' danger>
          Disable 2FA
        </Button>
      )}

      {tfaSetup && (
        <>
          <p>
            <b>Step 1:</b> scan this QR code with the Google Authenticator app:
          </p>
          {/* @ts-ignore */}
          <QRCode style={{ marginBottom: '10pt' }} value={tfaSetup.uri} />
          <p>
            <b>Stap 2:</b> confirm the verification code to enable 2FA
          </p>
          <Form form={form} layout='vertical'>
            <Form.Item
              name='code'
              label='Code'
              rules={[
                {
                  required: true,
                  message: 'Please enter verification code!',
                },
              ]}
            >
              <Input placeholder='code' />
            </Form.Item>
          </Form>
        </>
      )}
    </Modal>
  );
}
