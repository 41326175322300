import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Divider, Modal } from 'antd';

import { UserRolePermission } from '@transforms/userRole';
import type { EventResource } from '@transforms/event';

export interface EditRolePermissionsModalProps {
  visible: boolean;
  onOk: (data: {
    permissions: UserRolePermission[];
    visible: boolean;
    attendeesInSidebar: boolean;
  }) => void;
  onCancel: () => void;
  event?: EventResource;
  data: {
    visible: boolean;
    attendeesInSidebar: boolean;
    initalPermissions: UserRolePermission[];
  };
}

function CheckboxItem({
  title,
  description,
  checked,
  disabled,
  firstItem,
  onClick,
}: {
  title: string;
  description: string | JSX.Element;
  checked: boolean;
  disabled?: boolean;
  firstItem?: boolean;
  onClick: () => void;
}) {
  return (
    <>
      <p style={{ margin: firstItem ? 0 : '20px 0 0', fontWeight: 'bold' }}>{title}</p>
      <p style={{ marginBottom: 5 }}>{description}</p>
      <Checkbox checked={checked} disabled={disabled} onClick={onClick}>
        Enabled
      </Checkbox>
    </>
  );
}

export function EditRolePermissionsModal({
  visible: modalVisible,
  onOk,
  onCancel,
  event,
  data,
}: EditRolePermissionsModalProps) {
  const [permissions, setPermissions] = useState(data.initalPermissions);
  const [visible, setVisible] = useState(data.visible);
  const [attendeesInSidebar, setAttendeesInSidebar] = useState(data.attendeesInSidebar);

  const visibilityDisabled = event?.rolesEnabled === false;
  const attendeesSidebarDisabled = event?.attendeesInSidebarEnabled === false;
  const privateChatEnabled = event?.privateChatEnabled;
  const groupChatEnabled = event?.chatEnabled;
  const privateVideoCallEnabled = event?.videoCallEnabled;

  const togglePermission = useCallback(
    (permission: UserRolePermission) => {
      setPermissions((prev) =>
        prev.includes(permission)
          ? prev.filter((perm) => perm !== permission)
          : [...prev, permission],
      );
    },
    [setPermissions],
  );

  return (
    <Modal
      width={750}
      visible={modalVisible}
      title='User Role Permission Settings'
      okText='Ok'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => onOk({ permissions, visible, attendeesInSidebar })}
    >
      <h3>Visibility</h3>
      <CheckboxItem
        title='Name (Tag)'
        description='Attendees are visible, but there is no tag (label) visible from the front-end.'
        checked={visibilityDisabled ? false : visible}
        disabled={visibilityDisabled}
        onClick={() => setVisible((prev) => !prev)}
        firstItem
      />
      <CheckboxItem
        title='Hidden Attendees'
        description='Attendees (regardless of their tag [label]) are invisible from the front-end.'
        checked={permissions.includes(UserRolePermission.HIDE_USERS)}
        onClick={() => togglePermission(UserRolePermission.HIDE_USERS)}
      />
      <CheckboxItem
        title='Attendee Shortcut Icon'
        description={
          <>
            Allow attendees to use the shortcut icon in the sidebar.
            <br />
            <b>Note:</b> this only works if "Private Chat" is eanbled in the{' '}
            <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link> section. It is
            currently {`${privateChatEnabled ? 'enabled' : 'disabled'}`}.
          </>
        }
        checked={attendeesSidebarDisabled ? false : attendeesInSidebar}
        disabled={attendeesSidebarDisabled}
        onClick={() => setAttendeesInSidebar((prev) => !prev)}
      />
      <Divider />
      <h3>Host Privileges</h3>
      <CheckboxItem
        title='Virtual Event Host'
        description={
          <>
            Assign this role to an event moderator so attendees can start a private chat via the
            Support icon (in the sidebar).
            <br />
            {event?.hostChatEnabled ? (
              <b>Note:</b>
            ) : (
              <b style={{ color: '#faad14' }}>Warning:</b>
            )}{' '}
            This only works if 'Host Chat' is enabled in the{' '}
            <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link> section. It is
            currently {event?.hostChatEnabled ? 'enabled' : 'disabled'}
          </>
        }
        checked={permissions.includes(UserRolePermission.HOST_CHAT)}
        onClick={() => togglePermission(UserRolePermission.HOST_CHAT)}
        firstItem
      />
      <CheckboxItem
        title='Video Call Host'
        description={
          <>
            Assign this role to those attendees that should be able to create and control the group
            video call(s).
            <br />
            This includes: starting a video call; inviting attendees; controlling attendees' camera,
            audio, and screenshare settings; ending the meeting for all participants.
          </>
        }
        checked={permissions.includes(UserRolePermission.VIDEO_CALL_HOST)}
        onClick={() => togglePermission(UserRolePermission.VIDEO_CALL_HOST)}
      />
      <Divider />
      <h3>Attendee Features</h3>
      <CheckboxItem
        title='Public Chat'
        description={
          <>
            Attendees are allowed to participate in public chat.
            <br />
            <b>Note:</b> This only works if "Public Chat" is enabled in the{' '}
            <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link> section. It is
            currently {groupChatEnabled ? 'enabled' : 'disabled'}.
          </>
        }
        checked={permissions.includes(UserRolePermission.SEND_GROUP_CHAT)}
        onClick={() => togglePermission(UserRolePermission.SEND_GROUP_CHAT)}
        firstItem
      />
      <CheckboxItem
        title='Private Chat'
        description={
          <>
            Attendees are allowed to send direct messages to other attendees.
            <br />
            <b>Note:</b> This only works if "Private Chat" is enabled in the{' '}
            <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link> section. It is
            currently {privateChatEnabled ? 'enabled' : 'disabled'}.
          </>
        }
        checked={permissions.includes(UserRolePermission.CREATE_PRIVATE_CHAT)}
        onClick={() => togglePermission(UserRolePermission.CREATE_PRIVATE_CHAT)}
      />
      <CheckboxItem
        title='Private Video Call'
        description={
          <>
            Attendees are allowed to video call (1-on-1) other attendees.
            <br />
            <b>Note:</b> This only works if "Private Video Call" is enabled in the{' '}
            <Link to={`/events/${event?.id}/controlpanel`}>Control Panel</Link> section. It is
            currently {privateVideoCallEnabled ? 'enabled' : 'disabled'}.
          </>
        }
        checked={permissions.includes(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS)}
        onClick={() => togglePermission(UserRolePermission.CREATE_PRIVATE_VIDEO_CALLS)}
      />
    </Modal>
  );
}
