import { EyeOutlined } from '@ant-design/icons';

import { Card, Col, Row, Statistic } from 'antd';
import moment from 'moment';

import { SessionWithLocationVisit } from '@transforms/locationVisit';

export function StreamingCard({ session }: { session: SessionWithLocationVisit }) {
  return (
    <Card size='small' style={{ width: '550px', maxHeight: '125px' }} bodyStyle={{}}>
      <Row gutter={24} align='middle'>
        <Col span={6}>
          {!session.imageUrl ? (
            <div
              style={{
                backgroundColor: '#EEE8FF',
                display: 'grid',
                placeItems: 'center',
                fontSize: '2rem',
                fontWeight: 'bold',
                color: '#1d39c4',
                borderRadius: '20%',
                aspectRatio: '1 / 1',
                maxHeight: '100px',
                height: '100%',
              }}
            />
          ) : (
            <img
              src={session.imageUrl || ''}
              alt='session icon'
              style={{
                height: '100%',
                aspectRatio: '1 / 1',
                borderRadius: '20%',
                maxHeight: '100px',
              }}
            />
          )}
        </Col>

        <Col span={18}>
          <Statistic
            title={
              <div style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
                <b>{session.title}</b>
                <span>{`${moment(session.startsAt).format('HH:mm')} - ${moment(
                  session.endsAt,
                ).format('HH:mm')}`}</span>
              </div>
            }
            value={`${session.locationVisit?.count ?? 0} views`}
            valueStyle={{
              fontWeight: 'bold',
              fontSize: '2rem',
            }}
            prefix={<EyeOutlined style={{ marginRight: '1rem' }} />}
          />
        </Col>
      </Row>
    </Card>
  );
}
