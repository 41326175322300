import { useMutation, useQueryClient } from 'react-query';

import {
  useResource,
  useResources,
  useCreateResource,
  useUpdateResource,
  useDeleteResource,
} from '@hooks/useResources';
import useAuth from '@hooks/auth/useAuth';
import transform, { StageResource } from '@transforms/stage';
import fetch from '@utils/fetch';

export const useStage = (stageId: string) => {
  return useResource<StageResource>('stage', stageId, transform.one);
};

export const useStages = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: { field?: keyof StageResource; order?: 'ascend' | 'descend' };
  },
  search?: string,
) => {
  return useResources<StageResource>('stage', transform.many, {
    pagination,
    sorter,
    search,
  });
};

export const useCreateStage = () => {
  return useCreateResource<Omit<StageResource, 'id'>>('stage');
};

export const useDuplicateStage = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/stages/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stages');
      },
    },
  );
};

export const useUpdateStage = (id?: string) => {
  return useUpdateResource<Omit<StageResource, 'id'>>('stage', id);
};

export const useDeleteStage = () => {
  return useDeleteResource('stage');
};

export default {
  useStage,
  useStages,
  useCreateStage,
  useDuplicateStage,
  useUpdateStage,
  useDeleteStage,
};
