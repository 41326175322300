import { useNavigate } from 'react-router-dom';

import useAuth from '@hooks/auth/useAuth';

export const useProtected = ({ checkSuperAdmin } = { checkSuperAdmin: false }) => {
  const navigate = useNavigate();
  const { isAuthorized, isInitializing, isSuperAdmin } = useAuth();

  if (!isAuthorized && !isInitializing) navigate('/auth/login');
  if (checkSuperAdmin && !isSuperAdmin) navigate('/');
};

export default useProtected;
