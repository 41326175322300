import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message, Table, Popconfirm, Tooltip, Divider } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { Link, useNavigate } from 'react-router-dom';

import { PageTemplate } from '@transforms/page';
import { SessionResource } from '@transforms/session';
import { Stage, StageResource } from '@transforms/stage';

import { LazyModal } from '@components/LazyModal';
import { EventLayout } from '@components/Layout';
import { Stage as StageModal } from '@components/Modals';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import {
  useStages,
  useCreateStage,
  useDuplicateStage,
  useUpdateStage,
  useDeleteStage,
} from '@hooks/useStages';
import { useCurrentEvent } from '@hooks/useEvents';

export default function Stages() {
  const navigate = useNavigate();
  const { data: event } = useCurrentEvent();

  if (
    event &&
    event.organisation &&
    !event.organisation.enabledPageTemplates?.includes(PageTemplate.SCHEDULE)
  ) {
    navigate('/');
  }

  const [sorter, setSorter] = useState<{
    field?: keyof StageResource;
    order?: 'ascend' | 'descend';
  }>({});
  const [openStageModal, setOpenStageModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState<StageResource | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const { isLoading, data: stages, refetch } = useStages({ ...pagination, sorter }, searchTerm);
  const { mutate: createStage } = useCreateStage();
  const { mutate: updateStage } = useUpdateStage(selectedStage?.id);
  const { mutate: deleteStage } = useDeleteStage();
  const { mutateAsync: duplicateStage } = useDuplicateStage(event?.id);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Stages';
      }
    }
  }, [listRef.current?.innerHTML]);

  const onOpenStageModal = (stage?: StageResource) => {
    if (stage) {
      setSelectedStage(stage);
    } else {
      setSelectedStage(undefined);
    }
    setOpenStageModal(true);
  };

  const onCloseStageModal = () => {
    setOpenStageModal(false);
    setSelectedStage(undefined);
  };

  const onCreateStage = (values: Stage & { sessions: string[] }, done: () => void) => {
    createStage(values, {
      onSuccess: async () => {
        onCloseStageModal();
        done();
      },
    });
  };

  const onUpdateStage = (
    values: Stage & { sessions: string[] },
    done: (refetch?: () => void) => void,
  ) => {
    updateStage(values, {
      onSuccess: () => {
        onCloseStageModal();
        done(refetch);
      },
    });
  };

  const onDeleteStage = async (id: string) => {
    deleteStage(id, {
      onError: () => {
        message.error({
          content: (
            <div className='message-box-content-container'>
              <div className='message-box-content'>
                <h3>Deletion Failed!</h3>
                <span>
                  An error occurred while saving your changes. Note, you cannot delete a stage if
                  there are sessions attached.
                </span>
              </div>
            </div>
          ),
          className: 'message-box',
        });
      },
    });
  };

  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, 500);

  const onDuplicateStage = async (stage?: StageResource) => {
    if (!stage?.id) return;
    await duplicateStage(stage.id);
    message.success('Stage duplicated');
  };

  const renderSessions = (sessions?: SessionResource[]) => {
    if (!sessions?.length) return '';
    if (sessions.length === 1) return sessions[0].title;
    return (
      <Tooltip title={sessions.map((session) => session.title).join(', ')} key='sessions'>
        {sessions.length} sessions
      </Tooltip>
    );
  };

  return (
    <EventLayout
      title='Stages'
      extra={[
        <Input.Search
          placeholder='Search'
          allowClear
          onChange={(e) => onSearch(e.target.value)}
          style={{ width: 200 }}
          key='search'
        />,
        <Button key='menu' type='primary' onClick={() => onOpenStageModal()}>
          Add Stage
        </Button>,
        <EventLanguageSelect
          key='language-select'
          style={{ marginRight: 10 }}
          onUpdate={refetch}
        />,
      ]}
    >
      <div>
        Here you can add <b>Stages</b> and assign{' '}
        <Link to={`/events/${event?.id}/sessions`}>Sessions</Link>. Note, <b>Stages</b> are only
        necessary if your event will have multiple sessions at the same time and date. For instance
        if there are 2 sessions on January 27 at 13:00 - 14:00, then each session must be assigned
        to a different stage.
      </div>
      <br />
      <div ref={listRef}>
        <Table
          loading={isLoading}
          dataSource={stages?.items.map((stage) => ({ ...stage, key: stage.id }))}
          onChange={(_, __, sort: any) => setSorter(sort)}
          pagination={{
            pageSize: stages?.meta.itemsPerPage || 10,
            total: stages?.meta.totalItems,
            onChange: (page, pageSize) => {
              setPagination({ page, limit: pageSize || 10 });
            },
          }}
          columns={[
            {
              title: 'Stage',
              key: 'name',
              sorter: true,
              dataIndex: 'name',
            },
            {
              title: 'Sessions',
              key: 'sessions',
              dataIndex: 'sessions',
              render: renderSessions,
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              align: 'right',
              width: 170,
              render: (_, speaker) => [
                <Tooltip title='Duplicate' key='duplicate'>
                  <Button
                    shape='circle'
                    icon={<CopyOutlined />}
                    onClick={() => onDuplicateStage(speaker)}
                  />
                </Tooltip>,
                <Divider key='d1' type='vertical' />,
                <Tooltip title='Edit' key='edit'>
                  <Button
                    shape='circle'
                    icon={<EditOutlined />}
                    onClick={() => onOpenStageModal(speaker)}
                  />
                </Tooltip>,
                <Divider key='d2' type='vertical' />,
                <Popconfirm
                  title={
                    <div className='message-box-content-container'>
                      <div className='message-box-content'>
                        <h3>Confirm Deletion!</h3>
                        <span>
                          Are you sure you want to delete this stage and any associated data? Note,
                          this cannot be undone!
                        </span>
                      </div>
                    </div>
                  }
                  onConfirm={() => onDeleteStage(speaker.id)}
                  okText='Delete'
                  cancelText='Cancel'
                  key='delete'
                  okButtonProps={{
                    danger: true,
                  }}
                >
                  <Button shape='circle' icon={<DeleteOutlined />} danger />
                </Popconfirm>,
              ],
            },
          ]}
        />
      </div>
      <LazyModal open={openStageModal}>
        {(open) => (
          <StageModal
            visible={open}
            data={selectedStage}
            onCancel={onCloseStageModal}
            onCreate={onCreateStage}
            onUpdate={onUpdateStage}
          />
        )}
      </LazyModal>
    </EventLayout>
  );
}
