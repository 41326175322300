import React from 'react';
import dayjs from 'dayjs';
import { Modal } from 'antd';

import { Event } from '@transforms/event';

import styles from '../../styles/ThemePreview.module.css';

type PreviewLogoModalProps = {
  visible: boolean;
  event: Partial<Event> | undefined;
  onCancel(): void;
};

export function PreviewLogo({ visible, event, onCancel }: PreviewLogoModalProps) {
  const primaryColor = `#${event?.themePrimaryColor?.replace('#', '')}` ?? '#FEDDC6';
  const secondaryColor = `#${event?.themeSecondaryColor?.replace('#', '')}` ?? '#000';
  const backgroundColor = `#${event?.themeBackgroundColor?.replace('#', '')}` ?? '#0D0A26';

  const Content = (
    <>
      <div className={styles.macBookContainer}>
        <div className={styles.logoPreviewNavbar} style={{ backgroundColor }}>
          <div
            className={styles.logoPreviewLogo}
            style={{ backgroundImage: `url('${event?.themeLogoUrl}')` }}
          />
          <div className={styles.logoPreviewMenuItemContainer}>
            {[...Array(4)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.logoPreviewMenuItem}
                style={{ backgroundColor: index === 1 ? secondaryColor : primaryColor }}
              />
            ))}
          </div>
        </div>
        <div
          className={styles.background}
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${
              event?.themeBackgroundUrl ? `url('${event.themeBackgroundUrl}')` : backgroundColor
            }`,
            backgroundSize: '200% auto',
          }}
        />
      </div>
    </>
  );

  const startsAt = dayjs.tz(event?.startsAt, 'Europe/Amsterdam');
  const endsAt = dayjs.tz(event?.endsAt, 'Europe/Amsterdam');
  const eventDate = startsAt.isSame(endsAt ?? new Date(), 'day')
    ? startsAt.toDate().toLocaleDateString('en-US', { day: 'numeric', month: 'long' })
    : `${startsAt.toDate().toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
      })} - ${endsAt.toDate().toLocaleDateString('en-US', { day: 'numeric', month: 'long' })}`;

  return (
    <Modal title='Preview' visible={visible} onCancel={onCancel} footer={null} width='900px'>
      <h6 className={styles.title}>Landing Page (Cropped, 100% scale)</h6>
      <div className={styles.landingPreviewContainer}>
        {event?.themeBackgroundUrl ? (
          <img
            alt='Landing Page Background'
            src={event.themeBackgroundUrl}
            className={styles.landingPreviewBackground}
          />
        ) : (
          <div
            className={styles.landingPreviewBackground}
            style={{ background: backgroundColor }}
          />
        )}
        <div className={styles.landingPreview}>
          {!!event?.themeLogoUrl && (
            <img
              src={event.themeLogoUrl}
              alt='Landing Page Logo'
              className={styles.landingPreviewLogo}
            />
          )}
          <div className={styles.landingPreviewDetails}>
            <div className={styles.landingPreviewHeading}>
              <h2 className={styles.landingPreviewTitle}>{event?.name ?? 'Event'}</h2>
            </div>
            <div className={styles.landingPreviewSubtitle}>
              <h4 className={styles.landingPreviewDate} style={{ color: primaryColor }}>
                {eventDate}
              </h4>
            </div>
            <span className={styles.landingPreviewDescription}>
              <div>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: event?.description ?? '<p>Description with words.</p>',
                  }}
                />
              </div>
            </span>
          </div>
          <button
            className={styles.landingPreviewButton}
            style={{ backgroundColor: primaryColor }}
            type='button'
          >
            <div className='css-oobk4c-Button'>Register</div>
          </button>
          <button
            className={styles.landingPreviewButton}
            style={{ backgroundColor: secondaryColor, marginTop: '1em', marginBottom: '12px' }}
            type='button'
          >
            <div className='css-oobk4c-Button'>Login</div>
          </button>
        </div>
      </div>
      <h6 className={styles.title}>Event Page (Cropped, 100% scale)</h6>
      <div className={styles.smallLogoPreview}>{Content}</div>
      <h6 className={styles.title}>Event Page (Cropped, 200% scale)</h6>
      <div className={styles.largeLogoPreview}>
        <div
          className={styles.macBookWrapper}
          style={{ transform: 'scale(2)', transformOrigin: 'top left', marginBottom: '-235px' }}
        >
          {Content}
        </div>
      </div>
    </Modal>
  );
}
