import { useCallback } from 'react';
import queryString from 'query-string';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import type { SorterResult } from 'antd/lib/table/interface';

import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import transform, { Admin } from '@transforms/admin';

export const useAdmin = (eventId?: string, adminId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['admin', eventId, adminId], async () => {
    if (!eventId) throw new Error('No event');
    if (!adminId) throw new Error('No admin');

    const { body } = await fetch(`/events/${eventId}/admins/${adminId}`, { token: accessToken });

    return transform.one(body);
  });
};

export const useAdmins = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: SorterResult<Admin>;
  },
  search?: string,
) => {
  const { accessToken } = useAuth();
  const query = queryString.stringify({
    ...pagination,
    sortField: sorter?.field,
    sortOrder: sorter?.order,
    search: search || undefined,
  });

  return useQuery(
    ['admins', search, sorter?.field, sorter?.order, pagination?.page, pagination?.limit],
    async () => {
      const { body } = await fetch(`/admins?${query}`, { token: accessToken });

      return body;
    },
  );
};

export const useEventAdmins = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['admins', eventId],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(`/events/${eventId}/admins`, { token: accessToken });

      if (!Array.isArray(body)) return [];

      return body.map(transform.one);
    },
    { enabled: !!eventId },
  );
};

export const useCreateAdmin = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Admin) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/admins`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
      },
    },
  );
};

export const useCreateSuperAdmin = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: { email: string }) => {
      return fetch('/admins', {
        method: 'POST',
        body: { ...values },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
        queryClient.invalidateQueries('superAdmins');
      },
    },
  );
};

export const useUpdateSuperAdmin = (adminId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: { email: string }) => {
      if (!adminId) throw new Error('No admin');

      return fetch(`/admins/${adminId}`, {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
        queryClient.invalidateQueries('superAdmins');
      },
    },
  );
};

export const useDeleteSuperAdmin = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (adminId?: string) => {
      if (!adminId) throw new Error('No admin');

      return fetch(`/admins/${adminId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
        queryClient.invalidateQueries('superAdmins');
      },
    },
  );
};

export const useUpdateAdmin = (eventId?: string, adminId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Admin) => {
      if (!eventId) throw new Error('No event');
      if (!adminId) throw new Error('No admin');

      return fetch(`/events/${eventId}/admins/${adminId}`, {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
      },
    },
  );
};

export const useDeleteAdmin = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (adminId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/admins/${adminId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
      },
    },
  );
};

export const useCreateOrganisationAdmin = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ organisationId, email }: { organisationId: string; email: string }) => {
      return fetch(`/organisations/${organisationId}/admins`, {
        method: 'POST',
        body: { email },
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
        queryClient.invalidateQueries('organisationAdmins');
      },
    },
  );
};

export const useDeleteOrganisationAdmin = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ organisationId, adminId }: { organisationId: string; adminId: string }) => {
      return fetch(`/organisations/${organisationId}/admins/${adminId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
        queryClient.invalidateQueries('organisationAdmins');
      },
    },
  );
};

export const useResendInvitation = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useCallback(
    async (adminId: string) => {
      if (!eventId) throw new Error('No event');

      await fetch(`/events/${eventId}/admins/${adminId}/resend-invitation`, {
        token: accessToken,
      });
    },
    [eventId, accessToken],
  );
};

export default {
  useAdmin,
  useAdmins,
  useEventAdmins,
  useCreateAdmin,
  useUpdateAdmin,
  useDeleteAdmin,
  useResendInvitation,
};
