import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Card, Form, Input } from 'antd';

import useAuth from '@hooks/auth/useAuth';
import { showErrorResponse } from '@utils/showError';

export default function Verify() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthorized && !auth.accessToken) navigate('/auth/login');
    if (auth.isAuthorized && !auth.tfaRequired) navigate('/');
  }, [auth.isAuthorized, auth.tfaRequired, auth.accessToken, navigate]);

  useEffect(() => {
    if (auth.error) showErrorResponse(auth.error.response);
  }, [auth.error]);

  const onFinish = (values: any) => {
    auth.verifyTFA(values.code);
  };

  return (
    <Row justify='center' align='middle' style={{ marginTop: '2em' }}>
      <Col span={6}>
        <Card title='2FA' headStyle={{ textAlign: 'center' }}>
          <Form
            layout='vertical'
            initialValues={{ email: '', password: '', remember: true }}
            onFinish={(values) => {
              onFinish(values);
            }}
          >
            <Form.Item
              name='code'
              label='Code'
              rules={[
                {
                  required: true,
                  message: 'Please enter verification code!',
                },
              ]}
            >
              <Input placeholder='code' />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' block>
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
