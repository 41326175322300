import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Table,
  Avatar,
  Tooltip,
  Divider,
  Tag,
  DatePicker,
  Space,
  Popconfirm,
  message,
} from 'antd';
import dayjs from 'dayjs';
import {
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
  FilterFilled,
  InboxOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import type { AlignType } from 'rc-table/lib/interface';

import {
  useArchiveEvent,
  useDeleteEvent,
  useDuplicateEvent,
  useUnarchiveEvent,
} from '@hooks/useEvents';
import useAuth from '@hooks/auth/useAuth';
import { protocol } from '@utils/protocol';
import { EventResource } from '@transforms/event';

interface Props {
  readonly isLoading?: boolean;
  readonly events: EventResource[] | undefined;
  readonly searchTerm?: string;
  readonly archived?: boolean;
}

export default function EventTable({ isLoading, events, searchTerm, archived }: Props) {
  const navigate = useNavigate();
  const { isSuperAdmin, organisation } = useAuth();
  const initalEvents = useMemo(
    () => events?.map((event) => ({ ...event, key: event.id })),
    [events],
  );
  const [tableEvents, setTableEvents] = useState(
    events?.map((event) => ({ ...event, key: event.id })),
  );

  const { mutate: archiveEvent } = useArchiveEvent();
  const { mutate: unarchiveEvent } = useUnarchiveEvent();
  const { mutate: deleteEvent } = useDeleteEvent();
  const { mutateAsync: duplicateEvent } = useDuplicateEvent();

  useEffect(() => {
    if (searchTerm) {
      const filtertEvents = initalEvents?.filter((event) =>
        event.name.toLowerCase().includes(searchTerm.toLowerCase() ?? ''),
      );
      setTableEvents(filtertEvents);
      return;
    }
    setTableEvents(initalEvents);
  }, [searchTerm, events, initalEvents]);

  const renderName = (event: EventResource) => {
    return (
      <>
        {isSuperAdmin && event.internalName && (
          <>
            <Tag color='blue'>{event.internalName}</Tag>
            <br />
          </>
        )}
        {event.name}
      </>
    );
  };

  const renderActive = (event: EventResource) => {
    let tag = <Tag color='volcano'>inactive</Tag>;

    if (event.isTemplate) {
      tag = <Tag color='blue'>template</Tag>;
    } else if (event.active && !event.archivedAt) {
      tag = <Tag color='green'>active</Tag>;
    }
    return tag;
  };

  const renderStatus = (event: EventResource) => {
    if (dayjs().isBefore(event.startsAt, 'd')) {
      return <Tag color='blue'>future</Tag>;
    }
    if (dayjs().isAfter(event.endsAt, 'd')) {
      return <Tag color='volcano'>past</Tag>;
    }
    return <Tag color='green'>current</Tag>;
  };
  const getStatusString = (event: EventResource) => {
    if (dayjs().isBefore(event.startsAt, 'd')) {
      return 'future';
    }
    if (dayjs().isAfter(event.endsAt, 'd')) {
      return 'past';
    }
    return 'current';
  };

  const renderArchivedAt = (event: EventResource) => {
    if (!event?.archivedAt) return undefined;
    let date = dayjs(event?.archivedAt);
    date = date.add(14, 'day');
    return date.format('D MMMM YYYY');
  };

  const statusFilter = (value: any, record: EventResource) => {
    if (value === 'future' && dayjs().isBefore(record.startsAt, 'd')) {
      return true;
    }
    if (value === 'past' && dayjs().isAfter(record.endsAt, 'd')) {
      return true;
    }
    if (value === 'current' && dayjs().isBetween(record.startsAt, record.endsAt, 'd')) {
      return true;
    }
    return false;
  };

  const dateFilter = (dataIndex: [string, string]) => ({
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Space>
          {/* @ts-ignore */}
          <DatePicker
            onChange={(e) => {
              setSelectedKeys(e ? [e.format('YYYY-MM-DDT00:00:00Z')] : null);
            }}
            style={{ width: 190, marginBottom: 10 }}
          />
        </Space>
        <br />
        <Space>
          <Button onClick={() => clearFilters()} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='primary'
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      return record[dataIndex[0]] && record[dataIndex[1]]
        ? dayjs(value).isBetween(record[dataIndex[0]], record[dataIndex[1]], 'd')
        : false;
    },
    render: (text: any) => text,
  });

  const handleDuplicateEvent = useCallback(
    async (id: string) => {
      await duplicateEvent(id);
      message.success('Event template duplicated');
    },
    [duplicateEvent],
  );

  return (
    <Table
      loading={isLoading}
      dataSource={tableEvents}
      pagination={{
        pageSize: 10,
        total: events?.length,
      }}
      onRow={(record) => {
        return {
          onClick: (e) => {
            if (e.target instanceof HTMLTableCellElement) {
              navigate(`/events/${record.id}/attendees`);
            }
          },
          style: { cursor: 'pointer' },
        };
      }}
      columns={[
        {
          title: 'Auto removed at',
          key: 'removedAt',
          sorter: (a: EventResource, b: EventResource) =>
            new Date(a.archivedAt!).valueOf() - new Date(b.archivedAt!).valueOf(),
          dataIndex: 'archivedAt',
          render: (_: any, event: EventResource) => renderArchivedAt(event),
        },
        {
          title: 'Logo',
          key: 'image',
          dataIndex: 'themeLogoUrl',
          width: 40,
          render: (themeLogoUrl: any) => <Avatar size={50} src={themeLogoUrl} />,
        },
        {
          title: 'Event Name',
          key: 'name',
          sorter: (a: EventResource, b: EventResource) => a.name.localeCompare(b.name),
          dataIndex: 'name',
          render: (_: any, event: EventResource) => renderName(event),
        },
        {
          title: 'Organization',
          key: 'organisation',
          sorter: (a: EventResource, b: EventResource) =>
            (a.organisation?.name || 'zzz').localeCompare(b.organisation?.name || 'zzz'),
          dataIndex: 'organisation',
          render: (_: any, event: EventResource) => {
            return `${event.organisation?.name ?? 'No organization'}`;
          },
        },
        {
          title: 'Event Date(s)',
          key: 'eventdate',
          ...dateFilter(['startsAt', 'endsAt']),
          sorter: (a: EventResource, b: EventResource) =>
            new Date(a.startsAt).valueOf() - new Date(b.startsAt).valueOf(),
          dataIndex: 'startsAt',
          render: (_: any, event: EventResource) =>
            `${dayjs(event.startsAt).format('ddd D MMM')}\n ${dayjs(event.startsAt).format(
              'HH:mm',
            )} - ${dayjs(event.endsAt).format('ddd D MMM')}\n ${dayjs(event.endsAt).format(
              'HH:mm',
            )}`,
        },
        {
          title: 'Platform Date(s)',
          key: 'activedate',
          ...dateFilter(['activeFrom', 'activeTill']),
          sorter: (a: EventResource, b: EventResource) =>
            new Date(a.activeFrom).valueOf() - new Date(b.activeFrom).valueOf(),
          dataIndex: 'activeFrom',
          render: (_: any, event: EventResource) =>
            `${dayjs(event.activeFrom).format('ddd D MMM')}\n ${dayjs(event.activeFrom).format(
              'HH:mm',
            )} - ${dayjs(event.activeTill).format('ddd D MMM')}\n ${dayjs(event.activeTill).format(
              'HH:mm',
            )}`,
        },
        {
          width: 110,
          title: 'Event Status',
          key: 'active',
          dataIndex: 'active',
          render: (_: any, event: EventResource) => renderStatus(event),
          sorter: (a: EventResource, b: EventResource) =>
            getStatusString(a).localeCompare(getStatusString(b)),
          filters: [
            {
              text: 'Current',
              value: 'current',
            },
            {
              text: 'Future',
              value: 'future',
            },
            {
              text: 'Past',
              value: 'past',
            },
          ],
          onFilter: (value: any, record: EventResource) => statusFilter(value, record),
        },
        {
          title: 'Platform Status',
          key: 'active',
          dataIndex: 'active',
          sorter: (a: EventResource, b: EventResource) => Number(a.active) - Number(b.active),
          render: (_: any, event: EventResource) => renderActive(event),
        },
        {
          title: 'Actions',
          key: 'actions',
          dataIndex: 'actions',
          align: 'right' as AlignType,
          width: 170,
          render: (_: any, event: EventResource) => [
            isSuperAdmin && event.isTemplate && (
              <>
                <Tooltip title='Duplicate Event' key='3'>
                  <Button
                    shape='circle'
                    icon={<CopyOutlined />}
                    onClick={() => handleDuplicateEvent(event.id)}
                  />
                </Tooltip>
                <Divider key='d1' type='vertical' />
              </>
            ),
            !archived && (
              <Link to={`${protocol}://${event.slug}.${process.env.REACT_APP_APP_URL}`} key='1'>
                <a target='_blank'>
                  <Tooltip title='Open Event in new tab' key='2'>
                    <Button shape='circle' icon={<EyeOutlined />} />
                  </Tooltip>
                </a>
              </Link>
            ),
            (isSuperAdmin || organisation) && (
              <>
                <Divider key='d2' type='vertical' />
                <Tooltip title={archived ? 'Restore Event' : 'Move to Recycle Bin'} key='recycle'>
                  <Button
                    shape='circle'
                    icon={<InboxOutlined />}
                    onClick={() => (archived ? unarchiveEvent(event.id) : archiveEvent(event.id))}
                  />
                </Tooltip>
              </>
            ),
            archived && (isSuperAdmin || organisation) && (
              <>
                <Divider key='d3' type='vertical' />
                <Popconfirm
                  title='Delete event'
                  key='4'
                  placement='topRight'
                  onConfirm={() => deleteEvent(event.id)}
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </>
            ),
          ],
        },
      ].filter((col) => col.key !== 'removedAt' || archived)}
    />
  );
}
