import React, { useState } from 'react';

import { writeConsent, readConsent } from '@utils/auth';

const CookieConsent: React.FC = () => {
  const [consent, setConsent] = useState(readConsent());

  const onConsent = (status: 'accepted' | 'declined') => {
    writeConsent(status);
    setConsent(status);
  };

  if (consent) return null;

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 10px',
        zIndex: 99999,
        fontSize: 13,
        color: '#f9f9f9',
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <span
        style={{
          marginBottom: '0.5em',
        }}
      >
        We use cookies to allow you to stay logged in and keep track of how you use our platform.
      </span>
      <span className='cookie-bar__actions'>
        <button
          style={{
            color: '#f9f9f9',
            opacity: 0.5,
            marginRight: '1em',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none',
          }}
          className='cookie-bar__decline'
          onClick={() => onConsent('declined')}
        >
          {' '}
          Decline
        </button>
        <button
          style={{
            display: 'inline-block',
            padding: '6px 7px 7px 7px',
            background: 'forestgreen',
            color: 'white',
            borderRadius: '3px',
            lineHeight: '12px',
            cursor: 'pointer',
            border: 'none',
          }}
          onClick={() => onConsent('accepted')}
        >
          Accept
        </button>
      </span>
    </div>
  );
};

export default CookieConsent;
