import { useCallback } from 'react';

import fetch from '@utils/fetch';

import useAuth from './auth/useAuth';

export function usePrintEventPage(eventId: string) {
  const { accessToken } = useAuth();
  return useCallback(
    async (html: string, width: number, height: number): Promise<Blob> => {
      const body = await fetch(`/events/${eventId}/analytics/print`, {
        method: 'POST',
        token: accessToken,
        body: { html, width, height },
      });
      return body.body;
    },
    [accessToken, eventId],
  );
}

export function usePrintPage() {
  const { accessToken } = useAuth();
  return useCallback(
    async (html: string, width: number, height: number): Promise<Blob> => {
      const body = await fetch(`/analytics/print`, {
        method: 'POST',
        token: accessToken,
        body: { html, width, height },
      });
      return body.body;
    },
    [accessToken],
  );
}

export default {
  usePrintPage,
  usePrintEventPage,
};
