import React, { useEffect, useMemo } from 'react';
import { Form, Select, Input, List, Popconfirm, Button, Card, Avatar } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { EventLayout } from '@components/Layout';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import { Announcement } from '@transforms/announcement';
import { UserRolePermission } from '@transforms/userRole';
import { useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent } from '@hooks/useEvents';
import {
  useLastAnnouncement,
  useCreateAnnouncement,
  useDeleteAnnouncement,
} from '@hooks/useAnnoucements';
import { useAttendeeRoles, useRoles } from '@hooks/useRoles';

export default function HostAnnouncements() {
  const [form] = Form.useForm();
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const { data: announcement } = useLastAnnouncement(event?.id);
  const { mutate: createAnnouncement } = useCreateAnnouncement(event?.id);
  const { mutate: deleteAnnouncement } = useDeleteAnnouncement(event?.id);
  const { data: roles } = useRoles(selectedLanguage, event?.id);
  const { data: attendees } = useAttendeeRoles(event?.id);
  // TODO: test if role permissions check works

  const hostRoles = useMemo(
    () =>
      roles
        ?.filter(
          (role) =>
            role.permissions.includes(UserRolePermission.HOST_CHAT) ||
            role.permissions.includes(UserRolePermission.VIDEO_CALL_HOST),
        )
        .map((role) => role.id) ?? [],
    [roles],
  );
  const filteredHosts = useMemo(
    () =>
      attendees?.filter((attendee) => attendee.roleId && hostRoles.includes(attendee.roleId)) ?? [],
    [attendees, hostRoles],
  );

  const onCreateAnnouncement = (values: Announcement) => {
    createAnnouncement(values);
  };

  const onDeleteAnnouncement = (announcementId: string) => {
    deleteAnnouncement(announcementId);
  };

  const renderHosts = () => {
    return filteredHosts.map((host) => (
      <Select.Option key={host.id} value={host.id}>
        {[host.firstName, host.lastName].filter(Boolean).join(' ') || host.id}
      </Select.Option>
    ));
  };

  useEffect(() => {
    form.setFieldsValue(event);
  }, [form, event]);

  return (
    <EventLayout title='Host Announcements' extra={[<EventLanguageSelect key='language-select' />]}>
      <p>
        Host Announcements are displayed at top right of the platform (on all pages) and are visible
        to all attendees.
      </p>
      <p>
        Tip: Use this feature to notify attendees about important updates or general event
        information.
      </p>
      <List itemLayout='horizontal'>
        <Card
          style={{
            maxWidth: '500px',
            backgroundColor: 'black',
            opacity: 0.8,
            borderRadius: 16,
            marginBottom: 24,
          }}
        >
          {announcement ? (
            <List.Item
              key='1'
              style={{ color: 'white' }}
              actions={[
                <Popconfirm
                  title='Are you sure you want to delete this announcement?'
                  onConfirm={() => onDeleteAnnouncement(announcement.id)}
                  okText='Delete'
                  cancelText='Cancel'
                  key='2'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src='' size={60} />}
                title={<b style={{ color: 'white' }}>Announcement</b>}
                description={<div style={{ color: 'white' }}>{announcement.message}</div>}
              />
            </List.Item>
          ) : (
            <div style={{ color: 'white' }}>No announcement set right now</div>
          )}
        </Card>
      </List>
      <Form layout='vertical' onFinish={onCreateAnnouncement}>
        <Form.Item
          name='hostId'
          label={<b>Host</b>}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
        >
          <Select>{renderHosts()}</Select>
        </Form.Item>
        <Form.Item
          name='message'
          label={<b>Announcement</b>}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <br />
        <Button htmlType='submit' type='primary'>
          {announcement !== undefined ? 'Update Announcement' : 'Add Announcement'}
        </Button>
      </Form>
    </EventLayout>
  );
}
