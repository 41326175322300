import { useCallback, useEffect, useState } from 'react';
import { Button, Input, Modal, message, Form, Checkbox } from 'antd';
import dayjs from 'dayjs';

import { MailResource } from '@transforms/mail';
import { useMailNow } from '@hooks/useMails';

export const MailNowModal = ({
  visible,
  eventId,
  mail,
  onClose,
}: {
  visible: boolean;
  eventId: string;
  mail: MailResource;
  onClose(): void;
}) => {
  const { mutateAsync: mailNow } = useMailNow(eventId);
  const [time, setTime] = useState(dayjs().add(1, 'hour').startOf('hour'));
  const [useTime, setUseTime] = useState(false);
  const [minimumTime, setMinimumTime] = useState(dayjs().endOf('minute'));

  useEffect(() => {
    setInterval(() => {
      setMinimumTime(dayjs().endOf('minute'));
    }, 60_000);
  }, []);

  const sendMailNow = useCallback(async () => {
    await mailNow({ id: mail.id, time: useTime && time ? time.toISOString() : undefined });
    message.success(
      useTime && time
        ? `The mail has been successfully queued to be sent at ${time}.`
        : 'The mail has been sent successfully.',
    );
    onClose();
  }, [mail.id, mailNow, onClose, useTime, time]);

  return (
    <Modal title='Send mail now/today' visible={visible} onCancel={onClose} footer={null}>
      <Form onFinish={sendMailNow}>
        <Form.Item name='useTime' valuePropName='checked' noStyle>
          <Checkbox onChange={({ target }) => setUseTime(target.checked)}>
            Use specific time
          </Checkbox>
        </Form.Item>
        <Form.Item
          name='time'
          initialValue={time.format('HH:mm')}
          style={{ flex: 1, marginTop: '5px' }}
        >
          <Input
            type='time'
            style={{ width: '100%', paddingBottom: 0 }}
            min={minimumTime.format('HH:mm')}
            disabled={!useTime}
            onChange={({ target }) => setTime(dayjs(target.value, 'HH:mm'))}
          />
        </Form.Item>
        <b>
          This will send the mail{' '}
          {useTime ? `at ${time.tz('CET').format('HH:mm')} CET` : 'immediately'}, are you sure?
        </b>
        <p>The mail will have the current content, any unsaved changes will not be used.</p>
        <Input.Group compact style={{ marginTop: '20px' }}>
          <Button type='primary' htmlType='submit'>
            Send
          </Button>
        </Input.Group>
      </Form>
    </Modal>
  );
};
