import React, { useState, useEffect } from 'react';
import { Alert, Divider, Button, message } from 'antd';
import { MoreOutlined, UndoOutlined, SaveOutlined } from '@ant-design/icons';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';

import { ListItem } from '@components/Tags';
import { EventLayout } from '@components/Layout';
import { Tag as TagModal } from '@components/Modals';

import { useCurrentEvent } from '@hooks/useEvents';
import {
  useTags,
  useCreateTag,
  useUpdateTag,
  useUpdateTagPositions,
  useDeleteTag,
} from '@hooks/useTags';
import { useLanguage } from '@hooks/useTranslations';

import { Tag, TagResource } from '@transforms/tag';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import reorder from '@utils/reorder';

export default function Tags() {
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);

  const [openTagModal, setOpenTagModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState<TagResource | undefined>();

  const { data: tags } = useTags(event?.id, selectedLanguage);
  const { mutate: createTag } = useCreateTag(event?.id, selectedLanguage);
  const { mutate: updateTag } = useUpdateTag(event?.id, selectedTag?.id, selectedLanguage);
  const { mutate: deleteTag } = useDeleteTag(event?.id);
  const { mutate: updateTagPositions } = useUpdateTagPositions(event?.id);

  const onOpenTagModal = (tag?: TagResource) => {
    if (tag) {
      setSelectedTag(tag);
    } else {
      setSelectedTag(undefined);
    }
    setOpenTagModal(true);
  };

  const onCloseTagModal = () => {
    setOpenTagModal(false);
    setSelectedTag(undefined);
  };

  const onCreateTag = (values: Tag, done: (err?: Error) => void) => {
    createTag(values, {
      onSuccess: async () => {
        onCloseTagModal();
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onUpdateTag = (values: Tag, done: (err?: Error) => void) => {
    updateTag(values, {
      onSuccess: async () => {
        onCloseTagModal();
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onDeleteTag = (id: string) => {
    deleteTag(id);
  };

  const getListItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    index: number,
  ) => {
    const defaultStyles = {
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      cursor: 'inherit',
    };

    // eslint-disable-next-line no-nested-ternary
    const borderTop = isDragging ? '1px solid #f0f0f0' : index > 0 ? '1px solid #f0f0f0' : '';
    const borderBottom = isDragging ? '1px solid #f0f0f0' : '';

    return { ...draggableStyle, ...defaultStyles, borderTop, borderBottom };
  };

  const [initialDraggableItems, setInitialDraggableItems] = useState(tags);
  const [draggableItems, setDraggableItems] = useState(tags);
  const [isDragged, setIsDragged] = useState<boolean>(false);
  useEffect(() => {
    setInitialDraggableItems(tags);
    setDraggableItems(tags);
  }, [tags]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sorted = reorder(draggableItems, result.source.index, result.destination.index);
    setDraggableItems(sorted);
    setIsDragged(true);
    saveReoder();
  };

  const undoReorder = () => {
    setDraggableItems(initialDraggableItems);
    setIsDragged(false);
  };

  const saveReoder = () => {
    if (!draggableItems) {
      message.error('Unable to update page order');
      return;
    }

    updateTagPositions(draggableItems.map((tag, index) => [tag.id, index + 1]));

    setInitialDraggableItems(draggableItems);
    setIsDragged(false);
  };

  return (
    <EventLayout
      title='Interest Tags'
      extra={[
        <Button key='menu' type='primary' onClick={() => onOpenTagModal()}>
          Add Interest Tag
        </Button>,
        <EventLanguageSelect key='language-select' style={{ marginRight: 10 }} />,
      ]}
    >
      <div>
        Add optional descriptors to collect and display attendee passions (for instance, preferred
        animals or global topics). <strong>Interest Tags</strong> can be used as search filters
        among attendees.
      </div>
      <br />
      <div>
        <strong>Interest Tags</strong> can be pre-filled via attendee list CSV import. They will
        appear after any custom fields during the onboarding process and are editable from the
        attendee's user profile.
      </div>
      <br />
      {isDragged && (
        <div style={{ maxWidth: '300px', marginBottom: '20px' }}>
          <Alert type='warning' message='Order has been changed!' showIcon />

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Button block icon={<UndoOutlined />} onClick={undoReorder}>
              Undo
            </Button>
            <Divider key='d1' type='vertical' />
            <Button block type='primary' icon={<SaveOutlined />} onClick={saveReoder}>
              Save
            </Button>
          </div>
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='custom-fields'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {draggableItems?.map((tag, index) => (
                <Draggable key={tag.id} draggableId={tag.id} index={index}>
                  {(providedDeep, snapshot) => (
                    <div
                      {...providedDeep.draggableProps}
                      {...providedDeep.dragHandleProps}
                      ref={providedDeep.innerRef}
                      style={getListItemStyle(
                        snapshot.isDragging,
                        providedDeep.draggableProps.style,
                        index,
                      )}
                    >
                      <MoreOutlined style={{ marginRight: '10px', cursor: 'grab' }} />
                      <ListItem
                        tag={tag}
                        onDelete={onDeleteTag}
                        onEdit={() => onOpenTagModal(tag)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <TagModal
        visible={openTagModal}
        data={selectedTag}
        onCancel={onCloseTagModal}
        onCreate={onCreateTag}
        onUpdate={onUpdateTag}
      />
    </EventLayout>
  );
}
