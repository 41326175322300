import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout, Menu, Button, Tag, Space, message, PageHeader, Divider } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';

import Page from '@components/Page';
import AddNewPageDrawer from '@components/Page/AddNewPageDrawer';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import {
  usePages,
  useUpdatePage,
  useCreatePage,
  useDeletePage,
  useUploadCanvasImage,
} from '@hooks/usePages';
import useAuth from '@hooks/auth/useAuth';
import { useEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';

import { PageResource, PageTemplate } from '@transforms/page';

export default function PageTemplates() {
  const { selectedLanguage } = useLanguage();
  const [selectedPage, setSelectedPage] = useState<PageResource | undefined>();
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId?: string }>();

  const { isSuperAdmin } = useAuth();
  const { data: pages } = usePages();
  const { data: event } = useEvent(eventId);
  const { mutate: createPage } = useCreatePage(eventId, selectedLanguage);
  const { mutateAsync: updatePage } = useUpdatePage(eventId, selectedPage?.id, selectedLanguage);
  const { mutate: deletePage } = useDeletePage(eventId);
  const { mutateAsync: uploadCanvasImage } = useUploadCanvasImage(eventId);

  useEffect(() => {
    if (!isSuperAdmin) navigate(window.location.pathname.replace('page-templates', 'pages'));
    if (event?.isPageTemplate === false)
      navigate(window.location.pathname.replace('page-templates', 'pages'));
  }, [isSuperAdmin, navigate, event?.isPageTemplate]);

  useEffect(() => {
    if (!pages) {
      return;
    }

    const data = pages?.find((page) => page.id === selectedPage?.id);
    if (data === undefined) {
      setSelectedPage(pages?.[0]);
    } else {
      setSelectedPage(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const selectPage = (pageKey: string) => {
    setSelectedPage(pages?.find((page) => page.id === pageKey));
  };

  const onUpdatePage = async ({ dates, ...values }: PageResource & { dates: string[] }) => {
    const menuPosition = { menuPosition: values.menuPosition };
    if (!values.menuPosition) {
      menuPosition.menuPosition = null;
    }
    const formattedDates = dates
      ? { visibleFrom: dates[0], visibleTill: dates[1] }
      : { visibleFrom: null, visibleTill: null };

    const upload: Record<number, File> = {};
    if (values.template === PageTemplate.CANVAS) {
      // eslint-disable-next-line no-param-reassign
      values.parameters.images = (values.parameters.images as Array<string | File | null>).map(
        (image, index) => {
          if (!image) return null;
          if (image instanceof File) upload[index] = image;
          return '__MAINTAIN__';
        },
      );
    }

    await updatePage({
      ...selectedPage,
      ...values,
      ...menuPosition,
      ...formattedDates,
    });

    if (values.template === PageTemplate.CANVAS) {
      if (selectedPage) {
        await Promise.all(
          Object.entries(upload).map(async ([index, file]) =>
            uploadCanvasImage({
              pageId: selectedPage.id,
              image: file,
              imageIndex: Number.parseInt(index, 10),
            }),
          ),
        );
      } else {
        message.error('Unable to update canvas images');
      }
    }

    message.success({
      content: (
        <div className='message-box-content-container'>
          <div className='message-box-content'>
            <h3>Page Updated!</h3>
            <span>Your changes have been successfully updated and saved!</span>
          </div>
        </div>
      ),
      className: 'message-box',
    });
  };

  const onCreatePage = ({ dates, ...values }: PageResource & { dates: any }) => {
    const formattedDates = dates
      ? { visibleFrom: dates[0], visibleTill: dates[1] }
      : { visibleFrom: null, visibleTill: null };
    const parameters = values.parameters || {};

    createPage(
      { ...values, parameters, ...formattedDates },
      {
        onSuccess: async () => {
          toggleDrawer();
        },
      },
    );
  };

  const onDeletePage = (pageId: string) => {
    deletePage(pageId);
  };

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader
        title='Pages templates'
        extra={
          <Space>
            <EventLanguageSelect />
          </Space>
        }
      />
      <Divider key='d1' />
      <Layout>
        <Layout.Sider width={256}>
          <Menu
            style={{ width: 256 }}
            onClick={({ key }) => selectPage(key as string)}
            selectedKeys={[selectedPage?.id!]}
          >
            {pages?.map((page) => (
              <Menu.Item key={page.id}>
                <Space>
                  {!page.enabled && <EyeInvisibleOutlined />}
                  {page.title}
                  <Tag>{page.template}</Tag>
                </Space>
              </Menu.Item>
            ))}
          </Menu>
          <Button type='primary' block onClick={toggleDrawer}>
            New Page
          </Button>
        </Layout.Sider>
        {selectedPage && (
          <Page
            page={selectedPage}
            onFinish={onUpdatePage}
            onDelete={onDeletePage}
            language={selectedLanguage}
            title={selectedPage.title}
            key={selectedPage.id}
          />
        )}
      </Layout>
      <AddNewPageDrawer
        templatesEnabled={false}
        visible={showDrawer}
        toggleDrawer={toggleDrawer}
        onFinish={onCreatePage}
      />
    </Layout.Content>
  );
}
