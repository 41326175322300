import { useQuery, useMutation, useQueryClient } from 'react-query';

import transformMessage from '@transforms/message';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';

export const useGlobalChat = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['chat', 'global'],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(`/events/${eventId}/chats`, { token: accessToken });

      return transformMessage.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useWipeChat = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    () => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/chats/wipe`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('chat');
      },
    },
  );
};

export const useExportChat = (eventId?: string) => {
  const { accessToken } = useAuth();

  const exportChat = async () => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(`/events/${eventId}/chats/csv/export`, {
      token: accessToken,
      headers: {
        Acccept: 'text/csv',
        'Content-Type': 'text/csv',
      },
    });

    const blob = new Blob([body], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `chat-${+new Date()}.csv`;
    link.click();
  };

  return {
    exportChat,
  };
};

export default {
  useGlobalChat,
  useWipeChat,
  useExportChat,
};
