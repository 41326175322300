import { useState, useEffect, useCallback } from 'react';
import { Button, Form, Input, InputNumber, Select, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ImageUpload } from '@components/Upload';
import { VideoResource, VideoType } from '@transforms/video';

import { DataEntry, DataEntryProps } from './DataEntry';
import { PreviewBreakout } from './PreviewBreakout';

export interface VideoProps extends DataEntryProps {
  data?: VideoResource;
  eventId?: string;
}

export const VideoTypes = [
  { value: VideoType.VIXY, label: 'Vixy' },
  { value: VideoType.VIMEO, label: 'Vimeo' },
  { value: VideoType.HLS, label: 'HLS' },
  { value: VideoType.OTHER, label: 'Other' },
];

export function Video({ onCreate, onUpdate, ...props }: VideoProps) {
  const { data } = props;
  const [form] = Form.useForm();
  const [videoType, setVideoType] = useState(data?.type);
  const [imagePreview, setImagePreview] = useState<string | null>(data?.imageUrl ?? null);
  const [showPreviewBreakoutModal, setShowPreviewBreakoutModal] = useState<boolean>(false);

  useEffect(() => {
    if (data?.type) setVideoType(data.type);
    if (data?.imageUrl) setImagePreview(data.imageUrl);
  }, [data, setVideoType, setImagePreview]);

  const renderVideoTypeFields = () => {
    switch (videoType) {
      case VideoType.VIXY:
        return (
          <>
            <Form.Item
              name='vixyPlayerId'
              label={<b>VIXY UICONF ID</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='Enter the uiconf_id (ex. 12345678).' />
            </Form.Item>
            <Form.Item name='vixyEntryId' label={<b>VIXY Entry ID</b>} rules={[{ required: true }]}>
              <Input placeholder='Enter the entry_id (ex. 0_12a34bc5).' />
            </Form.Item>
            <Form.Item
              name='vixyPartnerId'
              label={<b>VIXY Partner ID</b>}
              rules={[{ required: true }]}
            >
              <Input placeholder='Enter the partner_id (ex. 123).' />
            </Form.Item>
          </>
        );
      case VideoType.VIMEO:
        return (
          <>
            <Form.Item name='vimeoVideoId' label='Vimeo video_id' rules={[{ required: true }]}>
              <Input placeholder='Enter the video_id (ex. 12345678).' />
            </Form.Item>
          </>
        );
      case VideoType.HLS:
        return (
          <>
            <Form.Item name='hlsUrl' label='HLS URL' rules={[{ required: true }]}>
              <Input placeholder='Paste your HLS URL here.' />
            </Form.Item>
          </>
        );
      case VideoType.OTHER:
        return (
          <>
            <Form.Item name='embedUrl' label='Video URL' rules={[{ required: true }]}>
              <Input placeholder='The URL (web address) to embed.' />
            </Form.Item>
          </>
        );
      default:
        return null;
    }
  };

  const onCreateCallback = useCallback(
    (values: any, done: () => void) =>
      onCreate({ ...values, imageUrl: imagePreview ? undefined : null }, () => {
        setVideoType(undefined);
        done();
      }),
    [onCreate, imagePreview],
  );

  const onUpdateCallback = useCallback(
    (values: any, done: () => void) =>
      onUpdate({ ...values, imageUrl: imagePreview ? undefined : null }, () => {
        setVideoType(undefined);
        done();
      }),
    [onUpdate, imagePreview],
  );

  return (
    <DataEntry
      {...props}
      form={form}
      name='Video'
      onCreate={onCreateCallback}
      onUpdate={onUpdateCallback}
    >
      <Form.Item name='position' label={<b>Position</b>}>
        <Input type='number' />
      </Form.Item>
      <Form.Item name='title' label={<b>Video Title</b>} rules={[{ required: true }]}>
        <Input placeholder='Video Title' />
      </Form.Item>
      <Form.Item
        name='subtitle'
        label={
          <>
            <b style={{ marginRight: 8 }}>Video Subtitle</b>
            <Tooltip title='Note, this does not mean closed captions!'>
              <ExclamationCircleOutlined style={{ color: '#faad14' }} />
            </Tooltip>
          </>
        }
        rules={[{ required: true }]}
      >
        <Input placeholder='Video Subtitle' />
      </Form.Item>
      <Form.Item name='description' label={<b>Video Description</b>} rules={[{ required: false }]}>
        <Input.TextArea
          placeholder='A short desciption of this video.'
          showCount
          maxLength={1000}
        />
      </Form.Item>

      <Form.Item name='duration' label={<b>Duration (minutes)</b>} rules={[{ required: true }]}>
        <InputNumber placeholder='(12)' />
      </Form.Item>
      <Form.Item name='image' label='Image'>
        <ImageUpload
          initialPreview={imagePreview}
          onChangePreview={setImagePreview}
          onRemove={() => form.setFields([{ name: 'image', value: null }])}
          extraButtons={
            <>
              <Button disabled={!imagePreview} onClick={() => setShowPreviewBreakoutModal(true)}>
                Preview
              </Button>
              {/* TODO: CHECK THIS OUT */}
              <PreviewBreakout
                visible={showPreviewBreakoutModal}
                formData={form.getFieldsValue()}
                imagePreview={imagePreview}
                onCancel={() => setShowPreviewBreakoutModal(false)}
              />
            </>
          }
        />
      </Form.Item>
      <Form.Item name='type' label={<b>Video Provider</b>} rules={[{ required: true }]}>
        <Select
          defaultActiveFirstOption={false}
          defaultValue={videoType}
          options={VideoTypes}
          onChange={(value) => {
            setVideoType(value);
          }}
        />
      </Form.Item>
      {renderVideoTypeFields()}
    </DataEntry>
  );
}
