import { useEffect, useRef, useState } from 'react';

export function LazyModal({
  children,
  open,
  delay = 500,
}: {
  children: (open: boolean) => React.ReactElement;
  open: boolean;
  delay?: number;
}) {
  const [mockOpen, setMockOpen] = useState(false);
  const renderCache = useRef<React.ReactElement | null>(null);

  useEffect(() => {
    if (open) setMockOpen(true);
    else setTimeout(() => setMockOpen(false), delay);
  }, [open, delay]);

  renderCache.current =
    open || mockOpen ? children(open !== false ? mockOpen : false) : renderCache.current;

  return open || mockOpen ? renderCache.current : null;
}
