import { MailTargetGroup, MailType } from '@transforms/mail';

export interface Mail {
  type: MailType;
  title: string;
  maxLength?: number;
  placeholder?: string;
  alwaysEnabled?: boolean;
  hasTargetGroup?: boolean;
  description: string;
  senderName: string | undefined;
  subject: string | undefined;
  useLayout?: boolean;
}

export const targetGroups = [
  {
    key: MailTargetGroup.ALL,
    label: 'All subscribers (excluding non-approved)',
  },
  {
    key: MailTargetGroup.VISITORS,
    label: 'Visitors (approved attendees who have signed in at least once)',
  },
  {
    key: MailTargetGroup.NON_VISITORS,
    label: "Non-Visitors (approved attendees who haven't yet signed in)",
  },
  {
    key: MailTargetGroup.ATTENDEES,
    label:
      'Attendees (Approved subscribers who have signed in at least once during the event dates)',
  },
  {
    key: MailTargetGroup.NON_APPROVED,
    label: 'Unapproved (Attendees that are still peding approval)',
  },
] as const;

export const mailsData: Mail[] = [
  {
    type: MailType.MAGIC_LINK,
    title: 'Login Link',
    placeholder:
      'Click the button below to access the event. You can use this link at any time; it is your personal login link.',
    alwaysEnabled: true,
    description:
      'This is the email that contains the personal login link. Note, there can only be one login link email for all attendees.',
    senderName: undefined,
    subject: undefined,
  },
  {
    type: MailType.EVENT_WELCOME,
    title: 'Registration Confirmation',
    placeholder: 'Welcome to the event.',
    alwaysEnabled: true,
    description:
      'This is the email that will be sent whenever an attendee self-registers via the platform. Note, there can only be one registration confirmation email for all attendees.',
    senderName: undefined,
    subject: undefined,
  },
  {
    type: MailType.EVENT_REMINDER,
    title: 'Event Reminder',
    placeholder: 'The event will soon be live.',
    hasTargetGroup: true,
    description:
      'This is the email that can be sent before the event start date. \nTip: Create multiple event reminder emails and customize per target group.',
    senderName: undefined,
    subject: undefined,
    useLayout: false,
  },
  {
    type: MailType.EVENT_FOLLOWUP,
    title: 'Event Follow-up',
    placeholder: 'Thanks for attending the event!',
    hasTargetGroup: true,
    description:
      'This is the email that can be sent after the event has ended. \nTip: Create multiple event reminder emails and customize per target group.',
    senderName: undefined,
    subject: undefined,
    useLayout: false,
  },
];
