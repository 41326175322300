/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import { PageHeader, Layout, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useProtected } from '@hooks/useProtected';
import { useAcademyPage, useUpdateAcademyPage } from '@hooks/useAcademyPages';
import { RichTextEditor } from '@components/RichTextEditor';

export default function EditAcademyPage() {
  useProtected();
  const navigate = useNavigate();
  const { isLoading, data } = useAcademyPage();
  const [html, setHtml] = useState<string>('');
  const { mutate: updateAcademyPage } = useUpdateAcademyPage();

  useEffect(() => {
    if (data) setHtml(data.content ?? '');
  }, [data]);

  const onUpdateOrganisation = useCallback(() => {
    updateAcademyPage(
      { content: html },
      {
        onSuccess: () => {
          navigate('/academy');
        },
      },
    );
  }, [html, navigate, updateAcademyPage]);

  if (isLoading || typeof data?.content !== 'string') return null;

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader
        title='Edit Academy Page'
        extra={
          <Button type='primary' onClick={onUpdateOrganisation}>
            Save
          </Button>
        }
      />
      <hr className='academy' />
      <Col style={{ margin: '25px 0' }}>
        <RichTextEditor
          value={html}
          onChange={(val) => setHtml(val ?? '')}
          disableFont
          disableColor
          placeholder='Enter content here'
          minHeight={300}
          embedsAllowed
        />
      </Col>
      <PageHeader title='Edit Example' />
      <hr className='academy' />
      <Col>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Col>
    </Layout.Content>
  );
}
