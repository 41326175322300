import React, { useEffect, useState } from 'react';
import {
  Form,
  Drawer,
  Button,
  Divider,
  Select,
  Space,
  message,
  Input,
  Switch,
  Row,
  Modal,
  Checkbox,
} from 'antd';
import dayjs from 'dayjs';
import { WarningOutlined } from '@ant-design/icons';

import { breakText } from '@utils/breakText';
import { ImageUpload } from '@components/Upload';
import { RichTextEditor } from '@components/RichTextEditor';
import { Mail, MailLayoutType, MailTargetGroup, MailType } from '@transforms/mail';
import { useCurrentEvent } from '@hooks/useEvents';

import Handlebars from './Handlebars';
import * as Mails from './mails';

export type AddNewMailDrawerProps = {
  visible: boolean;
  toggleDrawer: () => void;
  onFinish: (
    values: Omit<Mail, 'layout'> & {
      layout?: Omit<Mail['layout'], 'imageUrl'> & { image: File | string | null };
    } & { days?: number },
  ) => Promise<any>;
};

const mailsData = Mails.mailsData.filter((data) => !data.alwaysEnabled);

function AddNewMailDrawer({ toggleDrawer, visible, onFinish }: AddNewMailDrawerProps) {
  const [form] = Form.useForm<{
    type: MailType;
    targetGroup: MailTargetGroup;
    senderName: string | undefined;
    subject: string | undefined;
    addToCalendar: boolean;
    days: number;
    time: string;
    useLayout: boolean;
  }>();
  const { data: event } = useCurrentEvent();

  const availableTargetGroups = Mails.targetGroups.filter((group) =>
    form.getFieldValue('type') && form.getFieldValue('type') === MailType.EVENT_REMINDER
      ? group.key !== MailTargetGroup.ATTENDEES
      : true,
  );

  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [addToCalendar, setAddToCalendar] = useState(false);
  const [mailData, setMailData] = useState<Mails.Mail>(mailsData[0]);
  const [daysValue, setDaysValue] = useState(1);
  const [layoutType, setLayoutType] = useState(MailLayoutType.LEFT_IMAGE);
  const [layoutText, setLayoutText] = useState('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [useLayout, setUseLayout] = useState<boolean>(false);

  const [time, setTime] = useState(dayjs().add(1, 'hour').startOf('hour'));
  const [handlebar, setHandlebar] = useState<null | string>();

  const cancelButtonClicked = () => {
    toggleDrawer();
    setValue('');
    setMailData(mailsData[0]);
    form.setFieldsValue({ type: mailsData[0].type, days: 1 });
  };

  const onDismiss = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        width: 'fit-content',
        icon: <WarningOutlined />,
        title: 'Unsaved Changes!',
        content: (
          <>
            <p>There are unsaved changes! </p>
            <p>Are you sure you want to exit without saving?</p>
            <p>
              Choose 'Cancel' to return to the editor and save changes.
              <br />
              Choose 'Discard' to exit without saving changes. Note, any unsaved changes will be
              lost.
            </p>
          </>
        ),
        cancelText: 'Cancel',
        onCancel: () => {},
        okText: 'Discard',
        onOk: cancelButtonClicked,
        okButtonProps: {
          danger: true,
        },
      });
    } else {
      cancelButtonClicked();
    }
  };

  const onSubmit = async ({
    days,
    image = null,
    ...values
  }: Omit<Mail, 'value' | 'properties' | 'layout'> & {
    days?: number;
    time?: string;
    image?: File | null;
    layout?: Omit<Mail['layout'], 'imageUrl'> & { image?: File };
  }) => {
    if (useScheduling && !time.isValid()) {
      message.error('Please enter a valid scheduled time');
      return;
    }
    setSubmitting(true);
    await onFinish({
      ...values,
      value,
      properties: {
        days,
        time: useScheduling ? time.toISOString() : undefined,
        useScheduling,
        addToCalendar,
      },
      layout: useLayout
        ? {
            type: layoutType,
            text: layoutText,
            image,
          }
        : undefined,
    });
    setSubmitting(false);
    cancelButtonClicked();
    message.success('Created mail');
  };

  const [textEditor, setTextEditor] = useState(true);
  const [useScheduling, setUseScheduling] = useState(true);

  useEffect(() => {
    setTextEditor(false);
    setTimeout(() => setTextEditor(true), 0);
  }, [mailData]);

  // const scheduledTimeGMT = dayjs(
  //   mailData.type === MailType.EVENT_REMINDER ? event?.startsAt : event?.endsAt,
  // )
  //   .add(mailData.type === MailType.EVENT_REMINDER ? daysValue * -1 : daysValue, 'days')
  //   .set('hours', time.get('hours'))
  //   .set('minutes', time.get('minutes'))
  //   .tz('GMT')
  //   .format('dddd DD MMMM YYYY|HH:mm')
  //   .split('|');

  const scheduledTimeCET = dayjs(
    mailData.type === MailType.EVENT_REMINDER ? event?.startsAt : event?.endsAt,
  )
    .add(mailData.type === MailType.EVENT_REMINDER ? daysValue * -1 : daysValue, 'days')
    .set('hours', time.get('hours'))
    .set('minutes', time.get('minutes'))
    .tz('CET')
    .format('dddd DD MMMM YYYY|HH:mm')
    .split('|');

  return (
    <Drawer
      title={`Add new mail (${
        mailData.type === MailType.EVENT_REMINDER ? 'Reminder' : 'Follow-Up'
      })`}
      width='50%'
      onClose={onDismiss}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
            float: 'right',
          }}
        >
          <Button
            onClick={() => cancelButtonClicked()}
            disabled={submitting}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (daysValue < 1) {
                message.error('Please select 1 or more days');
                return;
              }
              form.submit();
            }}
            loading={submitting}
            htmlType='submit'
            type='primary'
          >
            Add Email
          </Button>
        </div>
      }
    >
      <Form form={form} layout='vertical' onFinish={(values) => onSubmit(values)}>
        <Form.Item name='type' label={<b>Type</b>} initialValue={mailData.type}>
          <Select
            style={{ width: '100%' }}
            filterOption={false}
            onChange={(mailType) => setMailData(mailsData.find((data) => data.type === mailType)!)}
          >
            {mailsData.map((data) => (
              <Select.Option value={data.type} key={data.type}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <p>{breakText(mailData.description)}</p>

        <Divider key='d1' />

        {mailData.type !== MailType.EVENT_FOLLOWUP && (
          <Row>
            <Form.Item
              name='addToCalendar'
              initialValue={addToCalendar}
              style={{ margin: '-4px 0 0' }}
              valuePropName='checked'
            >
              <Checkbox
                onChange={({ target }) => {
                  setAddToCalendar(target.checked);
                  form.setFieldsValue({ addToCalendar: target.checked });
                }}
                style={{ marginRight: '-8px' }}
              >
                <b>Include "Add to calendar" button</b>
              </Checkbox>
            </Form.Item>
          </Row>
        )}

        {mailData.hasTargetGroup && (
          <Form.Item
            name='targetGroup'
            label={<b>Target Group</b>}
            initialValue={availableTargetGroups[0].key}
          >
            <Select style={{ width: '100%' }} filterOption={false}>
              {availableTargetGroups.map((data) => (
                <Select.Option value={data.key} key={data.key}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {(mailData.type === MailType.EVENT_REMINDER ||
          mailData.type === MailType.EVENT_FOLLOWUP) && (
          <>
            <Row justify='space-between'>
              <b>
                {mailData.type === MailType.EVENT_REMINDER
                  ? 'Delivery Date (at least 1 day before event starts)'
                  : 'Days after end event date to notify (1 day or more)'}
              </b>
              <Form.Item
                name='useScheduling'
                initialValue={useScheduling}
                style={{ margin: '-4px 0 0' }}
                valuePropName='checked'
              >
                <Checkbox
                  onChange={({ target }) => {
                    setUseScheduling(target.checked);
                    if (!time.isValid()) {
                      const date = dayjs().add(1, 'hour').startOf('hour');
                      setTime(date);
                      form.setFieldsValue({ time: date.format('HH:mm') });
                    }
                  }}
                  style={{ marginRight: '-8px' }}
                >
                  Enable scheduling
                </Checkbox>
              </Form.Item>
            </Row>
            {daysValue < 1 && (
              <div>
                <h5 style={{ color: 'red' }}>This is a required field</h5>
              </div>
            )}
            <Row style={{ margin: '2px 0 -20px' }}>
              <Form.Item name='days' initialValue={1} style={{ flex: 1 }}>
                <Input
                  onChange={({ target }) => setDaysValue(Number(target.value))}
                  type='number'
                  min={1}
                  style={{ width: '100%', padding: '7.5px 11px' }}
                  disabled={!useScheduling}
                />
              </Form.Item>
              <span style={{ padding: '6px 12px 0' }}>at</span>
              <Form.Item name='time' initialValue={time.format('HH:mm')} style={{ flex: 1 }}>
                <Input
                  type='time'
                  style={{
                    width: '100%',
                    padding: '5px 11px 0',
                    border: time.isValid() || !useScheduling ? undefined : '1px solid red',
                  }}
                  disabled={!useScheduling}
                  onChange={({ target }) =>
                    setTime(
                      dayjs()
                        .set('hours', Number.parseInt(target.value.split(':')[0], 10))
                        .set('minutes', Number.parseInt(target.value.split(':')[1], 10)),
                    )
                  }
                />
              </Form.Item>
            </Row>
            {useScheduling ? (
              <Row style={{ margin: '10px 0 10px' }}>
                {time.isValid() ? (
                  <>
                    This email will be sent on&nbsp;
                    <b>{scheduledTimeCET[0]}</b>&nbsp;at&nbsp;
                    <b>{scheduledTimeCET[1]} CET</b>
                  </>
                ) : (
                  <p>
                    The email&nbsp;<b>will not</b>&nbsp;be sent. Please enter a valid time.
                  </p>
                )}
              </Row>
            ) : null}
          </>
        )}

        <Divider key='d2' />

        <Form.Item name='senderName' label={<b>Sender</b>} initialValue={mailData.senderName}>
          <Input placeholder='Sender' />
        </Form.Item>

        <Form.Item name='subject' label={<b>Subject</b>} initialValue={mailData.subject}>
          <Input placeholder='Subject' />
        </Form.Item>

        {textEditor && (
          <>
            <Handlebars onUpdateText={(hbar) => setHandlebar(hbar)} />
            <RichTextEditor
              minHeight={250}
              placeholder={mailData.placeholder}
              value={value}
              onChange={(text) => setValue(text || '')}
              insertHandleBar={handlebar}
              clearHandleBar={() => setHandlebar(null)}
            />
          </>
        )}

        <div>
          <Space style={{ marginTop: '24px' }}>
            <Form.Item name='useLayout' label={<b>Add Layout</b>} initialValue={mailData.useLayout}>
              <Switch title='Use layout' onChange={(val) => setUseLayout(val)} />
            </Form.Item>
          </Space>
          {useLayout && (
            <div>
              <br />
              <h3>Image Alignment</h3>
              <Select
                style={{ marginTop: '8px', marginBottom: '24px', width: '100%' }}
                filterOption={false}
                value={layoutType}
                onChange={setLayoutType}
              >
                <Select.Option value={MailLayoutType.LEFT_IMAGE}>Left Image</Select.Option>
                <Select.Option value={MailLayoutType.RIGHT_IMAGE}>Right Image</Select.Option>
                <Select.Option value={MailLayoutType.FULL_WIDTH_IMAGE}>
                  Full Width Image
                </Select.Option>
              </Select>

              {/* TODO: add preview? */}
              {layoutType === MailLayoutType.LEFT_IMAGE && (
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <Form.Item name='image' style={{ maxWidth: '230px' }}>
                    <ImageUpload
                      initialPreview={imagePreview}
                      onChangePreview={setImagePreview}
                      onRemove={() => form.setFields([{ name: 'image', value: null }])}
                      textOverwrite='The maximum file size is 5 MB and must be at least 250x200 pixels in either JPG or PNG formats.'
                    />
                  </Form.Item>
                  <div style={{ flex: 1, marginLeft: '1rem' }}>
                    <RichTextEditor
                      minHeight={250}
                      placeholder={mailData.placeholder}
                      value={layoutText}
                      onChange={(val) => setLayoutText(val || '')}
                      maxLength={1000}
                    />
                  </div>
                </div>
              )}
              {layoutType === MailLayoutType.RIGHT_IMAGE && (
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    <RichTextEditor
                      minHeight={250}
                      placeholder={mailData.placeholder}
                      value={layoutText}
                      onChange={(val) => setLayoutText(val || '')}
                      maxLength={1000}
                    />
                  </div>
                  <Form.Item name='image' style={{ maxWidth: '230px' }}>
                    <ImageUpload
                      initialPreview={imagePreview}
                      onChangePreview={setImagePreview}
                      onRemove={() => form.setFields([{ name: 'image', value: null }])}
                      textOverwrite='The maximum file size is 5 MB and must be at least 250x200 pixels in either JPG or PNG formats.'
                    />
                  </Form.Item>
                </div>
              )}
              {layoutType === MailLayoutType.FULL_WIDTH_IMAGE && (
                <Form.Item name='image'>
                  <ImageUpload
                    initialPreview={imagePreview}
                    onChangePreview={setImagePreview}
                    onRemove={() => form.setFields([{ name: 'image', value: null }])}
                    textOverwrite='The maximum file size is 5 MB and must be at least 500x200 pixels in either JPG or PNG formats.'
                  />
                </Form.Item>
              )}
            </div>
          )}
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewMailDrawer;
