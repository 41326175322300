import React, { useState } from 'react';
import { Layout, PageHeader, Table } from 'antd';
import type { ColumnGroupType } from 'antd/lib/table';

import { useProtected } from '@hooks/useProtected';
import { useJobs } from '@hooks/useJobs';
import type { Job, JobResource } from '@transforms/job';

export default function Jobs() {
  useProtected({ checkSuperAdmin: true });
  const [sorter, setSorter] = useState<{
    field?: keyof Job;
    order?: 'ascend' | 'descend';
  }>({});
  const { isLoading, data: jobs } = useJobs({
    page: 1,
    limit: 30,
    sorter,
  });

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader title='Jobs' />
      <Table
        loading={isLoading}
        dataSource={jobs?.items}
        style={{ marginTop: 10 }}
        onChange={(_, __, sort: any) => setSorter(sort)}
        rowKey='id'
        columns={
          (
            [
              {
                title: 'Job ID',
                key: 'jobId',
                sorter: true,
                dataIndex: 'jobId',
              },
              {
                title: 'Type',
                key: 'type',
                dataIndex: 'type',
              },
              {
                title: 'Run at',
                key: 'runAt',
                sorter: true,
                dataIndex: 'runAt',
              },
              {
                title: 'Locked at',
                key: 'lockedAt',
                sorter: true,
                dataIndex: 'lockedAt',
              },
            ] as any as Array<boolean | ColumnGroupType<JobResource>>
          ).filter(Boolean) as ColumnGroupType<JobResource>[]
        }
      />
    </Layout.Content>
  );
}
