import { useCallback, useEffect, useRef } from 'react';
import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';

function useIsMounted() {
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return () => isMountedRef.current;
}

export function useDebounce<Callback extends (...args: any[]) => any>(
  callback: Callback,
  delay: number,
): DebouncedFunc<Callback> {
  const inputsRef = useRef({ callback, delay });
  const isMounted = useIsMounted();

  // use mutable ref to make useCallback not depend on `callback` dependency
  useEffect(() => {
    inputsRef.current = { callback, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      ((...args) => {
        // Debounce is an async callback. Cancel it, if in the meanwhile component has been unmounted or delay has changed
        if (inputsRef.current.delay === delay && isMounted()) {
          inputsRef.current.callback(...(args as []));
        }
      }) as Callback,
      delay,
    ),
    [delay],
  );
}
