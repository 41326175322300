import React from 'react';
import { Col, Layout, PageHeader } from 'antd';

import { useProtected } from '@hooks/useProtected';

export default function ViewAcademyPage() {
  useProtected();

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader title='Support' />
      <hr className='academy' />
      <Col style={{ marginTop: '15px' }}>
        <embed
          type='text/html'
          src='https://vlep-superadmin.helpscoutdocs.com/'
          width='100%'
          height='1000'
        />
      </Col>
    </Layout.Content>
  );
}
