import React, { useEffect, useState } from 'react';
import { Layout, Button, message, Input, PageHeader, Tabs } from 'antd';
import dayjs from 'dayjs';

import EventTable from '@components/Events/table';
import { Event as EventModal } from '@components/Modals';

import useAuth from '@hooks/auth/useAuth';
import useProtected from '@hooks/useProtected';
import { Event, EventResource } from '@transforms/event';
import { useEvents, useCreateEvent, useArchivedEvents, useTemplateEvents } from '@hooks/useEvents';

type ActiveTab = '1' | '2' | '3';

const compare = (a: EventResource, b: EventResource) => {
  if (a.activeFrom && b.activeFrom) {
    if (dayjs(a.activeFrom).isAfter(b.activeFrom)) {
      return 1;
    }
    if (dayjs(a.activeFrom).isBefore(b.activeFrom)) {
      return -1;
    }
  }

  if (a.startsAt && b.startsAt) {
    if (dayjs(a.startsAt).isAfter(b.startsAt)) {
      return 1;
    }
    if (dayjs(a.startsAt).isBefore(b.startsAt)) {
      return -1;
    }
  }

  return 0;
};

compare.opposite = (a: EventResource, b: EventResource) => compare(b, a);

export default function Events() {
  useProtected();
  const { isSuperAdmin, organisation } = useAuth();
  const { isLoading, data: events } = useEvents();
  const { isLoading: archiveLoading, data: archivedEvents } = useArchivedEvents();
  const { isLoading: templatesLoading, data: templateEvents } = useTemplateEvents();
  const { mutate: createEvent } = useCreateEvent();
  const [openEventModal, setOpenEventModal] = useState(false);
  const [hasConflict, setHasConflict] = useState<string | undefined>(undefined);
  const [failedValidation, setFailedValidation] = useState(false);
  const [activeTab, setActiveTab] = useState<ActiveTab>('1');

  const [filteredEvents, setFilteredEvents] = useState<EventResource[] | undefined>();
  const [filteredArchivedEvents, setFilteredArchivedEvents] = useState<
    EventResource[] | undefined
  >();
  const [filteredTemplateEvents, setFilteredTemplateEvents] = useState<
    EventResource[] | undefined
  >();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!searchTerm) {
      setFilteredEvents(undefined);
      setFilteredArchivedEvents(undefined);
      setFilteredTemplateEvents(undefined);
    } else {
      setFilteredEvents(
        events?.filter((event) => {
          return event?.name.toLowerCase().includes(searchTerm.toLowerCase());
        }),
      );
      setFilteredArchivedEvents(
        archivedEvents?.filter((event) => {
          return event?.name.toLowerCase().includes(searchTerm.toLowerCase());
        }),
      );
      setFilteredTemplateEvents(
        templateEvents?.filter((event) => {
          return event?.name.toLowerCase().includes(searchTerm.toLowerCase());
        }),
      );
    }
  }, [activeTab, events, archivedEvents, templateEvents, searchTerm]);

  const onOpenEventModal = () => {
    setOpenEventModal(true);
  };

  const onCloseEventModal = () => {
    setHasConflict(undefined);
    setOpenEventModal(false);
  };

  const onCreateEvent = (values: Event, done: (err?: Error) => void) => {
    createEvent(
      { ...values, defaultLanguage: 'en' },
      {
        onSuccess: () => {
          onCloseEventModal();
          message.success('Event created');
          done();
        },
        onError: (error: any) => {
          if (error.response?.response?.status === 400) {
            setFailedValidation(true);
          }
          if (error.response?.response?.status === 409) {
            if (
              ((
                (error.response?.body?.message || error.response?.body?.error) as string | undefined
              )?.indexOf('slug') ?? 0) > 0
            ) {
              setHasConflict('vle_error_slug_already_exist');
            } else {
              setHasConflict(error.response?.body?.message || error.response?.body?.error);
            }
          }
          done(error);
        },
      },
    );
  };

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader
        title='Events'
        extra={[
          <Input.Search
            placeholder='Search'
            allowClear
            onChange={({ target }) => setSearchTerm(target.value)}
            style={{ width: 200, marginRight: 10 }}
            key='search'
          />,
          (isSuperAdmin || organisation) && (
            <Button key='menu' type='primary' onClick={onOpenEventModal}>
              Add Event
            </Button>
          ),
        ]}
      />
      <Tabs defaultActiveKey=' 1' onChange={(active) => setActiveTab(active as ActiveTab)}>
        <Tabs.TabPane
          tab={`Events (${filteredEvents ? filteredEvents.length : events?.length || 0})`}
          key='1'
        >
          <EventTable events={events ?? undefined} isLoading={isLoading} searchTerm={searchTerm} />
        </Tabs.TabPane>
        {(isSuperAdmin || (organisation && organisation.eventTemplatesEnabled)) && (
          <Tabs.TabPane
            tab={`Templates (${
              filteredTemplateEvents ? filteredTemplateEvents.length : templateEvents?.length || 0
            })`}
            key='3'
          >
            <EventTable
              events={templateEvents ?? undefined}
              isLoading={templatesLoading}
              searchTerm={searchTerm}
            />
          </Tabs.TabPane>
        )}
        {(isSuperAdmin || organisation) && (
          <Tabs.TabPane
            tab={`Recycle Bin (${
              filteredArchivedEvents ? filteredArchivedEvents.length : archivedEvents?.length || 0
            })`}
            key='2'
          >
            <EventTable
              events={archivedEvents ?? undefined}
              isLoading={archiveLoading}
              searchTerm={searchTerm}
              archived
            />
          </Tabs.TabPane>
        )}
      </Tabs>

      <EventModal
        visible={openEventModal}
        onCancel={onCloseEventModal}
        onCreate={onCreateEvent}
        onUpdate={() => null}
        hasConflict={hasConflict}
        currentOrganisationId={organisation?.id}
        failedValidation={failedValidation}
      />
    </Layout.Content>
  );
}
