declare global {
  interface Window {
    Beacon: {
      readyQueue: Array<{ method: string; options: string }>;
    };
  }
}

// Refactored embed script from: https://docs.helpscout.com/article/1356-add-beacon-to-your-website-or-app#code
export function initHelpScoutBeacon(beaconId?: string) {
  if (!beaconId) return;
  window.Beacon = {
    readyQueue: [
      // Source: https://developer.helpscout.com/beacon-2/web/javascript-api/#beaconinit-beaconid
      { method: 'init', options: beaconId },
    ],
  };

  const script = document.createElement('script');
  script.async = true;
  script.type = 'text/javascript';
  script.src = 'https://beacon-v2.helpscout.net';

  document.head.appendChild(script);
}
