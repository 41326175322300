import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Card, Checkbox, Form, Input } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, Link } from 'react-router-dom';

import useAuth from '@hooks/auth/useAuth';
import { useLogin } from '@hooks/auth/useLogin';
import { showErrorResponse } from '@utils/showError';

const RECAPTCHA_DISABLED = process.env.REACT_APP_RECAPTCHA_DISABLED === 'true';

export default function Login() {
  const login = useLogin();
  const navigate = useNavigate();
  const { error } = useAuth();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptcha, setRecaptcha] = useState<string | null>();

  useEffect(() => {
    if (error) {
      recaptchaRef.current?.reset();
      setRecaptcha(null);
      showErrorResponse(error.response);
    }
  }, [error, recaptchaRef]);

  const onFinish = async (values: any) => {
    const response = await login(values.email, values.password, recaptcha!);
    if (response.isAuthorized && !response.tfaRequired) navigate('/');
    if (!response.isAuthorized && response.tfaRequired) navigate('/auth/verify');
  };

  return (
    <Row justify='center' align='middle' style={{ marginTop: '2em' }}>
      <Col span={6}>
        <Card title='Login' headStyle={{ textAlign: 'center' }}>
          <Form
            initialValues={{ email: '', password: '', remember: true }}
            onFinish={(values) => {
              onFinish(values);
            }}
          >
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
              hasFeedback
            >
              <Input prefix={<MailOutlined />} type='email' placeholder='Email Address' />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password prefix={<LockOutlined />} placeholder='Password' />
            </Form.Item>
            {!RECAPTCHA_DISABLED && (
              <Form.Item>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
                  onChange={setRecaptcha}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Form.Item name='remember' valuePropName='checked' noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link to='/auth/forgot-password'>
                <a style={{ float: 'right' }}>Forgot password</a>
              </Link>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' block>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
