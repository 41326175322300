import { useCallback, useEffect, useState } from 'react';
import {
  Input,
  Form,
  Divider,
  Button,
  message,
  InputNumber,
  Switch,
  Row,
  Popconfirm,
  Select,
  Tag,
  Modal,
} from 'antd';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import DatePicker from '@components/DatePicker';
import { EventLayout } from '@components/Layout';
import { ImageUpload } from '@components/Upload';

import useAuth from '@hooks/auth/useAuth';
import { useUsers } from '@hooks/useUsers';
import { useOrganisations } from '@hooks/useOrganisations';
import { languages, Language } from '@hooks/useTranslations';
import { useResetBoothAnalytics } from '@hooks/useLocationVisits';
import {
  useCurrentEvent,
  useDeleteEvent,
  useUpdateEvent,
  useCreateTemplateFromEvent,
  useUploadFaviconImage,
} from '@hooks/useEvents';
import { Event, EventResource } from '@transforms/event';
import { OrganisationResource } from '@transforms/organisation';

export default function Preferences() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user, isSuperAdmin, organisation: isOrganisationAdmin } = useAuth();
  const { mutateAsync: deleteEventAsync } = useDeleteEvent();
  const { mutateAsync: uploadFaviconImage } = useUploadFaviconImage();
  const { mutateAsync: resetBoothAnalyticsAsync } = useResetBoothAnalytics();
  const { data: organisationData } = useOrganisations({ page: 1, limit: 99 });
  const { data: event, isLoading } = useCurrentEvent();
  const { mutateAsync: updateEvent } = useUpdateEvent(event?.id);
  const [active, setActive] = useState(event?.active || false);

  const [faviconPreview, setFaviconPreview] = useState<string | null | undefined>(
    event ? event.themeFaviconUrl ?? null : undefined,
  );

  useEffect(() => {
    if (user && !isSuperAdmin && !isOrganisationAdmin) {
      navigate(-1);
    }
  }, [user, navigate, isSuperAdmin, isOrganisationAdmin]);

  useEffect(() => {
    if (!event) return;
    setActive(event.active);
    setFaviconPreview(event.themeFaviconUrl ?? null);
  }, [event, setActive]);

  const [defaultLanguage, _setDefaultLanguage] = useState(
    languages[event?.defaultLanguage as Language] ?? languages.en,
  );

  const [eventLanguages, _setEventLanguages] = useState([
    defaultLanguage.value,
    ...(event?.languages?.filter((lang) => lang !== defaultLanguage.value) ?? []),
  ]);

  const setDefaultLanguage = useCallback(
    (language?: string) => {
      _setDefaultLanguage((prev) => {
        const result = languages[language as Language] ?? prev;
        _setEventLanguages([
          result.value,
          ...(event?.languages?.filter((lang) => lang !== result.value) ?? []),
        ]);
        return result;
      });
    },
    [event?.languages],
  );

  const setEventLanguages = useCallback(
    (langs?: string[]) => {
      _setEventLanguages((prev) =>
        !langs
          ? prev
          : [defaultLanguage.value, ...langs.filter((lang) => lang !== defaultLanguage.value)],
      );
    },
    [defaultLanguage.value],
  );

  useEffect(() => {
    setDefaultLanguage(event?.defaultLanguage);
  }, [setDefaultLanguage, event]);

  if (isLoading || !event) return null;

  const onFinish = async ({
    activeRange,
    ...values
  }: Event & {
    activeRange?: Dayjs[];
    themeFavicon?: File | null;
    unlimitedAttendees: boolean;
  }) => {
    try {
      await updateEvent({
        ...event,
        ...values,
        ...(activeRange
          ? {
              activeFrom: activeRange[0].toJSON(),
              activeTill: activeRange[1].toJSON(),
            }
          : undefined),
        maxUsers: values.unlimitedAttendees ? 0 : values.maxUsers,
        languages: eventLanguages,
        themeFaviconUrl: faviconPreview ? undefined : null,
        organisationId: values.organisationId ?? null,
        defaultLanguage: defaultLanguage.value,
      });

      if (!event) return;
      if (values.themeFavicon) {
        try {
          await uploadFaviconImage({ eventId: event.id, image: values.themeFavicon });
        } catch {
          message.error('Unable to update favicon image');
        }
      }

      message.success({
        content: (
          <div className='message-box-content-container'>
            <div className='message-box-content'>
              <h3>Settings Updated!</h3>
              <span>Your changes have been successfully updated and saved!</span>
            </div>
          </div>
        ),
        className: 'message-box',
      });
    } catch (error) {
      switch ((error as any).response?.body?.message || (error as any).response?.body?.error) {
        case 'vle_error_event_templates_disabled': {
          message.error('The selected organization does not allow templates');
          break;
        }
        case 'vle_error_active_event_limit_exceeded': {
          message.error('You have reached the active event limit of the selected organization');
          break;
        }
        default: {
          message.error('There was an error updating Super Admin settings');
        }
      }
    }
  };

  const googleAnalytics = (
    <>
      <Row>
        <Form.Item
          name='googleTrackingId'
          label={
            <div>
              <div style={{ fontWeight: 'bold' }}>Google Analytics</div>
              <div>
                Add a Google Analytics UA code to enable Google Analytics within the platform.
              </div>
            </div>
          }
        >
          <Input maxLength={15} placeholder='UA-1234567890' />
        </Form.Item>
      </Row>
      <Divider key='d1' />
    </>
  );

  const favIcon = (
    <>
      <Form.Item
        name='themeFavicon'
        label={
          <div>
            <b>Favicon</b>
            <br />
            Upload a website icon for this event.
          </div>
        }
      >
        {faviconPreview !== undefined && (
          <ImageUpload
            initialPreview={faviconPreview}
            onChangePreview={setFaviconPreview}
            onRemove={() => form.setFields([{ name: 'image', value: null }])}
            extraRequirements={[
              'Since it will be downscaled, a rectangular icon that is maximum 200x200 pixels is recommended (as non-rectangular shapes will get squished by the browser).',
            ]}
          />
        )}
      </Form.Item>
      <Divider key='d2' />
    </>
  );

  const resetBoothAnalytics = (
    <Form.Item
      name='reset-booth-analytics'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Reset Analytics</div>
          <div>
            Reset all internal platform analytics for this event! Note, this cannot be undone.
          </div>
        </div>
      }
    >
      <Popconfirm
        title={
          <div className='message-box-content-container'>
            <div className='message-box-content'>
              <h3>Confirm Deletion!</h3>
              <span>
                Are you sure you want to delete and reset all internal platform analytics? Note,
                this cannot be undone!
              </span>
            </div>
          </div>
        }
        onConfirm={() => {
          resetBoothAnalyticsAsync(event.id)
            .then(() => message.success('Analytics successfully reset'))
            .catch(() => message.error('An error occurred'));
        }}
        okText='Reset'
        cancelText='Cancel'
        okButtonProps={{
          danger: true,
        }}
      >
        <Button type='primary' danger icon={<RollbackOutlined />}>
          Reset
        </Button>
      </Popconfirm>
    </Form.Item>
  );

  const deleteEvent = (
    <Form.Item
      name='delete-event'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Delete Event</div>
          <div>
            Delete this entire event and all data associated with it! Note, this cannot be undone.
          </div>
        </div>
      }
    >
      <Popconfirm
        title={
          <div className='message-box-content-container'>
            <div className='message-box-content'>
              <h3>Confirm Deletion!</h3>
              <span>
                Are you sure you want to delete this event and all its data? Note, this cannot be
                undone!
              </span>
            </div>
          </div>
        }
        className='message-box'
        onConfirm={() => {
          deleteEventAsync(event.id)
            .then(() => {
              message.success('Event successfully deleted, redirecting in 5 seconds ...');
              setTimeout(() => {
                window.location.href = '/events';
              }, 5000);
            })
            .catch(() => message.error('An error occurred'));
        }}
        okText='Delete '
        cancelText='Cancel'
        okButtonProps={{
          danger: true,
        }}
      >
        <Button type='primary' danger icon={<DeleteOutlined />}>
          Delete
        </Button>
      </Popconfirm>
    </Form.Item>
  );

  const eventSlugInput = (
    <Form.Item
      key='slug'
      name='slug'
      className='required-label-title-only'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Event URL</div>
          <div>
            The URL name (web address) for this event. Note, only the prefix (the text displayed
            before <a>.virtual-live-event.com</a>) can be customized. Tip: Only certain characters
            are allowed (lowercase letters A-Z; numbers 0-9; and "-").
          </div>
        </div>
      }
      rules={[{ required: true }]}
    >
      <Input
        placeholder='custom-event-url'
        addonBefore='https://'
        addonAfter={`.${process.env.REACT_APP_APP_URL}`}
      />
    </Form.Item>
  );

  const renderInternalNameInput = (
    <Form.Item
      key='internalName'
      name='internalName'
      label={
        <div>
          <div style={{ fontWeight: 'bold' }}>Internal Name</div>
          <div>
            The internal name used to reference this event (ex. Salesforce number). Only other Super
            Admins will be able to see this name.
          </div>
        </div>
      }
    >
      <Input placeholder='Internal Name' />
    </Form.Item>
  );

  const languageSelect = (
    <>
      <div style={{ paddingBottom: 8 }}>
        <div style={{ fontWeight: 'bold' }}>Default Language</div>
        <div>Select the default language for this event.</div>
      </div>
      <Select
        placeholder='Select default language'
        options={Object.values(languages)}
        value={defaultLanguage.value}
        style={{ width: '100%' }}
        onChange={setDefaultLanguage}
        // @ts-ignore
        filterOption={(inputValue, option) =>
          option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
          option?.value.toLowerCase().includes(inputValue.toLowerCase())
        }
      />
      <div style={{ paddingBottom: 8, marginTop: 24 }}>
        <div style={{ fontWeight: 'bold' }}>Additional Languages</div>
        <div>
          Select any additional languages for this event. Note, all standard system text will be
          translated automatically; however, all custom text (ex. page titles, session descriptions,
          etc.) will need to be translated and added manually for all languages.
        </div>
      </div>
      <Select
        mode='multiple'
        allowClear
        placeholder='Select languages'
        value={eventLanguages}
        style={{ width: '100%' }}
        onChange={setEventLanguages}
        options={Object.values(languages).filter((lang) => lang.value !== defaultLanguage.value)}
        tagRender={(props) => {
          const isDefault = props.value === defaultLanguage.value;
          return (
            <Tag
              {...props}
              style={{
                // this is done to match the styling of a default tag render
                fontSize: '14px',
                lineHeight: '22px',
                background: '#f5f5f5',
                border: '1px solid #f0f0f0',
                ...(isDefault ? { color: '#999' } : {}),
              }}
              closable={isDefault ? false : props.closable}
            >
              {isDefault ? defaultLanguage.label : props.label}
            </Tag>
          );
        }}
        // @ts-ignore
        filterOption={(inputValue, option) =>
          (option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
            option?.value.toLowerCase().includes(inputValue.toLowerCase())) &&
          option?.value !== defaultLanguage.value
        }
      />
    </>
  );

  const eventActiveInput = (
    <>
      <Form.Item
        name='active'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Platform Active</div>
            <div>
              Toggle on to activate or off to deactivate this event. Note, when an event is
              inactive, no one will not be able to access any pages.
            </div>
          </div>
        }
      >
        <Switch defaultChecked={active} onChange={setActive} />
      </Form.Item>
      {active && (
        <Form.Item
          name='activeRange'
          className='required-label-title-only'
          rules={[{ required: true }]}
          label={
            <div>
              <div style={{ fontWeight: 'bold' }}>Platform Dates</div>
              <div>
                Determine the timeframe in which this event platform is active. Note, the event will
                automatically be made inactive outside this date range.
              </div>
            </div>
          }
        >
          {/* @ts-ignore */}
          <DatePicker.RangePicker showTime placeholder={['Start Date', 'End Date']} />
        </Form.Item>
      )}
      <Divider key='d3' />
    </>
  );

  const unlimitedAttendees = !event.maxUsers && (!event.organisation?.userLimit ?? true);

  return (
    <EventLayout title='Preferences'>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          ...event,
          unlimitedAttendees,
          activeRange: [
            dayjs(event?.activeFrom || undefined),
            dayjs(event?.activeTill || undefined),
          ],
        }}
        validateMessages={{
          required: 'This is a required field.',
        }}
      >
        {eventSlugInput}
        {renderInternalNameInput}
        {languageSelect}
        <Divider key='d4' />

        {!event?.isTemplate ? eventActiveInput : null}
        {(organisationData?.items?.length ?? 0) > 0 && (
          <OrganisationFields
            event={event}
            organisationData={organisationData?.items}
            unlimitedAttendees={unlimitedAttendees}
          />
        )}
        {googleAnalytics}
        {favIcon}
        {resetBoothAnalytics}
        <Divider key='d5' />

        {deleteEvent}
        <Divider key='d6' />
        <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
          Save
        </Button>
      </Form>
    </EventLayout>
  );
}

function OrganisationFields({
  event,
  organisationData,
  unlimitedAttendees,
}: {
  event: EventResource;
  organisationData?: OrganisationResource[];
  unlimitedAttendees: boolean;
}) {
  const { data: users } = useUsers();
  const [templateName, setTemplateName] = useState<string>('');
  const { mutate: createEventFromTemplate } = useCreateTemplateFromEvent(event?.id);
  const [showCreateEventTemplateModal, setShowCreateEventTemplateModal] = useState(false);
  const [updatedUnlimitedAttendees, setUnlimitedAttendees] = useState(unlimitedAttendees);
  const [selectedOrganisation, setSelectedOrganisation] = useState<
    EventResource['organisation'] | undefined
  >(event.organisation);

  useEffect(() => {
    setUnlimitedAttendees(unlimitedAttendees);
    setSelectedOrganisation(event.organisation);
  }, [unlimitedAttendees, event.organisation]);

  const eventTemplateInput = (
    <>
      <Form.Item
        name='createTemplate'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Create Template</div>
            <div>Save this event as a template for future use.</div>
          </div>
        }
      >
        <Button type='primary' onClick={() => setShowCreateEventTemplateModal(true)}>
          Create Template
        </Button>
      </Form.Item>
      <Divider key='d7' />
      <Modal
        visible={showCreateEventTemplateModal}
        title='Create a new template from this event'
        okText='Create'
        cancelText='Cancel'
        onCancel={() => setShowCreateEventTemplateModal(false)}
        onOk={() =>
          createEventFromTemplate(templateName, {
            onSuccess: () => {
              message.success('Template successfully created, redirecting in 5 seconds ...');
              setTimeout(() => {
                window.location.href = '/events';
              }, 5000);
            },
            onError: () => {
              message.error('An error occurred');
            },
          })
        }
      >
        <Form layout='vertical'>
          <Form.Item
            name='templateName'
            label={<div style={{ fontWeight: 'bold' }}>Template name</div>}
          >
            <Input placeholder='New template' onChange={(e) => setTemplateName(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  const attendeeCount = (
    <>
      <div style={{ fontWeight: 'bold', marginBottom: '2px' }}>Maximum Attendees</div>
      {/* TODO: replace with "This organization" when you're an organization admin instead of super */}
      {selectedOrganisation?.userLimit ? (
        `The selected organization has enforced a maximum number of ${selectedOrganisation.userLimit} attendees.`
      ) : (
        <>
          <div style={{ marginBottom: '15px' }}>
            The maximum number of attendees allowed in this event.
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <div style={{ fontWeight: 'bold', marginRight: '8px' }}>Unlimited</div>
            <Form.Item name='unlimitedAttendees' valuePropName='checked' style={{ margin: 0 }}>
              <Switch onChange={(val) => setUnlimitedAttendees(val)} />
            </Form.Item>
          </div>
          {!updatedUnlimitedAttendees && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <div style={{ fontWeight: 'bold', marginRight: '8px' }}>Maximum</div>
              <Form.Item name='maxUsers' style={{ margin: 0 }}>
                <InputNumber min={1} />
              </Form.Item>
            </div>
          )}
        </>
      )}
      <div style={{ opacity: 0.7, marginTop: '15px' }}>
        Current number of attendees: {users?.meta.totalItems ?? 0}
      </div>
      <Divider key='d8' />
    </>
  );

  const organisationSelector = (
    <>
      <Form.Item
        name='organisationId'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Organization</div>
            <div>Link this event to an organization.</div>
          </div>
        }
      >
        <Select
          allowClear
          placeholder='Select an organization'
          options={organisationData?.map((organisation) => ({
            value: organisation.id,
            label: organisation.name,
          }))}
          // @ts-ignore
          filterOption={(inputValue, option) =>
            option?.label?.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
            option?.value.toLowerCase().includes(inputValue.toLowerCase())
          }
          onSelect={(organisationId) =>
            setSelectedOrganisation(
              organisationData?.find((organisation) => organisation.id === organisationId),
            )
          }
        />
      </Form.Item>
      <Divider key='d9' />
    </>
  );

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {selectedOrganisation
        ? selectedOrganisation.eventTemplatesEnabled !== false
          ? eventTemplateInput
          : null
        : eventTemplateInput}
      {organisationSelector}
      {attendeeCount}
    </>
  );
}
