import { Modal, Button, Select } from 'antd';
import { useState } from 'react';

import { useCustomFields } from '@hooks/useCustomFields';
import { useCurrentEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { useMandatoryFields } from '@hooks/useMandatoryField';

export interface ExportAvatarsFormProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  action: (id: string) => void;
}

export function ExportAvatarsForm({ visible, onOk, onCancel, action }: ExportAvatarsFormProps) {
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const { data: fields } = useCustomFields(event?.id, selectedLanguage);
  const { data: mandatoryFields } = useMandatoryFields(event?.id, selectedLanguage);

  const [selectedField, setSelectedField] = useState<string>();

  return (
    <Modal
      visible={visible}
      title='Export avatars'
      okText='Close'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={onOk}
    >
      <h3 style={{ margin: 0 }}>Sorted field</h3>
      <p style={{ margin: 0, marginBottom: '8px' }}>
        On which field should the avatars be divided and sorted?
      </p>
      <Select
        style={{ marginTop: '8px', marginBottom: '24px', width: '100%' }}
        filterOption={false}
        value={selectedField}
        onChange={setSelectedField}
        placeholder='Select a custom field'
      >
        <Select.OptGroup label='Mandatory Fields'>
          {mandatoryFields?.map((field) => (
            <Select.Option key={field.key} value={field.key}>
              {field.label}
            </Select.Option>
          ))}
        </Select.OptGroup>
        <Select.OptGroup label='Custom Fields'>
          {fields?.map((field) => (
            <Select.Option key={field.id} value={field.id}>
              {field.label}
            </Select.Option>
          ))}
        </Select.OptGroup>
      </Select>
      <Button
        onClick={() => action(selectedField ?? '')}
        type='primary'
        disabled={!selectedField?.length}
      >
        Download ZIP
      </Button>
    </Modal>
  );
}
