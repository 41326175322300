import { GlobalOutlined, PlaySquareFilled, UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';

import DatePicker from '@components/DatePicker';

import { InfoCard } from './InfoCard';

export function AnalyticsHeader({
  title,
  startDate,
  endDate,
  analytics,
  handleDateChange,
}: {
  title: string;
  startDate: string | Date;
  endDate: string | Date;
  analytics: any;
  handleDateChange: (vals: any) => void;
}) {
  return (
    <Row align='top'>
      {/* Event info */}
      <Col span={12}>
        <h1
          style={{
            fontSize: '3rem',
            fontWeight: 'bold',
            maxWidth: '50%',
            margin: '0',
            lineHeight: '1.2',
          }}
        >
          {title}
        </h1>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <br />
          <br />
          <br />
          {/* @ts-ignore */}
          <DatePicker.RangePicker
            placeholder={['Start Date', 'End Date']}
            onChange={(vals) => handleDateChange(vals)}
            showTime
            defaultValue={[dayjs(startDate), dayjs(endDate)]}
          />
        </div>
        {analytics ? (
          <div>
            {analytics?.users.accepted.avatars.map((url: string) => {
              return <Avatar size={50} key={url} src={url} style={{ marginRight: '-1rem' }} />;
            })}
            <span style={{ marginLeft: '2rem' }}>
              {' '}
              {`${analytics?.users.accepted.total ?? 0} Registered Attendees`}
            </span>
            {analytics ? (
              <div style={{ marginTop: '1.5rem' }}>
                {analytics?.users.total.avatars.map((url: string) => {
                  return <Avatar size={50} key={url} src={url} style={{ marginRight: '-1rem' }} />;
                })}
                <span style={{ marginLeft: '2rem' }}>
                  {' '}
                  {`${analytics?.users.total.total ?? 0} Cumulative Attendees`}
                </span>
              </div>
            ) : null}
          </div>
        ) : null}
      </Col>

      {/* INFOCARDS */}
      <Col span={12}>
        <Row gutter={[42, 24]}>
          <Col span={24}>
            <h2
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                lineHeight: '1.2',
                margin: 0,
              }}
            >
              Attendees
            </h2>
          </Col>
          <Col span={12}>
            <InfoCard title='Total' value={analytics?.users.total!} icon={<UserOutlined />} />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Attended Sessions'
              tooltip='The amount of attendees that attended at least one session'
              value={analytics?.users.sessionsAttended}
              icon={<PlaySquareFilled />}
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Online Attendance'
              tooltip='The total number of attendees that have registered as online'
              value={analytics?.users.attendance.online!}
              icon={<GlobalOutlined />}
            />
          </Col>
          <Col span={12}>
            <InfoCard
              title='Onsite Attendance'
              tooltip='The total number of attendees that have registered as onsite'
              value={analytics?.users.attendance.onsite!}
              icon={
                <svg width='22px' height='22px' fill='rgb(29, 57, 196)' viewBox='0 0 512 512'>
                  <g>
                    <path
                      d='M489.1,2.8c4.3,3,6.9,7.9,6.9,13.2v480c0,8.8-7.2,16-16,16h-96c-8.8,0-16-7.2-16-16v-48h-32v48
		c0,8.8-7.2,16-16,16H32c-8.8,0-16-7.2-16-16V304c0-7.8,5.7-14.5,13.4-15.8L208,258.5V112c0-6.7,4.1-12.6,10.4-15l256-96
		C479.3-0.8,484.8-0.1,489.1,2.8L489.1,2.8z M240,123.1V272c0,7.8-5.7,14.5-13.4,15.8L48,317.5V480h256v-48c0-8.9,7.2-16,16-16h64
		c8.8,0,16,7.1,16,16v48h64V39.1L240,123.1z'
                    />
                    <rect x='208' y='272' width='32' height='224' />
                    <path
                      d='M80,352h32v32H80V352z M144,352h32v32h-32V352z M80,416h32v32H80V416z M144,416h32v32h-32V416z M336,96h32v32h-32V96z
		 M400,96h32v32h-32V96z M272,160h32v32h-32V160z M336,160h32v32h-32V160z M400,160h32v32h-32V160z M336,224h32v32h-32V224z
		 M400,224h32v32h-32V224z M272,224h32v32h-32V224z M272,288h32v32h-32V288z M336,288h32v32h-32V288z M400,288h32v32h-32V288z
		 M272,352h32v32h-32V352z M336,352h32v32h-32V352z M400,352h32v32h-32V352z'
                    />
                  </g>
                </svg>
              }
            />
          </Col>
        </Row>
      </Col>
      <Divider />
    </Row>
  );
}
