import { message } from 'antd';
import queryString from 'query-string';
import { useQuery } from 'react-query';

import fetch from '@utils/fetch';
import useAuth from '@hooks/auth/useAuth';
import type { PageResource } from '@transforms/page';
import type { VideoResource } from '@transforms/video';
import type { SessionResource } from '@transforms/session';

const getAnalytics = <Returns>(path?: string) => {
  return function GetAnalytics(
    organisationId?: string,
    startDate?: string | Date,
    endDate?: string | Date,
    points?: number,
  ) {
    const { accessToken } = useAuth();

    return useQuery(
      ['analytics', path, organisationId, startDate, endDate, points].filter(Boolean),
      async () => {
        const query = queryString.stringify(
          organisationId === '__ALL__' ? { all: true } : { organisation: organisationId },
        );

        const { body } = await fetch(`/analytics${path || ''}?${query}`, {
          method: 'POST',
          token: accessToken,
          body: {
            startDate,
            endDate,
            dataPoints: points,
          },
        });

        return body as Returns;
      },
      { keepPreviousData: true },
    );
  };
};

export const useAnalytics = getAnalytics<GeneralAnalytics>();
export const useInsightsAnalytics = getAnalytics<InsightAnalytics>('/insights');
export const useAttendeeAnalytics = getAnalytics<AttendeeAnalytics>('/attendees');
export const useVideosAnalytics = getAnalytics<VideoAnalytics>('/videos');
export const useSessionsAnalytics = getAnalytics<SessionAnalytics>('/sessions');
export const usePlatformAnalytics = getAnalytics<PlatformAnalytics>('/platform');

const getEventAnalytics = <Returns>(path?: string) => {
  return function GetAnalytics(
    eventId: string,
    startDate?: string | Date,
    endDate?: string | Date,
    points?: number,
  ) {
    const { accessToken } = useAuth();

    return useQuery(
      ['analytics', path, eventId, startDate, endDate, points].filter(Boolean),
      async () => {
        const { body } = await fetch(`/events/${eventId}/analytics${path || ''}`, {
          method: 'POST',
          token: accessToken,
          body: {
            startDate,
            endDate,
            dataPoints: points,
          },
        });

        return body as Returns;
      },
      {
        enabled: !!eventId,
        keepPreviousData: true,
      },
    );
  };
};

export const useEventAnalytics = getEventAnalytics<GeneralAnalytics>();
export const useEventInsightsAnalytics = getEventAnalytics<InsightAnalytics>('/insights');
export const useEventGeneralAttendeeAnalytics = getEventAnalytics<AttendeeAnalytics>('/attendees');
export const useEventVideosAnalytics = getEventAnalytics<VideoAnalytics>('/videos');
export const useEventSessionsAnalytics = getEventAnalytics<SessionAnalytics>('/sessions');
export const useEventPlatformAnalytics = getEventAnalytics<PlatformAnalytics>('/platform');

export const useExportAnalytics = () => {
  const { accessToken } = useAuth();

  const exportAnalytics = async () => {
    try {
      const { body } = await fetch('/analytics/csv', {
        token: accessToken,
        headers: {
          Acccept: 'text/csv',
          'Content-Type': 'text/csv',
        },
      });

      const blob = new Blob([body], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `analytics-${+new Date()}.csv`;
      link.click();
    } catch {
      message.error('Unable to download CSV.');
    }
  };

  const exportEventAnalytics = async (eventId: string) => {
    try {
      const { body } = await fetch(`/events/${eventId}/analytics/csv/statistics`, {
        token: accessToken,
        headers: {
          Acccept: 'text/csv',
          'Content-Type': 'text/csv',
        },
      });

      const blob = new Blob([body], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `analytics-${+new Date()}.csv`;
      link.click();
    } catch {
      message.error('Unable to download CSV.');
    }
  };

  const exportLocationVisits = async (eventId: string) => {
    try {
      const { body } = await fetch(`/events/${eventId}/analytics/csv/visits`, {
        token: accessToken,
        headers: {
          Acccept: 'text/csv',
          'Content-Type': 'text/csv',
        },
      });

      const blob = new Blob([body], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `visits-${+new Date()}.csv`;
      link.click();
    } catch {
      message.error('Unable to download CSV.');
    }
  };

  const exportVideoAnalytics = async (eventId: string) => {
    try {
      const { body } = await fetch(`/events/${eventId}/analytics/csv/videos`, {
        token: accessToken,
        headers: {
          Acccept: 'text/csv',
          'Content-Type': 'text/csv',
        },
      });

      const blob = new Blob([body], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `videos-${+new Date()}.csv`;
      link.click();
    } catch {
      message.error('Unable to download CSV.');
    }
  };

  return {
    exportAnalytics,
    exportEventAnalytics,
    exportLocationVisits,
    exportVideoAnalytics,
  };
};

export interface AnalyticsDataPoints {
  total: number;
  average: number;
  points: Array<{
    date: string;
    data: number;
  }>;
}

export interface GeneralAnalytics {
  chats: {
    total: AnalyticsDataPoints;
    unique: AnalyticsDataPoints;
    private: AnalyticsDataPoints;
  };
  users: {
    total: AnalyticsDataPoints;
    accepted: AnalyticsDataPoints;
    active: AnalyticsDataPoints;
    inactive: AnalyticsDataPoints;
    online: number;
    avatar: number;
    languages: { none: number } & Record<string, number>;
    sessionsAttended: AnalyticsDataPoints;
  };
  calls: {
    private: { amount: AnalyticsDataPoints; duration: AnalyticsDataPoints };
    group: AnalyticsDataPoints;
  };
  vod: AnalyticsDataPoints;
  sessions: AnalyticsDataPoints;
}

export interface InsightAnalytics {
  virtualVenues: AnalyticsDataPoints;
  sessions: AnalyticsDataPoints;
  videoCalls: {
    breakout: AnalyticsDataPoints;
    private: AnalyticsDataPoints;
  };
}

export interface AttendeeAnalytics {
  viewTime: AnalyticsDataPoints;
  sessions: {
    added: AnalyticsDataPoints;
    attended: AnalyticsDataPoints;
  };
  messages: {
    private: AnalyticsDataPoints;
    group: AnalyticsDataPoints;
  };
  videoCalls: {
    total: AnalyticsDataPoints;
    time: AnalyticsDataPoints;
  };
}

export interface PlatformAnalytics {
  platform: Array<PageResource & { visitCount: number }>;
  virtualVenue: Array<PageResource & { visitCount: number }>;
}

export interface SessionAnalytics {
  attendance: Array<SessionResource & { visitCount: number }>;
  scheduled: Array<SessionResource & { addCount: number }>;
}

export interface VideoAnalytics {
  watched: Array<VideoResource & { amountOfUsers: number; watchCount: number }>;
  views: AnalyticsDataPoints;
}
