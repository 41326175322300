import parse, { APIParseError, Attributes } from '@utils/parse';

export const parseTranslation = (attributes: Attributes) => {
  const objectType = attributes.string('objectType');
  const objectColumn = attributes.string('objectColumn');

  return {
    id: attributes.id('id'),
    iso: attributes.string('iso'),
    objectColumn,
    objectId: attributes.string('objectId'),
    objectType,
    value: attributes.custom('value', (value) => {
      switch (`${objectType}.${objectColumn}`) {
        case 'video.duration':
          if (typeof value === 'number' && !Number.isNaN(value)) return value;
          break;
        default:
          return attributes.string('value');
      }

      throw new APIParseError(`Invalid translation value: ${value}`);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseTranslation);
}

export function many(data: any) {
  return parse.many(data, parseTranslation);
}

export type TranslationResource = ReturnType<typeof one>;
export type Translation = Omit<TranslationResource, 'id'>;

export default { one, many };
