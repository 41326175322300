import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Card, Form, Input, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import useAuth from '@hooks/auth/useAuth';

export default function AcceptInvitation() {
  const navigate = useNavigate();
  const { invitationToken } = useParams();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthorized) navigate('/');
  }, [auth.isAuthorized, navigate]);

  useEffect(() => {
    if (auth.error) message.error('An error occurred');
  }, [auth.error]);

  const onFinish = (values: any) => {
    auth.acceptInvitation(values.invitationToken, values.password).then(() => {
      if (!auth.error) {
        navigate('/auth/login');
      }
    });
  };

  if (!invitationToken) return null;

  return (
    <Row justify='center' align='middle' style={{ marginTop: '2em' }}>
      <Col span={6}>
        <Card title='Create account' headStyle={{ textAlign: 'center' }}>
          <Form
            initialValues={{ invitationToken, password: '', confirm: '' }}
            onFinish={(values) => {
              onFinish(values);
            }}
          >
            <Form.Item
              name='invitationToken'
              rules={[
                {
                  required: true,
                },
              ]}
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                () => ({
                  validator(_, value) {
                    if (
                      value.match(
                        /^(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/,
                      )
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Password must be more than 8 characters and contain at least 1 number and 1 special character',
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password prefix={<LockOutlined />} placeholder='Password' />
            </Form.Item>
            <Form.Item
              name='confirm'
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder='Confirm password' />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' block>
                Create account
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
