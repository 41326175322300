import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Switch, PageHeader, Divider, Select, message } from 'antd';
import { Link } from 'react-router-dom';

import { EventLayout } from '@components/Layout';
import { Event } from '@transforms/event';
import { useRoles } from '@hooks/useRoles';
import { useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent, useUpdateEvent } from '@hooks/useEvents';

export default function SettingsAccess() {
  const [form] = Form.useForm();
  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { data: roles } = useRoles(selectedLanguage, event?.id);

  const { mutate: updateEvent } = useUpdateEvent(event?.id);
  const [initialPages, setInitialPages] = useState<Array<{ startDate: Date; pageId?: string }>>([]);
  const [attendanceActive, setAttendanceActive] = useState(event?.attendanceEnabled || false);

  useEffect(() => {
    setAttendanceActive(event?.attendanceEnabled || false);
    setInitialPages((current) => {
      if (current.length === 0 && event?.initialPages) {
        return event.initialPages;
      }

      return current;
    });
  }, [event]);

  const onUpdateEvent = (values: Event) => {
    let autoApprovalEmailScopes = (values?.autoApprovalEmailScopes ?? null) as
      | string
      | string[]
      | null;

    if (autoApprovalEmailScopes && typeof autoApprovalEmailScopes === 'string') {
      autoApprovalEmailScopes = autoApprovalEmailScopes.replace(/\s+/g, '');
      form.setFieldsValue({ autoApprovalEmailScopes });
      autoApprovalEmailScopes = autoApprovalEmailScopes.split(',');
    } else if (Array.isArray(autoApprovalEmailScopes)) {
      autoApprovalEmailScopes = autoApprovalEmailScopes.map((curr) =>
        curr.trim().replace(/\s+/g, ''),
      );
    } else {
      autoApprovalEmailScopes = null;
    }

    updateEvent({
      ...event,
      ...values,
      autoApprovalEmailScopes: autoApprovalEmailScopes!,
      initialPages,
    });
    message.success({
      content: (
        <div className='message-box-content-container'>
          <div className='message-box-content'>
            <h3>Event Updated!</h3>
            <span>Your changes have been successfully updated and saved!</span>
          </div>
        </div>
      ),
      className: 'message-box',
    });
  };

  const landingPage = (
    <>
      <Divider key='d1' />
      <h2>Landing Page</h2>
      <div>
        This is the initial platform page that all attendees will "land" on and contains the login
        and/or registration options.
      </div>
      <br />
      <Form.Item
        name='loginEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Allow Login</div>
            <div>
              Allow attendees to request their personal login link (email) for this event. Note,
              only approved attendees will receive this email.
            </div>
          </div>
        }
      >
        <Switch defaultChecked={event?.loginEnabled} />
      </Form.Item>
      <Form.Item
        name='registrationEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Allow Registration</div>
            <div>Allow attendees to self-register for this event.</div>
          </div>
        }
      >
        <Switch
          disabled={event?.organisation?.registrationEnabled === false}
          defaultChecked={event?.registrationEnabled}
        />
      </Form.Item>
    </>
  );

  const hybridSettings = (
    <>
      <Divider key='d2' />
      <h2>Hybrid Settings</h2>
      <Form.Item
        name='attendanceEnabled'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Enable Attendee Type</div>
            <div>Allow attendees to set their type of attendance.</div>
          </div>
        }
      >
        <Switch defaultChecked={attendanceActive} onChange={(val) => setAttendanceActive(val)} />
      </Form.Item>
      <Divider key='d3' />
    </>
  );

  const mailOptions = (
    <>
      <Form.Item
        name='autoApprovalEmailScopes'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Whitelist</div>
            <div>
              Enter any email address domains that should be automatically approved. For instance,
              if <a>my-company.com</a> is added, every attendee with an email ending in
              my-company.com will be automatically approved. Note, if multiple, each domain listed
              must be separated by a comma.
            </div>
          </div>
        }
      >
        <Input.TextArea
          placeholder='domain-1.com,domain-2.com
'
        />
      </Form.Item>
      <Form.Item
        name='autoApproval'
        valuePropName='checked'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>Auto Approve</div>
            <div>
              Every attendee that self-registers to this event will be automatically approved. Note,
              enabling this setting does not mean that a login link will also be automatically
              emailed after self-registration is complete. You can program the email behavior from
              the <Link to={`/events/${event?.id}/emails`}>Emails</Link> section.
            </div>
          </div>
        }
      >
        <Switch defaultChecked={event?.autoApproval || false} />
      </Form.Item>

      {landingPage}

      <Form.Item
        name='rolesAllowed'
        label={
          <div>
            <div style={{ fontWeight: 'bold' }}>User Role Access</div>
            <div>Limit access to these specific user roles.</div>
          </div>
        }
      >
        <Select
          filterOption={(input, option) =>
            // @ts-ignore
            option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            // @ts-ignore
            option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={event?.rolesAllowed?.map((role) => role.id) ?? undefined}
          options={roles?.map((role) => ({
            value: role.id,
            label: role.name,
          }))}
          mode='multiple'
          placeholder='Guest; Event Team'
        />
      </Form.Item>

      {hybridSettings}

      <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
        Save
      </Button>
    </>
  );

  const parsedData = {
    ...event,
    rolesAllowed: event?.rolesAllowed?.map((role) => role.id),
    registrationEnabled:
      event?.organisation?.registrationEnabled === false ? false : event?.registrationEnabled,
  };

  return (
    <EventLayout>
      <PageHeader title='Access' />
      <Divider key='d4' />
      <h3>Approval Settings</h3>
      <Form layout='vertical' initialValues={parsedData} onFinish={onUpdateEvent} form={form}>
        {mailOptions}
      </Form>
    </EventLayout>
  );
}
