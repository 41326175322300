import { Space, Modal } from 'antd';

import { SpeakerResource } from '@transforms/speaker';

import speakerStyles from '../../styles/SpeakerPreview.module.css';

type PreviewSpeakerModalProps = {
  visible: boolean;
  speaker?: SpeakerResource;
  imagePreview: string | undefined | null;
  onCancel(): void;
};

export function PreviewSpeakerModal({
  visible,
  speaker,
  imagePreview,
  onCancel,
}: PreviewSpeakerModalProps) {
  const name = speaker?.name || 'Test eventname';
  const jobTitle = speaker?.jobTitle || 'Job Title';
  const companyName = speaker?.companyName || 'Company name';

  const SpeakerList = () => (
    <div className={speakerStyles.listContainer}>
      <div
        className={speakerStyles.listImage}
        style={{ backgroundImage: `url('${imagePreview}')` }}
      />
      <div>
        <div className={speakerStyles.listSpeakerName}>{name}</div>
        <div className={speakerStyles.listSpeakerBio}>{jobTitle}</div>
        <div className={speakerStyles.listSpeakerBio}>{companyName}</div>
      </div>
    </div>
  );

  const SpeakerModal = () => (
    <div className={speakerStyles.detailsContainer}>
      <div className={speakerStyles.closeSpeakerModel} />
      <div
        className={speakerStyles.detailsImage}
        style={{ backgroundImage: `url('${imagePreview}')` }}
      />
      <div className={speakerStyles.details}>
        <Space direction='vertical'>
          <div className={speakerStyles.speakerName}>{name}</div>
          <div className={speakerStyles.speakerDetails}>
            {jobTitle} | {companyName}
          </div>
          <div className={speakerStyles.speakerInterests}>Interests</div>
        </Space>
        <div className={speakerStyles.speakerSessionsContainer}>
          <p>Session()</p>
        </div>
      </div>
    </div>
  );

  return (
    <Modal title='Preview' visible={visible} onCancel={onCancel} footer={null} width='700px'>
      <Space direction='vertical' size={24}>
        <SpeakerList />
        <Space size={92}>
          <SpeakerModal />
        </Space>
      </Space>
    </Modal>
  );
}
