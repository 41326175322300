import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
  Select,
  List,
  FormInstance,
  Button,
  TimePicker,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

import { StageResource } from '@transforms/stage';
import { SpeakerResource } from '@transforms/speaker';
import { SessionLocation, SessionResource } from '@transforms/session';

import DatePicker from '@components/DatePicker';
import { ListItem } from '@components/Speakers';
import { ImageUpload } from '@components/Upload';
import { RichTextEditor } from '@components/RichTextEditor';

import { PageTemplate } from '@transforms/page';
import { usePages } from '@hooks/usePages';
import { useRoles } from '@hooks/useRoles';
import { useVideos } from '@hooks/useVideos';
import { useVideoCalls } from '@hooks/useVideoCalls';
import { useCurrentEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';

import { PreviewSessionModal } from './PreviewSession';
import { DataEntry, DataEntryProps } from './DataEntry';

export interface SessionProps extends DataEntryProps {
  data?: SessionResource & { location: string };
  speakers?: SpeakerResource[];
  stages?: StageResource[];
}

interface Page {
  value: string;
  label: string;
  id: string;
  type: 'page' | 'videoCall';
  template?: string;
}

export function Session({
  data,
  speakers,
  stages,
  onCreate,
  onUpdate,
  ...restProps
}: SessionProps) {
  const [form] = Form.useForm();
  const parsedData = useMemo(
    () =>
      data
        ? {
            ...data,
            videoId: data?.video?.id ?? '__NONE__',
            date: data?.startsAt ? dayjs(data.startsAt) : null,
            roles: data?.roles?.map((role) => role.id),
            dates:
              data?.startsAt && data?.endsAt ? [dayjs(data.startsAt), dayjs(data.endsAt)] : null,
            page: !data?.url?.startsWith('http')
              ? data?.url?.replace(/&video=.+/, '') ?? '__NONE__'
              : '__NONE__',
            sessionRedirectDestination: data?.url?.startsWith('http') ? 'external' : 'platform',
            // eslint-disable-next-line no-nested-ternary
            onDemandRedirectDestination: data?.onDemandUrl
              ? data?.onDemandUrl?.startsWith('http')
                ? 'external'
                : 'platform'
              : '__NONE__',
            externalUrl: data?.url?.startsWith('http') ? data?.url : undefined,
            onDemandPage: !data?.onDemandUrl?.startsWith('http')
              ? data?.onDemandUrl?.replace(/&video=.+/, '') ?? '__NONE__'
              : '__NONE__',
            onDemandUrl: data?.onDemandUrl?.startsWith('http') ? data?.onDemandUrl : undefined,
            onDemandVideoId: data?.onDemandUrl?.includes('&video=')
              ? data?.onDemandUrl.replace(/.+&video=/, '')
              : undefined,
            automaticPush: !data?.mandatory ? false : data.automaticPush,
            location: data?.location ?? SessionLocation.ONLINE,
          }
        : undefined,
    [data],
  );

  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { data: roles } = useRoles(selectedLanguage, event?.id);

  const [showPreviewSessionModal, setShowPreviewSessionModal] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | undefined | null>(data?.imageUrl);
  const [sessionSpeakers, setSessionSpeakers] = useState<SpeakerResource[] | undefined>();
  const [disableTimePicker, setDisableTimePicker] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);

  const format = 'HH:mm';

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Speakers';
      }
    }
  }, [listRef.current?.innerHTML]);

  useEffect(() => {
    if (!form.getFieldValue('dates')) {
      form.setFields([
        {
          name: 'timepicker',
          value: '',
        },
        {
          name: 'reminderTime',
          value: null,
        },
      ]);
    } else {
      if (form.getFieldValue('dates')) {
        setDisableTimePicker(false);
      } else {
        setDisableTimePicker(true);
      }
      const sessionDate = form.getFieldValue('dates');

      const reminderTime = dayjs(sessionDate[0]?.toLocaleString())
        .subtract(data?.reminderTime ?? 0, 'minutes')
        .tz('CET', true);

      const sessionStartTime = dayjs(sessionDate[0]?.toLocaleString()).tz('CET', true);
      const timeDiff = sessionStartTime.diff(reminderTime, 'minute');

      if (data?.reminderTime) {
        form.setFields([
          {
            name: 'timepicker',
            value: reminderTime,
          },
          {
            name: 'reminderTime',
            value: timeDiff,
          },
        ]);
      }
    }
    setSessionSpeakers(data?.speakers as SpeakerResource[]);
  }, [data?.reminderTime, data?.speakers, form]);

  const onCreateCallback = useCallback(
    (
      {
        page,
        url,
        image,
        videoId,
        onDemandPage,
        onDemandVideoId,
        onDemandUrl,
        location,
        ...values
      }: SessionResource & {
        page?: string;
        videoId?: string;
        onDemandPage?: string;
        onDemandVideoId?: string;
        onDemandUrl?: string;
        image?: File | null;
      },
      done: () => void,
    ) =>
      onCreate(
        {
          ...values,
          page:
            // eslint-disable-next-line no-nested-ternary
            page === '__NONE__'
              ? null
              : videoId && videoId !== '__NONE__'
              ? `${page}&video=${videoId}`
              : page,
          onDemandUrl:
            // eslint-disable-next-line no-nested-ternary
            onDemandPage === '__NONE__'
              ? onDemandUrl
              : onDemandVideoId && onDemandVideoId !== '__NONE__'
              ? `${onDemandPage}&video=${onDemandVideoId}`
              : onDemandPage,
          image,
          imageUrl: imagePreview ? undefined : null,
          speakerIds: sessionSpeakers?.map((speaker) => speaker.id),
          videoId: videoId === '__NONE__' ? null : videoId,
          location: location || SessionLocation.ONLINE,
        },
        done,
      ),
    [onCreate, imagePreview, sessionSpeakers],
  );

  const onUpdateCallback = useCallback(
    (
      {
        page,
        url,
        image,
        videoId,
        onDemandPage,
        onDemandVideoId,
        onDemandUrl,
        location,
        ...values
      }: SessionResource & {
        page?: string;
        videoId?: string;
        onDemandPage?: string;
        onDemandVideoId?: string;
        onDemandUrl?: string;
        image?: File | null;
      },
      done: () => void,
    ) =>
      onUpdate(
        {
          ...values,
          page:
            // eslint-disable-next-line no-nested-ternary
            page === '__NONE__'
              ? null
              : videoId && videoId !== '__NONE__'
              ? `${page}&video=${videoId}`
              : page,
          onDemandUrl:
            // eslint-disable-next-line no-nested-ternary
            onDemandPage === '__NONE__'
              ? onDemandUrl
              : onDemandVideoId && onDemandVideoId !== '__NONE__'
              ? `${onDemandPage}&video=${onDemandVideoId}`
              : onDemandPage,
          image,
          imageUrl: imagePreview ? undefined : null,
          speakerIds: sessionSpeakers?.map((speaker) => speaker.id),
          videoId: videoId === '__NONE__' ? null : videoId,
          location: location || SessionLocation.ONLINE,
        },
        done,
      ),
    [onUpdate, imagePreview, sessionSpeakers],
  );

  const validateUrl = (value: string, otherField: string, callback: (error?: string) => void) => {
    const otherValue = form.getFieldValue(otherField);

    if ((!value || value === '__NONE__') && (!otherValue || otherValue === '__NONE__')) {
      callback('Either page or external link should have a value');
      return;
    }

    if (value && value !== '__NONE__' && otherValue && otherValue !== '__NONE__') {
      callback('Only page or external link may have a value');
      return;
    }

    callback();
  };

  const validateOnDemandUrl = (
    value: string,
    otherField: string,
    callback: (error?: string) => void,
  ) => {
    const otherValue = form.getFieldValue(otherField);

    if (value && value !== '__NONE__' && otherValue && otherValue !== '__NONE__') {
      callback('Only page or external link may have a value');
      return;
    }

    callback();
  };

  const addSpeaker = (speakerId: string) => {
    setSessionSpeakers((prevSessionSpeakers) =>
      // eslint-disable-next-line no-nested-ternary
      prevSessionSpeakers
        ? prevSessionSpeakers.some((speaker) => speaker.id === speakerId)
          ? prevSessionSpeakers
          : [
              ...prevSessionSpeakers,
              speakers?.find((speaker) => speaker.id === speakerId) as SpeakerResource,
            ]
        : [speakers?.find((speaker) => speaker.id === speakerId) as SpeakerResource],
    );
    form.setFieldsValue({ speaker_input: null });
  };

  const removeSpeaker = (speakerId: string) => {
    setSessionSpeakers(
      sessionSpeakers?.filter((speaker) => {
        return speaker.id !== speakerId;
      }),
    );
  };

  const setReminderInput = (sTime: moment.MomentInput) => {
    const sessionDate = form.getFieldValue('dates');

    const reminderTime = dayjs(sTime?.toLocaleString())
      .set('year', dayjs.utc(sessionDate[0]).year())
      .set('month', dayjs.utc(sessionDate[0]).month())
      .set('date', dayjs.utc(sessionDate[0]).date())
      .tz('CET', true);

    const sessionStartTime = dayjs(sessionDate[0]?.toLocaleString()).tz('CET', true);
    const timeDiff = sessionStartTime.diff(reminderTime, 'minute');

    if (timeDiff < 0) {
      // eslint-disable-next-line no-alert
      alert("The reminder can't start later then the session");
      form.setFields([
        {
          name: 'reminderTime',
          value: null,
        },
        {
          name: 'timepicker',
          value: '',
        },
      ]);
    } else {
      form.setFields([{ name: 'reminderTime', value: timeDiff }]);
    }
  };

  const removeReminder = () => {
    form.setFields([
      {
        name: 'timepicker',
        value: '',
      },
      {
        name: 'reminderTime',
        value: null,
      },
    ]);
  };

  const timepickerDisabler = () => {
    if (form.getFieldValue('dates')) {
      setDisableTimePicker(false);
    } else {
      setDisableTimePicker(true);
      form.setFields([
        {
          name: 'timepicker',
          value: '',
        },
        {
          name: 'reminderTime',
          value: null,
        },
      ]);
    }
  };

  const renderSpeakers = () => {
    return (
      <div ref={listRef}>
        <List
          itemLayout='horizontal'
          dataSource={sessionSpeakers}
          renderItem={(speaker) => <ListItem speaker={speaker} onDelete={removeSpeaker} />}
        />
      </div>
    );
  };

  const renderSpeakerOptions = () => {
    return (
      <>
        {speakers?.map((speaker) => (
          <Select.Option value={speaker.id} key={speaker.id}>
            {speaker.name}
          </Select.Option>
        ))}
      </>
    );
  };

  const renderStageOptions = () => {
    return (
      <>
        {stages?.map((stage) => (
          <Select.Option value={stage.id} key={stage.id}>
            {stage.name}
          </Select.Option>
        ))}
      </>
    );
  };

  const renderLocationOptions = () => {
    return (
      <>
        {Object.keys(SessionLocation).map((location) => (
          <Select.Option value={location} key={location}>
            {location.charAt(0).toUpperCase()}
            {location.slice(1).toLowerCase()}
          </Select.Option>
        ))}
      </>
    );
  };

  return (
    <DataEntry
      {...restProps}
      data={parsedData}
      onCreate={onCreateCallback}
      onUpdate={onUpdateCallback}
      form={form}
      name='Session'
    >
      <Form.Item
        name='dates'
        className='required-label-title-only'
        label={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>Session Availability (in CET)</b>
            Determine the timeframe in which this session is active. Note, the session will
            automatically be made inactive outside this date range.
            <br />
            <br />
          </div>
        }
        rules={[
          {
            required: true,
          },
        ]}
      >
        {/* @ts-ignore */}
        <DatePicker.RangePicker
          placeholder={['Start Date', 'End Date']}
          onChange={() => timepickerDisabler()}
          showTime
          defaultValue={
            data?.startsAt && data?.endsAt
              ? [dayjs(data.startsAt).tz('CET'), dayjs(data.endsAt).tz('CET')]
              : null
          }
        />
      </Form.Item>
      <Form.Item
        name='reminderTime'
        label={<b>Reminder Time (in CET)</b>}
        style={{ width: '100%', opacity: '0', display: 'none' }}
      >
        <InputNumber id='input' placeholder='Time of Day (in CET)' style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name='timepicker' label={<b>Reminder Time (in CET)</b>}>
        <TimePicker
          allowClear={false}
          disabled={disableTimePicker}
          placeholder='Time of Day (in CET)'
          format={format}
          onChange={(sTime) => {
            setReminderInput(sTime);
          }}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Button disabled={disableTimePicker} onClick={() => removeReminder()} danger>
        Remove Reminder
      </Button>
      <br />
      <br />
      <MandatoryFields form={form} />
      <Form.Item name='roles' label={<b>User Role Access</b>}>
        <Select
          filterOption={(input, option) =>
            option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={
            roles?.map((role) => ({
              value: role.id,
              label: role.name,
            })) ?? []
          }
          mode='multiple'
          placeholder='Limit access to these specific user roles.'
        />
      </Form.Item>
      <Divider key='d1' />

      <Form.Item
        name='title'
        label={<b>Session Title</b>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Session Title' />
      </Form.Item>
      <Form.Item
        name='description'
        label={<b>Session Description</b>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <RichTextEditor
          placeholder='A short description of this session.'
          maxLength={1000}
          disableFont
          disableColor
          minHeight={90}
        />
      </Form.Item>
      <Form.Item name='image' label={<b>Image</b>}>
        <ImageUpload
          initialPreview={imagePreview}
          onChangePreview={setImagePreview}
          onRemove={() => form.setFields([{ name: 'image', value: null }])}
          textOverwrite='The maximum file size is 5 MB and must be at least 380x600 pixels in either JPG or PNG formats.'
          extraButtons={
            <>
              <Button disabled={!imagePreview} onClick={() => setShowPreviewSessionModal(true)}>
                Preview
              </Button>
              <PreviewSessionModal
                visible={showPreviewSessionModal}
                sessionName={data?.title}
                imagePreview={imagePreview}
                onCancel={() => setShowPreviewSessionModal(false)}
              />
            </>
          }
        />
      </Form.Item>

      <Divider />
      <h2>Session Redirect Destination:</h2>
      <Form.Item
        name='sessionRedirectDestination'
        label={<b>Redirect Destination</b>}
        rules={[{ required: true }]}
      >
        <Select
          placeholder='Select a redirect destination'
          options={[
            { value: 'external', label: 'External Link URL' },
            { value: 'platform', label: 'Platform Page' },
          ]}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) => {
          if (prevValues.sessionRedirectDestination !== currentValues.sessionRedirectDestination) {
            form.resetFields(['externalUrl', 'page', 'videoId']);
            form.setFieldsValue({ externalUrl: '', page: '__NONE__', videoId: '' });
            return true;
          }
          return false;
        }}
        noStyle
      >
        {() => {
          switch (form.getFieldValue('sessionRedirectDestination')) {
            case 'external':
              return (
                <Form.Item
                  name='externalUrl'
                  label={<b>External Link URL (ex. Zoom or Teams meeting)</b>}
                  rules={[
                    {
                      type: 'url',
                      message: 'External link is not valid (make sure to add https://)',
                    },
                    { validator: (_, value, callback) => validateUrl(value, 'page', callback) },
                  ]}
                >
                  <Input placeholder='The URL (web address) for redirect.' />
                </Form.Item>
              );
            case 'platform':
              return (
                <SetPageSelect
                  eventId={event?.id}
                  validateUrl={validateUrl}
                  initialPage={parsedData?.page}
                  initialVideo={parsedData?.videoId}
                />
              );
            default:
              return <></>;
          }
        }}
      </Form.Item>

      <Divider />
      <h2>On Demand Redirect Destination:</h2>
      <Form.Item
        name='onDemandRedirectDestination'
        label={<b>Redirect Destination</b>}
        rules={[{ required: false }]}
      >
        <Select
          placeholder='Select a redirect destination'
          options={[
            { value: '__NONE__', label: 'None' },
            { value: 'external', label: 'External Link URL' },
            { value: 'platform', label: 'Platform Page' },
          ]}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) => {
          if (
            prevValues.onDemandRedirectDestination !== currentValues.onDemandRedirectDestination
          ) {
            form.resetFields(['onDemandUrl', 'onDemandPage']);
            form.setFieldsValue({ onDemandUrl: '', onDemandPage: '__NONE__' });
            return true;
          }
          return false;
        }}
        noStyle
      >
        {() => {
          switch (form.getFieldValue('onDemandRedirectDestination')) {
            case 'external':
              return (
                <Form.Item
                  name='onDemandUrl'
                  label={<b>External Link URL (ex. Zoom or Teams meeting)</b>}
                  rules={[
                    {
                      type: 'url',
                      message: 'On Demand link is not valid (make sure to add https://)',
                    },
                  ]}
                >
                  <Input placeholder='The URL (web address) for redirect.' />
                </Form.Item>
              );
            case 'platform':
              return (
                <SetExternalPageSelect
                  eventId={event?.id}
                  validateUrl={validateOnDemandUrl}
                  initialPage={parsedData?.onDemandPage}
                  initialVideo={parsedData?.videoId}
                />
              );
            default:
              return <></>;
          }
        }}
      </Form.Item>

      <Divider key='d4' />

      <Form.Item name='stageId' label={<b>Stage</b>}>
        <Select defaultValue={data?.stage?.id} style={{ width: '100%' }}>
          {renderStageOptions()}
        </Select>
      </Form.Item>
      <Form.Item name='speaker_input' label={<b>Speakers</b>}>
        <Select style={{ width: '100%' }} onChange={addSpeaker}>
          {renderSpeakerOptions()}
        </Select>
      </Form.Item>
      {renderSpeakers()}
      <Divider key='d5' />

      <Form.Item name='location' label={<b>Location</b>}>
        <Select
          defaultValue={`${SessionLocation.ONLINE.charAt(
            0,
          ).toUpperCase()}${SessionLocation.ONLINE.slice(1).toLowerCase()}`}
          style={{ width: '100%' }}
        >
          {renderLocationOptions()}
        </Select>
      </Form.Item>
    </DataEntry>
  );
}

function SetPageSelect({
  initialPage,
  initialVideo,
  eventId,
  validateUrl,
}: {
  initialPage?: string;
  initialVideo?: string;
  eventId?: string;
  validateUrl: (
    value: string,
    otherField: string,
    cb: (error?: string | undefined) => void,
  ) => void;
}) {
  const { data: rawPages } = usePages();
  const { data: rawVideos } = useVideos(eventId);
  const { data: videoCalls } = useVideoCalls(eventId);

  const pages: Page[] = useMemo(
    () => [
      {
        value: '__NONE__',
        label: 'Select a platform page for redirect.',
        id: '__NONE__',
        type: 'page',
      },
      ...(rawPages?.map((page) => ({
        value: `?page=${page.id}`,
        label: page.title,
        id: page.id,
        template: page.template,
        type: 'page' as const,
      })) || []),
      ...(videoCalls
        ?.filter((videoCall) => !!videoCall.title)
        ?.map((videoCall) => ({
          value: `?videoCall=${videoCall.id}`,
          label: `${videoCall.title} - Video call`,
          id: videoCall.id,
          type: 'videoCall' as const,
        })) || []),
    ],
    [rawPages, videoCalls],
  );

  const [selectedPage, setSelectedPage] = useState<Page | undefined>();

  useEffect(() => {
    if (pages.length) {
      setSelectedPage((current) => current ?? pages.find((page) => page.value === initialPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  const videos = useMemo(
    () => [
      { value: '__NONE__', label: 'None' },
      ...(rawVideos?.map((video) => ({
        value: video.id,
        label: video.title,
      })) ?? []),
    ],
    [rawVideos],
  );

  return (
    <>
      <Form.Item
        name='page'
        label={<b>Platform Page</b>}
        initialValue='__NONE__'
        rules={[
          {
            validator: (_, value, callback) => validateUrl(value, 'externalUrl', callback),
          },
        ]}
      >
        <Select
          placeholder='Page'
          options={pages}
          onSelect={(_, item) => (item as Page).type === 'page' && setSelectedPage(item as Page)}
        />
      </Form.Item>
      {selectedPage?.template === PageTemplate.VIDEO_ON_DEMAND && (
        <Form.Item name='videoId' label='Video' initialValue={initialVideo ?? '__NONE__'}>
          <Select placeholder='Video' options={videos} />
        </Form.Item>
      )}
    </>
  );
}

function SetExternalPageSelect({
  initialPage,
  initialVideo,
  eventId,
  validateUrl,
}: {
  initialPage?: string;
  initialVideo?: string;
  eventId?: string;
  validateUrl: (
    value: string,
    otherField: string,
    cb: (error?: string | undefined) => void,
  ) => void;
}) {
  const { data: rawPages } = usePages();
  const { data: rawVideos } = useVideos(eventId);
  const { data: videoCalls } = useVideoCalls(eventId);

  const pages: Page[] = useMemo(
    () => [
      {
        value: '__NONE__',
        label: 'Select a platform page for redirect.',
        id: '__NONE__',
        type: 'page',
      },
      ...(rawPages?.map((page) => ({
        value: `?page=${page.id}`,
        label: page.title,
        id: page.id,
        template: page.template,
        type: 'page' as const,
      })) || []),
      ...(videoCalls
        ?.filter((videoCall) => !!videoCall.title)
        ?.map((videoCall) => ({
          value: `?videoCall=${videoCall.id}`,
          label: `${videoCall.title} - Video call`,
          id: videoCall.id,
          type: 'videoCall' as const,
        })) || []),
    ],
    [rawPages, videoCalls],
  );

  const [selectedPage, setSelectedPage] = useState<Page | undefined>();

  useEffect(() => {
    if (pages.length) {
      setSelectedPage((current) => current ?? pages.find((page) => page.value === initialPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  const videos = useMemo(
    () => [
      { value: '__NONE__', label: 'None' },
      ...(rawVideos?.map((video) => ({
        value: video.id,
        label: video.title,
      })) ?? []),
    ],
    [rawVideos],
  );

  return (
    <>
      <Form.Item
        name='onDemandPage'
        label={<b>Platform Page</b>}
        initialValue='__NONE__'
        rules={[
          {
            validator: (_, value, callback) => validateUrl(value, 'onDemandUrl', callback),
          },
        ]}
      >
        <Select
          placeholder='Page'
          options={pages}
          onSelect={(_, item) => (item as Page).type === 'page' && setSelectedPage(item as Page)}
        />
      </Form.Item>
      {selectedPage?.template === PageTemplate.VIDEO_ON_DEMAND && (
        <Form.Item name='onDemandVideoId' label='Video' initialValue={initialVideo ?? '__NONE__'}>
          <Select placeholder='Video' options={videos} />
        </Form.Item>
      )}
    </>
  );
}

function MandatoryFields({ form }: { form: FormInstance<any> }) {
  const [mandatory, _setMandatory] = useState<boolean>(form.getFieldValue('mandatory') || false);
  const setMandatory = useCallback(
    (value: boolean) => {
      _setMandatory(value);
      form.setFieldsValue({ automaticPush: value });
    },
    [_setMandatory, form],
  );

  useEffect(() => {
    setMandatory(form.getFieldValue('mandatory'));
  }, [form, setMandatory]);

  return (
    <Row>
      <Col span={6}>
        <Form.Item
          name='highlighted'
          label={<b>Highlighted</b>}
          initialValue={false}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          name='mandatory'
          label={<b>Mandatory</b>}
          initialValue={false}
          valuePropName='checked'
        >
          <Switch onChange={setMandatory} />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          initialValue={false}
          name='automaticPush'
          label={<b>Automatic Push (at start)</b>}
          valuePropName='checked'
        >
          <Switch disabled={!mandatory} />
        </Form.Item>
      </Col>
    </Row>
  );
}
