import { useMutation, useQuery, useQueryClient } from 'react-query';

import transform from '@transforms/locationVisit';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';

export const useLocationVisits = (
  eventId?: string,
  startDate?: string | Date,
  endDate?: string | Date,
) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['locationVisits', eventId, startDate, endDate],
    async () => {
      const { body } = await fetch(
        `/events/${eventId}/location?${startDate ? `startDate=${startDate}` : ''}${
          endDate ? `&endDate=${endDate}` : ''
        }
      `,
        { token: accessToken },
      );

      return transform.one(body);
    },
    { enabled: !!eventId },
  );
};

export const useResetBoothAnalytics = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (eventId: string) => {
      return fetch(`/events/${eventId}/location`, { method: 'DELETE', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('events/locationVisits');
      },
    },
  );
};

export default { useLocationVisits, useResetBoothAnalytics };
