import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Button, List, Space, Divider, Popconfirm, message } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';

import { useCurrentEvent } from '@hooks/useEvents';
import { LazyModal } from '@components/LazyModal';
import { VideoCategory as VideoCategoryModal, Video as VideoModal } from '@components/Modals';

import {
  usePageVideoCategories,
  useUpdateVideoCategory,
  useCreateVideoCategory,
  useUpdateVideo,
  useCreateVideo,
  useDeleteVideo,
  useDeleteVideoCategory,
  useUploadVideoImage,
  useDuplicateVideo,
  useDuplicateVideoCategory,
} from '@hooks/useVideos';
import { VideoCategoryResource, VideoCategory } from '@transforms/videoCategory';
import { VideoResource, Video } from '@transforms/video';

import ListItem from './VOD/ListItem';
import type { PageProps } from './props';

export function VideoOnDemand({ page, language }: PageProps) {
  const { data: event } = useCurrentEvent();

  const listRef = useRef<HTMLDivElement>(null);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<VideoCategoryResource | undefined>();
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<{
    video?: VideoResource;
    categoryId?: string;
  }>({});

  const { data: videoCategories, isLoading } = usePageVideoCategories(
    event?.id,
    page?.id,
    language,
  );
  const { mutateAsync: duplicateVideoRoom } = useDuplicateVideo(event?.id);
  const { mutateAsync: duplicateVideoCategory } = useDuplicateVideoCategory(event?.id);
  const { mutate: updateVideoCategory } = useUpdateVideoCategory(
    event?.id,
    selectedCategory?.id,
    language,
  );
  const { mutate: deleteVideoCategory } = useDeleteVideoCategory(event?.id);

  const { mutate: createVideoCategory } = useCreateVideoCategory(event?.id, language);
  const { mutate: createVideo } = useCreateVideo(event?.id, language);
  const { mutate: updateVideo } = useUpdateVideo(event?.id, selectedVideo.video?.id, language);
  const { mutateAsync: uploadVideoImage } = useUploadVideoImage(event?.id);
  const { mutate: deleteVideo } = useDeleteVideo(event?.id);

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Videos';
      }
    }
  }, [listRef.current?.innerHTML]);

  const onOpenCategoryModal = (category?: VideoCategoryResource) => {
    if (category) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory(undefined);
    }
    setOpenCategoryModal(true);
  };

  const onCloseCategoryModal = () => {
    setOpenCategoryModal(false);
    setSelectedCategory(undefined);
  };

  // -----------------------------------------------
  // MARK: - CATEGORY
  // -----------------------------------------------

  const onCreateCategory = (values: VideoCategory, done: (err?: Error) => void) => {
    createVideoCategory(
      {
        ...values,
        position: values.position ? parseInt(String(values.position), 10) : undefined,
        pageId: page?.id,
      },
      {
        onSuccess: async () => {
          onCloseCategoryModal();
          done();
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  const onUpdateCategory = useCallback(
    (values: VideoCategory, done: (err?: Error) => void) => {
      updateVideoCategory(
        {
          ...values,
          position: values.position ? parseInt(String(values.position), 10) : undefined,
          pageId: page.id,
        },
        {
          onSuccess: () => {
            onCloseCategoryModal();
            done();
          },
          onError: (error: any) => {
            done(error);
          },
        },
      );
    },
    [page, updateVideoCategory],
  );

  const onDuplicateVideoCategory = async (category?: VideoCategoryResource) => {
    if (!category?.id) return;
    await duplicateVideoCategory(category.id);
    message.success('Video category duplicated');
  };

  const onDeleteCategory = (id: string) => {
    deleteVideoCategory(id);
  };

  // -----------------------------------------------
  // MARK: - VIDEOS
  // -----------------------------------------------

  const onOpenVideoModal = (video?: VideoResource, categoryId?: string) => {
    setSelectedVideo({ video, categoryId });
    setOpenVideoModal(true);
  };

  const onCloseVideoModal = () => {
    setOpenVideoModal(false);
    setSelectedVideo({ video: undefined, categoryId: undefined });
  };

  const onCreateVideo = (values: Video & { image?: File | Blob }, done: (err?: Error) => void) => {
    createVideo(
      {
        ...values,
        position: values.position ? parseInt(String(values.position), 10) : undefined,
        categoryId: selectedVideo.categoryId,
      },
      {
        onSuccess: async (val) => {
          try {
            if (values.image) {
              try {
                await uploadVideoImage({ videoId: val?.body?.id, image: values.image });
              } catch (error) {
                done(error as Error);
                return;
              }
            }

            onCloseVideoModal();
            done();
          } catch {
            done(new Error('REQUEST_ERROR'));
          }
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  const onUpdateVideo = useCallback(
    async (
      values: Video & { image?: File | Blob },
      done: (err?: Error) => void,
      shouldClose = true,
    ) => {
      const position = values.position ?? selectedVideo?.video?.position ?? null;

      updateVideo(
        {
          ...selectedVideo.video,
          ...values,
          position: position ? parseInt(String(position), 10) : undefined,
          categoryId: selectedVideo.categoryId,
        },
        {
          onSuccess: async () => {
            try {
              if (selectedVideo?.video?.id && values.image) {
                try {
                  await uploadVideoImage({
                    videoId: selectedVideo?.video?.id,
                    image: values.image,
                  });
                } catch (error) {
                  done(error as Error);
                  return;
                }
              }

              if (shouldClose) onCloseVideoModal();
              done();
            } catch {
              done(new Error('REQUEST_ERROR'));
            }
          },
          onError: (error: any) => {
            done(error);
          },
        },
      );
    },
    [selectedVideo, updateVideo, uploadVideoImage],
  );

  const onDuplicateVideo = async (video?: VideoResource) => {
    if (!video?.id) return;
    await duplicateVideoRoom(video.id);
    message.success('Video duplicated');
  };

  const onDeleteVideo = (videoId: string) => {
    deleteVideo(videoId);
  };

  if (isLoading || !videoCategories) return null;

  return (
    <>
      <h2>Video On Demand Settings</h2>
      <p>
        First, add a Video Category. Within the Category, add any Videos (whether an embed [ex. Vixy
        or Vimeo] player or URL) directly within the platform.
      </p>
      <p>
        Create a new Video Category or Video from scratch or duplicate any existing ones. Edit any
        existing Categories or Videos via the pencil icon.
      </p>
      <p>
        Each Video will be displayed as a thumbnail icon with option to upload an image. Note, there
        is a gradient filter overlay on this thumbnail to make the text legible.
      </p>
      <Space direction='vertical' style={{ width: '100%' }}>
        {videoCategories.map((videoCategory) => (
          <Card
            key={videoCategory.id}
            title={videoCategory.title}
            extra={
              <Space>
                <Button
                  shape='circle'
                  icon={<CopyOutlined />}
                  onClick={() => onDuplicateVideoCategory(videoCategory)}
                />
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => onOpenCategoryModal(videoCategory)}
                />
                <Popconfirm
                  title='Are you sure you want to delete this category?'
                  onConfirm={() => onDeleteCategory(videoCategory.id)}
                  okText='Delete'
                  cancelText='Cancel'
                >
                  <Button danger shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            }
          >
            <div ref={listRef}>
              <List
                itemLayout='horizontal'
                dataSource={videoCategory.videos}
                renderItem={(video) => (
                  <ListItem
                    video={video}
                    onDuplicate={onDuplicateVideo}
                    onEdit={() => onOpenVideoModal(video, videoCategory.id)}
                    onDelete={onDeleteVideo}
                  />
                )}
                footer={
                  <Button
                    type='dashed'
                    block
                    icon={<PlusOutlined />}
                    onClick={() => onOpenVideoModal(undefined, videoCategory.id)}
                  >
                    Add Video
                  </Button>
                }
              />
            </div>
          </Card>
        ))}
        <Button type='dashed' block icon={<PlusOutlined />} onClick={() => onOpenCategoryModal()}>
          Add Video Category
        </Button>
      </Space>
      <Divider key='d1' />
      <LazyModal open={!!openCategoryModal}>
        {(open) => (
          <VideoCategoryModal
            visible={open}
            data={selectedCategory}
            onCancel={onCloseCategoryModal}
            onCreate={onCreateCategory}
            onUpdate={onUpdateCategory}
          />
        )}
      </LazyModal>
      <LazyModal open={!!openVideoModal}>
        {(open) => (
          <VideoModal
            visible={open}
            data={selectedVideo.video}
            eventId={event?.id}
            onCancel={onCloseVideoModal}
            onCreate={onCreateVideo}
            onUpdate={onUpdateVideo}
          />
        )}
      </LazyModal>
    </>
  );
}
