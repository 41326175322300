import { useQuery, useMutation } from 'react-query';

import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import transformUserRole, { UserRole, UserRoleResource } from '@transforms/userRole';
import type { AttendeeRole, BreakoutRole, PageRole, SessionRole } from '@components/Roles';

export const useRoles = (language?: string, eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['roles', eventId, language].filter(Boolean),
    async () => {
      const { body } = await fetch(languageUrl(`/events/${eventId}/roles`, language), {
        token: accessToken,
      });

      return transformUserRole.many(body);
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
};

export const useUpdateRolesStart = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useMutation(async (roles: UserRole[]) => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch<Array<UserRoleResource & { tempId?: string }>>(
      languageUrl(`/events/${eventId}/roles/start`, language),
      {
        method: 'PUT',
        body: { roles },
        token: accessToken,
      },
    );

    return body;
  });
};

export const useUpdateRolesFinish = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useMutation(async (roles: UserRole[]) => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch<UserRoleResource[]>(
      languageUrl(`/events/${eventId}/roles/finish`, language),
      {
        method: 'PUT',
        body: { roles },
        token: accessToken,
      },
    );

    return body;
  });
};

export const useAttendeeRoles = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['attendee-roles', eventId, language].filter(Boolean),
    async () => {
      const { body } = await fetch<AttendeeRole[]>(
        languageUrl(`/events/${eventId}/roles/attendee`, language),
        {
          method: 'GET',
          token: accessToken,
        },
      );

      return body;
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
};

export const useUpdateAttendeeRoles = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useMutation((attendeeRoles: AttendeeRole[]) => {
    if (!eventId) throw new Error('No event');

    return fetch(`/events/${eventId}/roles/attendee`, {
      method: 'PUT',
      body: { attendeeRoles },
      token: accessToken,
    });
  });
};

export const usePageRoles = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['page-roles', eventId, language].filter(Boolean),
    async () => {
      const { body } = await fetch(languageUrl(`/events/${eventId}/roles/page`, language), {
        method: 'GET',
        token: accessToken,
      });

      return body as PageRole[];
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
};

export const useUpdatePageRoles = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useMutation((pageRoles: PageRole[]) => {
    if (!eventId) throw new Error('No event');

    return fetch(`/events/${eventId}/roles/page`, {
      method: 'PUT',
      body: { pageRoles },
      token: accessToken,
    });
  });
};

export const useSessionRoles = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['session-roles', eventId, language].filter(Boolean),
    async () => {
      const { body } = await fetch(languageUrl(`/events/${eventId}/roles/session`, language), {
        method: 'GET',
        token: accessToken,
      });

      return body as SessionRole[];
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
};

export const useUpdateSessionRoles = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useMutation((sessionRoles: SessionRole[]) => {
    if (!eventId) throw new Error('No event');

    return fetch(`/events/${eventId}/roles/session`, {
      method: 'PUT',
      body: { sessionRoles },
      token: accessToken,
    });
  });
};

export const useBreakoutRoles = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['breakout-roles', eventId, language].filter(Boolean),
    async () => {
      const { body } = await fetch(languageUrl(`/events/${eventId}/roles/breakout`, language), {
        method: 'GET',
        token: accessToken,
      });

      return body as BreakoutRole[];
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
};

export const useUpdateBreakoutRoles = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useMutation((breakoutRoles: BreakoutRole[]) => {
    if (!eventId) throw new Error('No event');

    return fetch(`/events/${eventId}/roles/breakout`, {
      method: 'PUT',
      body: { breakoutRoles },
      token: accessToken,
    });
  });
};
