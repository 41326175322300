import { Form, Input } from 'antd';
import React from 'react';

import { VideoCategoryResource } from '@transforms/videoCategory';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface VideoCategoryProps extends DataEntryProps {
  data?: VideoCategoryResource;
}

export function VideoCategory({ ...props }: VideoCategoryProps) {
  return (
    <DataEntry {...props} name='Video Category'>
      <Form.Item name='title' label={<b>Category Title</b>} rules={[{ required: true }]}>
        <Input placeholder='Category Title' />
      </Form.Item>
      <Form.Item name='position' label={<b>Position</b>}>
        <Input type='number' />
      </Form.Item>
    </DataEntry>
  );
}
