import { Modal } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import type { UserResource } from '@transforms/user';

export interface DefaultRoleWarningModalProps {
  visible: boolean;
  user: UserResource;
  onOk: () => void;
  onCancel: () => void;
}

export function DefaultRoleWarningModal({
  visible: modalVisible,
  onOk,
  onCancel,
  user,
}: DefaultRoleWarningModalProps) {
  return (
    <Modal
      width={500}
      visible={modalVisible}
      title={
        <>
          <WarningFilled style={{ color: '#F2B020', marginRight: '8px' }} />
          Confirm Role Assignment
        </>
      }
      okType='primary'
      okText='Confirm'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={onOk}
    >
      <div style={{ fontSize: '16px' }}>
        This user was previously assigned to the role
        {user?.role?.name ? (
          <>
            {' '}
            "<b>{user.role.name}</b>"
          </>
        ) : (
          ''
        )}
        , which is not the default role. Please confirm that you want to assign them to this role.
      </div>
    </Modal>
  );
}
