import { Input, Form, Modal } from 'antd';

type AddBulkUsersModalProps = {
  visible: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
};

export function AddBulkUsersModal({ visible, onCreate, onCancel }: AddBulkUsersModalProps) {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title='Bulk add attendees'
      okText='Invite attendees'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then((values) => {
          onCreate(values);
          form.resetFields();
        });
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
        }}
      >
        <p>Fill in the email addresses of attendees you want to add, separated by a comma.</p>
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder='attendee@company.com, attendee2@company.com' />
        </Form.Item>
      </Form>
    </Modal>
  );
}
