import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Form, Input, Select, List, Switch, Button } from 'antd';
import debounce from 'lodash/debounce';

import { ListItem } from '@components/Sessions';
import { ImageUpload } from '@components/Upload';

import { useSessions } from '@hooks/useSessions';
import { useInfiniteUsers } from '@hooks/useUsers';
import { UserResource } from '@transforms/user';
import { SpeakerResource } from '@transforms/speaker';
import { SessionResource } from '@transforms/session';

import { DataEntry, DataEntryProps } from './DataEntry';
import { PreviewSpeakerModal } from './PreviewSpeaker';

export interface SpeakerProps extends DataEntryProps {
  data?: SpeakerResource;
}

export function Speaker({ data, onCreate, onUpdate, ...restProps }: SpeakerProps) {
  const [form] = Form.useForm();
  const [imagePreview, setImagePreview] = useState<string | undefined | null>(data?.imageUrl);
  const [speakerSessions, setSpeakerSessions] = useState<SessionResource[] | undefined>();
  const { data: sessions } = useSessions({ page: 1, limit: 999 });
  const [searchTerm, setSearchTerm] = useState('');
  const { data: rawUsers, isFetching, fetchNextPage, hasNextPage } = useInfiniteUsers(searchTerm);
  const listRef = useRef<HTMLDivElement>(null);

  const [showSpeakerPreviewModal, setShowSpeakerPreviewModal] = useState<boolean>(false);
  useEffect(() => {
    setSpeakerSessions(data?.sessions as SessionResource[]);
  }, [data?.sessions]);

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Sessions';
      }
    }
  }, [listRef.current?.innerHTML]);

  const users = useMemo(() => {
    const paginatedUsers: UserResource[] = [];
    rawUsers?.pages?.map((userData) => {
      return paginatedUsers.push(...userData.items);
    });

    return paginatedUsers;
  }, [rawUsers]);

  const onCreateCallback = useCallback(
    (values: SpeakerResource & { image?: File | null }, done: () => void) =>
      onCreate(
        {
          ...values,
          imageUrl: imagePreview ? undefined : null,
          sessionIds: speakerSessions?.map((session) => session.id),
        },
        done,
      ),
    [onCreate, imagePreview, speakerSessions],
  );

  const onUpdateCallback = useCallback(
    (values: SpeakerResource & { image?: File | null }, done: () => void) =>
      onUpdate(
        {
          ...values,
          imageUrl: imagePreview ? undefined : null,
          sessionIds: speakerSessions?.map((session) => session.id),
        },
        done,
      ),
    [onUpdate, imagePreview, speakerSessions],
  );

  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 500);

  const onScroll = (event: any) => {
    const { target } = event;
    if (!isFetching && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // eslint-disable-next-line no-unused-expressions
      hasNextPage && fetchNextPage();
      target.scrollTo(0, target.scrollHeight);
    }
  };

  const addSession = (sessionId: string) => {
    setSpeakerSessions((prevSpeakerSessions) =>
      prevSpeakerSessions
        ? [
            ...prevSpeakerSessions,
            sessions?.items?.find((session) => session.id === sessionId) as SessionResource,
          ]
        : [sessions?.items?.find((session) => session.id === sessionId) as SessionResource],
    );
  };

  const removeSession = (sessionId: string) => {
    setSpeakerSessions(
      speakerSessions?.filter((session) => {
        return session.id !== sessionId;
      }),
    );
  };

  const renderUserOptions = () => {
    return (
      <>
        {users?.map((user) => (
          <Select.Option value={user.id} key={user.id}>
            {user.fullName ?? user.email}
          </Select.Option>
        ))}
      </>
    );
  };

  const renderSessions = () => {
    return (
      <div ref={listRef}>
        <List
          itemLayout='horizontal'
          dataSource={speakerSessions}
          renderItem={(session) => (
            <ListItem session={session} onDelete={removeSession} showTags={false} />
          )}
        />
      </div>
    );
  };

  const renderSessionOptions = () => {
    return (
      <>
        {sessions?.items?.map((session) => (
          <Select.Option value={session.id} key={session.id}>
            {session.title}
          </Select.Option>
        ))}
      </>
    );
  };

  return (
    <DataEntry
      {...restProps}
      data={data}
      form={form}
      onCreate={onCreateCallback}
      onUpdate={onUpdateCallback}
      name='Speaker'
    >
      <Form.Item
        name='name'
        label={<b>Name</b>}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Name' />
      </Form.Item>
      <Form.Item name='jobTitle' label={<b>Job Title</b>}>
        <Input placeholder='Job Title' />
      </Form.Item>
      <Form.Item name='companyName' label={<b>Company</b>}>
        <Input placeholder='Company' />
      </Form.Item>
      <Form.Item name='bio' label={<b>Bio</b>}>
        <Input.TextArea
          placeholder='A short bio about this speaker'
          showCount
          maxLength={1000}
          rows={4}
        />
      </Form.Item>
      <Form.Item name='image' label={<b>Image</b>}>
        <ImageUpload
          initialPreview={imagePreview}
          onChangePreview={setImagePreview}
          onRemove={() => form.setFields([{ name: 'image', value: null }])}
          textOverwrite='The maximum file size is 5 MB and must be at least 600x380 pixels in either JPG or PNG formats.'
          extraButtons={
            <>
              <Button onClick={() => setShowSpeakerPreviewModal(true)}>Preview</Button>
              <PreviewSpeakerModal
                visible={showSpeakerPreviewModal}
                speaker={data}
                imagePreview={imagePreview}
                onCancel={() => setShowSpeakerPreviewModal(false)}
              />
            </>
          }
        />
      </Form.Item>
      <Form.Item label={<b>Sessions</b>}>
        <Select style={{ width: '100%' }} onChange={addSession}>
          {renderSessionOptions()}
        </Select>
      </Form.Item>
      {renderSessions()}
      <Form.Item name='userId' label={<b>Attendee</b>}>
        <Select
          defaultValue={data?.user?.id}
          style={{ width: '100%' }}
          showSearch
          onSearch={onSearch}
          onPopupScroll={onScroll}
          filterOption={false}
        >
          {renderUserOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        name='chatEnabled'
        label={<b>Chat Enabled</b>}
        initialValue={data ? data.chatEnabled : true}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name='videoCallEnabled'
        label={<b>Video Call Enabled</b>}
        initialValue={data ? data.videoCallEnabled : true}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </DataEntry>
  );
}
