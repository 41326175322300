import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message, Table, Avatar, Tooltip, Popconfirm, Divider } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { PageTemplate } from '@transforms/page';
import { SessionResource } from '@transforms/session';
import { Speaker, SpeakerResource } from '@transforms/speaker';

import { EventLayout } from '@components/Layout';
import { Speaker as SpeakerModal } from '@components/Modals';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import {
  useSpeakers,
  useCreateSpeaker,
  useDuplicateSpeaker,
  useUpdateSpeaker,
  useDeleteSpeaker,
  useUploadSpeakerImage,
} from '@hooks/useSpeakers';
import { useCurrentEvent } from '@hooks/useEvents';

export default function Speakers() {
  const navigate = useNavigate();
  const { data: event } = useCurrentEvent();
  const listRef = useRef<HTMLDivElement>(null);

  if (
    event &&
    event.organisation &&
    !event.organisation.enabledPageTemplates?.includes(PageTemplate.SCHEDULE)
  ) {
    navigate('/');
  }
  const [sorter, setSorter] = useState<{
    field?: keyof SpeakerResource;
    order?: 'ascend' | 'descend';
  }>({});
  const [openSpeakerModal, setOpenSpeakerModal] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState<SpeakerResource | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const { isLoading, data: speakers } = useSpeakers({ ...pagination, sorter }, searchTerm);
  const { mutate: createSpeaker } = useCreateSpeaker();
  const { mutate: updateSpeaker } = useUpdateSpeaker(selectedSpeaker?.id);
  const { mutate: deleteSpeaker } = useDeleteSpeaker();
  const { mutateAsync: uploadSpeakerImage } = useUploadSpeakerImage();
  const { mutateAsync: duplicateSpeaker } = useDuplicateSpeaker(event?.id);

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Speakers';
      }
    }
  }, [listRef.current?.innerHTML]);

  const onOpenSpeakerModal = (speaker?: SpeakerResource) => {
    if (speaker) {
      setSelectedSpeaker(speaker);
    } else {
      setSelectedSpeaker(undefined);
    }
    setOpenSpeakerModal(true);
  };

  const onCloseSpeakerModal = () => {
    setOpenSpeakerModal(false);
    setSelectedSpeaker(undefined);
  };

  const onCreateSpeaker = (
    values: Speaker & { image?: File | Blob; sessionIds?: string[] },
    done: (err?: Error) => void,
  ) => {
    createSpeaker(values, {
      onSuccess: async (val) => {
        if (values.image) {
          try {
            await uploadSpeakerImage({ speakerId: val?.body?.id, image: values.image });
          } catch (error) {
            done(error as Error);
            return;
          }
        }

        onCloseSpeakerModal();
        done();
      },
    });
  };

  const onUpdateSpeaker = (
    values: Speaker & { image?: File | Blob; sessionIds?: string[] },
    done: (err?: Error) => void,
    shouldClose = true,
  ) => {
    updateSpeaker(values, {
      onSuccess: async () => {
        if (selectedSpeaker?.id && values.image) {
          try {
            await uploadSpeakerImage({ speakerId: selectedSpeaker?.id, image: values.image });
          } catch (error) {
            done(error as Error);
            return;
          }
        }

        if (shouldClose) onCloseSpeakerModal();
        done();
      },
    });
  };

  const onDeleteSpeaker = (id: string) => {
    deleteSpeaker(id);
  };

  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, 500);

  const onDuplicateSpeaker = async (speaker?: SpeakerResource) => {
    if (!speaker?.id) return;
    await duplicateSpeaker(speaker.id);
    message.success('Speaker duplicated');
  };

  const renderSessions = (sessions: SessionResource[]) => {
    if (!sessions?.length) return '';
    const title = sessions.length === 1 ? sessions[0].title : `${sessions.length} sessions`;
    return (
      <Tooltip title={title} key='sessions'>
        <p
          style={{
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            margin: 0,
          }}
        >
          {title}
        </p>
      </Tooltip>
    );
  };

  return (
    <EventLayout
      title='Speakers'
      extra={[
        <Input.Search
          placeholder='Search'
          allowClear
          onChange={(e) => onSearch(e.target.value)}
          style={{ width: 200 }}
          key='search'
        />,
        <Button key='menu' type='primary' onClick={() => onOpenSpeakerModal()}>
          Add Speaker
        </Button>,
        <EventLanguageSelect key='language-select' style={{ marginRight: 10 }} />,
      ]}
    >
      Here you can add Speakers which can be assigned to{' '}
      <Link to={`/events/${event?.id}/sessions`}>Sessions</Link> (and vice versa). Note, you can
      also attach Speakers to registered
      <Link to={`/events/${event?.id}/attendees`}> Attendees</Link>.
      <br />
      <br />
      <div ref={listRef}>
        <Table
          loading={isLoading}
          dataSource={speakers?.items.map((speaker) => ({ ...speaker, key: speaker.id }))}
          onChange={(_, __, sort: any) => setSorter(sort)}
          pagination={{
            pageSize: speakers?.meta.itemsPerPage || 10,
            total: speakers?.meta.totalItems,
            onChange: (page, pageSize) => {
              setPagination({ page, limit: pageSize || 10 });
            },
          }}
          columns={[
            {
              title: 'Profile Picture',
              key: 'image',
              dataIndex: 'imageUrl',
              render: (image) => <Avatar size={40} src={image} />,
            },

            {
              title: 'Name',
              key: 'name',
              sorter: true,
              dataIndex: 'name',
              render: (text) => text || 'Unknown speaker',
            },
            {
              title: 'Attendee (Linked)',
              key: 'user.name',
              sorter: true,
              dataIndex: 'user',
              render: (user) =>
                user?.fullName ? (
                  <a href={window.location.href.replace('/speakers', '/attendees')}>
                    {user?.fullName}
                  </a>
                ) : (
                  ''
                ),
            },
            {
              title: 'Job Title',
              key: 'jobTitle',
              sorter: true,
              dataIndex: 'jobTitle',
              render: (text) => text || 'Unknown Job Title',
            },
            {
              title: 'Company',
              key: 'companyName',
              sorter: true,
              dataIndex: 'companyName',
              render: (text) => text || 'Unknown Company',
            },
            {
              title: 'Sessions',
              key: 'sessions',
              dataIndex: 'sessions',
              render: renderSessions,
            },
            {
              title: 'Chat Enabled',
              key: 'chat',
              sorter: true,
              dataIndex: 'chatEnabled',
              width: 145,
              render: (enabled) => (enabled ? 'Yes' : 'No'),
            },
            {
              title: 'Video Call Enabled',
              key: 'videoChat',
              sorter: true,
              dataIndex: 'videoCallEnabled',
              width: 180,
              render: (enabled) => (enabled ? 'Yes' : 'No'),
            },

            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              align: 'right',
              width: 170,
              render: (_, speaker) => [
                <Tooltip title='Duplicate' key='duplicate'>
                  <Button
                    shape='circle'
                    icon={<CopyOutlined />}
                    onClick={() => onDuplicateSpeaker(speaker)}
                  />
                </Tooltip>,
                <Divider key='d1' type='vertical' />,
                <Tooltip title='Edit' key='edit'>
                  <Button
                    shape='circle'
                    icon={<EditOutlined />}
                    onClick={() => onOpenSpeakerModal(speaker)}
                  />
                </Tooltip>,
                <Divider key='d2' type='vertical' />,
                <Popconfirm
                  key='delete'
                  title={
                    <div className='message-box-content-container'>
                      <div className='message-box-content'>
                        <h3>Confirm Deletion!</h3>
                        <span>
                          Are you sure you want to delete this speaker and any associated data?
                          Note, this cannot be undone!
                        </span>
                      </div>
                    </div>
                  }
                  onConfirm={() => onDeleteSpeaker(speaker.id)}
                  okText='Delete'
                  cancelText='Cancel'
                  okButtonProps={{
                    danger: true,
                  }}
                >
                  <Button shape='circle' icon={<DeleteOutlined />} danger />
                </Popconfirm>,
              ],
            },
          ]}
        />
      </div>
      {openSpeakerModal && (
        <SpeakerModal
          visible={openSpeakerModal}
          data={selectedSpeaker}
          onCancel={onCloseSpeakerModal}
          onCreate={onCreateSpeaker}
          onUpdate={onUpdateSpeaker}
        />
      )}
    </EventLayout>
  );
}
