import { Avatar } from 'antd';

import { UserResource } from '@transforms/user';

export function AvatarRow({ attendees }: { attendees: UserResource[] }) {
  const numberOfAttendees = attendees.length > 15 ? 15 : attendees.length;

  return (
    <>
      {attendees.slice(0, numberOfAttendees).map((attendee) => {
        return (
          <Avatar
            size={70}
            key={attendee.id}
            src={attendee.avatarUrl}
            style={{ marginRight: '-1rem' }}
          />
        );
      })}
      {attendees.length > 15 ? (
        <Avatar
          size={70}
          style={{
            backgroundColor: '#f56a00',
            color: '#fde3cf',
            marginRight: '-1rem',
          }}
        >
          +{numberOfAttendees > 15 ? attendees.length - 15 : numberOfAttendees}
        </Avatar>
      ) : null}
    </>
  );
}
