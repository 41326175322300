import { useMutation, useQueryClient } from 'react-query';

import {
  useInit,
  useResource,
  useResources,
  useCreateResource,
  useUpdateResource,
  useDeleteResource,
} from '@hooks/useResources';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import { eventSessionImageEndpoint } from '@utils/uploadEndpoints';
import transform, { SessionResource } from '@transforms/session';

export const useSession = (sessionId: string) => {
  return useResource<SessionResource>('session', sessionId, transform.one);
};

export const useSessions = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: { field?: keyof SessionResource; order?: 'ascend' | 'descend' };
  },
  search?: string,
) => {
  return useResources<SessionResource>('session', transform.many, {
    pagination,
    sorter,
    search,
  });
};

export const useCreateSession = () => {
  return useCreateResource<Omit<SessionResource, 'id'>>('session');
};

export const useDuplicateSession = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/sessions/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sessions');
      },
    },
  );
};

export const useUpdateSession = (id?: string) => {
  return useUpdateResource<Omit<SessionResource, 'id'>>('session', id);
};

export const useDeleteSession = () => {
  return useDeleteResource('session');
};

export const useUploadSessionImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const { eventId } = useInit();

  return useMutation(
    ({ sessionId, image }: { sessionId: string; image: File | Blob }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventSessionImageEndpoint(eventId!, sessionId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sessions');
      },
    },
  );
};

export default {
  useSession,
  useSessions,
  useCreateSession,
  useDuplicateSession,
  useUpdateSession,
  useDeleteSession,
  useUploadSessionImage,
};
