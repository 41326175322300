import React, { useEffect, useState } from 'react';
import { Layout, Button, Tag, Space, message } from 'antd';
import { EyeInvisibleOutlined, MoreOutlined } from '@ant-design/icons';
import ReactShadowScroll from 'react-shadow-scroll';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';

import { useParams } from 'react-router-dom';

import AddNewPageDrawer from '@components/Page/AddNewPageDrawer';
import { LazyModal } from '@components/LazyModal';
import { EventLayout } from '@components/Layout';
import Page from '@components/Page';

import {
  usePages,
  useUpdatePage,
  useUpdatePagePositions,
  useCreatePage,
  useDuplicatePage,
  useDeletePage,
  useUploadCanvasImage,
} from '@hooks/usePages';
import { useLanguage } from '@hooks/useTranslations';

import transformPage, { PageResource, PageTemplate } from '@transforms/page';
import useAuth from '@hooks/auth/useAuth';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';
import { VideoType } from '@transforms/video';

export default function Pages() {
  const { eventId } = useParams<{ eventId?: string }>();
  const pageId = new URLSearchParams(window.location.search).get('page');
  const [selectedPage, setSelectedPage] = useState<PageResource | undefined>(
    pageId ? ({ id: pageId } as any) : undefined,
  );
  const { selectedLanguage } = useLanguage();
  const { data: pages } = usePages();
  const { mutateAsync: duplicatePage } = useDuplicatePage(eventId, selectedLanguage);
  const { mutate: createPage } = useCreatePage(eventId, selectedLanguage);
  const { mutateAsync: updatePage } = useUpdatePage(eventId, selectedPage?.id, selectedLanguage);
  const { mutate: deletePage } = useDeletePage(eventId);
  const { mutate: updatePagePositions } = useUpdatePagePositions(eventId);
  const { mutateAsync: uploadCanvasImage } = useUploadCanvasImage(eventId);

  const [draggableItems, setDraggableItems] = useState(pages);
  const [isDragged, setIsDragged] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const { isSuperAdmin, organisation } = useAuth();

  useEffect(() => {
    if (!pages) {
      return;
    }

    setDraggableItems(pages);

    const page = pages?.find((currPage) => currPage.id === selectedPage?.id);
    if (page === undefined) {
      setSelectedPage(pages?.[0]);
    } else {
      setSelectedPage(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  useEffect(() => {
    if (draggableItems && isDragged) {
      updatePagePositions(draggableItems.map((page, index) => [page.id, index + 1]));

      message.success({
        content: (
          <div className='message-box-content-container'>
            <div className='message-box-content'>
              <h3>Order Updated!</h3>
              <span>Your changes have been successfully updated and saved!</span>
            </div>
          </div>
        ),
        className: 'message-box',
      });
      setIsDragged(false);
    } else if (!draggableItems && isDragged) {
      message.error('Unable to update page order');
    }
  }, [draggableItems, isDragged, updatePagePositions]);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const selectPage = (pageKey: string) => {
    setSelectedPage(pages?.find((page) => page.id === pageKey));
  };

  const onUpdatePage = async ({ dates, ...values }: PageResource & { dates: string[] }) => {
    const menuPosition = { menuPosition: values.menuPosition };
    if (!values.menuPosition) {
      menuPosition.menuPosition = null;
    }
    const formattedDates = dates
      ? { visibleFrom: dates[0], visibleTill: dates[1] }
      : { visibleFrom: null, visibleTill: null };

    const upload: Record<number, File> = {};
    if (values.template === PageTemplate.CANVAS && values?.parameters?.images) {
      // eslint-disable-next-line no-param-reassign
      values.parameters.images = (values.parameters.images as Array<string | File | null>).map(
        (image, index) => {
          if (!image) return null;
          if (image instanceof File) upload[index] = image;
          return '__MAINTAIN__';
        },
      );
    }

    await updatePage({
      ...selectedPage,
      ...values,
      ...menuPosition,
      ...formattedDates,
    });

    if (values.template === PageTemplate.CANVAS) {
      if (selectedPage) {
        await Promise.all(
          Object.entries(upload).map(async ([index, file]) =>
            uploadCanvasImage({
              pageId: selectedPage.id,
              image: file,
              imageIndex: Number.parseInt(index, 10),
            }),
          ),
        );
      } else {
        message.error('Unable to update canvas images');
      }
    }

    message.success({
      content: (
        <div className='message-box-content-container'>
          <div className='message-box-content'>
            <h3>Page Updated!</h3>
            <span>Your changes have been successfully updated and saved!</span>
          </div>
        </div>
      ),
      className: 'message-box',
    });
  };

  const onCreatePage = ({ dates, ...values }: PageResource & { dates: any }) => {
    const formattedDates = dates
      ? { visibleFrom: dates[0], visibleTill: dates[1] }
      : { visibleFrom: null, visibleTill: null };
    const parameters =
      values.template === PageTemplate.LIVE
        ? { ...values.parameters, videoType: values.parameters?.videoType ?? VideoType.VIXY }
        : values.parameters || {};

    createPage(
      { ...values, parameters, ...formattedDates },
      {
        onSuccess: async () => {
          toggleDrawer();
        },
      },
    );
  };

  const onDeletePage = (id: string) => {
    deletePage(id);
  };

  const onDuplicatePage = async () => {
    if (!selectedPage) return;
    const { body } = await duplicatePage(selectedPage!.id);
    const newPage = transformPage.one(body);
    setSelectedPage(newPage);
    message.success('Page duplicated, make sure to check content translations');
  };

  const reorder = (list: any, startIndex: number, endIndex: number): Array<any> => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sorted = reorder(draggableItems, result.source.index, result.destination.index);
    setDraggableItems(sorted);
    setIsDragged(true);
  };

  const getListItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    id: string,
  ) => {
    const defaultStyles = {
      lineHeight: '40px',
      margin: '4px 0 8px',
      padding: '0 16px',
      cursor: 'pointer',
    };

    const isActiveListItem = id === selectedPage?.id;

    const backgroundColor = isActiveListItem ? '#e6f7ff' : '#fff';
    const color = isActiveListItem ? '#1890ff' : '';

    const border = isDragging ? '1px solid #d3d3d3' : '';

    return { ...draggableStyle, ...defaultStyles, backgroundColor, color, border };
  };

  return (
    <EventLayout
      title='All Pages'
      extra={
        <Space>
          <EventLanguageSelect />
        </Space>
      }
    >
      <Layout style={{ background: 'white' }}>
        <Layout.Sider width={256} style={{ background: 'white' }}>
          <div
            style={{
              height: '100%',
              minHeight: '235px',
              maxHeight: 'calc(100vh - 249px)',
              display: 'flex',
              flexDirection: 'column',
              borderRight: '1px solid #ddd',
            }}
          >
            {/* @ts-ignore */}
            <ReactShadowScroll isShadow={false}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='pages'>
                  {(provided) => (
                    <ul
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        padding: '0',
                        margin: '0',
                        listStyleType: 'none',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {draggableItems?.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(providedDeep, snapshot) => (
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            <li
                              {...providedDeep.draggableProps}
                              {...providedDeep.dragHandleProps}
                              ref={providedDeep.innerRef}
                              onClick={() => selectPage(item.id)}
                              style={getListItemStyle(
                                snapshot.isDragging,
                                providedDeep.draggableProps.style,
                                item.id,
                              )}
                            >
                              <MoreOutlined style={{ marginRight: '5px', cursor: 'grab' }} />
                              {!item.enabled && (
                                <EyeInvisibleOutlined style={{ marginRight: '5px' }} />
                              )}
                              {item.title}
                              <Tag style={{ marginLeft: '5px' }}>{item.template}</Tag>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </ReactShadowScroll>

            {/* <Menu
              style={{ flex: 1, width: 255, borderRight: 'none', overflowY: 'auto' }}
              onClick={({ key }) => selectPage(key)}
              selectedKeys={[selectedPage?.id!]}
            >
              {pages?.map((page) => (
                <Menu.Item key={page.id}>
                  <Space>
                    {!page.enabled && <EyeInvisibleOutlined />}
                    {page.title}
                    <Tag>{page.template}</Tag>
                  </Space>
                </Menu.Item>
              ))}
            </Menu> */}

            {(isSuperAdmin || organisation) && (
              <div style={{ width: '100%', borderTop: '1px solid #ddd' }}>
                <Space direction='vertical' style={{ margin: '20px', width: 'calc(100% - 40px)' }}>
                  <Button type='primary' block onClick={toggleDrawer}>
                    Add Page
                  </Button>
                  <Button type='primary' block onClick={onDuplicatePage} disabled={!pages?.length}>
                    Duplicate
                  </Button>
                </Space>
              </div>
            )}
          </div>
        </Layout.Sider>
        {selectedPage ? (
          <Page
            page={selectedPage!}
            onFinish={onUpdatePage}
            onDelete={onDeletePage}
            language={selectedLanguage}
            title={selectedPage!.title}
            key={selectedPage!.id}
          />
        ) : (
          <div style={{ maxWidth: '60%', padding: '1em' }}>
            There are 8 types of pages:
            <ol>
              <li>Attendees: Portal to interact with other attendees.</li>
              <li>Schedule: Virtual agenda linked to Sessions.</li>
              <li>
                Live: Livestream embed (URL) with Virtual Audience (Zoom) and Q&A (Slido)
                integration options.
              </li>
              <li>
                Video On Demand: Store videos (embed [Vixy or Vimeo] players or URL) directly within
                the platform.
              </li>
              <li> Virtual Venue: Virtual 360 Environment (Lobby, Expo, or Show Room)</li>
              <li>
                Basic Content Page: Options for basic text and iFrame embed displayed over the event
                background image (Theme).
              </li>
              <li>
                Breakout Page: Landing page for various links (internal or external video
                conferences or any embed URL).
              </li>
            </ol>
            Create a new Page from scratch or duplicate any existing Page. Select an existing Page
            from the sidebar to edit its contents or change the Page Type. Note, only a Super Admin
            can create, duplicate, or delete pages and change the URL.
          </div>
        )}
      </Layout>
      <LazyModal open={showDrawer}>
        {(open) => (
          <AddNewPageDrawer
            templatesEnabled
            visible={open}
            toggleDrawer={toggleDrawer}
            onFinish={onCreatePage}
          />
        )}
      </LazyModal>
    </EventLayout>
  );
}
