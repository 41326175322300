import { useEffect, useState } from 'react';
import { Alert, Button, Col, Divider, Drawer as AntDrawer, Form, Select, Switch } from 'antd';
import { DrawerProps as AntDrawerProps } from 'antd/lib/drawer';
import { RefetchOptions } from 'react-query';

import { useRoles } from '@hooks/useRoles';
import { useCurrentEvent } from '@hooks/useEvents';
import { languages, useLanguage } from '@hooks/useTranslations';
import { ATTENDANCE_TYPE, UserResource } from '@transforms/user';
import type { UserRoleResource } from '@transforms/userRole';

type BulkDrawerProps = AntDrawerProps & {
  users?: UserResource[];
  onClose: () => void;
  showMagicLinks?: () => void;
  refetch?: (options?: RefetchOptions) => any;
  onFinish: (values: UserResource) => Promise<boolean> | void;
};

export function BulkDrawer({
  users,
  onClose,
  showMagicLinks,
  refetch,
  onFinish,
  ...restProps
}: BulkDrawerProps) {
  const [form] = Form.useForm();
  const { data: event } = useCurrentEvent();
  const { selectedLanguage } = useLanguage();
  const { data: roles } = useRoles(selectedLanguage, event?.id);
  const [oldRoles, setOldRoles] = useState<UserRoleResource[]>([]);

  const language = (
    <Col span={12}>
      <Form.Item name='language' label='Language'>
        <Select
          allowClear
          placeholder='English'
          options={Object.values(languages).filter(
            (lng) => event?.languages?.includes(lng.value) !== false,
          )}
        />
      </Form.Item>
    </Col>
  );

  const userRole = (
    <Col span={12}>
      <Form.Item
        name='role'
        label='User Role'
        rules={[{ required: true, message: 'Please choose the user type' }]}
      >
        <Select
          filterOption={(input, option) =>
            option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder='Please choose the type'
          options={roles?.map((role) => ({
            value: role.id,
            label: role.name,
          }))}
        />
      </Form.Item>
    </Col>
  );

  const attendance = (
    <Col span={12}>
      <Form.Item name='attendance' label='Attendance'>
        <Select
          allowClear
          placeholder='Choose attendance type'
          options={ATTENDANCE_TYPE.map((item) => ({
            value: item.key,
            label: item.value,
          }))}
        />
      </Form.Item>
    </Col>
  );

  const boothIds = (
    <Col span={12}>
      <Form.Item name='boothIds' label='Booths'>
        <Select mode='tags' allowClear placeholder='Booths' />
      </Form.Item>
    </Col>
  );

  const unsubscribe = (
    <Col span={12}>
      <Form.Item
        name='subscribed'
        label='Subscribed to emails'
        initialValue
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </Col>
  );

  const renderUserID = () => {
    if (!users) return null;
    return (
      <>
        <Divider key='d1' />
        <h2>User IDs</h2>
        <table>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                  <b>{user.email}</b>
                </td>
                <td style={{ paddingLeft: '15px' }}>{user.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const cancelButtonClicked = () => {
    form.resetFields();
    onClose();
    setOldRoles([]);
  };

  useEffect(() => {
    if (users) {
      const uniqueRoles = (
        users.map((user) => user.role).filter(Boolean) as UserRoleResource[]
      ).filter((role, index, all) => all.findIndex(({ id }) => id === role.id) === index);

      if (uniqueRoles.length > 1) setOldRoles(uniqueRoles);
    }
  }, [users]);

  const footer = (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Button onClick={() => cancelButtonClicked()} style={{ marginRight: 10 }}>
        Cancel
      </Button>
      <Button htmlType='submit' type='primary' onClick={form.submit}>
        Save
      </Button>
    </div>
  );

  const onSubmit = async (values: UserResource & { subscribed: boolean }) => {
    const success = (await onFinish({ ...values, unsubscribed: !values.subscribed })) ?? true;
    if (success === true) {
      form.resetFields();
    }
  };

  if (!users) return null;

  const data = users.find((user) => !!user.language);

  const alertDescription = (
    <>
      <div style={{ marginBottom: '1em' }}>
        Friendly reminder! Some attendees already have another role, these will be overwritten.
        Roles:
      </div>
      <ul>
        {oldRoles.map((role) => {
          return <li key={role.id}>{role.name}</li>;
        })}
      </ul>
    </>
  );

  if (!data) return null;

  return (
    <AntDrawer
      title='Edit Profiles'
      width={720}
      bodyStyle={{ paddingBottom: 80 }}
      footer={footer}
      onClose={() => cancelButtonClicked()}
      {...restProps}
    >
      <Form
        key={data?.id}
        form={form}
        layout='vertical'
        initialValues={{ ...data, attendance: null, subscribed: !data?.unsubscribed }}
        onFinish={onSubmit}
      >
        <Alert
          message='Warning'
          description={alertDescription}
          type='warning'
          style={{ marginBottom: '1em' }}
          showIcon
        />
        {language}
        {userRole}
        {attendance}
        {boothIds}
        {unsubscribe}
        {renderUserID()}
        <Divider key='d2' />
      </Form>
    </AntDrawer>
  );
}
