import { useMutation, useQueryClient } from 'react-query';

import {
  useInit,
  useResource,
  useResources,
  useCreateResource,
  useUpdateResource,
  useDeleteResource,
} from '@hooks/useResources';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import { eventSpeakerImageEndpoint } from '@utils/uploadEndpoints';
import transform, { SpeakerResource } from '@transforms/speaker';

export const useSpeaker = (speakerId: string) => {
  return useResource<SpeakerResource>('speaker', speakerId, transform.one);
};

export const useSpeakers = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: { field?: keyof SpeakerResource; order?: 'ascend' | 'descend' };
  },
  search?: string,
) => {
  return useResources<SpeakerResource>('speaker', transform.many, {
    pagination,
    sorter,
    search,
  });
};

export const useCreateSpeaker = () => {
  return useCreateResource<Omit<SpeakerResource, 'id'>>('speaker');
};

export const useDuplicateSpeaker = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (id: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/speakers/${id}/duplicate`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('speakers');
      },
    },
  );
};

export const useUpdateSpeaker = (id?: string) => {
  return useUpdateResource<Omit<SpeakerResource, 'id'>>('speaker', id);
};

export const useDeleteSpeaker = () => {
  return useDeleteResource('speaker');
};

export const useUploadSpeakerImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();
  const { eventId } = useInit();

  return useMutation(
    ({ speakerId, image }: { speakerId: string; image: File | Blob }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventSpeakerImageEndpoint(eventId!, speakerId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('speakers');
      },
    },
  );
};

export default {
  useSpeaker,
  useSpeakers,
  useCreateSpeaker,
  useDuplicateSpeaker,
  useUpdateSpeaker,
  useDeleteSpeaker,
};
