import parse, { Attributes } from '@utils/parse';

import { parseUserRole } from './userRole';
import { parseVideoCallParticipant } from './videoCallParticipant';

export const parseVideoCall = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    title: attributes.optional.string('title'),
    description: attributes.optional.string('description'),
    imageUrl: attributes.optional.string('imageUrl'),
    roomUrl: attributes.string('roomUrl'),
    hostRoomUrl: attributes.optional.string('hostRoomUrl'),
    full: attributes.boolean('full'),
    video: attributes.optional.boolean('video'),
    audio: attributes.optional.boolean('audio'),
    screenShare: attributes.optional.boolean('screenShare'),
    chat: attributes.optional.boolean('chat'),
    startDate: attributes.date('startDate'),
    endDate: attributes.date('endDate'),
    maxUsers: attributes.optional.number('maxUsers'),
    categoryId: attributes.optional.id('categoryId'),
    position: attributes.optional.number('position'),
    roles: attributes.optional.custom('roles', (roles: any) => {
      return parse.many(roles, parseUserRole);
    }),
    participants: attributes.optional.custom('participants', (participants: any) => {
      return parse.many(participants, parseVideoCallParticipant);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseVideoCall);
}

export function many(data: any) {
  return parse.many(data, parseVideoCall);
}

export type VideoCallResource = ReturnType<typeof one>;
export type VideoCall = Omit<VideoCallResource, 'id'>;

export default { one, many };
