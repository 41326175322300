import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import queryString from 'query-string';
import dayjs from 'dayjs';

import transform, { User } from '@transforms/user';
import useAuth from '@hooks/auth/useAuth';
import fetch from '@utils/fetch';
import { csvUploadEndpoint, eventUserImageEndpoint } from '@utils/uploadEndpoints';
import { useInit } from '@hooks/useResources';

export const useUser = (eventId: string, userId: string) => {
  const { accessToken } = useAuth();

  return useQuery(['user', eventId, userId], async () => {
    const { body } = await fetch(`/events/${eventId}/users/${userId}`, { token: accessToken });

    return transform.one(body);
  });
};

export const useUsers = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: { field?: keyof User; order?: 'ascend' | 'descend' };
  } = { page: 1, limit: 10 },
  search?: string,
  role?: string,
  options?: string[],
  fields = false,
  tags = false,
) => {
  const { eventId } = useInit();
  const { accessToken } = useAuth();
  const query = queryString.stringify({
    ...pagination,
    sortField: sorter?.field,
    sortOrder: sorter?.order,
    search: search || undefined,
    roles: role,
    options: options?.join(','),
    getFields: fields,
    getTags: tags,
  });

  return useQuery(
    ['users', eventId, pagination?.page, pagination?.limit, search, role, options].filter(Boolean),
    async () => {
      const { body } = await fetch(`/events/${eventId}/users?${query}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      keepPreviousData: true,
      enabled: !!eventId,
    },
  );
};

export const useInfiniteUsers = (search?: string) => {
  const { eventId } = useInit();
  const { accessToken } = useAuth();

  return useInfiniteQuery(
    ['users', eventId, search].filter(Boolean),
    async ({ pageParam = 1 }) => {
      const query = queryString.stringify({
        search: search || undefined,
        page: pageParam,
        limit: '30',
      });
      const { body } = await fetch(`/events/${eventId}/users?${query}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) return undefined;
        return lastPage.meta.currentPage + 1;
      },
    },
  );
};

export const useCreateUser = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: User) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useCreateBulkUsers = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users/bulk`, {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useUpdateUser = (eventId?: string, userId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: User) => {
      if (!eventId) throw new Error('No event');
      if (!userId) throw new Error('No user');

      return fetch(`/events/${eventId}/users/${userId}`, {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useUpdateUsers = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: { data: User; userIds: string[] }) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users`, {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useDeleteUser = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (userId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users/${userId}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useDeleteUsers = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (userIds?: string[]) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users`, {
        method: 'DELETE',
        token: accessToken,
        body: { userIds },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useExportUsers = (eventId?: string) => {
  const { accessToken } = useAuth();

  const timezone = dayjs.tz.guess();

  const exportUsers = async () => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(`/events/${eventId}/users/csv/export?timezone=${timezone}`, {
      token: accessToken,
      headers: {
        Acccept: 'text/csv',
        'Content-Type': 'text/csv',
      },
    });

    const blob = new Blob([body], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `users-${+new Date()}.csv`;
    link.click();
  };

  const exportAvatars = async (fieldId: string) => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(`/events/${eventId}/analytics/export/avatars/${fieldId}`, {
      token: accessToken,
      headers: {
        Acccept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
    });

    const blob = new Blob([body], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `export-${+new Date()}.zip`;
    link.click();
  };

  return {
    exportUsers,
    exportAvatars,
  };
};

export const useSendMagicLink = (eventId?: string) => {
  const { accessToken } = useAuth();

  const sendMagicLink = async (userId: string) => {
    if (!eventId) throw new Error('No event');

    const { body: success } = await fetch(`/events/${eventId}/users/${userId}/send-magic-link`, {
      token: accessToken,
    });

    return success;
  };

  return {
    sendMagicLink,
  };
};

export const useSendMagicLinks = (eventId?: string) => {
  const { accessToken } = useAuth();

  const sendMagicLinks = async () => {
    if (!eventId) throw new Error('No event');

    const { body: success } = await fetch(`/events/${eventId}/users/send-magic-links`, {
      method: 'POST',
      token: accessToken,
    });

    return success;
  };

  return {
    sendMagicLinks,
  };
};

export const useAcceptBulkUsers = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (userIds: string[]) => {
      if (!eventId) throw new Error('No event');
      return fetch(`/events/${eventId}/users/accept/bulk`, {
        method: 'POST',
        body: userIds,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useAcceptUser = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (userId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users/accept/${userId}`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useDeclineUser = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (userId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/users/decline/${userId}`, {
        method: 'POST',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useUploadUserImage = () => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ userId, image }: { userId: string; image: File }) => {
      const body = new FormData();
      body.append('image', image);

      return fetch(eventUserImageEndpoint(userId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};

export const useImportUsers = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (body: FormData) => {
      if (!eventId) throw new Error('No event');

      return fetch(csvUploadEndpoint(eventId), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};
