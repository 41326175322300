import parse, { Attributes } from '@utils/parse';

export enum MailType {
  MAGIC_LINK = 'MAGIC_LINK',
  EVENT_WELCOME = 'EVENT_WELCOME',
  EVENT_REMINDER = 'EVENT_REMINDER',
  EVENT_FOLLOWUP = 'EVENT_FOLLOWUP',
}

export enum MailTargetGroup {
  ALL = 'ALL',
  VISITORS = 'VISITORS',
  NON_VISITORS = 'NON_VISITORS',
  ATTENDEES = 'ATTENDEES',
  NON_APPROVED = 'NON_APPROVED',
}

export enum MailLayoutType {
  LEFT_IMAGE = 'LEFT_IMAGE',
  RIGHT_IMAGE = 'RIGHT_IMAGE',
  FULL_WIDTH_IMAGE = 'FULL_WIDTH_IMAGE',
}

export const parseMailLayout = (attributes: Attributes) => {
  return {
    type: attributes.enum<typeof MailLayoutType>('type', MailLayoutType),
    imageUrl: attributes.string('imageUrl'),
    text: attributes.optional.string('text'),
  };
};

export const parseMail = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    type: attributes.enum<typeof MailType>('type', MailType),
    value: attributes.string('value'),
    subject: attributes.optional.string('subject'),
    senderName: attributes.optional.string('senderName'),
    targetGroup: attributes.enum<typeof MailTargetGroup>('targetGroup', MailTargetGroup),
    properties: attributes.object('properties'),
    useLayout: attributes.boolean('useLayout'),
    delayedDate: attributes.optional.date('delayedDate'),
    layout: {
      text: attributes.optional.string('layout_text'),
      type: attributes.optional.enum<typeof MailLayoutType>('layout_type', MailLayoutType),
      imageUrl: attributes.optional.string('layout_imageUrl'),
    },
  };
};

export function one(data: any) {
  return parse.one(data, parseMail);
}

export function many(data: any) {
  return parse.many(data, parseMail);
}

export type MailResource = ReturnType<typeof one>;
export type Mail = Omit<MailResource, 'id' | 'delayedDate'>;

export default { one, many };
