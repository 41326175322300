import React from 'react';
import { Space, Modal } from 'antd';

import breakoutStyles from '../../styles/BreakoutPreview.module.css';

type PreviewBreakoutModalProps = {
  visible: boolean;
  formData: any;
  imagePreview: string | undefined | null;
  onCancel(): void;
};

export function PreviewBreakout({
  visible,
  formData,
  imagePreview,
  onCancel,
}: PreviewBreakoutModalProps) {
  const name = formData.title || 'Test eventname';
  const description = formData.subtitle || 'Event description';

  const Breakout = () => (
    <div
      className={breakoutStyles.container}
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0), rgb(60,60,60)), url('${imagePreview}')`,
      }}
    >
      <Space direction='vertical'>
        <div className={breakoutStyles.eventDescription}>{description}</div>
        <div className={breakoutStyles.eventName}>{name}</div>
        <div className={breakoutStyles.button}>Finished</div>
      </Space>
    </div>
  );

  return (
    <Modal title='Preview' visible={visible} onCancel={onCancel} footer={null} width='700px'>
      <Breakout />
    </Modal>
  );
}
