import React, { useEffect, useMemo, useState } from 'react';
import { Button, Space, Layout, Menu, message } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';

import { EventLayout } from '@components/Layout';
import { LazyModal } from '@components/LazyModal';
import AddNewMailDrawer from '@components/Mail/AddNewMailDrawer';
import MailComponent, { mailsData } from '@components/Mail';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import { stripHtml } from '@utils/html';
import { useAuth } from '@hooks/auth/useAuth';
import { useCurrentEvent, useUpdateEvent } from '@hooks/useEvents';
import { useLanguage } from '@hooks/useTranslations';
import { useCreateMail, useDeleteMail, useMails, useUpdateMail } from '@hooks/useMails';
import { Mail, MailLayoutType, MailResource, MailTargetGroup, MailType } from '@transforms/mail';
import type { Event } from '@transforms/event';

const alwaysEnabledMails = mailsData.filter((mailData) => mailData.alwaysEnabled === true);
const alwaysEnabledMailTypes = mailsData
  .filter((mailData) => mailData.alwaysEnabled === true)
  .map((mailData) => mailData.type);

export default function Emails() {
  const { selectedLanguage } = useLanguage();
  const { isSuperAdmin, organisation } = useAuth();
  const mailKey = new URLSearchParams(window.location.search).get('mail');

  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedMail, setSelectedMail] = useState<string | undefined>(mailKey ?? undefined);

  const { data: event } = useCurrentEvent(selectedLanguage);
  const { data: rawMails } = useMails(event?.id, selectedLanguage);
  const mails = useMemo(
    () =>
      rawMails
        ? [
            ...alwaysEnabledMails.map(
              (mailData) =>
                rawMails.find((mail) => mail.type === mailData.type) ?? {
                  id: `new-${mailData.type}`,
                  type: mailData.type,
                  value: '',
                  targetGroup: MailTargetGroup.ALL,
                  properties: {},
                  subject: undefined,
                  senderName: undefined,
                  useLayout: false,
                  delayedDate: undefined,
                  layout: {
                    imageUrl: '',
                    text: '',
                    type: MailLayoutType.LEFT_IMAGE,
                  },
                },
            ),
            ...rawMails.filter((mail) => !alwaysEnabledMailTypes.includes(mail.type as MailType)),
          ]
        : undefined,
    [rawMails],
  );

  const { mutate: createMail } = useCreateMail(event?.id, selectedLanguage);
  const { mutateAsync: updateMail } = useUpdateMail(event?.id, selectedMail, selectedLanguage);
  const { mutate: deleteMail } = useDeleteMail(event?.id);
  const { mutateAsync: updateEvent } = useUpdateEvent(event?.id, selectedLanguage);

  useEffect(() => {
    if (!mails) return;

    setSelectedMail((prev) => {
      const mail = mails?.find((mailData) => mailData.id === prev);

      return !mail ? mails?.[0]?.id : mail.id;
    });
  }, [mails]);

  useEffect(() => {
    // this effect makes sure the content is reloaded on language change
    let prevId: string | undefined;
    setSelectedMail((prev) => {
      prevId = prev;
      return undefined;
    });
    setTimeout(() => setSelectedMail(prevId), 0);
  }, [selectedLanguage]);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const selectMail = (id: string) => {
    setSelectedMail(id);
  };

  const mail = useMemo(() => mails?.find(({ id }) => id === selectedMail), [mails, selectedMail]);
  const mailData = useMemo(() => mailsData?.find(({ type }) => type === mail?.type), [mail]);

  const onUpdateMail = async ({
    sendWelcomeEmail,
    sendMagicLinkOnApproval,
    ...values
  }: Omit<MailResource, 'layout'> & {
    layout?: Omit<MailResource['layout'], 'imageUrl'> & { image: File | string | null };
  } & { sendWelcomeEmail: boolean; sendMagicLinkOnApproval: boolean }) => {
    if (values.id.startsWith('new')) {
      onCreateMail(values);
      return;
    }

    let { value } = values;
    const { length } = stripHtml(value);
    if (length === 0) value = '';

    if (mailData?.maxLength && length > mailData.maxLength) {
      message.error(`The ${mailData.title.toLowerCase()} is too long.`);
      return;
    }

    if (values.type === MailType.EVENT_REMINDER || values.type === MailType.EVENT_FOLLOWUP) {
      const days = parseInt(values.properties.days, 10);
      if (Number.isNaN(days) || days < 1) {
        message.error(`The amount of days is not enough. (Minimum: 1)`);
        return;
      }
    }

    await updateMail({
      ...mail,
      ...values,
      value,
      layout: values.layout
        ? {
            ...values.layout,
            image: values.layout?.image ?? null,
          }
        : undefined,
    });
    await updateEvent({
      ...(event as Event),
      sendWelcomeEmail,
      sendMagicLinkOnApproval,
    });

    message.success('Updated mail');
  };

  const onCreateMail = (
    values: Omit<Mail, 'layout'> & {
      layout?: Omit<Mail['layout'], 'imageUrl'> & { image: File | string | null };
    },
  ) => {
    return new Promise((resolve, reject) => {
      if (mailData?.maxLength && stripHtml(values.value).length > mailData.maxLength) {
        message.error(`The ${mailData.title.toLowerCase()} is too long.`);
        return;
      }

      createMail(values, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const onDeleteMail = async (id: string) => {
    await deleteMail(id);
  };

  return (
    <EventLayout
      title='Emails'
      extra={
        <Space>
          <EventLanguageSelect />
        </Space>
      }
    >
      <Layout style={{ background: 'white' }}>
        <Layout.Sider width={256} style={{ background: 'white' }}>
          <div
            style={{
              height: '100%',
              minHeight: '235px',
              maxHeight: 'calc(100vh - 249px)',
              display: 'flex',
              flexDirection: 'column',
              borderRight: '1px solid #ddd',
            }}
          >
            <Menu
              style={{ flex: 1, width: 255, borderRight: 'none', overflowY: 'auto' }}
              onClick={({ key }) => selectMail(key as string)}
              selectedKeys={[selectedMail!]}
            >
              {mails?.map((mailOption) => (
                <Menu.Item key={mailOption.id}>
                  <Space>
                    {mailOption.type === MailType.EVENT_WELCOME && !event?.sendWelcomeEmail && (
                      <EyeInvisibleOutlined />
                    )}
                    {mailsData?.find(({ type }) => type === mailOption?.type)?.title}
                    {(mailOption?.type === 'EVENT_REMINDER' ||
                      mailOption?.type === 'EVENT_FOLLOWUP') && (
                      <h6>
                        {mailOption?.properties?.days}{' '}
                        {mailOption?.properties?.days > 1 ? 'days' : 'day'}{' '}
                        {mailOption?.type === 'EVENT_FOLLOWUP' ? 'after' : 'before'}
                      </h6>
                    )}
                  </Space>
                </Menu.Item>
              ))}
            </Menu>
            {(isSuperAdmin || organisation) && (
              <div style={{ width: '100%', borderTop: '1px solid #ddd' }}>
                <Space direction='vertical' style={{ margin: '20px', width: 'calc(100% - 40px)' }}>
                  <Button type='primary' block onClick={toggleDrawer}>
                    Add Mail
                  </Button>
                </Space>
              </div>
            )}
          </div>
        </Layout.Sider>
        {mail && event && (
          <MailComponent
            key={selectedMail}
            mailData={mailData}
            mail={mail}
            eventId={event.id!}
            sendWelcomeEmail={event.sendWelcomeEmail}
            onDelete={onDeleteMail}
            onFinish={onUpdateMail}
          />
        )}
      </Layout>
      <LazyModal open={showDrawer}>
        {(open) => (
          <AddNewMailDrawer visible={open} toggleDrawer={toggleDrawer} onFinish={onCreateMail} />
        )}
      </LazyModal>
    </EventLayout>
  );
}
