import { useEffect, useRef, useState } from 'react';
import { Button, Input, Table, Space, Tooltip, Popconfirm, Divider, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { Drawer } from '@components/Admins';
import { EventLayout } from '@components/Layout';
import { Admin as AdminModal } from '@components/Modals';

import useAuth from '@hooks/auth/useAuth';
import { useEvent } from '@hooks/useEvents';
import { useCreateAdmin, useDeleteAdmin, useEventAdmins, useUpdateAdmin } from '@hooks/useAdmins';
import { Admin, AdminResource } from '@transforms/admin';

export default function Editors() {
  const { isSuperAdmin, organisation } = useAuth();
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId?: string }>();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<AdminResource | undefined>();
  const [filteredAdminsList, setFilteredAdminsList] = useState<AdminResource[] | undefined>();
  const [searchTerm, setSearchTerm] = useState('');

  const { data: event } = useEvent(eventId);
  const { isLoading, data: admins } = useEventAdmins(eventId);
  const { mutate: createAdmin } = useCreateAdmin(eventId);
  const { mutate: updateAdmin } = useUpdateAdmin(eventId, selectedAdmin?.id);
  const { mutate: deleteAdmin } = useDeleteAdmin(eventId);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (event && !isSuperAdmin && !organisation)
      navigate(window.location.pathname.replace('settings/editors', 'info'));
  }, [navigate, event, isSuperAdmin, organisation]);

  useEffect(() => {
    if (listRef.current) {
      const emptyText = listRef.current.getElementsByClassName('ant-empty-description')[0];
      if (emptyText) {
        emptyText.textContent = 'No Event Admins';
      }
    }
  }, [listRef.current?.innerHTML]);

  const onCreateAdmin = (values: Admin, done: (err?: Error) => void) => {
    createAdmin(values, {
      onSuccess: () => {
        setOpenAdminModal(false);
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onUpdateAdmin = (values: Admin, done: (err?: Error) => void) => {
    updateAdmin(values, {
      onSuccess: () => {
        setOpenAdminModal(false);
        done();
      },
      onError: (error: any) => {
        done(error);
      },
    });
  };

  const onDeleteAdmin = (id: string) => {
    deleteAdmin(id);
  };

  const onOpenAdminModal = (admin?: AdminResource) => {
    if (admin) {
      setSelectedAdmin(admin);
    } else {
      setSelectedAdmin(undefined);
    }
    setOpenAdminModal(true);
  };

  const onCloseAdminModal = () => {
    setOpenAdminModal(false);
    setSelectedAdmin(undefined);
  };

  const onOpenDrawer = (user: AdminResource) => {
    setSelectedAdmin(user);
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setSelectedAdmin(undefined);
  };

  const onAfterCloseDrawer = (open: boolean) => {
    if (open) return;

    setSelectedAdmin(undefined);
  };

  // TODO: Allow it to search through all objects
  const onSearch = (value: string) => {
    const filtered = admins?.filter((admin) => {
      return admin?.email?.toLowerCase().includes(value.toLowerCase());
    });

    setFilteredAdminsList(filtered);
    setSearchTerm(value);
  };

  return (
    <EventLayout
      title='Event Admins'
      extra={[
        <Input.Search
          placeholder='Search'
          allowClear
          onSearch={onSearch}
          style={{ width: 200, marginRight: 10 }}
          key='search'
        />,
        <Button key='menu' type='primary' onClick={() => onOpenAdminModal()}>
          Add Event Admin
        </Button>,
      ]}
    >
      Event Admins can manage most event settings and access should be reserved for client's event
      team only.
      <br />
      <br />
      <div ref={listRef}>
        <Table
          loading={isLoading}
          dataSource={searchTerm ? filteredAdminsList : admins}
          columns={[
            {
              title: 'Email',
              key: 'email',
              dataIndex: 'email',
              render: (email, record) => (
                <Space direction='vertical'>
                  {email}
                  {!record.invitationAcceptedAt && (
                    <Tag color='processing'>Invitation not yet accepted</Tag>
                  )}
                </Space>
              ),
              sorter: (a, b) => a.email.localeCompare(b.email),
            },
            {
              title: 'Actions',
              key: 'actions',
              align: 'right',
              render: (editor) => (
                <Space size={2} split={<Divider key='d1' type='vertical' />}>
                  <Tooltip title='Edit' key='edit'>
                    <Button
                      shape='circle'
                      icon={<EditOutlined />}
                      onClick={() => onOpenDrawer(editor)}
                    />
                  </Tooltip>

                  <Popconfirm
                    title={
                      <div className='message-box-content-container'>
                        <div className='message-box-content'>
                          <h3>Confirm Deletion!</h3>
                          <span>
                            Are you sure you want to delete this event admin? Note, this cannot be
                            undone!
                          </span>
                        </div>
                      </div>
                    }
                    onConfirm={() => onDeleteAdmin(editor.id)}
                    okText='Delete'
                    cancelText='Cancel'
                    key='delete'
                  >
                    <Button shape='circle' icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </div>
      <Drawer
        visible={openDrawer}
        user={selectedAdmin}
        eventId={eventId}
        onClose={onCloseDrawer}
        onFinish={(values) => onUpdateAdmin(values, () => null)}
        afterVisibleChange={(visible) => onAfterCloseDrawer(visible)}
      />
      <AdminModal
        visible={openAdminModal}
        data={selectedAdmin}
        onCancel={onCloseAdminModal}
        onCreate={onCreateAdmin}
        onUpdate={onUpdateAdmin}
      />
    </EventLayout>
  );
}
