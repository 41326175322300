import { useQuery, useInfiniteQuery } from 'react-query';
import queryString from 'query-string';

import fetch from '@utils/fetch';
import transform, { Job } from '@transforms/job';
import { useAuth } from '@hooks/auth/useAuth';

export const useJobs = (
  {
    sorter,
    ...pagination
  }: {
    page: number;
    limit: number;
    sorter?: { field?: keyof Job; order?: 'ascend' | 'descend' };
  },
  search?: string,
) => {
  const { accessToken } = useAuth();
  const query = queryString.stringify({
    ...pagination,
    sortField: sorter?.field,
    sortOrder: sorter?.order,
    search: search || undefined,
  });

  return useQuery(
    ['jobs', search, sorter?.field, sorter?.order, pagination?.page, pagination?.limit],
    async () => {
      const { body } = await fetch(`/jobs?${query}`, { token: accessToken });

      return transform.many(body);
    },
  );
};

export const useInfiniteJobs = (search?: string) => {
  const { accessToken } = useAuth();

  return useInfiniteQuery(
    ['jobs', search].filter(Boolean),
    async ({ pageParam = 1 }) => {
      const query = queryString.stringify({
        search: search || undefined,
        page: pageParam,
        limit: '30',
      });
      const { body } = await fetch(`/jobs?${query}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.currentPage === lastPage.meta.totalPages) return undefined;
        return lastPage.meta.currentPage + 1;
      },
    },
  );
};

export default {
  useJobs,
  useInfiniteJobs,
};
