import React, { useEffect } from 'react';

import { Language, useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent } from '@hooks/useEvents';

import { LanguageSelect } from './LanguageSelect';

interface Props {
  readonly style?: React.CSSProperties;
  readonly onUpdate?: () => any;
}

export const EventLanguageSelect = ({ style, onUpdate }: Props) => {
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent();

  useEffect(() => {
    onUpdate?.();
  }, [onUpdate, selectedLanguage]);

  return event?.languages && event.languages.length > 1 ? (
    <LanguageSelect
      availableLanguages={event.languages as Language[]}
      selectedLanguage={selectedLanguage}
      onChange={setSelectedLanguage}
      style={style}
    />
  ) : null;
};
