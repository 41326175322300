import dayjs from 'dayjs';

/**
 * @param value In seconds
 * @returns Time string in format HH:MM:SS
 */
export function getTimeString(value?: number): string {
  const duration = dayjs().add(value || 0, 'seconds');
  let delta = duration.diff(dayjs(), 'seconds');

  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  const seconds = Math.floor(delta % 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}
