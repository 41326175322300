import parse, { Attributes } from '@utils/parse';

export const parseTag = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    key: attributes.string('key'),
    label: attributes.string('label'),
    position: attributes.optional.number('position'),
  };
};

export function one(data: any) {
  return parse.one(data, parseTag);
}

export function many(data: any) {
  return parse.many(data, parseTag);
}

export type TagResource = ReturnType<typeof one>;
export type Tag = Omit<TagResource, 'id'>;

export default { one, many };
