import { useState, useEffect, useMemo } from 'react';
import {
  Avatar,
  Menu,
  Dropdown,
  Button,
  Input,
  message,
  Popconfirm,
  Space,
  Select,
  Table,
  Tag,
  Tooltip,
  Divider,
} from 'antd';
import { readableColor } from 'polished';
import dayjs from 'dayjs';
import {
  ClockCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  LikeOutlined,
  LinkOutlined,
  MailOutlined,
  SyncOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import debounce from 'lodash/debounce';
import copy from 'copy-to-clipboard';

import { EventLayout } from '@components/Layout';
import { LazyModal } from '@components/LazyModal';
import {
  AddBulkUsersModal,
  BulkDrawer,
  Drawer,
  ExportAvatarsForm,
  ImportCSVForm,
  ExportCSVForm,
  DeleteAllModal,
} from '@components/Users';

import {
  useUsers,
  useExportUsers,
  useCreateUser,
  useCreateBulkUsers,
  useUpdateUser,
  useUpdateUsers,
  useDeleteUser,
  useDeleteUsers,
  useAcceptUser,
  useSendMagicLink,
  useSendMagicLinks,
  useAcceptBulkUsers,
} from '@hooks/useUsers';
import { useRoles } from '@hooks/useRoles';
import { useCurrentEvent } from '@hooks/useEvents';
import { protocol } from '@utils/protocol';
import { validateEmail } from '@utils/validateEmail';
import { EventResource } from '@transforms/event';
import { ATTENDANCE_TYPE, getAttendanceValue, User, UserResource } from '@transforms/user';

function getDomain(event: EventResource, path = ''): string {
  return (event.domains?.length ?? 0) > 0
    ? `${protocol}://${event.domains![0]}/${path}`
    : `${protocol}://${event.slug}.${process.env.REACT_APP_APP_URL}/${path}`;
}

export default function Attendees() {
  const { data: event } = useCurrentEvent();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBulkDrawer, setOpenBulkDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserResource | undefined>();
  const [bulkAddModalVisible, setBulkAddModalVisible] = useState(false);
  const [importCSVModalVisible, setImportCSVModalVisible] = useState(false);
  const [exportCSVModalVisible, setExportCSVModalVisible] = useState(false);
  const [exportAvatarsModalVisible, setExportAvatarsModalVisible] = useState(false);
  const [deleteAllModalVisible, setDeleteAllModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [sorter, setSorter] = useState<{
    field?: keyof User;
    order?: 'ascend' | 'descend';
  }>({});
  const [userRole, setUserRole] = useState<string | undefined>();
  const [options, setOptions] = useState<string[] | undefined>();
  const { data: roles } = useRoles(event?.defaultLanguage, event?.id);

  const {
    data: users,
    isLoading,
    isFetching,
    refetch: refetchUsers,
  } = useUsers({ ...pagination, sorter }, searchTerm, userRole, options, true);
  const [createUserError, setCreateUserError] = useState();
  const { exportUsers, exportAvatars } = useExportUsers(event?.id);
  const { sendMagicLink } = useSendMagicLink(event?.id);
  const { sendMagicLinks } = useSendMagicLinks(event?.id);
  const { mutateAsync: createUser } = useCreateUser(event?.id);
  const { mutateAsync: createBulkUsers, error: createBulkUsersError } = useCreateBulkUsers(
    event?.id,
  );
  const { mutateAsync: acceptBulkUsers, error: acceptBulkUsersError } = useAcceptBulkUsers(
    event?.id,
  );
  const { mutate: updateUser, error: updateUserError } = useUpdateUser(event?.id, selectedUser?.id);
  const { mutate: updateUsers, error: updateUsersError } = useUpdateUsers(event?.id);
  const { mutate: deleteUser, error: deleteUserError } = useDeleteUser(event?.id);
  const { mutateAsync: deleteUsers, error: deleteUsersError } = useDeleteUsers(event?.id);
  const { mutate: acceptUser, error: acceptUserError } = useAcceptUser(event?.id);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const hasSelected = selectedUsers.length > 0;

  useEffect(() => {
    if (!event?.id) return;

    refetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter.field, sorter.order]);

  useEffect(() => {
    // NOTE: updates drawer on image upload
    if (selectedUser && users?.items.length) {
      const updatedUser = users.items.find((user) => user.id === selectedUser.id);
      if (updatedUser) setSelectedUser(updatedUser);
    }
  }, [users, selectedUser]);

  useEffect(() => {
    if (
      createUserError ||
      createBulkUsersError ||
      acceptBulkUsersError ||
      updateUserError ||
      updateUsersError ||
      deleteUserError ||
      deleteUsersError ||
      acceptUserError
    ) {
      message.error('An error occured');
    }
  }, [
    createUserError,
    createBulkUsersError,
    acceptBulkUsersError,
    updateUserError,
    updateUsersError,
    deleteUserError,
    deleteUsersError,
    acceptUserError,
  ]);

  const onCreateUser = async (values: User) => {
    try {
      await createUser(values);
      setSelectedUser(undefined);
      setOpenDrawer(false);
      return true;
      // eslint-disable-next-line no-empty
    } catch (error: any) {
      if (error.status === 409) {
        message.error(`Attendee with email "${values.email}" already exists`);
      } else {
        setCreateUserError(error);
      }
      return false;
    }
  };

  const onCreateBulkUsers = async (value: any) => {
    const emails: string[] = value.email.split(',');
    const errors = emails.some((email) => !validateEmail(email));

    if (!errors && emails.length > 0) {
      const {
        body: { users: added, failed, existing },
      } = (await createBulkUsers(emails as any)) as {
        body: {
          users: UserResource[];
          failed: Array<{ value: string; message: string; index: number }>;
          existing: string[];
        };
      };

      const hasHave = (length: number) => (length === 1 ? 'has' : 'have');

      let success = `${added.length} ${added.length === 1 ? 'attendee' : 'attendees'} ${hasHave(
        added.length,
      )} been added`;

      if (existing.length) {
        success += ` (${existing.length} ${hasHave(existing.length)} been skipped)`;
      }

      if (failed.length) {
        success += ` (${failed.length} ${hasHave(failed.length)} failed)`;
      }

      (added.length === 0 ? message.error : message.success)(success);
    } else {
      message.error('Incorrect email provided');
    }
  };

  const handleEditSelection = () => {
    setOpenBulkDrawer(true);
  };

  const handleDeleteSelection = () => {
    deleteUsers(selectedUsers);
    setSelectedUsers([]);
  };

  const onUpdateUser = (values: User, shouldClose = true) => {
    updateUser(values);
    if (shouldClose) {
      setSelectedUser(undefined);
      setOpenDrawer(false);
    }
  };

  const onUpdateUsers = (data: User) => {
    updateUsers({ data, userIds: selectedUsers });
    setOpenBulkDrawer(false);
  };

  const onDeleteUser = (id: string) => {
    deleteUser(id);
  };

  const onOpenDrawer = (user: UserResource) => {
    setSelectedUser(user);
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setSelectedUser(undefined);
  };

  const onCloseBulkDrawer = () => {
    setOpenBulkDrawer(false);
  };

  const onAfterCloseDrawer = (open: boolean) => {
    if (open) return;

    setSelectedUser(undefined);
  };

  const showDrawerForNewUser = () => {
    setSelectedUser(undefined);
    setOpenDrawer(true);
  };

  const onSearch = debounce((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, 500);

  const onFilterUserRole = (value: string) => {
    setUserRole(value);
  };

  const onFilterOptions = (value: string) => {
    setOptions([value]);
  };

  const onResetFilters = () => {
    setUserRole(undefined);
    setOptions(undefined);
  };

  const showDeleteAllModal = () => {
    setDeleteAllModalVisible(true);
  };

  const onSendMagicLink = async (user: UserResource) => {
    try {
      const success = await sendMagicLink(user.id);
      if (success) {
        message.success(`Successfully sent a magic link email to "${user.email}"`);
      } else throw new Error('NO_SUCCESS');
    } catch {
      message.error('An error occurred');
    }
  };

  const onSendMagicLinks = async () => {
    try {
      const count = await sendMagicLinks();
      if (count > 0) {
        message.success(
          `Successfully sent a magic link email to ${count} ${count === 1 ? 'user' : 'users'}`,
        );
      } else throw new Error('NO_SENT');
    } catch (error) {
      message.error('An error occurred');
    }
  };

  const onAcceptAttendees = () => {
    const NonApproved = users?.items.filter((user) => !user.acceptedAt) ?? [];
    acceptBulkUsers(NonApproved.map((user) => user.id));
  };

  const handleAcceptSelection = () => {
    acceptBulkUsers(selectedUsers);
  };

  const handleExportAvatars = async (fieldId: string) => {
    await exportAvatars(fieldId);
    setExportAvatarsModalVisible(false);
  };

  const maxUsers = event?.maxUsers ?? 0;
  const maxUsersReached = users && maxUsers !== 0 && maxUsers <= users.meta.totalItems;

  const renderCsvMenu = (
    <Menu>
      <Menu.Item
        key='bulkAdd'
        onClick={() => {
          if (maxUsersReached) {
            message.error(
              `Max attendees reached for event (Maximum attendees: ${event?.maxUsers})`,
            );
          } else setBulkAddModalVisible(true);
        }}
      >
        Add Email List
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          if (maxUsersReached) {
            message.error(
              `Max attendees reached for event (Maximum attendees: ${event?.maxUsers})`,
            );
          } else setImportCSVModalVisible(true);
        }}
      >
        Import CSV File
      </Menu.Item>
      <Menu.Item key='exportCsv' onClick={() => setExportCSVModalVisible(true)}>
        Export CSV File
      </Menu.Item>
      <Menu.Item key='exportAvatarsZip' onClick={() => setExportAvatarsModalVisible(true)}>
        Export Avatars Zip
      </Menu.Item>
    </Menu>
  );

  const renderAttendeesCount = useMemo(() => {
    if (!event?.maxUsers || event?.maxUsers === 0) {
      return <div style={{ fontSize: '8pt' }}>{`${users?.meta.totalItems} / Unlimited`}</div>;
    }

    const userCount = users?.meta.totalItems;

    if (userCount === undefined) {
      return null;
    }
    return (
      <div style={{ fontSize: '8pt' }}>{`${users?.meta.totalItems} / ${event?.maxUsers}`}</div>
    );
  }, [users, event?.maxUsers]);

  return (
    <EventLayout
      title={
        <div>
          Attendees
          {renderAttendeesCount}
        </div>
      }
      extra={
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>
            <Select
              filterOption={(input, option) =>
                // @ts-ignore
                option?.props?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                // @ts-ignore
                option?.props?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder='Filter by Role'
              style={{ width: 160, marginRight: 10 }}
              onChange={onFilterUserRole}
              allowClear
              options={
                roles?.map((role) => ({
                  value: role.id,
                  label: role.name,
                })) ?? []
              }
            />
            <Input.Search
              placeholder='Search'
              allowClear
              onChange={(e) => onSearch(e.target.value)}
              style={{ width: 200, marginRight: 10 }}
              key='search'
            />
            <Space>
              <Button
                key='menu'
                type='primary'
                onClick={() => {
                  if (maxUsersReached) {
                    message.error(
                      `Max attendees reached for event (Maximum attendees: ${event?.maxUsers})`,
                    );
                  } else showDrawerForNewUser();
                }}
              >
                Add Attendee
              </Button>
              {(users?.items.length ?? 0) > 0 && (
                <Button key='deleteAll' danger onClick={showDeleteAllModal}>
                  Delete All
                </Button>
              )}
              <Dropdown overlay={renderCsvMenu}>
                <Button>
                  Import/Export <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </div>
          <div style={{ fontSize: '8pt', paddingTop: '8pt' }}>
            <Space>
              {(users?.items.filter((user) => !!user.acceptedAt).length ?? 0) > 0 && (
                <Popconfirm
                  title={
                    <div style={{ maxWidth: 400 }}>
                      Are you sure you want to send the personal login link (email) to all approved
                      attendees now?
                      <br />
                      <b>Please note, this will invalidate all previously sent personal links!</b>
                    </div>
                  }
                  onConfirm={onSendMagicLinks}
                  okText='Send'
                  cancelText='Cancel'
                  key='magic_link'
                >
                  <Button size='small'>Send Personal Link to All Approved</Button>
                </Popconfirm>
              )}

              <Button
                size='small'
                onClick={() => {
                  onFilterOptions('declined');
                }}
              >
                Show Unapproved
              </Button>

              <Button size='small' onClick={onAcceptAttendees}>
                Approve All
              </Button>

              <Button size='small' danger onClick={onResetFilters}>
                <CloseOutlined />
                Clear Filters
              </Button>
            </Space>
          </div>
        </div>
      }
    >
      <div style={{ marginBottom: 16 }}>
        <Button
          type='primary'
          onClick={
            selectedUsers.length > 1
              ? handleEditSelection
              : () => {
                  const user = users?.items.find((usr) => usr.id === selectedUsers[0]);
                  if (user) onOpenDrawer(user);
                }
          }
          disabled={!hasSelected}
          loading={isLoading || isFetching}
        >
          Edit
        </Button>
        <Button
          onClick={
            selectedUsers.length > 1
              ? handleAcceptSelection
              : () => {
                  const user = users?.items.find((usr) => usr.id === selectedUsers[0]);
                  if (user) acceptUser(user.id);
                }
          }
          style={{ marginLeft: 10 }}
          disabled={!hasSelected}
          loading={isLoading || isFetching}
        >
          Approve
        </Button>
        <Popconfirm
          title='Are you sure you want to delete the selected users?'
          onConfirm={handleDeleteSelection}
          okText='Delete'
          okType='danger'
          cancelText='Cancel'
          disabled={!hasSelected}
          key='delete_users'
        >
          <Button
            danger
            type='default'
            disabled={!hasSelected}
            style={{ marginLeft: 12 }}
            loading={isLoading || isFetching}
          >
            Delete
          </Button>
        </Popconfirm>
        <span style={{ marginLeft: 12 }}>
          {hasSelected ? `Selected ${selectedUsers.length} items` : ''}
        </span>
      </div>
      <Table
        loading={isLoading || isFetching}
        dataSource={users?.items.map((user) => ({ ...user, key: user.id }))}
        onChange={(_, __, sort: any) => setSorter(sort)}
        rowSelection={{
          selectedRowKeys: selectedUsers,
          onChange: (keys) => setSelectedUsers(keys as string[]),
        }}
        pagination={{
          pageSize: users?.meta.itemsPerPage || 10,
          total: users?.meta.totalItems,
          onChange: (page, pageSize) => {
            setPagination({ page, limit: pageSize || 10 });
          },
        }}
        columns={[
          {
            title: 'Profile Picture',
            key: 'avatar',
            dataIndex: 'avatarUrl',
            render: (image) => <Avatar size={40} src={image} />,
          },
          {
            title: 'Name',
            key: 'name',
            sorter: true,
            dataIndex: 'fullName',
            render: (text) => text || 'Unknown user',
          },
          {
            title: 'Email',
            key: 'email',
            sorter: true,
            dataIndex: 'email',
            render: (text) => (text ? <a href={`mailto:${text}`}>{text}</a> : null),
          },
          {
            title: 'Role',
            key: 'role',
            sorter: true,
            dataIndex: 'role',
            width: 150,
            render: (role) => {
              return role ? (
                <Tag color={role?.color ?? '#666'}>
                  <div style={{ color: readableColor(role?.color ?? '#666') }}>{role.name}</div>
                </Tag>
              ) : null;
            },
          },
          ...(event?.attendanceEnabled
            ? [
                {
                  title: 'Attendance',
                  key: 'attendance',
                  sorter: true,
                  dataIndex: 'attendance',
                  width: 150,
                  render: (text: string) => text && getAttendanceValue(text),
                  filters: ATTENDANCE_TYPE.map((item) => ({
                    text: item.value,
                    value: item.key,
                  })),
                  onFilter: (value: any, record: any) =>
                    record.attendance?.indexOf(value.toString()) === 0,
                },
              ]
            : []),
          {
            title: 'Accepted',
            key: 'accepted',
            sorter: true,
            dataIndex: 'acceptedAt',
            render: (date, user) =>
              user.acceptedAt ? (
                <Space key='lastLogin'>
                  <ClockCircleOutlined />
                  {dayjs(date).fromNow()}
                </Space>
              ) : (
                <div>
                  <Tag icon={<SyncOutlined />} color='processing'>
                    Waiting for approval
                  </Tag>

                  <Space>
                    <Button
                      shape='circle'
                      icon={<LikeOutlined />}
                      size='small'
                      style={{ color: 'green', borderColor: 'green' }}
                      onClick={() => acceptUser(user.id)}
                    />
                  </Space>
                </div>
              ),
          },
          {
            title: 'Last Activity',
            key: 'lastActivity',
            sorter: true,
            dataIndex: 'lastActivityAt',
            render: (date) =>
              date ? (
                <Space key='lastActivity'>
                  <ClockCircleOutlined />
                  {dayjs(date).fromNow()}
                </Space>
              ) : null,
          },
          {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            align: 'right',
            width: 226,
            render: (_, user) => [
              ...(user.acceptedAt
                ? [
                    <Popconfirm
                      title={
                        <div style={{ maxWidth: 370 }}>
                          Are you sure you want to send a magic link to this user?
                          <br />
                          <b>
                            Please note, this will invalidate any previously sent personal links!
                          </b>
                        </div>
                      }
                      onConfirm={() => onSendMagicLink(user)}
                      okText='Send'
                      cancelText='Cancel'
                      key='magic_link'
                    >
                      <Button shape='circle' icon={<MailOutlined />} />
                    </Popconfirm>,
                    <Divider key='d1' type='vertical' />,
                  ]
                : []),
              ...(user.loginToken
                ? [
                    <Tooltip title='Copy Login Token' key='copyLoginToken'>
                      <Button
                        shape='circle'
                        icon={<LinkOutlined />}
                        onClick={() => {
                          if (!user.loginToken || !event?.slug) {
                            message.error('Missing data.');
                            return;
                          }
                          copy(getDomain(event, `auth/login/${user.loginToken}`));
                        }}
                      />
                    </Tooltip>,
                    <Divider key='d2' type='vertical' />,
                  ]
                : []),
              ...(process.env.REACT_APP_LOCAL_ONLY === 'true'
                ? [
                    <Tooltip title='Open Login Token' key='openLoginToken'>
                      <Button
                        shape='circle'
                        icon={<EyeOutlined />}
                        onClick={() => {
                          if (!user.loginToken || !event?.slug) {
                            message.error('Missing data.');
                            return;
                          }
                          window.open(getDomain(event, `auth/login/${user.loginToken}`), '_blank');
                        }}
                      />
                    </Tooltip>,
                    <Divider key='d4' type='vertical' />,
                  ]
                : []),
              <Tooltip title='Edit' key='edit'>
                <Button shape='circle' icon={<EditOutlined />} onClick={() => onOpenDrawer(user)} />
              </Tooltip>,
              <Divider key='d3' type='vertical' />,
              <Popconfirm
                title='Are you sure you want to delete this user?'
                onConfirm={() => onDeleteUser(user.id)}
                okText='Delete'
                cancelText='Cancel'
                key='delete'
              >
                <Button shape='circle' icon={<DeleteOutlined />} danger />
              </Popconfirm>,
            ],
          },
        ]}
      />
      <Drawer
        visible={openDrawer}
        user={selectedUser}
        roles={roles}
        refetch={refetchUsers}
        onClose={onCloseDrawer}
        showMagicLink={() => onSendMagicLink(selectedUser!)}
        onFinish={selectedUser ? onUpdateUser : onCreateUser}
        afterVisibleChange={(visible) => onAfterCloseDrawer(visible)}
      />
      <BulkDrawer
        visible={openBulkDrawer}
        refetch={refetchUsers}
        onClose={onCloseBulkDrawer}
        onFinish={onUpdateUsers}
        users={
          selectedUsers
            .map((id) => users?.items.find((usr) => usr.id === id))
            .filter((usr) => !!usr) as UserResource[]
        }
      />
      <LazyModal open={importCSVModalVisible}>
        {(open) => (
          <ImportCSVForm
            visible={open}
            onOk={() => setImportCSVModalVisible(false)}
            onCancel={() => setImportCSVModalVisible(false)}
          />
        )}
      </LazyModal>
      <LazyModal open={exportCSVModalVisible}>
        {(open) => (
          <ExportCSVForm
            visible={open}
            action={() => exportUsers()}
            onOk={() => setExportCSVModalVisible(false)}
            onCancel={() => setExportCSVModalVisible(false)}
          />
        )}
      </LazyModal>
      <LazyModal open={exportAvatarsModalVisible}>
        {(open) => (
          <ExportAvatarsForm
            visible={open}
            action={handleExportAvatars}
            onOk={() => setExportAvatarsModalVisible(false)}
            onCancel={() => setExportAvatarsModalVisible(false)}
          />
        )}
      </LazyModal>
      <LazyModal open={deleteAllModalVisible}>
        {(open) => (
          <DeleteAllModal
            visible={open}
            onOk={async () => {
              await deleteUsers(undefined);
              setDeleteAllModalVisible(false);
            }}
            onCancel={() => setDeleteAllModalVisible(false)}
          />
        )}
      </LazyModal>
      <LazyModal open={bulkAddModalVisible}>
        {(open) => (
          <AddBulkUsersModal
            visible={open}
            onCreate={onCreateBulkUsers}
            onCancel={() => setBulkAddModalVisible(false)}
          />
        )}
      </LazyModal>
    </EventLayout>
  );
}
