import { useQuery, useMutation, useQueryClient } from 'react-query';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import useAuth from '@hooks/auth/useAuth';
import transform, { Tag } from '@transforms/tag';

export const useTag = (eventId?: string, tagId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['tag', eventId, tagId], async () => {
    if (!eventId) throw new Error('No event');
    if (!tagId) throw new Error('No tag');

    const { body } = await fetch(`/events/${eventId}/tags/${tagId}`, { token: accessToken });

    return transform.one(body);
  });
};

export const useTags = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['tags', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(languageUrl(`/events/${eventId}/tags`, language), {
        token: accessToken,
      });

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useCreateTag = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Tag) => {
      if (!eventId) throw new Error('No event');

      return fetch(languageUrl(`/events/${eventId}/tags`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    },
  );
};

export const useUpdateTag = (eventId?: string, tagId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (values: Tag) => {
      if (!eventId) throw new Error('No event');
      if (!tagId) throw new Error('No tag');

      return fetch(languageUrl(`/events/${eventId}/tags/${tagId}`, language), {
        method: 'PUT',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    },
  );
};

export const useUpdateTagPositions = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (body: Array<[string, number]>) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/tags/positions`, {
        method: 'POST',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    },
  );
};

export const useDeleteTag = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (tagId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/tags/${tagId}`, { method: 'DELETE', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tags');
      },
    },
  );
};

export default { useTag, useTags, useCreateTag, useUpdateTag, useUpdateTagPositions, useDeleteTag };
