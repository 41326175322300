import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Input,
  Layout,
  PageHeader,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd';
import type { ColumnGroupType } from 'antd/lib/table';

import { useAuth } from '@hooks/auth/useAuth';
import { useProtected } from '@hooks/useProtected';
import { useOrganisations, useDeleteOrganisation } from '@hooks/useOrganisations';
import type { Organisation, OrganisationResource } from '@transforms/organisation';

export default function Organisations() {
  useProtected();
  const navigate = useNavigate();
  const { isSuperAdmin } = useAuth();
  const [query, setQuery] = useState('');
  const [sorter, setSorter] = useState<{
    field?: keyof Organisation;
    order?: 'ascend' | 'descend';
  }>({});
  const { isLoading, data: organisations } = useOrganisations(
    {
      page: 1,
      limit: 30,
      sorter,
    },
    query,
  );
  const { mutate: deleteOrganisation } = useDeleteOrganisation();

  const onEdit = (id: string) => {
    navigate(`/organisations/${id}/edit`);
  };

  const onDelete = (id: string) => {
    deleteOrganisation(id);
  };

  const onSearch = (value: string) => {
    setQuery(value);
  };

  return (
    <Layout.Content className='site-layout-content'>
      <PageHeader
        title='Organizations'
        extra={[
          <Input.Search
            placeholder='Search'
            allowClear
            onSearch={onSearch}
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: 200, marginRight: 10 }}
            key='search'
          />,
          isSuperAdmin && (
            <Button key='menu' type='primary' onClick={() => navigate('/organisations/new')}>
              Add Organization
            </Button>
          ),
        ]}
      />
      <Table
        loading={isLoading}
        dataSource={organisations?.items}
        style={{ marginTop: 10 }}
        onChange={(_, __, sort: any) => setSorter(sort)}
        rowKey='id'
        onRow={(record) => {
          return {
            onClick: (e) => {
              if (e.target instanceof HTMLTableCellElement) {
                navigate(`/organisations/${record.id}`);
              }
            },
            style: { cursor: 'pointer' },
          };
        }}
        columns={
          (
            [
              {
                title: 'Logo',
                key: 'logo',
                dataIndex: 'imageUrl',
                width: 40,
                render: (imageUrl) => <Avatar size={50} src={imageUrl} />,
              },
              {
                title: 'Organization Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
              },
              isSuperAdmin
                ? {
                    title: 'Internal Name',
                    key: 'internalName',
                    sorter: true,
                    dataIndex: 'internalName',
                  }
                : false,
              {
                title: 'Events',
                key: 'events',
                sorter: true,
                dataIndex: 'eventCount',
                render: (_, organisation) => (
                  <>
                    {organisation.eventCount} / {organisation.eventLimit ?? '∞'}
                  </>
                ),
              },
              {
                title: 'Active Events',
                key: 'activeEvents',
                sorter: true,
                dataIndex: 'activeEventCount',
                render: (_, organisation) => (
                  <>
                    {organisation.activeEventCount} / {organisation.activeEventLimit ?? '∞'}
                  </>
                ),
              },
              {
                title: 'Actions',
                key: 'actions',
                dataIndex: 'actions',
                align: 'right',
                width: 226,
                render: (_, organisation) => [
                  <Tooltip title='Edit' key='edit'>
                    <Button
                      shape='circle'
                      icon={<EditOutlined />}
                      onClick={() => onEdit(organisation.id)}
                    />
                  </Tooltip>,
                  <Divider key='d1' type='vertical' />,
                  <Popconfirm
                    title='Are you sure you want to delete this organization?'
                    key='delete'
                    placement='topRight'
                    onConfirm={() => onDelete(organisation.id)}
                  >
                    <Button shape='circle' icon={<DeleteOutlined />} />
                  </Popconfirm>,
                ],
              },
            ] as Array<boolean | ColumnGroupType<OrganisationResource>>
          ).filter(Boolean) as ColumnGroupType<OrganisationResource>[]
        }
      />
    </Layout.Content>
  );
}
