import { isUndefined } from 'lodash';

const reorder = (
  list: any,
  startIndex: number | undefined,
  endIndex: number | undefined,
): Array<any> => {
  if (isUndefined(startIndex) || isUndefined(endIndex)) {
    return list;
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export default reorder;
