import { Form, Input, Divider } from 'antd';

import { MandatoryField } from '@transforms/mandatoryField';

import { DataEntry, DataEntryProps } from './DataEntry';

export interface MandatoryFieldProps extends DataEntryProps {
  data?: MandatoryField;
}

export function MandatoryFieldModal({ data, ...props }: MandatoryFieldProps) {
  return (
    <DataEntry {...props} data={data} name='Mandatory field'>
      <Form.Item name='label' label='Label'>
        <Input placeholder='Label' disabled />
      </Form.Item>

      <Divider key='d1' />
      <Form.Item name='key' label='Key'>
        <Input placeholder='Key' disabled />
      </Form.Item>

      <Form.Item name='position' label='Position'>
        <Input type='number' />
      </Form.Item>
    </DataEntry>
  );
}
