import { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Button, Form, PageHeader, message } from 'antd';

import { Colors } from '@components/Theme';
import { EventLayout } from '@components/Layout';
import { ImageUpload } from '@components/Upload';
import { PreviewLogo } from '@components/Modals/PreviewLogo';
import { PreviewEmailLogo } from '@components/Modals/PreviewEmailLogo';
import { PreviewBackground } from '@components/Modals/PreviewBackground';

import { Event } from '@transforms/event';
import { useLanguage } from '@hooks/useTranslations';
import {
  useCurrentEvent,
  useUpdateEvent,
  useUploadBackgroundImage,
  useUploadLogoEmailImage,
  useUploadLogoImage,
} from '@hooks/useEvents';

export default function Theme() {
  const [form] = Form.useForm();
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const { mutateAsync: updateEvent } = useUpdateEvent(event?.id, selectedLanguage);
  const { mutateAsync: uploadBackgroundImage } = useUploadBackgroundImage();
  const { mutateAsync: uploadLogoImage } = useUploadLogoImage();
  const { mutateAsync: uploadLogoEmailImage } = useUploadLogoEmailImage();

  const parsedEvent = useMemo(
    () =>
      event
        ? {
            ...event,
            themeBackgroundColor: event?.themeBackgroundColor?.replace('#', ''),
            themePrimaryColor: event?.themePrimaryColor?.replace('#', ''),
            themeSecondaryColor: event?.themeSecondaryColor?.replace('#', ''),
            themeNormalColor: event?.themeNormalColor?.replace('#', ''),
          }
        : undefined,
    [event],
  );

  const [showPreviewBackgroundModal, setShowPreviewBackgroundModal] = useState<boolean>(false);
  const [showPreviewLogoModal, setShowPreviewLogoModal] = useState<boolean>(false);
  const [showPreviewEmailLogoModal, setShowPreviewEmailLogoModal] = useState<boolean>(false);
  const [backgroundImagePreview, setBackgroundImagePreview] = useState<string | null>(
    parsedEvent?.themeBackgroundUrl ?? null,
  );
  const [logoImagePreview, setLogoImagePreview] = useState<string | null>(
    parsedEvent?.themeLogoUrl ?? null,
  );
  const [logoEmailImagePreview, setLogologoEmailImagePreview] = useState<string | null>(
    parsedEvent?.themeLogoEmailUrl ?? null,
  );

  useEffect(() => {
    if (!parsedEvent) return;
    form.setFieldsValue(parsedEvent);
    setBackgroundImagePreview(parsedEvent.themeBackgroundUrl ?? null);
    setLogoImagePreview(parsedEvent.themeLogoUrl ?? null);
    setLogologoEmailImagePreview(parsedEvent.themeLogoEmailUrl ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedEvent, setBackgroundImagePreview, setLogoImagePreview, setLogologoEmailImagePreview]);

  const onFinish = useCallback(
    async (
      values: Event & {
        themeBackground?: File | null;
        themeLogo?: File | null;
        themeLogoEmail?: File | null;
      },
    ) => {
      await updateEvent(
        {
          ...event,
          ...values,
          themeBackgroundColor: values.themeBackgroundColor
            ? `#${values.themeBackgroundColor.toUpperCase()}`
            : null,
          themePrimaryColor: values.themePrimaryColor
            ? `#${values.themePrimaryColor.toUpperCase()}`
            : null,
          themeSecondaryColor: values.themeSecondaryColor
            ? `#${values.themeSecondaryColor.toUpperCase()}`
            : null,
          themeNormalColor: values.themeNormalColor
            ? `#${values.themeNormalColor.toUpperCase()}`
            : null,
          themeBackgroundUrl: backgroundImagePreview ? undefined : null,
          themeLogoUrl: logoImagePreview ? undefined : null,
          themeLogoEmailUrl: logoEmailImagePreview ? undefined : null,
        },
        {
          onSuccess: () => {
            message.success({
              content: (
                <div className='message-box-content-container'>
                  <div className='message-box-content'>
                    <h3>Event Updated!</h3>
                    <span>Your changes have been successfully updated and saved!</span>
                  </div>
                </div>
              ),
              className: 'message-box',
            });
          },
        },
      );

      if (!event) return;
      if (values.themeBackground) {
        try {
          await uploadBackgroundImage({ eventId: event.id, image: values.themeBackground });
        } catch {
          message.error('Unable to update background image');
        }
      }
      if (values.themeLogo) {
        try {
          await uploadLogoImage({ eventId: event.id, image: values.themeLogo });
        } catch {
          message.error('Unable to update logo');
        }
      }
      if (values.themeLogoEmail) {
        try {
          await uploadLogoEmailImage({ eventId: event.id, image: values.themeLogoEmail });
        } catch {
          message.error('Unable to update email logo');
        }
      }
    },
    [
      updateEvent,
      event,
      backgroundImagePreview,
      logoImagePreview,
      logoEmailImagePreview,
      uploadBackgroundImage,
      uploadLogoImage,
      uploadLogoEmailImage,
    ],
  );

  if (!parsedEvent) return null;

  return (
    <EventLayout>
      <PageHeader title='Theme' />
      <Form form={form} layout='vertical' onFinish={onFinish} initialValues={parsedEvent}>
        <Divider key='d1' />
        <h2>Images</h2>
        <Form.Item name='themeBackground' label={<b>Background Image</b>}>
          <ImageUpload
            initialPreview={backgroundImagePreview}
            onChangePreview={setBackgroundImagePreview}
            onRemove={() => form.setFields([{ name: 'image', value: null }])}
            textOverwrite={[
              'This image will appear on the main landing page as well as every basic content page.',
              'The maximum file size is 5 MB and must be at least 1920x1080 pixels in either JPG or PNG formats.',
            ]}
            extraButtons={
              <>
                <Button
                  disabled={!backgroundImagePreview}
                  onClick={() => setShowPreviewBackgroundModal(true)}
                >
                  Preview
                </Button>
              </>
            }
          />
        </Form.Item>
        <Form.Item name='themeLogo' label={<b>Event Logo</b>}>
          <ImageUpload
            initialPreview={logoImagePreview}
            onChangePreview={setLogoImagePreview}
            onRemove={() => form.setFields([{ name: 'image', value: null }])}
            textOverwrite={[
              'This logo is shown at the top of the landing place card and left of the top menu bar.',
              'The maximum file size is 5 MB and must be at least 300x150 pixels in either JPG or PNG formats.',
              'Tip: Use a transparent horizontal image that works well against a dark background.',
            ]}
            extraButtons={
              <>
                <Button disabled={!logoImagePreview} onClick={() => setShowPreviewLogoModal(true)}>
                  Preview
                </Button>
              </>
            }
          />
        </Form.Item>
        <Form.Item name='themeLogoEmail' label={<b>Email Logo</b>}>
          <ImageUpload
            initialPreview={logoEmailImagePreview}
            onChangePreview={setLogologoEmailImagePreview}
            onRemove={() => form.setFields([{ name: 'image', value: null }])}
            textOverwrite={[
              'This logo will be displayed in all email communications.',
              'The maximum file size is 5 MB and must be at least 380x600 pixels in either JPG or PNG formats.',
              'Tip: There is a gradient filter overlay on this thumbnail to make the text legible.', // TODO: check if this is true
              'Tip: Use a transparent horizontal image.',
            ]}
            extraButtons={
              <>
                <Button
                  disabled={!logoEmailImagePreview}
                  onClick={() => setShowPreviewEmailLogoModal(true)}
                >
                  Preview
                </Button>
              </>
            }
          />
        </Form.Item>
        <Divider key='d2' />
        <h2>Colors</h2>
        {parsedEvent && <Colors event={parsedEvent} />}
        <Divider key='d3' />
        <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
          Save
        </Button>
        <Button
          style={{ float: 'right', marginRight: 10 }}
          onClick={() => setShowPreviewBackgroundModal(true)}
        >
          Preview
        </Button>
      </Form>
      <PreviewBackground
        visible={showPreviewBackgroundModal}
        onCancel={() => setShowPreviewBackgroundModal(false)}
        event={{
          ...parsedEvent,
          themeBackgroundUrl: backgroundImagePreview,
        }}
      />
      <PreviewLogo
        visible={showPreviewLogoModal}
        onCancel={() => setShowPreviewLogoModal(false)}
        event={{
          ...parsedEvent,
          themeLogoUrl: logoImagePreview,
        }}
      />
      <PreviewEmailLogo
        visible={showPreviewEmailLogoModal}
        onCancel={() => setShowPreviewEmailLogoModal(false)}
        event={{
          ...parsedEvent,
          themeLogoEmailUrl: logoEmailImagePreview,
        }}
      />
    </EventLayout>
  );
}
