import { Space, Modal } from 'antd';

import { UserResource } from '@transforms/user';

import userCardStyles from '../../styles/UserCardPreview.module.css';
import attendeesIndicatorStyles from '../../styles/AttendeesIndicatorPreview.module.css';

type PreviewAvatarModalProps = {
  visible: boolean;
  user?: UserResource;
  onCancel(): void;
};

export function PreviewAvatarModal({ visible, user, onCancel }: PreviewAvatarModalProps) {
  const UserCard = () => (
    <div className={userCardStyles.container}>
      <div
        className={userCardStyles.avatar}
        style={{
          backgroundImage: `url('${user?.avatarUrl}')`,
        }}
      />
      <div>
        <span className={userCardStyles.name}>{user?.fullName}</span>
        <div className={userCardStyles.iconContainer}>
          <div className={userCardStyles.iconWrapper}>
            <svg width='14' height='14' viewBox='0 0 24 24'>
              <path
                d='M3.90456 3.41218C9.95788 3.151 14.0381 3.16302 20.0907 3.41562C21.1583 3.46018 22 4.34086 22 5.4094V16.088C22 17.1576 21.1584 18.0386 20.0897 18.0818C19.187 18.1182 18.3281 18.1494 17.5 18.1753C17.2743 18.1821 17.3034 19.1174 17.3716 20.1924C17.4271 21.0665 16.4461 21.5253 15.7984 20.9356C14.2492 19.5251 12.8031 18.2657 12.5 18.2672C9.79792 18.2773 7.13272 18.2173 3.90736 18.0841C2.8399 18.04 2 17.1594 2 16.091V5.40478C2 4.33811 2.83888 3.45816 3.90456 3.41218Z'
                fill='#FFFFFF'
              />
            </svg>
          </div>
          <div className={userCardStyles.iconWrapper}>
            <svg width='14' height='14' viewBox='0 0 24 24'>
              <path
                d='M8.43533 10.6158C7.53231 9.36307 8.41793 9.06903 9.27572 8.25822C10.4241 7.17423 10.358 7.01938 8.77984 5.43952C7.95511 4.61653 6.75977 3.26982 6.04988 2.61212C5.13293 1.76129 4.87716 1.79957 3.9202 2.63126C3.28629 3.15256 2.78308 3.81489 2.45077 4.56532C2.11845 5.31576 1.96623 6.13351 2.00627 6.95326C1.94189 12.2253 9.50539 19.6548 14.4625 21.5479C15.2778 21.8846 16.1539 22.0493 17.0358 22.0316C17.8553 22.0712 18.6727 21.9188 19.4227 21.5865C20.1728 21.2542 20.8349 20.7512 21.3561 20.1176C22.1965 19.1607 22.2261 18.8997 21.3752 17.9862C20.7175 17.2763 19.4213 16.0288 18.5478 15.258C16.8862 13.793 16.8079 13.6137 15.7291 14.7621C14.9201 15.6199 14.6243 16.502 13.3715 15.6007C11.5982 14.0708 9.94777 12.4041 8.43533 10.6158V10.6158Z'
                fill='#BBBBBB'
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );

  const AttendeesIndicator = () => (
    <div className={attendeesIndicatorStyles.container}>
      <span
        className={attendeesIndicatorStyles.avatar}
        style={{
          backgroundImage: `url('${user?.avatarUrl}')`,
        }}
      />
      <span
        className={attendeesIndicatorStyles.initials}
        style={{
          background: '#d4a303',
        }}
      >
        DS
      </span>
      <span
        className={attendeesIndicatorStyles.initials}
        style={{
          background: '#ffe4ba',
        }}
      >
        FE
      </span>
      <span
        className={attendeesIndicatorStyles.initials}
        style={{
          background: '#a63aaa',
          color: '#fff',
          marginRight: '0.7em',
        }}
      >
        TT
      </span>
      <span className={attendeesIndicatorStyles.text}>4 attendees</span>
    </div>
  );

  return (
    <Modal title='Preview' visible={visible} onCancel={onCancel} footer={null} width='700px'>
      <Space align='start' size={52}>
        <UserCard />
        <AttendeesIndicator />
      </Space>
    </Modal>
  );
}
