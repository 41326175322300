import parse, { Attributes } from '@utils/parse';

import { parseUserRole } from './userRole';

export const parseBreakoutRoom = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    title: attributes.string('title'),
    subtitle: attributes.optional.string('subtitle'),
    url: attributes.optional.string('url'),
    imageUrl: attributes.optional.string('imageUrl'),
    position: attributes.optional.number('position'),
    addUserId: attributes.optional.boolean('addUserId') ?? false,
    categoryId: attributes.optional.id('categoryId'),
    roles: attributes.optional.custom('roles', (data: any) => {
      return parse.many(data, parseUserRole);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseBreakoutRoom);
}

export function many(data: any) {
  return parse.many(data, parseBreakoutRoom);
}

export type BreakoutRoomResource = ReturnType<typeof one>;
export type BreakoutRoom = Omit<BreakoutRoomResource, 'id'>;

export default { one, many };
