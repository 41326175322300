import { useRef, useCallback, useEffect, useState } from 'react';
import SunEditorCore from 'suneditor/src/lib/core';
import SunEditor from 'suneditor-react';

import 'suneditor/dist/css/suneditor.min.css';

import { stripHtml } from '@utils/html';

interface Props {
  readonly minHeight?: number;
  readonly maxLength?: number;
  readonly placeholder?: string;
  readonly style?: string;
  readonly value?: string;
  readonly embedsAllowed?: boolean;
  readonly disabled?: boolean;
  readonly onChange?: (value?: string) => void;
  readonly clearHandleBar?: () => void;
  readonly insertHandleBar?: string | null;
  readonly disableFont?: boolean;
  readonly disableColor?: boolean;
}
export function RichTextEditor({
  placeholder,
  minHeight,
  maxLength,
  value,
  embedsAllowed,
  onChange,
  clearHandleBar,
  insertHandleBar,
  style,
  disabled,
  disableFont,
  disableColor,
}: Props) {
  const editor = useRef<SunEditorCore>();
  const [render, forceRerender] = useState(!disabled);
  console.log(maxLength);

  useEffect(() => {
    forceRerender((prev) => !prev);
  }, [disabled]);
  useEffect(() => {
    if (!render) forceRerender(true);
  }, [render]);

  const update = useCallback(
    (raw: string) => {
      const newValue = raw.replace(/href="((?!\/)(?!http)\S+)"/, 'href="https://$1"');
      const newLength = stripHtml(newValue).length;
      if (newLength === 0) {
        onChange?.('');
      } else {
        onChange?.(newValue);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (editor.current && insertHandleBar) {
      editor.current.core.focus();
      editor.current.insertHTML(insertHandleBar);
      update(editor.current.getContents(true));
      if (clearHandleBar) {
        clearHandleBar();
      }
    }
  }, [insertHandleBar, update, clearHandleBar]);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = useCallback(
    (sunEditor: SunEditorCore) => {
      editor.current = sunEditor;
      if (disabled) {
        editor.current.disabled();
      }
    },
    [disabled],
  );

  const handlePaste = useCallback(
    (_: object, cleanData: string) => {
      if (editor.current) {
        editor.current.core.focus();

        const tempEl = document.createElement('div');
        tempEl.innerHTML = cleanData;

        const plainText = document.createElement('span');
        plainText.innerText = tempEl.innerText;

        editor.current?.insertHTML(plainText);
        update(editor.current.getContents(true));
      }
    },
    [update],
  );

  if (!render) return null;

  return (
    <div>
      <SunEditor
        setDefaultStyle={style}
        getSunEditorInstance={getSunEditorInstance}
        defaultValue={value ?? ''}
        onChange={update}
        onPaste={handlePaste}
        placeholder={placeholder}
        setOptions={{
          minHeight: `${minHeight}px`,
          showPathLabel: false,
          // maxCharCount: maxLength,
          defaultStyle: `font-family: 'Arial'; font-size:13px; ${
            disabled
              ? 'color: rgba(0, 0, 0, 0.25); background-color: #f5f5f5; cursor: not-allowed;'
              : 'color: rgba(0, 0, 0, 0.85); background-color: #ffffff; cursor: inherit;'
          }`,
          // charCounter: !!maxLength,
          font: [
            'Arial',
            'Arial Black, Arial',
            'Comic Sans MS, Comic Sans',
            'Courier',
            'Courier New',
            'Georgia',
            'Helvetica',
            'Impact',
            'Lucida Sans Unicode',
            'Tahoma',
            'Times',
            'Times New Roman',
            'Trebuchet MS',
            'Verdana',
          ],
          buttonList: [
            [...(disableFont ? [] : ['font']), 'fontSize', ...(disableColor ? [] : ['fontColor'])],
            ['bold', 'underline', 'italic', 'strike'],
            ['list'],
            embedsAllowed ? ['link', 'video'] : ['link'],
          ],
        }}
      />
    </div>
  );
}
