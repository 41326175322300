import { List, Button, Tooltip, Popconfirm, Space, Tag, Avatar } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { SessionResource } from '@transforms/session';

export function ListItem({
  session,
  onDuplicate,
  onEdit,
  onDelete,
  showTags = true,
  deletesFromStage = false,
}: {
  session: SessionResource;
  onDuplicate?: (session: SessionResource) => void;
  onEdit?: (user: SessionResource) => void;
  onDelete?: (id: string) => void;
  showTags?: boolean;
  deletesFromStage?: boolean;
}) {
  return (
    <List.Item
      key={session.id}
      actions={[
        onDuplicate && (
          <Tooltip title='Duplicate' key='duplicate'>
            <Button shape='circle' icon={<CopyOutlined />} onClick={() => onDuplicate(session)} />
          </Tooltip>
        ),
        onEdit && (
          <Tooltip title='Edit' key='edit'>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => onEdit(session)} />
          </Tooltip>
        ),
        onDelete && (
          <Popconfirm
            title={`Are you sure you want to delete this session from this ${
              deletesFromStage ? 'stage' : 'event'
            }?`}
            onConfirm={() => onDelete(session.id)}
            okText='Delete'
            cancelText='Cancel'
            key='delete'
          >
            <Button shape='circle' icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      ].filter((item) => item !== undefined)}
    >
      <List.Item.Meta
        title={<div>{session.title}</div>}
        description={
          <Space>
            {`${dayjs(session.startsAt).format('ddd D MMM, HH:mm')} - ${dayjs(
              session.endsAt,
            ).format('HH:mm')}`}
            {showTags && session.mandatory && (
              <div>
                <Tag color='green'>Mandatory</Tag>
              </div>
            )}
            {showTags && session.highlighted && (
              <div>
                <Tag color='blue'>Highlighted</Tag>
              </div>
            )}
          </Space>
        }
        avatar={session.imageUrl && <Avatar src={session.imageUrl} />}
      />
    </List.Item>
  );
}
