import React from 'react';
import { Tag } from 'antd';
import { useParams } from 'react-router-dom';

import { useHandlebars } from '@hooks/useMails';

interface Props {
  disabled?: boolean;
  onUpdateText: (value: string) => void;
}

function Handlebars({ disabled, onUpdateText }: Props) {
  const { eventId } = useParams();
  const { data: handlebars } = useHandlebars(eventId);
  if (!handlebars) return null;

  return (
    <>
      <p>
        <b>Handlebars</b>
      </p>
      <p>
        Use handlebars as shortcuts to dynamically populate event information directly into your
        email content. Click on a tag to directly add it to the body field.
      </p>
      {handlebars.map((handlebar: { key: string; value: string }) => {
        const handleClick = () => {
          onUpdateText(handlebar.value);
        };

        return (
          <Tag
            onClick={disabled ? undefined : handleClick}
            key={handlebar.key}
            style={{
              transition: 'background-color 0.3s',
              backgroundColor: disabled ? '#FFF' : undefined,
            }}
          >
            <a style={{ cursor: disabled ? 'default' : 'pointer' }}>{handlebar.value}</a>
          </Tag>
        );
      })}
      <br />
      <br />
    </>
  );
}

export default Handlebars;
