import parse, { Attributes } from '@utils/parse';

import parseMeta from './meta';
import parseVideo from './video';
import { parseData as parseSpeaker } from './speaker';
import { parseUser } from './user';
import { parseStage } from './stage';
import { parseUserRole } from './userRole';
import { parseUserSession } from './userSession';

const parseRelations = (attributes: Attributes) => {
  return {
    speakers: attributes.optional.custom('speakers', (speaker) => {
      return parse.many(speaker, parseSpeaker);
    }),
    attendees: attributes.optional.custom('attendees', (attendee) => {
      return parse.many(attendee, parseUser);
    }),
    stage: attributes.optional.custom('stage', (stage) => {
      return parse.one(stage, parseStage);
    }),
  };
};

export enum SessionLocation {
  ONSITE = 'ONSITE',
  ONLINE = 'ONLINE',
  BOTH = 'BOTH',
}

export const parseSession = (attributes: Attributes) => {
  const scheduled = !!attributes.optional.custom('userSession', (userSession) => {
    return parse.one(userSession, parseUserSession);
  });
  const reminder =
    attributes.optional.custom('userSession', (userSession) => {
      return parse.one(userSession, parseUserSession).reminder;
    }) || false;

  return {
    id: attributes.id('id'),
    title: attributes.string('title'),
    description: attributes.string('description'),
    imageUrl: attributes.optional.string('imageUrl'),
    startsAt: attributes.date('startsAt'),
    endsAt: attributes.date('endsAt'),
    url: attributes.optional.string('url'),
    onDemandUrl: attributes.optional.string('onDemandUrl'),
    highlighted: attributes.boolean('highlighted'),
    mandatory: attributes.boolean('mandatory'),
    automaticPush: attributes.boolean('automaticPush'),
    live: attributes.optional.boolean('live'),
    toggleAttendees: attributes.boolean('toggleAttendees'),
    reminderTime: attributes.optional.number('reminderTime'),
    roles: attributes.custom('roles', (roles: any) => {
      return parse.many(roles, parseUserRole);
    }),
    video: attributes.optional.custom('video', parseVideo.one),
    scheduled,
    reminder,
    location: attributes.enum<typeof SessionLocation>('location', SessionLocation),
  };
};

export const parseData = (attributes: Attributes) => {
  return {
    ...parseRelations(attributes),
    ...parseSession(attributes),
  };
};

export function one(data: any) {
  return parse.one(data, parseData);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseData),
    meta: parseMeta(data.meta),
  };
}

export type SessionResource = ReturnType<typeof one>;
export type Session = Omit<SessionResource, 'id'>;

export default { one, many };
