import { useCallback } from 'react';

import fetch from '@utils/fetch';
import { writeRefreshToken } from '@utils/auth';
import { queryClient } from '@utils/queryClient';

import { tokens, useAuth } from './useAuth';

export function useLogout() {
  const { setIsAuthorized, setUser, setError, setTfaRequired } = useAuth();

  return useCallback(async () => {
    try {
      await fetch('/auth/logout', {
        method: 'DELETE',
        token: tokens.accessToken,
      });
      setError(undefined);
      setIsAuthorized(false);
      setTfaRequired(false);
      setUser(undefined);
      tokens.accessToken = undefined;
      tokens.refreshToken = undefined;
      writeRefreshToken();
      queryClient.removeQueries();
    } catch (err) {
      setError(err);
    }
  }, [setTfaRequired, setIsAuthorized, setUser, setError]);
}
