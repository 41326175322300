import parse, { Attributes } from '@utils/parse';

import { parseOrganisation } from './organisation';
import { parseEvent } from './event';
import parseMeta from './meta';

export enum AdminRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  EVENT_ADMIN = 'EVENT_ADMIN',
  ADMIN = 'ADMIN',
}

export const parseAdmin = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    email: attributes.string('email'),
    role: attributes.enum<typeof AdminRole>('role', AdminRole),
    invitationAcceptedAt: attributes.optional.date('invitationAcceptedAt'),
    totpEnabled: attributes.custom('totpEnabledAt', (totpEnabledAt: any) => !!totpEnabledAt),
    organisations: attributes.optional.custom('organisations', (organisations) => {
      return parse.many(organisations, parseOrganisation);
    }),
    assignments: attributes.optional.custom('assignments', (assignments) => {
      return parse.many(assignments, parseEvent);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseAdmin);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseAdmin),
    meta: parseMeta(data.meta),
  };
}

export type AdminResource = ReturnType<typeof one>;
export type Admin = Omit<AdminResource, 'id'>;

export default { one, many };
