import { useCallback, useEffect, useState } from 'react';
import { Avatar, Checkbox, message, Modal, Select, Table } from 'antd';

import type { UserRoleResource } from '@transforms/userRole';

export interface SessionRole {
  id: string;
  title: string;
  imageUrl: string | null;
  roleIds: string[];
}

export interface SessionModalProps {
  visible: boolean;
  onOk: (data: SessionRole[]) => void;
  onCancel: () => void;
  roles: UserRoleResource[];
  roleId: string;
  sessionRoles?: SessionRole[];
  defaultRole?: UserRoleResource;
}

export function SessionModal({
  visible: modalVisible,
  onOk,
  onCancel,
  roles,
  roleId,
  sessionRoles,
  defaultRole,
}: SessionModalProps) {
  const [updatedSessionRoles, setUpdatedSessionRoles] = useState(sessionRoles);

  const role = roles.find(({ id }) => id === roleId);

  useEffect(() => {
    setUpdatedSessionRoles((prev) => [
      ...(prev ?? []),
      ...(sessionRoles?.filter(({ id }) => !prev?.some((session) => session.id === id)) ?? []),
    ]);
  }, [sessionRoles, roleId]);

  const updateSessionRoles = useCallback(
    (session: SessionRole) => {
      setUpdatedSessionRoles((prev) => {
        const prevSessionRoles = prev?.find(({ id }) => session.id === id)?.roleIds ?? [];
        const removeOrAdd = prevSessionRoles.includes(roleId);
        if (removeOrAdd && prevSessionRoles.length - 1 === 0) {
          message.error(
            `A session needs at least 1 role, please add another role to this session first. If you delete this role, the session will be re-assigned to the default role${
              defaultRole ? ` "${defaultRole.name}"` : ''
            }.`,
            10,
          );
          return prev;
        }
        return [
          ...prev!.filter(({ id }) => session.id !== id),
          /* eslint-disable no-nested-ternary */
          {
            id: session.id,
            title: session.title,
            imageUrl: session.imageUrl,
            roleIds: removeOrAdd
              ? // Remove role from session
                prevSessionRoles.filter((id) => id !== roleId)
              : // Add role to session
              // -- Add all roles except this one
              prevSessionRoles.length === 0
              ? roles.map(({ id }) => id).filter((id) => id !== roleId)
              : // -- Clear array to allow all roles
              prevSessionRoles.length + 1 === roles.length
              ? []
              : // -- Add role to session
                [...prevSessionRoles, roleId],
          },
          /* eslint-enable no-nested-ternary */
        ];
      });
    },
    [defaultRole, roleId, roles],
  );

  return (
    <Modal
      width={750}
      visible={modalVisible}
      title='Sessions with Role'
      okText='Ok'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => onOk(updatedSessionRoles ?? [])}
    >
      <Table
        loading={!updatedSessionRoles}
        dataSource={updatedSessionRoles?.sort((a, b) => a.id.localeCompare(b.id))}
        pagination={{
          pageSize: 10,
          total: updatedSessionRoles?.length ?? 0,
        }}
        columns={[
          {
            title: 'Image',
            key: 'image',
            dataIndex: 'imageUrl',
            width: 40,
            render: (image) => <Avatar size={40} src={image} />,
          },
          {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: `Has ${role?.name ? `"${role.name}" ` : ''}Role`,
            key: 'hasRole',
            dataIndex: 'roleId',
            render: (_, session) => {
              const updatedSessionRole = updatedSessionRoles?.find(({ id }) => session.id === id);
              return (
                <Checkbox
                  checked={
                    updatedSessionRole?.roleIds.includes(roleId) ||
                    updatedSessionRole?.roleIds.length === 0
                  }
                  onClick={() => updateSessionRoles(session)}
                >
                  {roles.map((data) => (
                    <Select.Option value={data.id} key={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Checkbox>
              );
            },
          },
        ]}
      />
    </Modal>
  );
}
