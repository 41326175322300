import { Modal } from 'antd';

import { pascalCase } from '@utils/transformText';

export interface DeleteWarningModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  defaultRoleName?: string;
  singleAssignments: Array<{
    id: string;
    type: string;
    title: string;
  }>;
}

export function DeleteWarningModal({
  visible: modalVisible,
  onOk,
  onCancel,
  defaultRoleName,
  singleAssignments,
}: DeleteWarningModalProps) {
  return (
    <Modal
      width={750}
      visible={modalVisible}
      title='Confirm Role Delete'
      okType='danger'
      okText='Delete'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={onOk}
    >
      <div style={{ fontSize: '16px' }}>
        There {singleAssignments.length === 1 ? 'is an item' : 'are items'} with just this role
        assigned. {singleAssignments.length === 1 ? 'It' : 'They'} will be re-assigned to the
        default role{defaultRoleName ? <b> "{defaultRoleName}"</b> : ''}.
        <br />
        <br />
        <b>This concerns the following item{singleAssignments.length === 1 ? '' : 's'}:</b>
        <ul>
          {singleAssignments.map((item) => (
            <li key={item.id}>
              {pascalCase(item.type)} "{item.title}"
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}
