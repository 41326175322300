import React, { useCallback, useState } from 'react';
import { Button, Card, Divider, List, message, Popconfirm, Space } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';

import { Breakout as BreakoutModal } from '@components/Modals/Breakout';
import { BreakoutRoom, BreakoutRoomResource } from '@transforms/breakoutRoom';
import {
  useCreateBreakoutRoom,
  useCreateBreakoutRoomCategory,
  useDeleteBreakoutRoom,
  useDeleteBreakoutRoomCategory,
  useDuplicateBreakoutRoom,
  useDuplicateBreakoutRoomCategory,
  useUpdateBreakoutRoom,
  useUpdateBreakoutRoomCategory,
  useUploadBreakoutRoomImage,
} from '@hooks/useBreakoutRooms';
import { usePageItemCategories } from '@hooks/usePageItemCategories';
import { useCurrentEvent } from '@hooks/useEvents';
import { PageItemCategory, PageItemCategoryResource } from '@transforms/pageItemCategory';
import { BreakoutCategory } from '@components/Modals/BreakoutCategory';
import { VideoCallResource } from '@transforms/videoCall';
import { LazyModal } from '@components/LazyModal';
import { VideoCall as VideoCallModal } from '@components/Modals/VideoCall';
import {
  useCreateVideoCall,
  useDeleteVideoCall,
  useUpdateVideoCall,
  useUploadVideoCallImage,
} from '@hooks/useVideoCalls';

import ListItem from './Breakout/ListItem';
import VideoCallListItem from './VideoCall/ListItem';
import type { PageProps } from './props';

function BreakoutPage({ page, language }: PageProps) {
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<PageItemCategoryResource | undefined>();
  const [openRoomModal, setOpenRoomModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<{
    room?: BreakoutRoomResource;
    categoryId?: string;
  }>({});
  const [openVideoCallModal, setOpenVideoCallModal] = useState(false);
  const [selectedVideoCall, setSelectedVideoCall] = useState<{
    videoCall?: VideoCallResource;
    categoryId?: string;
  }>({});

  const { data: event } = useCurrentEvent();
  const { data: categories, isLoading } = usePageItemCategories(event?.id, page?.id, language);
  const { mutate: createBreakoutRoomCategory } = useCreateBreakoutRoomCategory(event?.id, language);
  const { mutateAsync: duplicateBreakoutRoomCategory } = useDuplicateBreakoutRoomCategory(
    event?.id,
  );
  const { mutateAsync: duplicateBreakoutRoom } = useDuplicateBreakoutRoom(event?.id);
  const { mutate: updateBreakoutRoomCategory } = useUpdateBreakoutRoomCategory(
    event?.id,
    selectedCategory?.id,
    language,
  );
  const { mutate: deleteBreakoutRoomCategory } = useDeleteBreakoutRoomCategory(event?.id);

  const { mutate: createBreakoutRoom } = useCreateBreakoutRoom(event?.id, language);
  const { mutate: updateBreakoutRoom } = useUpdateBreakoutRoom(
    event?.id,
    selectedRoom?.room?.id,
    language,
  );
  const { mutateAsync: uploadBreakoutRoomImage } = useUploadBreakoutRoomImage(event?.id);
  const { mutate: deleteBreakoutRoom } = useDeleteBreakoutRoom(event?.id);

  const { mutate: createVideoCall } = useCreateVideoCall(event?.id, language);
  const { mutate: updateVideoCall } = useUpdateVideoCall(
    event?.id,
    selectedVideoCall?.videoCall?.id,
    language,
  );
  const { mutateAsync: uploadVideoCallImage } = useUploadVideoCallImage(event?.id);
  const { mutate: deleteVideoCall } = useDeleteVideoCall(event?.id);

  // -----------------------------------------------
  // MARK: - CATEGORY
  // -----------------------------------------------

  const onOpenCategoryModal = (category?: PageItemCategoryResource) => {
    if (category) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory(undefined);
    }
    setOpenCategoryModal(true);
  };

  const onCloseCategoryModal = () => {
    setOpenCategoryModal(false);
    setSelectedCategory(undefined);
  };

  const onCreateCategory = (values: PageItemCategory, done: (err?: Error) => void) => {
    createBreakoutRoomCategory(
      {
        ...values,
        position: values.position ? parseInt(String(values.position), 10) : undefined,
        pageId: page?.id,
      },
      {
        onSuccess: async () => {
          onCloseCategoryModal();
          done();
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  const onUpdateCategory = useCallback(
    (values: PageItemCategory, done: (err?: Error) => void) => {
      updateBreakoutRoomCategory(
        {
          ...values,
          position: values.position ? parseInt(String(values.position), 10) : undefined,
          pageId: page.id,
        },
        {
          onSuccess: () => {
            onCloseCategoryModal();
            done();
          },
          onError: (error: any) => {
            done(error);
          },
        },
      );
    },
    [page, updateBreakoutRoomCategory],
  );

  const onDeleteCategory = (id: string) => {
    deleteBreakoutRoomCategory(id);
  };

  const onDuplicateRoomCategory = async (room?: PageItemCategoryResource) => {
    if (!room?.id) return;
    await duplicateBreakoutRoomCategory(room.id);
    message.success('Breakout room category duplicated');
  };

  // -----------------------------------------------
  // MARK: - ROOMS
  // -----------------------------------------------

  const onOpenRoomModal = (room?: BreakoutRoomResource, categoryId?: string) => {
    setSelectedRoom({ room, categoryId });
    setOpenRoomModal(true);
  };

  const onCloseRoomModal = () => {
    setOpenRoomModal(false);
    setSelectedRoom({ room: undefined, categoryId: undefined });
  };

  const onCreateRoom = (
    values: BreakoutRoom & { page?: string; externalUrl?: string; image?: File | Blob },
    done: (err?: Error) => void,
  ) => {
    createBreakoutRoom(
      {
        ...values,
        pageId: page!.id,
        url: values.page || values.externalUrl,
        categoryId: selectedRoom.categoryId,
      },
      {
        onSuccess: async (val) => {
          if (values.image) {
            try {
              await uploadBreakoutRoomImage({
                breakoutRoomId: val.body.id,
                image: values.image,
                lang: language,
              });
            } catch (error) {
              done(error as Error);
              return;
            }
          }

          onCloseRoomModal();
          done();
        },
        onError: (error: any) => {
          done(error);
        },
      },
    );
  };

  const onUpdateRoom = useCallback(
    async (
      other: BreakoutRoom & { page?: string; externalUrl?: string; image?: File | Blob | null },
      done: (err?: Error) => void,
      shouldClose = true,
    ) => {
      const position = { position: other.position };
      if (!other.position) {
        position.position = null;
      }

      updateBreakoutRoom(
        {
          ...selectedRoom.room!,
          ...other,
          ...position,
          pageId: page.id,
          url: other.page || other.externalUrl,
          categoryId: selectedRoom.categoryId,
        },
        {
          onSuccess: async (response) => {
            if (other.image) {
              try {
                await uploadBreakoutRoomImage({
                  breakoutRoomId: response.body.id,
                  image: other.image,
                  lang: language,
                });
              } catch (error) {
                done(error as Error);
                return;
              }
            }

            if (shouldClose) onCloseRoomModal();
            done();
          },
          onError: (error: any) => {
            done(error);
          },
        },
      );
    },
    [language, selectedRoom, page, updateBreakoutRoom, uploadBreakoutRoomImage],
  );

  const onDuplicateRoom = async (room?: BreakoutRoomResource) => {
    if (!room?.id) return;
    await duplicateBreakoutRoom(room.id);
    message.success('Breakout room duplicated');
  };

  const onDeleteRoom = (id: string) => {
    deleteBreakoutRoom(id);
  };

  // -----------------------------------------------
  // MARK: - VIDEO CALLS
  // -----------------------------------------------

  const onOpenVideoCallModal = (videoCall?: VideoCallResource, categoryId?: string) => {
    setSelectedVideoCall({ videoCall, categoryId });
    setOpenVideoCallModal(true);
  };

  const onCloseVideoCallModal = () => {
    setOpenVideoCallModal(false);
    setSelectedVideoCall({ videoCall: undefined, categoryId: undefined });
  };

  const onCreateVideoCall = (
    values: Omit<VideoCallResource, 'position'> & {
      dates: Dayjs[];
      maxUsers: string;
      position: string;
      image?: File | Blob;
    },
    done: (err?: Error) => void,
  ) => {
    const { dates, maxUsers, position, image, ...rest } = values;
    createVideoCall(
      {
        ...rest,
        startDate: dates[0].toISOString(),
        endDate: dates[1].toISOString(),
        maxUsers: maxUsers ? parseInt(maxUsers, 10) : undefined,
        position: position ? parseInt(position, 10) : undefined,
        categoryId: selectedVideoCall.categoryId,
      },
      {
        onSuccess: async (response) => {
          if (image) {
            try {
              await uploadVideoCallImage({
                videoCallId: response.body.id,
                image,
                lang: language,
              });
            } catch (error) {
              done(error as Error);
              return;
            }
          }

          onCloseVideoCallModal();
          done();
        },
      },
    );
  };

  const onUpdateVideoCall = useCallback(
    async (
      other: Omit<VideoCallResource, 'position'> & {
        dates: Dayjs[];
        maxUsers: string;
        position: string;
        image?: File | Blob;
      },
      done: (err?: Error) => void,
      shouldClose = true,
    ) => {
      const { dates, maxUsers, position, image, ...values } = other;

      updateVideoCall(
        {
          ...values,
          startDate: dates[0].toISOString(),
          endDate: dates[1].toISOString(),
          maxUsers: maxUsers ? parseInt(maxUsers, 10) : undefined,
          position: position ? parseInt(position, 10) : undefined,
          categoryId: selectedVideoCall.categoryId,
        },
        {
          onSuccess: async (response) => {
            if (image) {
              try {
                await uploadVideoCallImage({
                  videoCallId: response.body.id,
                  image,
                  lang: language,
                });
              } catch (error) {
                done(error as Error);
                return;
              }
            }

            if (shouldClose) onCloseVideoCallModal();
            done();
          },
        },
      );
    },
    [selectedVideoCall, updateVideoCall, uploadVideoCallImage, language],
  );

  const onDeleteVideoCall = (id: string) => {
    deleteVideoCall(id);
  };

  if (isLoading || !categories || page.id === 'new') return null;

  return (
    <>
      <h2>Break Out</h2>
      <p>
        First, add a Breakout Category. Within the Category, add any amount of Breakout Rooms
        (whether an external link, redirect to another platform page, or an internal video call).
      </p>
      <p>
        Create a new Breakout Category or Breakout Room from scratch or duplicate any existing ones.
        Edit any existing Categories or Rooms via the pencil icon.
      </p>
      <p>
        Individual Breakout Rooms (only, not Categories) can be programmed for specific user roles
        and connected to the Sessions/Schedule feature.{' '}
      </p>
      <p>
        Each Breakout Room will be displayed as a thumbnail icon with option to upload an image.
        Note, there is a gradient filter overlay on this thumbnail to make the text legible.
      </p>
      <Space direction='vertical' style={{ width: '100%' }}>
        {categories.map((category) => (
          <Card
            key={category.id}
            title={category.title}
            extra={
              <Space>
                <Button
                  shape='circle'
                  icon={<CopyOutlined />}
                  onClick={() => onDuplicateRoomCategory(category)}
                />
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => onOpenCategoryModal(category)}
                />
                <Popconfirm
                  title='Are you sure you want to delete this category?'
                  onConfirm={() => onDeleteCategory(category.id)}
                  okText='Delete'
                  cancelText='Cancel'
                >
                  <Button danger shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            }
          >
            <List
              itemLayout='horizontal'
              dataSource={category.rooms}
              renderItem={(room) => (
                <ListItem
                  breakoutRoom={room}
                  onDuplicate={onDuplicateRoom}
                  onEdit={() => onOpenRoomModal(room, category.id)}
                  onDelete={onDeleteRoom}
                />
              )}
              footer={
                <>
                  <Button
                    type='dashed'
                    block
                    icon={<PlusOutlined />}
                    onClick={() => onOpenRoomModal(undefined, category.id)}
                  >
                    Add Link
                  </Button>
                </>
              }
            />
            <List
              itemLayout='horizontal'
              dataSource={
                event?.organisation?.videoCallsEnabled === false ? [] : category.videoCalls
              }
              renderItem={(videoCall) => (
                <VideoCallListItem
                  event={event}
                  videoCall={videoCall}
                  onEdit={() => onOpenVideoCallModal(videoCall, category.id)}
                  onDelete={onDeleteVideoCall}
                />
              )}
              footer={
                <>
                  <Button
                    type='dashed'
                    block
                    icon={<PlusOutlined />}
                    onClick={() => onOpenVideoCallModal(undefined, category.id)}
                    disabled={event?.organisation?.videoCallsEnabled === false}
                  >
                    Add Video Call
                  </Button>
                </>
              }
            />
          </Card>
        ))}
        <Button type='dashed' block icon={<PlusOutlined />} onClick={() => onOpenCategoryModal()}>
          Add Breakout Category
        </Button>
      </Space>
      <Divider key='d1' />
      <LazyModal open={!!openCategoryModal}>
        {(open) => (
          <BreakoutCategory
            visible={open}
            data={selectedCategory}
            onCancel={onCloseCategoryModal}
            onCreate={onCreateCategory}
            onUpdate={onUpdateCategory}
          />
        )}
      </LazyModal>
      <LazyModal open={!!openRoomModal}>
        {(open) => (
          <BreakoutModal
            visible={open}
            data={selectedRoom.room}
            onCancel={onCloseRoomModal}
            onCreate={onCreateRoom}
            onUpdate={onUpdateRoom}
          />
        )}
      </LazyModal>
      <LazyModal open={!!openVideoCallModal}>
        {(open) => (
          <VideoCallModal
            visible={open}
            data={selectedVideoCall.videoCall}
            onCancel={onCloseVideoCallModal}
            onCreate={onCreateVideoCall}
            onUpdate={onUpdateVideoCall}
          />
        )}
      </LazyModal>
    </>
  );
}

export default BreakoutPage;
