import React, { useEffect } from 'react';

import { Form, Divider, Button, message } from 'antd';

import { EventLayout } from '@components/Layout';
import { RichTextEditor } from '@components/RichTextEditor';
import { EventLanguageSelect } from '@components/LanguageSelect/EventLanguageSelect';

import { useLanguage } from '@hooks/useTranslations';
import { useCurrentEvent, useUpdateEvent } from '@hooks/useEvents';
import { stripHtml } from '@utils/html';

const MAX_REGISTRATION_COMPLETED_MESSAGE_LENGTH = 800;

export default function RegistrationComplete() {
  const [form] = Form.useForm();
  const { selectedLanguage } = useLanguage();
  const { data: event } = useCurrentEvent(selectedLanguage);
  const { mutate: updateEvent } = useUpdateEvent(event?.id, selectedLanguage);

  const validateRegistrationCompletedMessage = (
    value: string,
    callback: (error?: string) => void,
  ) => {
    if (stripHtml(value).length > MAX_REGISTRATION_COMPLETED_MESSAGE_LENGTH) {
      callback('The registration complete message is too long.');
      return;
    }

    callback();
  };

  useEffect(() => {
    form.setFieldsValue(event);
  }, [form, event]);

  const onUpdateEmails = (values: any) => {
    updateEvent({ ...event, ...values });
    message.success({
      content: (
        <div className='message-box-content-container'>
          <div className='message-box-content'>
            <h3>Event Updated!</h3>
            <span>Your changes have been successfully updated and saved!</span>
          </div>
        </div>
      ),
      className: 'message-box',
    });
  };

  return (
    <EventLayout
      title='Registration Complete'
      extra={[<EventLanguageSelect key='language-select' />]}
    >
      <Form
        form={form}
        onFinish={(values) => onUpdateEmails(values)}
        initialValues={event}
        layout='vertical'
      >
        <Form.Item
          key='registrationCompletedMessage'
          name='registrationCompletedMessage'
          label={
            <div>
              <div style={{ fontWeight: 'bold' }}>Registration Complete</div>
              <div>
                This is the message that will be displayed at the end of the registration flow once
                an attendee has successfully signed up for the event.
              </div>
            </div>
          }
          rules={[
            {
              validator: (_, value, callback) =>
                validateRegistrationCompletedMessage(value, callback),
            },
          ]}
        >
          <RichTextEditor
            minHeight={150}
            disableFont
            disableColor
            maxLength={MAX_REGISTRATION_COMPLETED_MESSAGE_LENGTH}
            placeholder="Congratulations! You've successfully completed registration for this event."
          />
        </Form.Item>

        <Divider />
        <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
          Save
        </Button>
      </Form>
    </EventLayout>
  );
}
