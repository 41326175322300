import { useCallback, useMemo, useState } from 'react';
import { Modal, List, message, Checkbox, Button, Upload } from 'antd';
import { FileOutlined, UploadOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons';

import { useImportUsers } from '@hooks/useUsers';
import { useCurrentEvent } from '@hooks/useEvents';

export interface ImportCSVFormProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export function ImportCSVForm({ visible, onOk, onCancel }: ImportCSVFormProps) {
  const { data: currentEvent } = useCurrentEvent();
  const { mutateAsync: importUsers } = useImportUsers(currentEvent?.id);

  const [existingUsers, setExistingUsers] = useState<Array<{ email: string; line: number }>>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [welcomeMail, setWelcomeMail] = useState(false);
  const [loginMail, setLoginMail] = useState(false);
  const [failedUsers, setFailedUsers] = useState<
    Array<{ message: string; value: any; line: number }>
  >([]);

  const handleCancel = useCallback(() => {
    if (!selectedFile) return onCancel();
    return Modal.confirm({
      width: 'fit-content',
      icon: <WarningOutlined />,
      title: 'Unsaved Changes!',
      content: (
        <>
          <p>There are unsaved changes! </p>
          <p>Are you sure you want to exit without saving?</p>
          <p>
            Choose 'Cancel' to return to the editor and save changes.
            <br />
            Choose 'Discard' to exit without saving changes. Note, any unsaved changes will be lost.
          </p>
        </>
      ),
      cancelText: 'Cancel',
      onCancel: () => {},
      okText: 'Discard',
      onOk: onCancel,
      okButtonProps: {
        danger: true,
      },
    });
  }, [selectedFile, onCancel]);

  const handleUpload = useCallback((file: File) => {
    setSelectedFile(file);
    return false;
  }, []);

  const handleImport = useCallback(async () => {
    if (!selectedFile) {
      message.error('No csv file selected');
      return;
    }

    const body = new FormData();
    body.append('csv', selectedFile);
    body.append('welcomeMail', welcomeMail.toString());
    body.append('loginMail', loginMail.toString());

    const {
      body: { users: added = [], existing = [], failed = [] },
    } = await importUsers(body);

    setExistingUsers(existing);
    setFailedUsers(failed);

    const hasHave = (length: number) => (length === 1 ? 'has' : 'have');

    let success = `${added.length} ${added.length === 1 ? 'attendee' : 'attendees'} ${hasHave(
      added.length,
    )} been added from "${selectedFile.name ?? 'import.csv'}"`;

    if (existing.length) {
      success += ` (${existing.length} ${hasHave(existing.length)} been skipped)`;
    }

    if (failed.length) {
      success += ` (${failed.length} ${hasHave(failed.length)} failed)`;
    }

    (added.length === 0 ? message.error : message.success)(success, 7);
    if (failed.length || existing.length) {
      setSelectedFile(undefined);
    } else {
      onOk();
    }
  }, [selectedFile, welcomeMail, loginMail, importUsers, onOk]);

  const getErrorMessage = useCallback((error: string, valueRaw?: any) => {
    const errMsg: string[] = [];
    const value = String(valueRaw);

    if (error.includes('isemail')) {
      errMsg.push('It should be a valid email address.');
    }
    if (error.includes('isboolean')) {
      errMsg.push('It should be either true or false.');
    }
    if (error.includes('isarray')) {
      errMsg.push('It should be an array of values (eg.: ["John", "Doe"]).');
    }
    if (value.match(/[;,]/) !== null) {
      errMsg.push(
        'Make sure both the entries and headers are either semicolon or comma separated.',
      );
    }
    if (errMsg.length === 0) {
      errMsg.push(
        'Something went wrong, please use the sample file to make sure you use the correct header names and field values',
      );
    }

    return errMsg;
  }, []);

  const failedUsersRender = useMemo(() => {
    if (failedUsers.length === 0) return null;

    return (
      <List
        bordered
        header={
          <p style={{ fontWeight: 600, fontSize: '14px', margin: '0px' }}>
            <WarningFilled style={{ color: 'red', marginRight: '8px' }} />
            Failed attendees
          </p>
        }
        style={{ marginTop: 8 }}
        dataSource={failedUsers.sort((a, b) => a.line - b.line)}
        className='max-height-list'
        renderItem={(failedUser) => (
          <List.Item>
            <div style={{ fontSize: '12px' }}>
              Invalid value
              {typeof failedUser.value === 'string' ? (
                <>
                  &nbsp;&quot;
                  <b>{failedUser.value}</b>
                  &quot;&nbsp;
                </>
              ) : (
                ' '
              )}
              on <b>line {failedUser.line}</b>.<br />
              {getErrorMessage(failedUser.message.toLowerCase(), failedUser.value).join(' ')}
            </div>
          </List.Item>
        )}
      />
    );
  }, [failedUsers, getErrorMessage]);

  const existingUsersRender = useMemo(() => {
    if (existingUsers.length === 0) return null;

    return (
      <List
        bordered
        header={
          <p style={{ fontWeight: 600, fontSize: '14px', margin: '0px' }}>
            <WarningFilled style={{ color: 'orange', marginRight: '8px' }} />
            Existing attendees
          </p>
        }
        style={{ marginTop: 8 }}
        dataSource={existingUsers.sort((a, b) => a.line - b.line)}
        className='max-height-list'
        renderItem={(existingUser) => (
          <List.Item>
            <div style={{ fontSize: '12px' }}>
              Email "<b>{existingUser.email}</b>" on <b>line {existingUser.line}</b>.
            </div>
          </List.Item>
        )}
      />
    );
  }, [existingUsers]);

  return (
    <Modal
      visible={visible}
      title='Import attendees with CSV (beta)'
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleImport} disabled={!selectedFile}>
          Import
        </Button>,
      ]}
    >
      <p>
        This feature is currently in beta, it should only be used as an initial import of attendees.
        <br />
        <br />
        Attendees in the CSV need to have unique email addresses, a name, matching custom fields
        &amp; enabled language codes. Attendees with existing email adresses will be skipped.
        <br />
        <br />
        The CSV file may only have matching headers and needs to be either semicolon or comma
        separated. You can download the{' '}
        <a
          href='https://admin.virtual-live-event.com/sample.csv'
          target='_blank'
          rel='noreferrer'
          download
        >
          sample file here
        </a>
        .
      </p>

      <Checkbox
        style={{ margin: '5px 0 10px' }}
        onChange={({ target }) => setWelcomeMail(target.checked)}
      >
        Send registration confirmation email to imported attendees
      </Checkbox>
      <div />
      <Checkbox
        style={{ marginBottom: '25px' }}
        onChange={({ target }) => setLoginMail(target.checked)}
      >
        Send login link email to imported attendees
      </Checkbox>

      <Upload.Dragger
        accept='.csv'
        multiple={false}
        showUploadList={false}
        beforeUpload={handleUpload}
      >
        <p className='ant-upload-drag-icon' style={{ margin: '15px 0 10px' }}>
          {selectedFile ? <FileOutlined /> : <UploadOutlined />}
        </p>
        <p className='ant-upload-text' style={{ margin: '10px 0' }}>
          {selectedFile?.name ?? 'Select or drag a CSV file here'}
        </p>
      </Upload.Dragger>

      {existingUsersRender}
      {failedUsersRender}
    </Modal>
  );
}
