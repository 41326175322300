import { Modal, Button } from 'antd';

export interface ExportCSVFormProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  action: () => void;
}

export function ExportCSVForm({ visible, onOk, onCancel, action }: ExportCSVFormProps) {
  return (
    <Modal
      visible={visible}
      title='Export attendees in CSV'
      okText='Close'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={onOk}
    >
      This CSV contains these columns: <br />
      <ul style={{ fontSize: '0.9rem' }}>
        <li>
          <b>id</b> - An identifier for each attendee
        </li>
        <li>
          <b>email</b> - The email address from this attendee
        </li>
        <li>
          <b>role</b> - The role assigned to the person (if applicable)
        </li>
        <li>
          <b>avatarUrl</b> - The URL for the avatar image
        </li>
        <li>
          <b>firstName</b> - The first name of the attendee
        </li>
        <li>
          <b>lastName</b> - The last name of the attendee
        </li>
        <li>
          <b>language</b> - This attendee will see the platform translated into this language
        </li>
        <li>
          <b>createdAt</b> - A datetime stamp of when this attendee registered or is imported
        </li>
        <li>
          <b>updatedAt</b> - A datetime stamp of when this attendee had any user value adjusted
          (interest, custom field, role) or was logged in with a magic link
        </li>
        <li>
          <b>acceptedAt</b> - A datetime stamp of when this attendee was accepted by an admin or
          auto approved
        </li>
        <li>
          <b>privacyPolicyAcceptedAt</b> - A datetime stamp of when this attendee had accepted the
          privacy policy
        </li>
        <li>
          <b>lastActivityAt</b> - A datetime stamp of when this attendee last moved within the
          platform and virtual venue
        </li>
        <li>
          <b>lastLoginAt</b> - A datetime stamp of when this attendee last logged in with a magic
          link
        </li>
        <li>
          <b>eventId</b> - An identifier of the parent event
        </li>
        <li>
          <b>magicLink</b> - An URL to login with this attendee
        </li>
        <li>
          <b>Custom</b> fields (multiple) - The values from the set custom fields
        </li>
        <li>
          <b>interests</b> - The interests from the attendee
        </li>
      </ul>
      <Button onClick={() => action()} type='primary'>
        Download CSV
      </Button>
    </Modal>
  );
}
