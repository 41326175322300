import { Card, Col, List, Row, Tooltip } from 'antd';
import { ArrowUpOutlined, InfoCircleOutlined, TeamOutlined } from '@ant-design/icons';

import { getTimeString } from '@utils/getTimeString';

export enum LargeCardViewType {
  DynamicList = 'dynamic-list',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  List = 'list',
  Default = 'default',
}

interface Item {
  title: string;
  attendees?: number;
  percentage?: number;
  attended?: number;
  percentageAttended?: number;
  isTrend?: boolean;
  icon?: string;
  uniqueViewers?: number;
  totalViewers?: number;
  avgPlaytime?: number;
  totalPlaytime?: number;
  duration?: number;
}

export default function LargeCard({
  title,
  subtitle,
  icon,
  body,
  reverseBody,
  viewType = LargeCardViewType.Default,
  data,
  columns,
  columnSizes = [14, 6, 4],
}: {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  icon: React.ReactNode;
  body?: React.ReactNode;
  reverseBody?: boolean;
  viewType?: LargeCardViewType;
  columns?: {
    key: string;
    title?: string;
    type?:
      | 'session'
      | 'percentage'
      | 'attendees'
      | 'attended'
      | 'percentageAttended'
      | 'visits'
      | 'uniqueViewers'
      | 'totalViewers'
      | 'avgPlaytime'
      | 'totalPlaytime'
      | 'duration';
    tooltip?: string;
  }[];
  columnSizes?: number[];
  data?: Item[];
}) {
  const renderSession = (hasIcon: boolean, index: number, item: Item) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={(data?.length ?? 0) > 9 ? { width: '18px' } : {}}>{index + 1}.</span>
        <div style={{ marginLeft: '1rem' }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {hasIcon ? (
            item.icon ? (
              <img
                src={item.icon}
                alt='session icon'
                style={{
                  width: '3rem',
                  height: '3rem',
                  objectFit: 'cover',
                  borderRadius: '0.6rem',
                  backgroundColor: '#fefefe',
                  border: '1px solid #eee',
                }}
              />
            ) : (
              <div
                style={{
                  width: '3rem',
                  height: '3rem',
                  borderRadius: '0.6rem',
                  backgroundColor: '#fefefe',
                  border: '1px solid #eee',
                }}
              />
            )
          ) : null}
        </div>
        <div style={{ marginLeft: hasIcon ? '1rem' : 'unset' }}>{item.title}</div>
      </div>
    );
  };

  const renderPercentage = (percentage?: number, isTrend?: boolean | undefined) => {
    if (isTrend) {
      return (
        <span
          style={{
            background: 'lightgreen',
            padding: '10px',
            borderRadius: '20px',
            fontSize: '0.6rem',
          }}
        >
          <ArrowUpOutlined />
          {percentage || 0}%
        </span>
      );
    }
    return <span>{percentage || 0}%</span>;
  };

  const renderAttendees = (attendees?: number, isTrend?: boolean | undefined) => {
    return (
      <>
        {isTrend ? <TeamOutlined /> : null} {attendees || 0} attendee{attendees === 1 ? '' : 's'}
      </>
    );
  };

  const renderVisits = (visits?: number, isTrend?: boolean | undefined) => {
    return (
      <>
        {isTrend ? <TeamOutlined /> : null} {visits || 0} visit{visits === 1 ? '' : 's'}
      </>
    );
  };

  const renderNumber = (value?: number, isTrend?: boolean | undefined) => {
    return (
      <>
        {isTrend ? <TeamOutlined /> : null} {Math.round(value || 0)}
      </>
    );
  };

  const renderTime = (value?: number, isTrend?: boolean | undefined) => {
    return (
      <>
        {isTrend ? <TeamOutlined /> : null} {getTimeString(value)}
      </>
    );
  };

  const renderContent = () => {
    if (data) {
      const hasIcon = data.reduce((result, item) => (result ? true : !!item.icon), false);
      if (viewType === LargeCardViewType.DynamicList) {
        const dynamicList = (
          <List
            itemLayout='vertical'
            split={false}
            dataSource={data}
            header={
              (columns?.length ?? 0) > 0 && data?.length ? (
                <Row
                  style={{
                    justifyContent: 'space-between',
                    height: hasIcon ? '3rem' : undefined,
                    fontWeight: 'bold',
                  }}
                >
                  {columns!.map((col, index) => (
                    <Col span={columnSizes?.[index] ?? 1} key={col.key}>
                      {col.tooltip ? (
                        <Tooltip title={col.tooltip} key={col.key}>
                          {col?.title ?? col.key}
                          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                        </Tooltip>
                      ) : (
                        col?.title ?? col.key
                      )}
                    </Col>
                  ))}
                </Row>
              ) : null
            }
            renderItem={(item, index) => (
              <List.Item>
                <Row
                  style={{
                    justifyContent: 'space-between',
                    height: hasIcon ? '3rem' : undefined,
                  }}
                >
                  {columns!.map((col, colIndex) => (
                    <Col span={columnSizes?.[colIndex] ?? 1} key={col.key}>
                      {col.type === 'session' && renderSession(hasIcon, index, item)}
                      {col.type === 'percentage' && renderPercentage(item.percentage, item.isTrend)}
                      {col.type === 'attendees' && renderAttendees(item.attendees, item.isTrend)}
                      {col.type === 'visits' && renderVisits(item.attendees, item.isTrend)}
                      {col.type === 'uniqueViewers' &&
                        renderNumber(item.uniqueViewers, item.isTrend)}
                      {col.type === 'totalViewers' && renderNumber(item.totalViewers, item.isTrend)}
                      {col.type === 'avgPlaytime' && renderTime(item.avgPlaytime, item.isTrend)}
                      {col.type === 'totalPlaytime' && renderTime(item.totalPlaytime, item.isTrend)}
                      {col.type === 'duration' &&
                        (item.duration === null ? '—' : renderTime(item.duration, item.isTrend))}
                      {col.type === 'attended' && renderAttendees(item.attended ?? 0, item.isTrend)}
                      {col.type === 'percentageAttended' &&
                        renderPercentage(item.percentageAttended ?? 0, item.isTrend)}
                    </Col>
                  ))}
                </Row>
              </List.Item>
            )}
          />
        );

        return body ? (
          <Row>
            <Col span={12} style={{ paddingRight: '20px', borderRight: '1px solid #f0f0f0' }}>
              {reverseBody ? dynamicList : body}
            </Col>
            <Col span={12} style={{ paddingLeft: '20px' }}>
              {reverseBody ? body : dynamicList}
            </Col>
          </Row>
        ) : (
          dynamicList
        );
      }
      if (viewType === LargeCardViewType.List) {
        const list = (
          <List
            itemLayout='vertical'
            split={false}
            dataSource={data}
            header={
              columns?.length === 3 && data?.length ? (
                <Row
                  style={{
                    justifyContent: 'space-between',
                    height: hasIcon ? '3rem' : undefined,
                    fontWeight: 'bold',
                  }}
                >
                  {columns.map((col, index) => (
                    <Col span={columnSizes[index]} key={col.key}>
                      {col?.title ?? col.key}
                    </Col>
                  ))}
                </Row>
              ) : null
            }
            renderItem={(item, index) => (
              <List.Item>
                <Row
                  style={{
                    justifyContent: 'space-between',
                    height: hasIcon ? '3rem' : undefined,
                  }}
                >
                  <Col span={14}>{renderSession(hasIcon, index, item)}</Col>
                  <Col span={6}>{renderPercentage(item.percentage, item.isTrend)}</Col>
                  <Col span={4}>{renderAttendees(item.attendees, item.isTrend)}</Col>
                </Row>
              </List.Item>
            )}
          />
        );

        return body ? (
          <Row>
            <Col span={12}>{reverseBody ? list : body}</Col>
            <Col span={12}>{reverseBody ? body : list}</Col>
          </Row>
        ) : (
          list
        );
      }
    }
    return body;
  };

  return (
    <Card
      title={
        <Row style={{ alignItems: 'center' }}>
          <div
            style={{
              height: '2.5rem',
              width: '2.5rem',
              backgroundColor: '#EEE8FF',
              display: 'grid',
              placeItems: 'center',
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#1d39c4',
              borderRadius: '20%',
              aspectRatio: '1 / 1',
            }}
          >
            {icon}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: '1.2',
              marginLeft: '1.5rem',
              flexGrow: 1,
            }}
          >
            <div style={{ fontWeight: 'bold', fontSize: '1.6rem' }}>{title}</div>
            {subtitle && (
              <div style={{ fontWeight: 'bold', fontSize: '0.9rem', color: 'grey' }}>
                {subtitle}
              </div>
            )}
          </div>
        </Row>
      }
    >
      {renderContent()}
    </Card>
  );
}
