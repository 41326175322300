import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Menu } from 'antd';
import {
  AppstoreOutlined,
  MailOutlined,
  ContactsOutlined,
  InfoCircleOutlined,
  UserOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  BarChartOutlined,
  TagOutlined,
  ScheduleOutlined,
  NotificationOutlined,
  BankOutlined,
  SettingOutlined,
  LockOutlined,
  SoundOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

import useAuth from '@hooks/auth/useAuth';
import { PageTemplate } from '@transforms/page';
import { useCurrentEvent, useEventTemplate } from '@hooks/useEvents';

export default function Sidebar() {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { data: event, isLoading } = useCurrentEvent();
  const { isEvent } = useEventTemplate();
  const { isSuperAdmin, organisation } = useAuth();

  const getOpenKeys = useCallback(() => {
    let newOpenKeys: string[] = [];
    switch (true) {
      case location.pathname.includes('settings'):
        newOpenKeys = ['settings'];
        break;
      case location.pathname.includes('pages'):
        newOpenKeys = ['pages'];
        break;
      case location.pathname.includes('messages'):
        newOpenKeys = ['messages'];
        break;
      case location.pathname.includes('roles'):
        newOpenKeys = ['roles'];
        break;
      case location.pathname.includes('custom-fields'):
        newOpenKeys = ['custom-fields'];
        break;
      case location.pathname.includes('tags'):
        newOpenKeys = ['tags'];
        break;
      case location.pathname.includes('info'):
        newOpenKeys = ['info'];
        break;
      case location.pathname.includes('theme'):
        newOpenKeys = ['theme'];
        break;
      case location.pathname.includes('attendees'):
        newOpenKeys = ['attendees'];
        break;
      case location.pathname.includes('emails'):
        newOpenKeys = ['emails'];
        break;
      case location.pathname.includes('speakers'):
        newOpenKeys = ['speakers'];
        break;
      case location.pathname.includes('sessions'):
        newOpenKeys = ['sessions'];
        break;
      case location.pathname.includes('stages'):
        newOpenKeys = ['stages'];
        break;
      case location.pathname.includes('controlpanel'):
        newOpenKeys = ['controlpanel'];
        break;
      case location.pathname.includes('analytics'):
        newOpenKeys = ['analytics'];
        break;
      default:
        break;
    }
    return [...openKeys, ...newOpenKeys];
  }, [openKeys, location.pathname]);

  useEffect(() => {
    const newOpenKeys = getOpenKeys();
    if (newOpenKeys.length) setSelectedKeys([location.pathname]);
  }, [getOpenKeys, location.pathname]);

  const onClickSubMenu = ({ key }: { key: string }) =>
    openKeys.includes(key)
      ? setOpenKeys((prevState) => prevState.filter((item) => item !== key))
      : setOpenKeys((prevState) => [...prevState, key]);

  if (isLoading || !event) return <Menu theme='dark' mode='inline' />;

  return (
    <Menu theme='dark' mode='inline' openKeys={getOpenKeys()} selectedKeys={selectedKeys}>
      {(isSuperAdmin || organisation) && (
        <>
          <Menu.SubMenu
            title='Event Settings'
            key='settings'
            icon={<SettingOutlined />}
            onTitleClick={onClickSubMenu}
          >
            <Menu.Item icon={<ControlOutlined />} key={`/events/${event.id}/settings/preferences`}>
              <Link to={`/events/${event.id}/settings/preferences`}>Preferences</Link>
            </Menu.Item>
            <Menu.Item icon={<LockOutlined />} key={`/events/${event.id}/settings/access`}>
              <Link to={`/events/${event.id}/settings/access`}>Access</Link>
            </Menu.Item>
            <Menu.Item icon={<UserOutlined />} key={`/events/${event.id}/settings/editors`}>
              <Link to={`/events/${event.id}/settings/editors`}>Admins</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </>
      )}
      {(isSuperAdmin || organisation) && (
        <>
          <Menu.Item key={`/events/${event.id}/roles`} icon={<ContactsOutlined />}>
            <Link to={`/events/${event.id}/roles`}>User Roles</Link>
          </Menu.Item>
          <Menu.Item key={`/events/${event.id}/custom-fields`} icon={<InfoCircleOutlined />}>
            <Link to={`/events/${event.id}/custom-fields`}>Custom Fields</Link>
          </Menu.Item>
          <Menu.Item key={`/events/${event.id}/tags`} icon={<TagOutlined />}>
            <Link to={`/events/${event.id}/tags`}>Interest Tags</Link>
          </Menu.Item>
          <Divider style={{ backgroundColor: '#FFFFFF33' }} />
        </>
      )}
      <Menu.Item key={`/events/${event.id}/info`} icon={<InfoCircleOutlined />}>
        <Link to={`/events/${event.id}/info`}>General</Link>
      </Menu.Item>
      <Menu.Item key={`/events/${event.id}/theme`} icon={<AppstoreOutlined />}>
        <Link to={`/events/${event.id}/theme`}>Theme</Link>
      </Menu.Item>
      <Menu.SubMenu
        title='Pages'
        key='pages'
        icon={<UnorderedListOutlined />}
        onTitleClick={onClickSubMenu}
      >
        <Menu.Item key={`/events/${event.id}/pages/all`} icon={<UnorderedListOutlined />}>
          <Link to={`/events/${event.id}/pages/all`}>All</Link>
        </Menu.Item>
        <Menu.Item key={`/events/${event.id}/pages/initial`} icon={<UnorderedListOutlined />}>
          <Link to={`/events/${event.id}/pages/initial`}>Initial</Link>
        </Menu.Item>
      </Menu.SubMenu>

      {isEvent && (
        <Menu.Item key={`/events/${event.id}/attendees`} icon={<TeamOutlined />}>
          <Link to={`/events/${event.id}/attendees`}>Attendees</Link>
        </Menu.Item>
      )}

      <Menu.Item key={`/events/${event.id}/emails`} icon={<MailOutlined />}>
        <Link to={`/events/${event.id}/emails`}>Emails</Link>
      </Menu.Item>

      <Divider style={{ backgroundColor: '#FFFFFF33' }} />

      <Menu.SubMenu
        title='Custom Messages'
        key='messages'
        icon={<MailOutlined />}
        onTitleClick={onClickSubMenu}
      >
        <Menu.Item key={`/events/${event.id}/messages/host-announcements`} icon={<SoundOutlined />}>
          <Link to={`/events/${event.id}/messages/host-announcements`}>Host Announcements</Link>
        </Menu.Item>
        <Menu.Item
          key={`/events/${event.id}/messages/registration-complete`}
          icon={<SolutionOutlined />}
        >
          <Link to={`/events/${event.id}/messages/registration-complete`}>
            Registration Complete
          </Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Divider key='d2' style={{ backgroundColor: '#FFFFFF33' }} />

      {(!event.organisation ||
        (event.organisation &&
          event.organisation.enabledPageTemplates?.includes(PageTemplate.SCHEDULE))) && (
        <>
          <Menu.Item key={`/events/${event.id}/speakers`} icon={<NotificationOutlined />}>
            <Link to={`/events/${event.id}/speakers`}>Speakers</Link>
          </Menu.Item>
          <Menu.Item key={`/events/${event.id}/sessions`} icon={<ScheduleOutlined />}>
            <Link to={`/events/${event.id}/sessions`}>Sessions</Link>
          </Menu.Item>
          <Menu.Item key={`/events/${event.id}/stages`} icon={<BankOutlined />}>
            <Link to={`/events/${event.id}/stages`}>Stages</Link>
          </Menu.Item>
          <Divider key='d3' style={{ backgroundColor: '#FFFFFF33' }} />
        </>
      )}

      <Menu.Item key={`/events/${event.id}/controlpanel`} icon={<ControlOutlined />}>
        <Link to={`/events/${event.id}/controlpanel`}>Control Panel</Link>
      </Menu.Item>

      {isEvent && (
        <Menu.Item key={`/events/${event.id}/analytics`} icon={<BarChartOutlined />}>
          <Link to={`/events/${event.id}/analytics`}>Analytics</Link>
        </Menu.Item>
      )}
    </Menu>
  );
}
