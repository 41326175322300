import React, { useState, useMemo, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import LineChart from 'react-linechart';

interface InfoCardValue {
  average: number;
  total: number;
  points: {
    date: string;
    data: number;
  }[];
}

const getFontSize = (value: string) => {
  for (let a = 0; a < value.length; a += 1) {
    if (value.length > 12 - a) return 0.6 + a * 0.1;
  }
  return 1.7;
};

const makeValueADuration = (val: number | string) => {
  if (typeof val === 'string') return val;

  const start = dayjs().startOf('hour');
  const total = start.clone().add(val, 'minutes');
  const hours = total.diff(start, 'hours');
  const minutes = total.get('minutes');
  const seconds = total.get('seconds');

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export function InfoCard({
  value,
  title,
  tooltip,
  icon,
  isDuration,
  noGraph,
  isAverage,
  inSession,
  suffix,
  percentage,
  averagePercentage,
}: {
  value?: InfoCardValue;
  title: string;
  tooltip?: string;
  icon: React.ReactNode;
  isDuration?: boolean;
  noGraph?: boolean;
  inSession?: boolean;
  isAverage?: boolean;
  suffix?: string;
  percentage?: number;
  averagePercentage?: boolean;
}) {
  // eslint-disable-next-line no-param-reassign
  if (!isAverage) averagePercentage = false;

  const [trendUp, setTrendUp] = useState(true);
  const [highestNumber, setHighestNumber] = useState(0);

  const data = useMemo(() => {
    if (value && value.points && value.points.length > 0) {
      return [
        {
          color: trendUp ? 'green' : 'red',
          points: value.points.map((point) => ({
            x: dayjs(point.date).format('YYYY-MM-DD'),
            y: point.data,
          })),
        },
      ];
    }
    return [];
  }, [trendUp, value]);

  useEffect(() => {
    if (data && data.length > 0) {
      const lastPoint = data[0].points[data[0].points.length - 1];

      const secondLastPoint = data[0].points[0];

      if (lastPoint.y > secondLastPoint.y) {
        setTrendUp(true);
      } else {
        setTrendUp(false);
      }

      const highest = data[0].points.reduce((acc, point) => {
        if (point.y > acc) {
          return point.y;
        }
        return acc;
      }, 0);
      setHighestNumber(highest);
    }
  }, [data]);

  if (value === undefined || value === null || (data.length === 0 && !noGraph)) return null;

  const renderValue = () => {
    if (isAverage) {
      if (isDuration) return value.average;
      return Math.round(value.average * 100) / 100 || 0;
    }
    if (inSession) {
      return (
        value.points[value.points.length - 1].data - value.points[value.points.length - 2].data
      );
    }
    if (noGraph) {
      return value.toString() || 0;
    }
    return Math.round(value.total * 100) / 100 || 0;
  };

  const parsedValue = `${isDuration ? makeValueADuration(renderValue()) : renderValue()}${
    suffix?.length ? ` ${suffix}` : ''
  }`;

  return (
    <Card
      style={{ width: '350px' }}
      bodyStyle={{
        maxHeight: '95px',
      }}
      size='small'
    >
      <Row gutter={24} align='middle'>
        <Col span={5}>
          <div
            style={{
              height: '60px',
              width: '60px',
              backgroundColor: '#EEE8FF',
              display: 'grid',
              placeItems: 'center',
              fontSize: '1.6rem',
              fontWeight: 'bold',
              color: '#1d39c4',
              borderRadius: '20%',
              aspectRatio: '1 / 1',
            }}
          >
            {icon}
          </div>
        </Col>
        <Col span={19}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.45)',
            }}
          >
            {title}
            {!!tooltip && (
              <Tooltip title={tooltip} key={title}>
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: !noGraph && averagePercentage ? 'flex-start' : 'center',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: `${getFontSize(parsedValue)}rem`,
                  lineHeight: `${getFontSize(parsedValue) + 0.2}rem`,
                }}
              >
                {parsedValue}
              </div>
              {!noGraph && averagePercentage && (
                <span
                  style={{
                    fontSize: '0.9rem',
                    maxHeight: '22px',
                    color: '#999',
                  }}
                >
                  {Math.round(Math.min(percentage ?? value.average, 1) * 10_000) / 100}%
                </span>
              )}
            </div>
            {!noGraph && (
              <div
                className='line-chart'
                style={{
                  marginLeft: '8px',
                  marginTop: '5px',
                  marginBottom: '-5px',
                }}
              >
                <LineChart
                  data={data}
                  width='150'
                  height='30'
                  hideXLabel
                  hideYLabel
                  hideXAxis
                  hideYAxis
                  yMax={highestNumber}
                  yMin={0}
                  isDate
                  hidePoints
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
