import { useQuery } from 'react-query';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import useAuth from '@hooks/auth/useAuth';
import transformCategory from '@transforms/pageItemCategory';

export const usePageItemCategories = (eventId?: string, pageId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['pageItemCategories', eventId, pageId, language], async () => {
    if (!eventId) throw new Error('No event');
    if (!pageId) throw new Error('No page');

    const { body } = await fetch(
      languageUrl(`/events/${eventId}/pages/${pageId}/categories`, language),
      {
        token: accessToken,
      },
    );

    return transformCategory.many(body);
  });
};
