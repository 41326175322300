import React from 'react';
import { Col, Divider, List, Row } from 'antd';
import { StarOutlined } from '@ant-design/icons';

import { ReactBubbleChart } from '@components/react-bubble-chart';

import LargeCard, { LargeCardViewType } from './LargeCard';

export function AnalyticsPlatform({
  platforms,
  virtualVenues,
}: {
  platforms: any;
  virtualVenues: any;
}) {
  const dataPlatform = platforms &&
    platforms &&
    platforms.length > 0 && [
      ...platforms?.map((platform: any, index: number) => {
        return {
          _id: platform.id,
          value: platform.visitCount,
          colorValue: -0.3 + index / 10,
          displayText: platform.title,
        };
      }),
    ];
  const dataVirtualVenue = virtualVenues &&
    virtualVenues.length > 0 && [
      ...virtualVenues?.map((virtualVenue: any, index: number) => {
        return {
          _id: virtualVenue.id,
          value: virtualVenue.visitCount,
          colorValue: -0.3 + index / 10,
          displayText: virtualVenue.title,
        };
      }),
    ];

  const colorLegend = [
    { color: '#67000d', textColor: '#fee0d2' },
    { color: '#a50f15', textColor: '#fee0d2' },
    { color: '#cb181d', textColor: '#fee0d2' },
    '#ef3b2c',
    '#fb6a4a',
    '#fc9272',
    '#fcbba1',
    '#fee0d2',
    { color: '#f0f0f0' },
    '#deebf7',
    '#c6dbef',
    '#9ecae1',
    '#6baed6',
    '#4292c6',
    { color: '#2171b5', textColor: '#deebf7' },
    { color: '#08519c', textColor: '#deebf7' },
    { color: '#08306b', textColor: '#deebf7' },
  ];

  const tooltipProps = [
    {
      css: 'displayText',
      prop: 'displayText',
      display: 'Page',
    },
    {
      css: 'value',
      prop: 'value',
      display: 'Visit Count',
    },
  ];
  const tooltipProps2 = [
    {
      css: 'displayText',
      prop: 'displayText',
      display: 'Venue',
    },
    {
      css: 'value',
      prop: 'value',
      display: 'Visit Count',
    },
  ];

  if (!platforms || !virtualVenues) return null;

  return (
    <>
      <h2
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          lineHeight: '1.2',
        }}
      >
        Platform
      </h2>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <LargeCard
            icon={<StarOutlined />}
            title='Platform Distribution'
            subtitle='Attendee distribution across the event platform'
            viewType={LargeCardViewType.DynamicList}
            columnSizes={[13, 6, 5]}
            columns={[
              { key: 'title', title: 'Platform Page', type: 'session' },
              {
                key: 'percentage',
                title: 'Attendance',
                type: 'percentage',
                tooltip:
                  'Percentage of all attendees on the platform that attended the page at least once',
              },
              {
                key: 'visitCount',
                title: 'Visit Count',
                type: 'visits',
                tooltip: 'Amount of times the page has been visited by an attendee',
              },
            ]}
            data={platforms.map((platform: any) => ({
              title: platform.title,
              attendees: platform.visitCount,
              percentage: Math.round(platform.amountOfUsers * 100) / 100,
            }))}
            body={
              dataPlatform ? (
                <ReactBubbleChart
                  colorLegend={colorLegend}
                  className='my-cool-chart'
                  data={dataPlatform}
                  legendSpacing={0}
                  legend={false}
                  tooltipProps={tooltipProps}
                  fixedDomain={{ min: -1, max: 1 }}
                  tooltip
                />
              ) : (
                // Renders "No Data" view
                <List />
              )
            }
          />
        </Col>
        <Col span={24}>
          <LargeCard
            icon={<StarOutlined />}
            title='Virtual Venue Distribution'
            subtitle='Attendee distribution within the virtual venue'
            viewType={LargeCardViewType.DynamicList}
            columnSizes={[13, 6, 5]}
            columns={[
              { key: 'title', title: 'Venue', type: 'session' },
              {
                key: 'percentage',
                title: 'Attendance',
                type: 'percentage',
                tooltip:
                  'Percentage of all attendees on the platform that attended the virtual venue at least once',
              },
              {
                key: 'visitCount',
                title: 'Visit Count',
                type: 'visits',
                tooltip: 'Amount of times the virtual venue has been visited by an attendee',
              },
            ]}
            data={virtualVenues.map((virtualVenue: any) => ({
              title: virtualVenue.title,
              attendees: virtualVenue.visitCount,
              percentage: Math.round(virtualVenue.amountOfUsers * 100) / 100,
            }))}
            reverseBody
            body={
              dataVirtualVenue ? (
                <ReactBubbleChart
                  colorLegend={colorLegend}
                  className='my-cool-chart'
                  data={dataVirtualVenue}
                  onClick={() => {}}
                  legendSpacing={0}
                  legend={false}
                  tooltipProps={tooltipProps2}
                  tooltip
                  fixedDomain={{ min: -1, max: 1 }}
                />
              ) : (
                // Renders "No Data" view
                <List />
              )
            }
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
}
