import { useQuery, useMutation, useQueryClient } from 'react-query';

import fetch from '@utils/fetch';
import { languageUrl } from '@utils/languageUrl';
import useAuth from '@hooks/auth/useAuth';
import transform, { Mail } from '@transforms/mail';

import { Language } from './useTranslations';

export const useMail = (eventId?: string, mailId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['mail', eventId, mailId], async () => {
    if (!eventId) throw new Error('No event');
    if (!mailId) throw new Error('No mail');

    const { body } = await fetch(`/events/${eventId}/mails/${mailId}`, { token: accessToken });

    return transform.one(body);
  });
};

export const useMails = (eventId?: string, language?: string) => {
  const { accessToken } = useAuth();

  return useQuery(
    ['mails', eventId, language],
    async () => {
      if (!eventId) throw new Error('No event');

      const { body } = await fetch(languageUrl(`/events/${eventId}/mails`, language), {
        token: accessToken,
      });

      return transform.many(body);
    },
    { enabled: !!eventId },
  );
};

export const useHandlebars = (eventId?: string) => {
  const { accessToken } = useAuth();

  return useQuery(['mail', eventId], async () => {
    if (!eventId) throw new Error('No event');

    const { body } = await fetch(`/events/${eventId}/mails/handlebars`, { token: accessToken });

    return body;
  });
};

export const useCreateMail = (eventId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (
      values: Omit<Mail, 'layout'> & {
        layout?: Omit<Mail['layout'], 'imageUrl'> & { image: File | string | null };
      },
    ) => {
      if (!eventId) throw new Error('No event');

      const body = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (typeof value === 'object') return;
        if (value === undefined) return;
        body.append(key, value);
      });

      if (values.layout) {
        if (values.layout.image instanceof File) {
          body.append('image', values.layout.image);
          // eslint-disable-next-line no-param-reassign
          delete (values.layout as any as { image?: File | string | null }).image;
        }
        body.append('layout', JSON.stringify(values.layout));
      }

      if (values.properties) {
        body.append('properties', JSON.stringify(values.properties));
      }

      return fetch(languageUrl(`/events/${eventId}/mails`, language), {
        method: 'POST',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mails');
      },
    },
  );
};

export const useUpdateMail = (eventId?: string, mailId?: string, language?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (
      values: Omit<Mail, 'layout'> & {
        layout?: Omit<Mail['layout'], 'imageUrl'> & { image: File | string | null };
      },
    ) => {
      if (!eventId) throw new Error('No event');

      const body = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (typeof value === 'object') return;
        body.append(key, value);
      });

      if (values.layout) {
        if (values.layout.image instanceof File) {
          body.append('image', values.layout.image);
          // eslint-disable-next-line no-param-reassign
          delete (values.layout as any as { image?: File | string | null }).image;
        }
        body.append('layout', JSON.stringify(values.layout));
      }

      if (values.properties) {
        body.append('properties', JSON.stringify(values.properties));
      }

      return fetch(languageUrl(`/events/${eventId}/mails/${mailId}`, language), {
        method: 'PUT',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mails');
      },
    },
  );
};

export const useDeleteMail = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    (mailId: string) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/mails/${mailId}`, { method: 'DELETE', token: accessToken });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mails');
      },
    },
  );
};

export const useTestMail = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({
      values,
      recipient,
      selectedLanguage,
    }: {
      values: Omit<Mail, 'layout'> & {
        layout?: Omit<Mail['layout'], 'imageUrl'> & { image?: File | null };
      };
      recipient: string;
      selectedLanguage: Language;
    }) => {
      if (!eventId) throw new Error('No event');

      const body = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (typeof value === 'object') return;
        body.append(key, value);
      });

      if (values.layout) {
        const { image, ...restLayout } = values.layout;
        if (image) body.append('image', image);
        body.append('layout', JSON.stringify(restLayout));
      }

      if (values.properties) {
        body.append('properties', JSON.stringify(values.properties));
      }

      body.append('recipient', recipient);
      body.append('selectedLanguage', selectedLanguage);

      return fetch(`/events/${eventId}/mails/test`, {
        method: 'POST',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mails');
      },
    },
  );
};

export const useMailNow = (eventId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken } = useAuth();

  return useMutation(
    ({ id, ...body }: { id: string; time?: string }) => {
      if (!eventId) throw new Error('No event');

      return fetch(`/events/${eventId}/mails/now/${id}`, {
        method: 'POST',
        body,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mails');
      },
    },
  );
};

export default {
  useMail,
  useMails,
  useCreateMail,
  useUpdateMail,
  useDeleteMail,
  useHandlebars,
  useTestMail,
  useMailNow,
};
