import { CSSProperties, useCallback, useState } from 'react';
import { Input, Form, Col, Row } from 'antd';

import { EventResource } from '@transforms/event';

function sanitize(hex?: string | null) {
  if (!hex) return null;

  // NOTE: this makes sure inputs like `red` or `rgb(...)` won't work
  return `#${hex.replace('#', '')}`;
}

function hexToRgb(hex?: string) {
  if (!hex) return null;

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function luminance(red: number, green: number, blue: number) {
  const result = [red, green, blue].map((color) => {
    const calculated = color / 255;
    return calculated <= 0.03928 ? calculated / 12.92 : ((calculated + 0.055) / 1.055) ** 2.4;
  });
  return result[0] * 0.2126 + result[1] * 0.7152 + result[2] * 0.0722;
}

function contrast(rgb1: number[], rgb2: number[]) {
  const luminance1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const luminance2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function Colors({ event }: { event: EventResource }) {
  const [primaryColor, setPrimaryColor] = useState(sanitize(event.themePrimaryColor));
  const [secondaryColor, setSecondaryColor] = useState(sanitize(event.themeSecondaryColor));
  const [backgroundColor, setBackgroundColor] = useState(sanitize(event.themeBackgroundColor));
  const [normalColor, setNormalColor] = useState(sanitize(event.themeNormalColor ?? '#FFFFFF'));

  const contrastFor = useCallback(
    (name: string, color: string, bgColor?: string) => {
      const background = hexToRgb(
        (bgColor?.length ?? 0) > 0 ? bgColor : backgroundColor || undefined,
      );
      const foreground = hexToRgb(color);

      const contrastNumber =
        !background || !foreground
          ? 1
          : contrast(
              [background.r, background.g, background.b],
              [foreground.r, foreground.g, foreground.b],
            );

      return (
        <div>
          <b>{name}</b>
          {color.length < 7 ? (
            <h2
              style={{
                color: 'red',
                fontSize: 18,
                marginTop: '10px',
                borderRadius: '2px',
              }}
            >
              Invalid HEX code
            </h2>
          ) : (
            <h2
              style={{
                minWidth: 50,
                padding: '2px 4px',
                color: '#fff',
                fontSize: 18,
                marginTop: '8px',
                borderRadius: '2px',
                textAlign: 'center',
                width: 'fit-content',
                backgroundColor: contrastNumber > 3.5 ? 'green' : 'red',
              }}
            >
              {Math.round(contrastNumber)}
            </h2>
          )}
        </div>
      );
    },
    [backgroundColor],
  );

  const colorPreview = (
    <div
      style={{
        minWidth: 330,
        marginBottom: 20,
        borderRadius: 10,
        padding: '10px 20px',
        backgroundColor: (!backgroundColor || backgroundColor?.length < 7
          ? 'transparent'
          : backgroundColor) as CSSProperties['color'],
      }}
    >
      <h1
        style={{
          fontSize: 30,
          color: (!primaryColor || primaryColor?.length < 7
            ? 'transparent'
            : primaryColor) as CSSProperties['color'],
          margin: 0,
        }}
      >
        Primary Color
      </h1>
      <h3
        style={{
          color: (!secondaryColor || secondaryColor?.length < 7
            ? 'transparent'
            : secondaryColor) as CSSProperties['color'],
        }}
      >
        Secondary Color
      </h3>
      <p
        style={{
          color: (!normalColor || normalColor?.length < 7
            ? 'transparent'
            : normalColor) as CSSProperties['color'],
          marginBottom: 8,
        }}
      >
        Normal text
      </p>
    </div>
  );

  const backgroundColorInput = (
    <Form.Item name='themeBackgroundColor' style={{ fontWeight: 'bold' }} label='Background Color'>
      <Input
        prefix='#'
        minLength={6}
        maxLength={6}
        placeholder='000000'
        className='capitalize'
        onChange={({ target }) => setBackgroundColor(sanitize(target.value))}
      />
    </Form.Item>
  );

  const primaryColorInput = (
    <Form.Item name='themePrimaryColor' style={{ fontWeight: 'bold' }} label='Primary Color'>
      <Input
        prefix='#'
        minLength={6}
        maxLength={6}
        placeholder='EF44FE'
        className='capitalize'
        onChange={({ target }) => setPrimaryColor(sanitize(target.value))}
      />
    </Form.Item>
  );

  const secondaryColorInput = (
    <Form.Item name='themeSecondaryColor' style={{ fontWeight: 'bold' }} label='Secondary Color'>
      <Input
        prefix='#'
        minLength={6}
        maxLength={6}
        placeholder='53FE88'
        className='capitalize'
        onChange={({ target }) => setSecondaryColor(sanitize(target.value))}
      />
    </Form.Item>
  );

  const normalColorInput = (
    <Form.Item name='themeNormalColor' style={{ fontWeight: 'bold' }} label='Normal Text Color'>
      <Input
        prefix='#'
        minLength={6}
        maxLength={6}
        placeholder='FFFFFF'
        className='capitalize'
        onChange={({ target }) => setNormalColor(sanitize(target.value))}
      />
    </Form.Item>
  );

  return (
    <>
      {colorPreview}

      <Row>
        <Col>
          <p style={{ marginBottom: 0, fontStyle: 'italic' }}>
            Set the color palette for this event. Note, all colors must be supplied in HEX code (ex.
            #FF00FF).
          </p>
          <p style={{ marginBottom: 10, fontStyle: 'italic' }}>
            Tip: Choose a dark background in order to maintain visibility of the (white) sidebar
            icons.
          </p>
          {backgroundColorInput}
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
            {primaryColorInput}
            {!!primaryColor && contrastFor('Contrast score (to background)', primaryColor)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
            {secondaryColorInput}
            {!!secondaryColor && contrastFor('Contrast score (to background)', secondaryColor)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
            {normalColorInput}
            {!!normalColor && contrastFor('Contrast score (to background)', normalColor)}
          </div>
        </Col>
      </Row>
    </>
  );
}
